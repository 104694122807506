import {RefinanceInfoType} from "../../store/capitalBudget/selectors/refinanceSelector";
import {Box, Button, IconButton} from "@mui/material";
import {Close as CloseIcon, Launch as LaunchIcon} from "@mui/icons-material";
import {DataDisplay} from "../../components";
import {formatDate} from "../../utils/DateUtils";
import {fCurrency} from "../../utils/formatNumber";


export default function RefinanceInfo({refinance, onClose}: { refinance: RefinanceInfoType, onClose: () => void }) {

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <IconButton size='small' onClick={() => onClose()}>
                    <CloseIcon/>
                </IconButton>
            </Box>
            <Box sx={{px: 3, pt: 1, mt: -4}}>
                <DataDisplay
                    dataValues={[
                        {label: 'Borrower', data: refinance.borrower},
                        {label: 'Deal Name', data: refinance.dealName},
                        {label: 'Owner', data: refinance.owner},
                        {label: 'Proj. Close Date', data: formatDate(new Date(refinance.closeDate))},
                        {label: 'Tenor', data: Number(refinance.tenor).toFixed(2)},
                        {label: 'Refinance Commitment', data: fCurrency(refinance.commitment)},
                        {
                            label: 'Refinance Type',
                            data: refinance.incremental ? 'Incremental Increase' : 'Refinance Total Commitment'
                        },
                        {
                            label: 'Refinanced Tranches',
                            subData: [
                                ...refinance.tranches.map(tranche => {
                                    return [
                                        {label: 'Tranche Id', data: tranche.trancheId.toString()},
                                        {label: 'Tranche', data: tranche.tranche},
                                        {label: 'Commitment', data: fCurrency(tranche.commitment)},
                                    ]
                                })
                            ]
                        },
                        {label: 'Total Commitment', data: fCurrency(refinance.totalCommitment)},
                    ]}
                />
            </Box>
            <Box sx={{
                px: 5,
                pt: 5,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }}>
                <Button
                    endIcon={<LaunchIcon/>}
                    onClick={() => window.open(`https://metricscp.lightning.force.com/${refinance.ncinoId}`, '_blank')}
                >
                    Go To nCino/Salesforce
                </Button>
            </Box>
        </>
    )
}