import {Formik, FormikProps} from "formik";
import {Button, Dialog, DialogContent, DialogTitle, Grid} from "@mui/material";
import {FormInput} from "../../../components";
import {InputTypes} from "../../../types/InputTypes";


type WIPCommentEditProps = {
    wipComment: {id: string | number | null, ncinoId: string, notes: string},
    open: boolean,
    onClose: () => void,
    editValues: (values: {id: string | number, ncinoId: string, notes: string}) => void,
    addValues: (values: {ncinoId: string, notes: string}) => void
    removeValues: (id: string | number) => void
}


export const WIPCommentEdit = ({wipComment, open, onClose, editValues, addValues, removeValues}: WIPCommentEditProps) => {
    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Edit Comment</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{notes: wipComment.notes}}
                        // validationSchema={Yup.object().shape({notes: Yup.string().required('Required')})}
                        onSubmit={(values) => {
                            if (wipComment.id) {
                                if (values.notes === '') {
                                    removeValues(wipComment.id);
                                } else {
                                    editValues({id: wipComment.id, ncinoId: wipComment.ncinoId, notes: values.notes});
                                }
                            } else {
                                addValues({ncinoId: wipComment.ncinoId, notes: values.notes});
                            }
                        }}
                    >
                        {(props: FormikProps<any>) => {
                            const {
                                handleSubmit,
                                values,
                            } = props;

                            return (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <Grid container>
                                            <FormInput
                                                id='notes'
                                                label='Notes'
                                                fieldType={InputTypes.TEXT_BOX}
                                                layout={{xs: 12, md: 12, lg: 12}}
                                            />
                                            <Grid item container direction='row'>
                                                <Grid item sx={{width: '50%', p: 2}}>
                                                    <Button
                                                        fullWidth
                                                        size="large"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                {(!!wipComment.id && values.notes === '') ?
                                                    <>
                                                        <Grid item sx={{width: '50%', p: 2}}>
                                                            <Button
                                                                fullWidth
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"

                                                            >
                                                                Remove Comment
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                    :
                                                    <>
                                                        <Grid item sx={{width: '50%', p: 2}}>
                                                            <Button
                                                                fullWidth
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"

                                                            >
                                                                Save Comment
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                }

                                            </Grid>
                                        </Grid>
                                    </form>
                                </>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    )
}