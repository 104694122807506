import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import capitalReducers from "./capitalReducer";
import fundFinanceReducer from "./fundFinanceReducer";
import fumReducer from "./fumReducer";
import manualDealReducer from "./manualDealReducer";
import maturityReducer from "./maturityReducer";
import newDealsReducer from "./newDealsReducer";
import otherTransactionReducers from "./otherTransactionsReducer";
import refinanceReducer from "./refinanceReducer";
import sellRepayReducer from "./sellRepayReducer";
import transferReducer from "./transfersReducer";
import wipCommentsReducer from "./wipCommentsReducer";

// Groups together separated capital Budget reducers
const reducers: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    setFund: (state, action: PayloadAction<null | string>) => {
        if (state.forecastData) {
            console.log(action.payload)
            if (!action.payload || action.payload === 'ALL') state.misc.fund = null;
            else {
                const fund = state.forecastData.funds.find(fund => action.payload === fund.label)
                if (fund) {
                    state.misc.fund = fund;
                }
            }
        }
    },
    setWeeksAndMonths: (state, action: PayloadAction<{ weeks: number, months: number }>) => {
        state.misc.weeks = action.payload.weeks;
        state.misc.months = action.payload.months;
        // state.misc.periods = generatePeriods(state.misc.weeks, state.misc.months)
    },
    setWeeks: (state, action: PayloadAction<number>) => {
        state.misc.weeks = action.payload;
        // state.misc.periods = generatePeriods(state.misc.weeks, state.misc.months)
    },
    setMonths: (state, action: PayloadAction<number>) => {
        state.misc.months = action.payload;
        // state.misc.periods = generatePeriods(state.misc.weeks, state.misc.months)
    },
    ...capitalReducers,
    ...fundFinanceReducer,
    ...fumReducer,
    ...manualDealReducer,
    ...maturityReducer,
    ...newDealsReducer,
    ...otherTransactionReducers,
    ...refinanceReducer,
    ...sellRepayReducer,
    ...transferReducer,
    ...wipCommentsReducer
}

export default reducers;