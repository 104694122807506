import {RootState} from "../../store";
import {createSelector} from "@reduxjs/toolkit";
import {groupedPortfolioSelector, groupPortfolioByTranche} from "./portfolioSelector";
import {MaturityChanges} from "../../../types/forecastTypes";
import {Loan} from "../../../types/externalDataTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";

// Retrieves all Portfolio Changes
export const retrieveMaturityChanges = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.portfolio?.portfolio || [],
    (state: RootState) => state.capitalBudget.forecastData?.maturityChanges || [],
    (state: RootState) => state.capitalBudget.warnings?.maturity.advise || [],
    (portfolio, maturityChanges, checks) => {
        const groupedPortfolio = groupPortfolioByTranche(portfolio);
        return maturityChanges.reduce((changes: Array<any>, mc) => {
            if (mc.status === SaveStatus.REMOVED) return changes
            const loan = groupedPortfolio.find(p => p.tranche_id === mc.trancheId);

            const check = checks.find(c => c.id === mc.id);

            if (loan) {
                changes.push({
                    ...mc,
                    asset: loan.asset,
                    borrower: loan.borrower,
                    tranche: loan.tranche,
                    maturity: loan.maturity,
                    commitment: loan.commitment,
                    undrawn: loan.undrawn,
                    drawn: loan.drawn,
                    ...(check) ? {advise: check.flag} : {}
                })
            }

            return changes
        }, [])
    }
)

type LoanWithMC = Loan & {
    maturityChange: MaturityChanges | null
}
export const retrieveGroupedPortfolioWMatChanges = createSelector(
    groupedPortfolioSelector,
    (state: RootState) => state.capitalBudget.forecastData?.maturityChanges || [],
    (portfolio, maturityChanges) => {
        const portfolioMap: Map<number, LoanWithMC> = new Map(portfolio.map(p => [p.tranche_id, {...p, maturityChange: null}]));

        maturityChanges.forEach(mc => {
            if (mc.status !== SaveStatus.REMOVED && portfolioMap.has(mc.trancheId)) {
                portfolioMap.get(mc.trancheId)!.maturityChange = mc;
            }
        })

        return Array.from(portfolioMap.values()) as Array<LoanWithMC>;
    }
)