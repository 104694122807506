import {useState} from "react";
import {useAppSelector} from "../../../store/store";
import {checkInPeriod} from "../../../utils/DateUtils";
import {fCurrency} from "../../../utils/formatNumber";
import {CapitalAction, InvestmentType, LoanTags} from "../../../types/capitalBudgetEnums";

import {DrillDownSection, DrillDownValue} from "../../../components";
import {CalculationLoanType, ForecastPeriod} from "../../../types/capitalBudgetTypes";
import {forecastCapitalColumn} from "../../../store/capitalBudget/selectors/Reports/forecastCapitalReport";
// @MUI
import {Grid, IconButton, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {Capital} from "../../../types/forecastTypes";
import {KeyboardArrowDown as ArrowDownIcon, KeyboardArrowRight as ArrowRightIcon} from "@mui/icons-material";
import {fundHolderSelector} from "../../../store/capitalBudget/selectors/generalSelectors";

interface ForecastCapitalDrillDownProps {
    forecastPeriod: ForecastPeriod,
    periodForecastCapital: forecastCapitalColumn
}

export const ForecastCapitalDrillDown = ({forecastPeriod, periodForecastCapital}: ForecastCapitalDrillDownProps) => {

    const fund = useAppSelector(state => fundHolderSelector(state));

    const [openRepayments, setOpenRepayments] = useState(false);

    return (
        <>
            <Grid>
                <Table size="small">
                    <TableBody>
                        {/*FUM --------------------------------------------------------------------------------------*/}
                        <TableRow sx={{bgcolor: 'info.lighter'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    FUM
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{...(((periodForecastCapital.openingFum || 0) < 0) ? {color: 'red'} : {})}}
                                >
                                    {fCurrency(periodForecastCapital.openingFum || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {/* Bank Facilities -------------------------------------------------------------------------*/}
                        <TableRow sx={{bgcolor: 'info.lighter'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Total Bank Facilities
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{...(((periodForecastCapital.totalBankFacilities || 0) < 0) ? {color: 'red'} : {})}}
                                >
                                    {fCurrency(periodForecastCapital.totalBankFacilities || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <DrillDownSection
                            header={'External Facilities'}
                            value={periodForecastCapital.externalFacilities}
                            retrieveValues={() => []}
                            noExpand
                        />
                        <DrillDownSection
                            header={'Internal Facilities'}
                            value={periodForecastCapital.internalFacilities}
                            retrieveValues={() => []}
                            noExpand
                        />
                        {/*Commitments in Axcess --------------------------------------------------------------------*/}
                        <TableRow sx={{bgcolor: 'info.lighter'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Commitments in Axcess
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{...(((periodForecastCapital.commitments || 0) < 0) ? {color: 'red'} : {})}}
                                >
                                    {fCurrency(periodForecastCapital.commitments || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {/*Surplus / (Deficit) ----------------------------------------------------------------------*/}
                        <TableRow sx={{bgcolor: 'info.dark'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Surplus / (Deficit)
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{...(((periodForecastCapital.surplusDeficit || 0) < 0) ? {color: 'red'} : {})}}
                                >
                                    {fCurrency(periodForecastCapital.surplusDeficit || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{height: 25, border: 'none'}}>
                            <TableCell colSpan={4}>
                            </TableCell>
                        </TableRow>
                        {!forecastPeriod.base ?
                            // IF NOT BASE SHOW CALCS ------------------------------------------------------------------
                            <>
                                <TableRow sx={{bgcolor: 'primary.light'}}>
                                    <TableCell colSpan={4}>
                                        <Typography
                                            variant='h6'
                                            sx={{color: 'white'}}
                                        >
                                            Adjustments
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {/*INFLOWS -----------------------------------------------------------------------------*/}
                                <>
                                    <TableRow sx={{bgcolor: 'primary.main'}}>
                                        <TableCell colSpan={4}>
                                            <Typography
                                                variant='h6'
                                                sx={{color: 'white'}}
                                            >
                                                Inflows
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {/*Investor Subscriptions*/}
                                    <DrillDownSection
                                        header={'Investor Subscriptions'}
                                        value={periodForecastCapital.subscriptions}
                                        retrieveValues={() => {
                                            return forecastPeriod.capital.filter((c: Capital) =>
                                                c.transactionType === CapitalAction.SUBSCRIPTION).map((ct: Capital) => {
                                                return {
                                                    id: ct.id,
                                                    date: ct.date,
                                                    name: ct.name,
                                                    value: ct.amount
                                                }
                                            })
                                        }}
                                    />
                                    {/*REPAYMENTS -----------------------------------------------------------------------------*/}
                                    <>
                                        <TableRow>
                                            <TableCell
                                                sx={{width: 5, py: 0, px: 0}}
                                                align='center'
                                            >
                                                <IconButton
                                                    size='small'
                                                    onClick={() => setOpenRepayments(!openRepayments)}
                                                >
                                                    {openRepayments ? <ArrowDownIcon/> : <ArrowRightIcon/>}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{py: 0}} align='left'>Repayments</TableCell>
                                            <TableCell
                                                sx={{py: 0, ...(periodForecastCapital.repayments < 0) ? {color: 'red'} : {}}}
                                                align='right'>{fCurrency(periodForecastCapital.repayments)}</TableCell>
                                        </TableRow>
                                        {openRepayments &&
                                            <>
                                                {/* CONTRACTUAL REPAYMENTS ---------------------------------------------*/}
                                                <DrillDownSection
                                                    header={'Loan Repayments'}
                                                    value={periodForecastCapital.contractualRepayments}
                                                    retrieveValues={() => {
                                                        return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                            l.tags.includes(LoanTags.REPAYMENT)).map((l: CalculationLoanType) => {
                                                            return {
                                                                id: l.id,
                                                                date: l.endDate,
                                                                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                                value: l.value,
                                                                ...(l.amendedMaturity) ? {} : {sx: {bgcolor: 'info.light'}}
                                                            }
                                                        })
                                                    }}
                                                    sx={{borderTop: 2}}
                                                />
                                                {/* EXTENSIONS ---------------------------------------------------------*/}
                                                <DrillDownSection
                                                    header={'Extensions'}
                                                    value={periodForecastCapital.extensions}
                                                    retrieveValues={() => {
                                                        return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                            l.tags.includes(LoanTags.EXTENSION)).map((l: CalculationLoanType) => {
                                                            console.log(l)
                                                            return {
                                                                id: l.id,
                                                                date: l.amendedMaturity,
                                                                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                                value: l.value
                                                            }
                                                        })
                                                    }}
                                                />
                                                {/* EARLY REPAYMENTS ---------------------------------------------------------*/}
                                                <DrillDownSection
                                                    header={'Early Repayments'}
                                                    value={periodForecastCapital.earlyRepayments}
                                                    retrieveValues={() => {
                                                        return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                            l.tags.includes(LoanTags.EARLY_REPAYMENT)).map((l: CalculationLoanType) => {
                                                            return {
                                                                id: l.id,
                                                                date: l.amendedMaturity,
                                                                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                                value: l.value
                                                            }
                                                        })
                                                    }}
                                                />
                                                {/* SELLDOWN AND REPAYMENTS ---------------------------------------------------------*/}
                                                <DrillDownSection
                                                    header={'Selldown and Repayments'}
                                                    value={periodForecastCapital.sellRepay}
                                                    retrieveValues={() => forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                        l.tags.includes(LoanTags.SELLDOWN)).reduce((values: Array<DrillDownValue>, loan: CalculationLoanType) => {
                                                        loan.selldowns?.forEach(selldown => {
                                                            if (checkInPeriod(selldown.date, forecastPeriod)) {
                                                                let value = selldown.amount;

                                                                if (fund && fund.holdMap) {
                                                                    console.log(fund.holdMap)
                                                                    if (fund.holdMap.get(loan.fund)) {
                                                                        value = fund.holdMap.get(loan.fund) as number * value
                                                                    }
                                                                }

                                                                values.push({
                                                                    id: selldown.id,
                                                                    date: selldown.date,
                                                                    name: `${loan.trancheId} (${loan.fund}) - ${loan.name}`,
                                                                    value: value
                                                                })
                                                            }
                                                        })

                                                        return values;
                                                    }, [])}
                                                />
                                                {/* OFFSET EXTENSION ---------------------------------------------------------*/}
                                                <DrillDownSection
                                                    header={'Extension Offset at Contractual Maturity'}
                                                    value={periodForecastCapital.offsetExtension}
                                                    retrieveValues={() => {
                                                        return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                            l.tags.includes(LoanTags.OFFSET_EXTENSION)).map((l: CalculationLoanType) => {
                                                            return {
                                                                id: l.id,
                                                                date: l.endDate,
                                                                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                                value: -l.value
                                                            }
                                                        })
                                                    }}
                                                />
                                                {/* OFFSET EXTENSION ---------------------------------------------------------*/}
                                                <DrillDownSection
                                                    header={'Early Repayment Offset at Contractual Maturity'}
                                                    value={periodForecastCapital.offsetEarly}
                                                    retrieveValues={() => {
                                                        return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                            l.tags.includes(LoanTags.OFFSET_EARLY)).map((l: CalculationLoanType) => {
                                                            return {
                                                                id: l.id,
                                                                date: l.endDate,
                                                                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                                value: -l.value
                                                            }
                                                        })
                                                    }}
                                                />
                                                {/* OFFSET SELLDOWN ---------------------------------------------------------*/}
                                                <DrillDownSection
                                                    header={'Sell Downs/Partial Repayments Offset at Contractual Maturity'}
                                                    value={periodForecastCapital.offsetSellRepay}
                                                    retrieveValues={() => forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                        l.tags.includes(LoanTags.OFFSET_SELLDOWN)).reduce((values: Array<DrillDownValue>, loan: CalculationLoanType) => {
                                                        loan.selldowns?.forEach(selldown => {
                                                            let value = selldown.amount;

                                                            if (fund && fund.holdMap) {
                                                                if (fund.holdMap.get(loan.fund)) {
                                                                    value = fund.holdMap.get(loan.fund) as number * value
                                                                }
                                                            }

                                                            values.push({
                                                                id: selldown.id,
                                                                date: loan.endDate,
                                                                name: `${loan.trancheId} (${loan.fund}) - ${loan.name}`,
                                                                value: -value
                                                            })
                                                        })
                                                        return values;
                                                    }, [])}
                                                />
                                                {/* TRANSFERS ---------------------------------------------------------*/}
                                                <DrillDownSection
                                                    header={'Transfers'}
                                                    value={periodForecastCapital.transfers}
                                                    retrieveValues={() => forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                        l.tags.includes(LoanTags.TRANSFER_IN) || l.tags.includes(LoanTags.TRANSFER_OUT)
                                                    ).reduce((values: Array<DrillDownValue>, loan: CalculationLoanType) => {
                                                        loan.transfersIn?.forEach(transfer => {
                                                            if (checkInPeriod(transfer.transferDate, forecastPeriod)) {
                                                                let value = transfer.amount;

                                                                if (fund && fund.holdMap) {
                                                                    if (fund.holdMap.get(loan.fund)) {
                                                                        value = fund.holdMap.get(loan.fund) as number * value
                                                                    }
                                                                }

                                                                values.push({
                                                                    id: transfer.id,
                                                                    date: transfer.transferDate,
                                                                    name: `${loan.trancheId} (${transfer.fromFund} -> ${transfer.toFund}) - ${loan.name}`,
                                                                    value: -value
                                                                })
                                                            }
                                                        })
                                                        loan.transfersOut?.forEach(transfer => {
                                                            if (checkInPeriod(transfer.transferDate, forecastPeriod)) {
                                                                let value = transfer.amount;

                                                                if (fund && fund.holdMap) {
                                                                    if (fund.holdMap.get(loan.fund)) {
                                                                        value = fund.holdMap.get(loan.fund) as number * value
                                                                    }
                                                                }
                                                                values.push({
                                                                    id: transfer.id,
                                                                    date: transfer.transferDate,
                                                                    name: `${loan.trancheId} (${transfer.fromFund} -> ${transfer.toFund}) - ${loan.name}`,
                                                                    value: value
                                                                })
                                                            }
                                                        })
                                                        return values;
                                                    }, [])}
                                                />
                                                {/* TRANSFERS OFFSET ---------------------------------------------------------*/}
                                                <DrillDownSection
                                                    header={'Transfers Offset'}
                                                    value={periodForecastCapital.transfersOffset}
                                                    retrieveValues={() => forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                        l.tags.includes(LoanTags.OFFSET_TRANSFER_IN) || l.tags.includes(LoanTags.OFFSET_TRANSFER_OUT)
                                                    ).reduce((values: Array<DrillDownValue>, loan: CalculationLoanType) => {
                                                        loan.transfersIn?.forEach(transfer => {
                                                            let value = transfer.amount;

                                                            if (fund && fund.holdMap) {
                                                                if (fund.holdMap.get(loan.fund)) {
                                                                    value = fund.holdMap.get(loan.fund) as number * value
                                                                }
                                                            }
                                                            values.push({
                                                                id: transfer.id,
                                                                date: loan.endDate,
                                                                name: `${loan.trancheId} (${transfer.fromFund} -> ${transfer.toFund}) - ${loan.name}`,
                                                                value: value
                                                            })
                                                        })
                                                        loan.transfersOut?.forEach(transfer => {
                                                            let value = transfer.amount;

                                                            if (fund && fund.holdMap) {
                                                                if (fund.holdMap.get(loan.fund)) {
                                                                    value = fund.holdMap.get(loan.fund) as number * value
                                                                }
                                                            }
                                                            values.push({
                                                                id: transfer.id,
                                                                date: loan.endDate,
                                                                name: `${loan.trancheId} (${transfer.fromFund} -> ${transfer.toFund}) - ${loan.name}`,
                                                                value: -value
                                                            })
                                                        })
                                                        return values;
                                                    }, [])}
                                                    sx={{borderBottom: 2}}
                                                />
                                            </>
                                        }
                                    </>
                                    {/*Increased Bank Facilities*/}
                                    <DrillDownSection
                                        header={'Increased Bank Facilities'}
                                        value={periodForecastCapital.bankFacilities}
                                        retrieveValues={() => {
                                            return forecastPeriod.capital.filter((c: Capital) =>
                                                (c.transactionType === CapitalAction.COMMITMENT || c.transactionType === CapitalAction.CANCELLATION)).map((ct: Capital) => {
                                                return {
                                                    id: ct.id,
                                                    date: ct.date,
                                                    name: ct.name,
                                                    value: ct.amount
                                                }
                                            })
                                        }}
                                    />
                                </>
                                {/*OUTFLOWS ----------------------------------------------------------------------------*/}
                                <>
                                    <TableRow sx={{bgcolor: 'primary.main'}}>
                                        <TableCell colSpan={4}>
                                            <Typography
                                                variant='h6'
                                                sx={{color: 'white'}}
                                            >
                                                Outflows
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {/*Investor Redemptions*/}
                                    <DrillDownSection
                                        header={'Investor Redemptions'}
                                        value={periodForecastCapital.redemptions}
                                        retrieveValues={() => {
                                            return forecastPeriod.capital.filter((c: Capital) =>
                                                c.transactionType === CapitalAction.REDEMPTION).map((ct: Capital) => {
                                                return {
                                                    id: ct.id,
                                                    date: ct.date,
                                                    name: ct.name,
                                                    value: ct.amount
                                                }
                                            })
                                        }}
                                    />
                                    {/*New Loan Commitments*/}
                                    <DrillDownSection
                                        header={'New Loan Commitments'}
                                        value={periodForecastCapital.newCommitments}
                                        retrieveValues={() => {
                                            return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                l.tags.includes(LoanTags.NEW_LOAN)).map((l: CalculationLoanType) => {
                                                return {
                                                    id: l.id,
                                                    date: l.startDate,
                                                    name: `${l.name}`,
                                                    value: -l.value
                                                }
                                            })
                                        }}
                                    />
                                </>
                                {/*-------------------------------------------------------------------------------------*/}
                                <>
                                    {/* Adjusted Surplus / (Deficit) ---------------------------------------------------*/}
                                    <TableRow sx={{bgcolor: 'info.dark'}}>
                                        <TableCell colSpan={3}>
                                            <Typography
                                                variant='h6'
                                            >
                                                Adjusted Surplus / (Deficit)
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Typography
                                                variant='h6'
                                                sx={{...(((periodForecastCapital.adjustedSurplus || 0) < 0) ? {color: 'red'} : {})}}
                                            >
                                                {fCurrency(periodForecastCapital.adjustedSurplus || 0)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {/* Forecast CRE CTC Commitments ---------------------------------------------------*/}
                                    <DrillDownSection
                                        header={'Forecast CRE CTC Commitments'}
                                        value={periodForecastCapital.creCTCCommitments}
                                        retrieveValues={() => {
                                            return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                (l.tags.includes(LoanTags.CRE_CTC) || (l.tags.includes(LoanTags.NEW_LOAN) && l.investmentType === InvestmentType.REAL_ESTATE))).map((l: CalculationLoanType) => {
                                                return {
                                                    id: l.id,
                                                    date: null,
                                                    name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                    value: l.updatedUndrawn
                                                }
                                            })
                                        }}
                                    />
                                    {/* Forecast Undrawn Fund Finance Commitments --------------------------------------*/}
                                    <DrillDownSection
                                        header={'Forecast Undrawn Fund Finance Commitments'}
                                        value={periodForecastCapital.unusedFundFinance}
                                        retrieveValues={() => {
                                            let values: Array<DrillDownValue> = [];

                                            values = [...values, ...forecastPeriod.fundFinances.map((f) => {
                                                return {
                                                    id: f.id,
                                                    date: null,
                                                    name: f.name,
                                                    value: -f.unusedMaster
                                                }
                                            })];

                                            if (periodForecastCapital.manualFF) {
                                                values.push({
                                                    id: 'manual',
                                                    date: null,
                                                    name: '(Manual Fund Finance Adjustment)',
                                                    value: periodForecastCapital.manualFF
                                                })
                                            }

                                            return values;
                                        }}
                                        sx={{
                                            borderTop: 1
                                        }}
                                    />
                                    {/* Adjusted Closing Surplus / (Deficit) -------------------------------------------*/}
                                    <TableRow sx={{bgcolor: 'info.dark'}}>
                                        <TableCell colSpan={3}>
                                            <Typography
                                                variant='h6'
                                            >
                                                Adjusted Closing Surplus / (Deficit)
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Typography
                                                variant='h6'
                                                sx={{...(((periodForecastCapital.adjustedClosingSurplus || 0) < 0) ? {color: 'red'} : {})}}
                                            >
                                                {fCurrency(periodForecastCapital.adjustedClosingSurplus || 0)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </>
                                {/*-------------------------------------------------------------------------------------*/}
                            </>
                            :
                            // IF BASE SHOW UNDRAWNS -------------------------------------------------------------------
                            <>
                                <TableRow sx={{bgcolor: 'primary.light'}}>
                                    <TableCell colSpan={4}>
                                        <Typography
                                            variant='h6'
                                        >
                                            Funded via:
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {/*------------------------------------------------------------------------------------------*/}
                                {/*NET LIQUIDITY*/}
                                <>
                                    {/*Revolvers*/}
                                    <DrillDownSection
                                        header={'Revolvers'}
                                        value={periodForecastCapital.revolvers}
                                        retrieveValues={() => {
                                            return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                (l.tags.includes(LoanTags.ACTIVE) && l.investmentType !== InvestmentType.REAL_ESTATE && l.trancheType === 'Revolving')).map((l: CalculationLoanType) => {
                                                return {
                                                    id: l.id,
                                                    date: null,
                                                    name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                    value: -l.undrawn
                                                }
                                            })
                                        }}
                                    />
                                    {/*Capex*/}
                                    <DrillDownSection
                                        header={'Capex'}
                                        value={periodForecastCapital.capex}
                                        retrieveValues={() => {
                                            return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                (l.tags.includes(LoanTags.ACTIVE) && l.investmentType !== InvestmentType.REAL_ESTATE && l.trancheType === 'Capex')).map((l: CalculationLoanType) => {
                                                return {
                                                    id: l.id,
                                                    date: null,
                                                    name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                    value: -l.undrawn
                                                }
                                            })
                                        }}
                                    />
                                    {/*Other*/}
                                    <DrillDownSection
                                        header={'Other'}
                                        value={periodForecastCapital.other}
                                        retrieveValues={() => {
                                            return forecastPeriod.book.filter((l: CalculationLoanType) =>
                                                (l.tags.includes(LoanTags.ACTIVE) && l.investmentType !== InvestmentType.REAL_ESTATE && l.trancheType !== 'Revolving' && l.trancheType !== 'Capex')).map((l: CalculationLoanType) => {
                                                return {
                                                    id: l.id,
                                                    date: null,
                                                    name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                                                    value: -l.undrawn
                                                }
                                            })
                                        }}
                                    />
                                    {/* Total ----------------------------------------------------------------------*/}
                                    <TableRow sx={{bgcolor: 'info.dark'}}>
                                        <TableCell colSpan={3}>
                                            <Typography
                                                variant='h6'
                                            >
                                                Total
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Typography
                                                variant='h6'
                                                sx={{...(((periodForecastCapital.fundedTotal || 0) < 0) ? {color: 'red'} : {})}}
                                            >
                                                {fCurrency(periodForecastCapital.fundedTotal || 0)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </>
                            </>
                        }
                    </TableBody>
                </Table>
            </Grid>
        </>
    )
}