// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {Refinance} from "../../../types/forecastTypes";
import {checkObjectChanged} from "../../../utils/generalUtils";


const refinanceReducer: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Create New Refinance
    addRefinance: {
        prepare(newRefinance: Refinance) {
            return {
                payload: {
                    ...newRefinance,
                    id: nanoid(),
                    status: SaveStatus.NEW
                }
            }
        },
        reducer(state, action: PayloadAction<Refinance>) {
            if (state.forecastData) {
                state.forecastData.refinances = [action.payload, ...state.forecastData.refinances];
                state.changes = true;
            }
        }
    },
    // Update Refinance
    updateRefinances: (state, action) => {
        if (state.forecastData) {
            const index = state.forecastData.refinances.findIndex(r => r.id === action.payload.id && r.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;

                if (state.forecastData.refinances[index].status !== SaveStatus.NEW) {
                    const existing = state.forecastData.refinances[index];
                    changed = (checkObjectChanged(existing, action.payload, ['clientId', 'trancheIds', 'incremental', 'comments']) ? true : changed);
                    if (changed) {
                        if (!existing.previous) action.payload.previous = existing;
                        action.payload.status = SaveStatus.EDITED;
                        state.forecastData.refinances[index] = action.payload;
                        state.changes = true;
                    }
                } else {
                    state.forecastData.refinances[index] = {...state.forecastData.refinances[index], ...action.payload};
                }
            }
        }
    },
    // Remove Refinance
    removeRefinance: (state, action: PayloadAction<number>) => {
        if (state.forecastData) {
            const index = state.forecastData?.refinances.findIndex(r => r.id === action.payload && r.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.forecastData.refinances[index].status !== SaveStatus.NEW) {
                    state.forecastData.refinances[index].status = SaveStatus.REMOVED;
                } else {
                    const refinances = state.forecastData.refinances;
                    refinances.splice(index, 1);
                    state.forecastData.refinances = refinances;
                }
                state.changes = true;

            }
        }
    },
    // Bulk Remove Refinance
    bulkRemoveRefinance: (state, action: PayloadAction<Array<number>>) => {
        action.payload.forEach((id: number) => {
            if (state.forecastData) {
                const index = state.forecastData.refinances.findIndex(r => r.id === id && r.status !== SaveStatus.REMOVED);
                if (index !== -1) {
                    if (state.forecastData.refinances[index].status !== SaveStatus.NEW) {
                        state.forecastData.refinances[index].status = SaveStatus.REMOVED;
                    } else {
                        const refinances = state.forecastData.refinances;
                        refinances.splice(index, 1);
                        state.forecastData.refinances = refinances;
                    }
                    state.changes = true;
                }
            }
        })
    }
}

export default refinanceReducer;