import {Capital, ForecastData, OtherTransaction} from "../../../types/forecastTypes";
import {ForecastLoan, maturityCheck, sellRepayCheck, transferCheck} from "./portfolioChecks";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {checkDateBefore} from "../../../utils/DateUtils";
import {ForecastChecks} from "../../../types/capitalBudgetTypes";
import {CheckFlags} from "../../../types/GeneralTypes";
import {ExternalData} from "../../../types/externalDataTypes";
import {wipCheck} from "./wipCheck";
import {refinanceChecks} from "./refinanceChecks";
import {wipCommentsCheck} from "./wipCommentsCheck";

/**
 * Runs Checks to produce a list of warnings and advisory indicators for users.
 * @param forecastDate
 * @param portfolio
 * @param forecastData
 * @param externalData
 */
function runInputCheck(forecastDate: Date | number, portfolio: Map<number, Map<string, ForecastLoan>>, forecastData: ForecastData, externalData: ExternalData): ForecastChecks {
    let checks: ForecastChecks = {
        capital: {
            advise: []
        },
        maturity: {
            remove: [],
            advise: [],
            modification: []
        },
        otherTransactions: {
            advise: []
        },
        refinance: {
            remove: []
        },
        sellRepay: {
            remove: [],
            advise: [],
            alert: [],
        },
        transfer: {
            remove: [],
            advise: [],
            alert: []
        },
        wipCheck: {
            remove: [],
            advise: [],
            alert: [],
            modification: []
        },
        wipComment: {
            remove: []
        }
    }

    // CHECK PORTFOLIO
    if (portfolio) {
        checks.maturity = maturityCheck(forecastDate, portfolio, forecastData.maturityChanges)
        checks.sellRepay = sellRepayCheck(forecastDate, portfolio, forecastData.selldownRepayments);
        checks.transfer = transferCheck(forecastDate, portfolio, forecastData.transfers);
    }

    // CHECK Capital Changes
    checks.capital.advise = checkTransactions(forecastDate, forecastData.capital);
    checks.otherTransactions.advise = checkTransactions(forecastDate, forecastData.otherTransactions);

    if (externalData.ncinoWip) {
        // CHECK WIP/nCino Data
        checks.wipCheck = wipCheck(forecastDate, forecastData.newDeals, externalData.ncinoWip.deals);
        // CHECK REFINANCE DATA
        checks.refinance = refinanceChecks(forecastData.refinances, externalData.ncinoWip.deals);
        // CHECK WIP COMMENTS
        checks.wipComment = wipCommentsCheck(forecastData.wipComments, externalData.ncinoWip.deals);
    }

    return checks;
}

export default runInputCheck;

function checkTransactions(forecastDate: Date | number, transactions: Array<Capital | OtherTransaction>) {
    return transactions.reduce((checks: Array<{id: number, flag: CheckFlags}>, t: Capital | OtherTransaction) => {
        if (t.status !== SaveStatus.REMOVED) {
            if (checkDateBefore(t.date, forecastDate)) {
                checks.push({
                    id: t.id,
                    flag: CheckFlags.PRE_FORECAST
                })
            }
        }
        return checks;
    }, [])
}