import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {Loan} from "../../../types/externalDataTypes";
import {groupPortfolioByTranche, rawPortfolioGpSelector} from "./portfolioSelector";
import {retrieveNewLoansConfigFx} from "./wipSelector";
import {addValues} from "../../../utils/mathUtil";
import {NewDeal} from "../../../types/forecastTypes";
import {EditableTableRow} from "../../../components";


export const retrievePortfoliByBorrower = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.portfolio?.portfolio || [],
    (portfolio) => {
        return groupPortfolioByTranche(portfolio).reduce((borrowers: Map<number, {
            id: number,
            clientId: number,
            name: string,
            loans: Array<Loan>
        }>, portfolio) => {

            if (!borrowers.has(portfolio.client_id)) {
                borrowers.set(portfolio.client_id, {
                    id: portfolio.client_id,
                    clientId: portfolio.client_id,
                    name: portfolio.borrower,
                    loans: []
                })
            }

            borrowers.get(portfolio.client_id)?.loans.push(portfolio)

            return borrowers;
        }, new Map())
    }
)

export type RefinanceInfoType = {
    id: number,
    ncinoId: string,
    clientId: number,
    dealName: string,
    owner: string,
    closeDate: Date | number,
    tenor: number,
    incremental: boolean,
    commitment: number,
    currentCommitment: number,
    totalCommitment: number,
    borrower: string,
    maturity: Date | number,
    tranches: Array<{id: number, trancheId: number, tranche: string, commitment: number, maturity: Date | number}>,
    comments: string,
    status: SaveStatus
}

// Find a format Refinance information with Borrower and new loan
export const retrieveRefinances = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.refinances || [],
    retrieveNewLoansConfigFx,
    rawPortfolioGpSelector,
    (refinances, newLoans, portfolio): Array<RefinanceInfoType> => {
        const refinancedLoans: any[] = [];

        // Loop Through new refinances
        refinances.forEach((refinance) => {
            if (refinance.status === SaveStatus.REMOVED) return;
            // Find new loan
            const newLoan = newLoans.find(l => l.ncino_id === refinance.ncinoId);
            // Find corresponding existing loans for refinance
            const tranches = portfolio.filter(p => refinance.trancheIds.includes(p.tranche_id));
            if (newLoan && tranches.length > 0) {
                let updatedCommitment = newLoan.amendedCommitment || newLoan.llc_bi_amount;
                let currentCommitment = tranches.reduce((total, tranche) => addValues(total, tranche.commitment), 0);

                // If incremental add existing commitments together with new loan
                if (refinance.incremental) {
                    updatedCommitment = addValues(updatedCommitment, currentCommitment);
                }

                refinancedLoans.push({
                    id: refinance.id,
                    ncinoId: refinance.ncinoId,
                    clientId: refinance.clientId,
                    dealName: newLoan.name,
                    owner: newLoan.llc_bi_product_package.primary_origination_director.name,
                    closeDate: newLoan.adjustedClose,
                    tenor: newLoan.amendedTenor || newLoan.tenor,
                    incremental: refinance.incremental,
                    commitment: newLoan.amendedCommitment || newLoan.llc_bi_amount,
                    currentCommitment,
                    totalCommitment: updatedCommitment,
                    borrower: tranches[0].borrower,
                    // maturity: tranches[0].adjustedMaturity,
                    tranches: tranches.map(tranche => ({
                        id: tranche.tranche_id,
                        trancheId: tranche.tranche_id,
                        tranche: tranche.tranche,
                        commitment: tranche.commitment,
                        maturity: tranche.adjustedMaturity
                    })),
                    comments: refinance.comments,
                    status: refinance.status
                })
            }
        })

        return refinancedLoans;
    }
)

export type RefinanceSuggestion = {
    id: string,
    ncinoId: string,
    clientId: number,
    borrower: string,
    name: string,
    owner: string,
    closeDate: Date | number,
    tenor: number,
    value: number | null,
    comment: string,
    loans: Loan[],
    modificationId: string | null
}

export const selectSuggestedRefinances = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.ncinoWip?.deals || [],
    retrievePortfoliByBorrower,
    (state: RootState) => state.capitalBudget.forecastData?.newDeals || [],
    (state: RootState) => state.capitalBudget.forecastData?.refinances || [],
    (wipLoans, borrowers, newLoans, refinances) => {
        const loans: Array<RefinanceSuggestion> = [];

        newLoans.forEach((loan: NewDeal) => {
            if (loan.status !== SaveStatus.REMOVED && loan.ncinoId) {
                // Check if refinance already configured
                const existingRefinance = refinances.find(r => r.ncinoId === loan.ncinoId);

                if (!existingRefinance) {

                    const wipLoan = wipLoans.find((wipLoan) => wipLoan.ncino_id === loan.ncinoId);

                    if (wipLoan) {
                        if (wipLoan.account?.client_id) {
                            const borrower = borrowers.get(wipLoan.account?.client_id)

                            if (borrower) {
                                loans.push({
                                    id: loan.ncinoId,
                                    ncinoId: loan.ncinoId,
                                    clientId: wipLoan.account?.client_id,
                                    borrower: borrower.name,
                                    name: wipLoan.name,
                                    owner: wipLoan.llc_bi_product_package.primary_origination_director.name,
                                    closeDate: loan.amendedCloseDate ? loan.amendedCloseDate : wipLoan.llc_bi_close_date,
                                    tenor: loan.amendedTenor ? loan.amendedTenor : wipLoan.llc_bi_pricing_streams[0]?.llc_bi_term_length / 12,
                                    value: loan.amendedCommitment ? loan.amendedCommitment : wipLoan.llc_bi_amount,
                                    comment: wipLoan.narrative_comments,
                                    loans: borrower.loans,
                                    modificationId: (wipLoan.llc_bi_lookupkey) ? wipLoan.llc_bi_lookupkey.match(/\d+/g)?.[0] || null : null
                                })
                            }
                        }
                    }
                }
            }
        })

        return loans;
    }
)

export const retrieveLoansBorrowersWithRefinance = createSelector(
    retrieveNewLoansConfigFx,
    retrievePortfoliByBorrower,
    (state: RootState) => state.capitalBudget.forecastData?.refinances || [],
    (newLoans, borrowers, refinances): {newLoans: Array<EditableTableRow>, borrowers: Array<any>} => {
        return {
            newLoans: newLoans.map(loan => {
                const index = refinances.findIndex(r => r.ncinoId === loan.ncinoId)
                return {
                    ...loan,
                    refinance: (index !== -1)
                }
            }),
            borrowers: [...borrowers.values()]
        }
    }
)