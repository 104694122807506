import {PeriodType} from "./capitalBudgetEnums";

export type alignment = 'left' | 'center' | 'right'

export type Order = 'asc' | 'desc'

export type Period = {
    label: string,
    labelTwo: string,
    tooltip: string,
    startDate: number | string,
    lastDate: number | string,
    type: PeriodType,
    base?: boolean,
    [x: string]: any
}

export enum CheckFlags {
    'AFTER_MATURITY' = 'AFTER_MATURITY',
    'EXCEEDED' = 'EXCEEDED',
    'MISALLOCATED' = 'MISALLOCATED',
    'PRE_FORECAST' = 'PRE_FORECAST',
    'UNALLOCATED' = 'UNALLOCATED',
    'UPDATED_MATURITY' = 'UPDATED_MATURITY',
    'UPDATED_WIP' = 'UPDATED_WIP',
    'WIP_MISSING_COMMITMENT' = 'WIP_MISSING_COMMITMENT',
    'WIP_MISSING_DRAWDOWN' = 'WIP_MISSING_DRAWDOWN',
    'WIP_MISSING_CLOSE_DATE' = 'WIP_MISSING_CLOSE_DATE',
    'WIP_MISSING_TENOR' = 'WIP_MISSING_TENOR',
}