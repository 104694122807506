import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {SaveStatus} from "../../../types/capitalBudgetEnums";

// Retrieves all Transfers
export const retrieveTransfers = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.portfolio?.portfolio || [],
    (state: RootState) => state.capitalBudget.forecastData?.transfers || [],
    (state: RootState) => state.capitalBudget.warnings?.transfer.advise || [],
    (state: RootState) => state.capitalBudget.warnings?.transfer.alert || [],
    (portfolio, transfers, checkAdvise, checkAlert) => {
        return transfers.reduce((transfers: Array<any>, sr) => {
            if (sr.status === SaveStatus.REMOVED) return transfers;
            const loan = portfolio.find(p => (p.tranche_id === sr.trancheId && p.fund === sr.fromFund));

            if (loan) {
                const alert = checkAlert.find(c => c.id === sr.id);
                const advise = checkAdvise.find(c => c.id === sr.id);

                transfers.push({
                    ...sr,
                    borrower: loan.borrower,
                    tranche: loan.tranche,
                    commitment: loan.commitment,
                    ...(advise) ? {advise: advise.flag} : {},
                    ...(alert) ? {alert: alert.flag} : {},
                })
            }

            return transfers;
        }, [])
    }
)