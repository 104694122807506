import {RootState} from "../../../store";
import {createSelector} from "@reduxjs/toolkit";
import {HorizontalTableRow} from "../../../../components";
import {fCurrency} from "../../../../utils/formatNumber";
import {CellType} from "../../../../types/InputTypes";
import {addValues} from "../../../../utils/mathUtil";
import {fundHolderSelector} from "../generalSelectors";
import {ExternalFacility, InternalFacility} from "../../../../types/externalDataTypes";

function trancheComparator(a: any, b: any) {
    if (a.tranche > b.tranche) return 1;
    if (a.tranche < b.tranche) return -1;
    else return (a.local_currency > b.local_currency) ? 1 : -1
}

// Determines facilities remaining undrawn and formats the facility for display in Horizontal table
function formatDebtFacility(facility: ExternalFacility) {
    const openingFacility: any = {};
    const facilityRows: Array<HorizontalTableRow> = [];

    // Only for DASLF list each individual tranche in the facility
    if (facility.fund === 'DASLF' && facility.trancheName === 'Debt Facility') {
        openingFacility['DASLFFacilityLimit'] = facility.facilityLimit;
        facilityRows.push({
            id: 'DASLFFacilityLimit',
            label: 'DASLF Debt Facility Limit',
            formatter: fCurrency,
            type: CellType.CURRENCY
        })
        facility.tranches.sort((a: any, b: any) => trancheComparator(a, b)).forEach((t: any) => {
            if (t.drawn > 0) {
                const rowId = `DASLFFacility${t.tranche}${(t.loanType === 'Bank Guarantee') ? `${t.local_currency}Bank guarantees` : `${t.local_currency}Drawn`}`;
                const rowString = `DASLF Debt Facility ${t.tranche} - ${(t.loanType === 'Bank Guarantee') ? `${t.local_currency} Bank guarantees` : `${t.local_currency} Drawn`}`
                openingFacility[rowId] = -t.drawn;
                facilityRows.push({id: rowId, label: rowString, formatter: fCurrency, type: CellType.CURRENCY})
            }
        })
        openingFacility['DASLFUndrawnBalanceAvailable'] = addValues(facility.facilityLimit, -facility.drawn);
        facilityRows.push({
            id: 'DASLFUndrawnBalanceAvailable',
            label: 'DASLF Undrawn Balance Available',
            formatter: fCurrency,
            type: CellType.CURRENCY,
            headSX: {
                bgcolor: 'info.lighter',
                color: 'black',
                fontWeight: 'bold',
                '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
            },
            sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
        })

    } else {
        const rowId = `${facility.fund}Available${facility.trancheName}`;
        const rowString = `${facility.fund} Available ${facility.trancheName} (Limit: ${fCurrency(facility.facilityLimit / 1000000)}m)`
        openingFacility[rowId] = addValues(facility.facilityLimit, -facility.drawn);
        facilityRows.push({id: rowId, label: rowString, formatter: fCurrency, type: CellType.CURRENCY})
    }

    return {
        openingFacility,
        facilityRows,
    };
}

// Extracts and formalises the internal and external facilities for the capital budget
export const retrieveBankFacilities = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.debtFacilities?.debtFacilities || [],
    (debtFacilities) => {
        const facilities = debtFacilities.reduce((facilities, facility) => {
            // Determine internal or external facility
            if (facility.flag_internal) {
                const internalFacility: InternalFacility = {
                    tranche: facility.tranche_id,
                    trancheName: facility.tranche,
                    loanType: 'Internal',
                    local_currency: facility.local_currency,
                    facilityLimit: facility.facility_limit,
                    drawn: facility.drawn_tranche_currency,
                    fund: facility.fund
                }

                facilities.internal.push(internalFacility)
            } else {
                const fundRegex = /MCP\s(\w+)\s(.*)/.exec(facility.client);

                if (!fundRegex) {
                    return facilities;
                }

                const fundKey = facility.fund ?? fundRegex[1];
                const fundTrancheKey = fundKey + fundRegex[2];

                // Establish the tranche details
                const tranche = {
                    tranche: facility.tranche,
                    trancheName: fundRegex[2],
                    loanType: facility.loan_type,
                    local_currency: facility.local_currency,
                    facilityLimit: facility.facility_limit,
                    drawn: facility.drawn_tranche_currency,
                }
                // Find or create Fund Debt Facility to group by
                const item = facilities.external.get(fundTrancheKey);
                if (item) {
                    if (!item.trancheIdentifier.includes(tranche.tranche)) {
                        item.facilityLimit = addValues(item.facilityLimit, tranche.facilityLimit);
                        item.trancheIdentifier.push(tranche.tranche);
                    }
                    item.drawn = addValues(item.drawn, tranche.drawn);

                    item.tranches.push(tranche);
                    facilities.external.set(fundTrancheKey, item);
                } else {
                    const itemObject = {
                        fund: fundKey,
                        trancheName: fundRegex[2],
                        facilityLimit: tranche.facilityLimit,
                        drawn: tranche.drawn,
                        tranches: [tranche],
                        trancheIdentifier: [tranche.tranche]
                    }
                    facilities.external.set(fundTrancheKey, itemObject)
                }
            }

            return facilities
        }, {external: new Map(), internal: [] as Array<InternalFacility>})

        return {
            external: [...facilities.external.values()],
            internal: facilities.internal
        };
    }
)

// Retrieve all facilities for fund or all facilities grouped
export const getFacilitiesByFund = createSelector(
    retrieveBankFacilities,
    fundHolderSelector,
    (facilities, fund) => {

        let externalFacilities = facilities.external;

        externalFacilities = externalFacilities.sort((a, b) => (a.fund > b.fund) ? 1 : -1);

        let openingFacility: any = {};
        let externalFacilitiesAvailable: number = 0;
        let internalFacilitiesAvailable: number = 0;

        let externalFacilitiesSubRows: Array<HorizontalTableRow> = []

        externalFacilities.forEach(facility => {
            if (!fund || (facility.fund === fund.label)) {
                const formattedFacility = formatDebtFacility(facility);
                openingFacility = {
                    ...openingFacility,
                    ...formattedFacility.openingFacility
                };
                externalFacilitiesSubRows.push(...formattedFacility.facilityRows);
                externalFacilitiesAvailable = addValues(externalFacilitiesAvailable, addValues(facility.facilityLimit, -facility.drawn));
            }
        })

        let internalFacilitiesSubRows: Array<HorizontalTableRow> = []

        facilities.internal.forEach(facility => {
            if (!fund || (facility.fund === fund.label)) {
                const rowId = `${facility.fund}${facility.tranche}`;
                const rowString = `${facility.trancheName} (${facility.fund}) (Limit: ${fCurrency(facility.facilityLimit / 1000000)}m)`
                openingFacility[rowId] = addValues(facility.facilityLimit, -facility.drawn);
                internalFacilitiesSubRows.push({id: rowId, label: rowString, formatter: fCurrency, type: CellType.CURRENCY})
                internalFacilitiesAvailable = addValues(internalFacilitiesAvailable, addValues(facility.facilityLimit, -facility.drawn));
            }
        })

        return {
            openingFacility: {
                ...openingFacility,
                externalFacilitiesAvailable,
                internalFacilitiesAvailable
            },
            facilityRows: [
                {
                    id: 'externalFacilitiesAvailable',
                    label: 'External Facilities',
                    formatter: fCurrency,
                    type: CellType.CURRENCY,
                    headSX: {bgcolor: 'primary.dark'},
                    sx: {bgcolor: 'info.lighter'},
                    subRows: externalFacilitiesSubRows
                },
                {
                    id: 'internalFacilitiesAvailable',
                    label: 'Internal Facilities',
                    formatter: fCurrency,
                    type: CellType.CURRENCY,
                    headSX: {bgcolor: 'primary.dark'},
                    sx: {bgcolor: 'info.lighter'},
                    subRows: internalFacilitiesSubRows
                }
            ],
            facilitiesAvailable: addValues(externalFacilitiesAvailable, internalFacilitiesAvailable)
        }
    }
)

// RETRIEVES FACILITY LIMITS FOR FUND ON DEBT FACILITY
export const retrieveBankFacilitiesLimit = createSelector(
    retrieveBankFacilities,
    fundHolderSelector,
    (debtFacilities, fund) => {
        const external = debtFacilities.external.reduce((limit, facility) => {
            if (!fund || (facility.fund === fund.label)) {
                return addValues(limit, facility.facilityLimit);
            }
            return limit;
        }, 0);

        const internal = debtFacilities.internal.reduce((limit, facility) => {
            if (!fund || (facility.fund === fund.label)) {
                return addValues(limit, facility.facilityLimit);
            }
            return limit;
        }, 0);

        return {
            external,
            internal,
            total: addValues(external, internal)
        }
    }
)

