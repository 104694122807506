// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {FundFinance} from "../../../types/forecastTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {checkObjectChanged} from "../../../utils/generalUtils";


const fundFinanceReducer: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Create New Fund Finance
    addFundFinance: {
        prepare(newFundFinance: FundFinance) {
            return {
                payload: {
                    ...newFundFinance,
                    id: nanoid(),
                    status: SaveStatus.NEW
                }
            }
        },
        reducer(state, action: PayloadAction<FundFinance>) {
            if (state.forecastData) {
                state.forecastData.fundFinance = [action.payload, ...state.forecastData.fundFinance];
                state.changes = true;
            }
        }
    },
    // Update Fund Finance
    updateFundFinance: (state, action) => {
        if (state.forecastData) {
            const index = state.forecastData.fundFinance.findIndex(r => r.id === action.payload.id && r.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;

                if (state.forecastData.fundFinance[index].status !== SaveStatus.NEW) {
                    const existing = state.forecastData.fundFinance[index];
                    changed = (checkObjectChanged(existing, action.payload, ['amendedMasterDate', 'amendedMasterLimit', 'loans']) ? true : changed);

                    if (changed) {
                        if (!existing.previous) action.payload.previous = existing;
                        action.payload.status = SaveStatus.EDITED;
                        state.forecastData.fundFinance[index] = action.payload;
                        state.changes = true;
                    }
                } else {
                    state.forecastData.fundFinance[index] = {...state.forecastData.fundFinance[index], ...action.payload};
                }
            }
        }
    },
    // Remove Fund Finance
    removeFundFinance: (state, action: PayloadAction<number>) => {
        if (state.forecastData) {
            const index = state.forecastData?.fundFinance.findIndex(r => r.id === action.payload && r.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.forecastData.fundFinance[index].status !== SaveStatus.NEW) {
                    state.forecastData.fundFinance[index].status = SaveStatus.REMOVED;
                } else {
                    const fundFinance = state.forecastData.fundFinance;
                    state.forecastData.fundFinance = fundFinance.filter(f => f.id !== action.payload);
                }
                state.changes = true;
            }
        }
    }
}

export default fundFinanceReducer;