import {memo, useEffect, useState, MouseEvent} from 'react';
import {addDays, checkDateSame, formatDate} from "../../../utils/DateUtils";
// MUI
import {
    Badge,
    Button,
    CircularProgress,
    FormControl,
    Grid, IconButton,
    InputLabel, LinearProgress,
    MenuItem, Popover,
    Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup, Typography
} from "@mui/material";
import {
    ErrorOutline as AlertIcon,
    Info as InfoButton,
    Tune as TuneIcon,
    WarningAmber as WarningIcon
} from "@mui/icons-material"
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
// COMPONENTS
import {AlertDialog, SideDrawer} from "../../../components";
// STORE
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    getCapitalBudgetFunds,
    getForecastExternalDataDates
} from "../../../store/capitalBudget/selectors/generalSelectors";
import {getForecastDates} from "../../../store/forecast/forecastSelectors";
import {setFund, setMonths, setWeeks} from "../../../store/capitalBudget/capitalBudgetSlice";
import {setMillions} from "../../../store/general/generalSlice";
import {styled} from "@mui/material/styles";
import {millionsSelector} from "../../../store/general";
import {changeForecastDate, saveForecast, saveNewForecast} from "../../../store/forecast/forecastFunctions";
import {checkInputs, generateCapitalBudget} from "../../../store/capitalBudget/capitalBudgetFunctions";
import ForecastSideBarDescription from "./ForecastSideBarDescription";
import {warningSelector} from "../../../store/capitalBudget/selectors/warningsSelector";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    "& .MuiToggleButtonGroup-grouped": {
        "&.MuiToggleButton-root": {
            // width: 50,
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            // width: 50,
        },

    }
}));


function ForecastConfigBar({title, configuration = false}: { title: string, configuration?: boolean }) {
    const dispatch = useAppDispatch();

    const funds = useAppSelector(state => getCapitalBudgetFunds(state));
    const forecast = useAppSelector(state => state.forecast.forecast);
    const forecastDates = useAppSelector(state => getForecastDates(state));
    const externalDataDates = useAppSelector(state => getForecastExternalDataDates(state));
    const selectedFund = useAppSelector(state => state.capitalBudget.misc.fund?.label || 'ALL');
    const weeks = useAppSelector(state => state.capitalBudget.misc.weeks);
    const months = useAppSelector(state => state.capitalBudget.misc.months);
    const changes = useAppSelector(state => state.capitalBudget.changes);
    const loading = useAppSelector(state => state.capitalBudget.loading);
    const calculating = useAppSelector(state => state.capitalBudget.calculating);
    const millions = useAppSelector(state => millionsSelector(state));
    const warnings = useAppSelector(state => warningSelector(state));

    const [selectDate, setDate] = useState<Date | null>(null);
    const [dateChange, setDateChange] = useState<boolean>(false);
    const [openForecast, setOpenForecast] = useState<boolean>(false);
    const [settings, openSettings] = useState<null | HTMLElement>(null);

    // Set Select Date
    useEffect(() => {
        if ((!selectDate && forecast)) {
            setDate(new Date(forecast.forecastDate))
        }
        if (!loading && selectDate && forecast && !checkDateSame(selectDate, forecast.forecastDate) && !dateChange) {
            setDate(new Date(forecast.forecastDate))
        }
    }, [selectDate, forecast, setDate, loading, dateChange])


    const checkDisable = (day: Date | string | number) => {
        if (checkDateSame(day, addDays(new Date(), -1))) return false;
        return !forecastDates.includes(formatDate(new Date(day), 'yyyy-MM-dd'));
    }

    const handleDateChange = (date: Date | null) => {
        if (changes) {
            setDate(date);
            setDateChange(true);
        } else if (date) {
            dispatch(changeForecastDate(date))
        }
    }

    return (
        <>
            <Grid container direction='row' sx={{}}>
                <Grid container item md={12}
                      sx={{display: 'flex', flexDirection: 'row', justify: 'flexStart', alignItems: 'center', mb: 1}}>
                    <Grid
                        sx={{display: 'flex', flexDirection: 'row', justify: 'flexStart', alignItems: 'center'}}>
                        <Typography variant="h5" sx={{color: 'primary.main'}}>
                            {title}
                        </Typography>
                    </Grid>
                    {loading ?
                        <Grid sx={{display: 'flex', flexDirection: 'row', justify: 'flexStart', alignItems: 'center'}}>
                            <CircularProgress/>
                        </Grid>
                        :
                        <>
                            <Grid
                                container
                                direction='row'
                                justifyContent='flex-start'
                                sx={{
                                    flex: 1,
                                    alignItems: 'center',
                                }}
                            >
                                {/* Small view */}
                                <Grid
                                    container
                                    direction='row'
                                    justifyContent='space-between'
                                    display={{sm: "flex", md: "flex", lg: 'none'}}
                                    sx={{
                                        flex: 1,
                                        alignItems: 'center'
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}

                                    >
                                        <Typography variant="h5" sx={{color: 'grey.600'}}>
                                            {'\xa0'}- {forecast && formatDate((forecast.new ? forecast.forecastDataDate : forecast.forecastDate), 'dd-MMM-yyyy')}
                                        </Typography>
                                        <IconButton
                                            size="large"
                                            color="primary"
                                            onClick={() => setOpenForecast(true)}
                                        >
                                        </IconButton>

                                    </Grid>
                                    <Grid item
                                    >
                                        <IconButton
                                            size="large"
                                            color="primary"
                                            onClick={(event: MouseEvent<HTMLElement>) => {
                                                openSettings(event.currentTarget);
                                            }}
                                        >
                                            <TuneIcon/>
                                        </IconButton>
                                    </Grid>
                                    <Popover
                                        open={Boolean(settings)}
                                        anchorEl={settings}
                                        onClose={() => openSettings(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        elevation={4}
                                    >
                                        <Grid container padding={1} direction='row' width={300}>
                                            <Grid item xs={12} padding={0.5}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        label={'Forecast Date'}
                                                        value={selectDate}
                                                        onChange={event => handleDateChange(event)}
                                                        format="dd/MM/yyyy"
                                                        shouldDisableDate={checkDisable}
                                                        sx={{bgcolor: 'common.white'}}
                                                        slotProps={{
                                                            textField: {
                                                                size: 'small',
                                                                fullWidth: true
                                                            },
                                                            day: {
                                                                sx: {
                                                                    '&.Mui-disabled': {
                                                                        bgcolor: 'white'
                                                                    },
                                                                    '&.Mui-selected': {
                                                                        bgcolor: 'primary.main',
                                                                        opacity: 1
                                                                    },
                                                                    '&.MuiButtonBase-root-MuiPickersDay-root': {
                                                                        opacity: 1
                                                                    },
                                                                    '&.MuiPickersDay-today': {
                                                                        border: 'none'
                                                                    },
                                                                    bgcolor: 'grey.200',
                                                                    opacity: '1 !important',
                                                                    fontWeight: 'bold',
                                                                    color: 'primary.main',
                                                                    borderRadius: 1,
                                                                    '&:hover': {
                                                                        bgcolor: 'primary.light',
                                                                        color: 'white'
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />

                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={6} padding={0.5}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel>Select Fund</InputLabel>
                                                    <Select
                                                        size='small'
                                                        label='Select Fund'
                                                        value={selectedFund}
                                                        sx={{bgcolor: 'common.white'}}
                                                        onChange={(event) => dispatch(setFund(event.target.value))}
                                                    >
                                                        <MenuItem value={'ALL'}>All Funds</MenuItem>
                                                        {funds.map(fund => (
                                                            <MenuItem value={fund} key={fund}>{fund}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} padding={0.5}>
                                                <StyledToggleButtonGroup
                                                    fullWidth
                                                    disabled={(loading || calculating)}
                                                    value={millions}
                                                    exclusive
                                                    onChange={(e, value) => dispatch(setMillions(value))}
                                                    size='small'
                                                    sx={{bgcolor: 'common.white'}}
                                                >
                                                    <ToggleButton fullWidth value={false}>$</ToggleButton>
                                                    <ToggleButton fullWidth value={true}>$m</ToggleButton>
                                                </StyledToggleButtonGroup>
                                            </Grid>
                                            <Grid item xs={6} padding={0.5}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Weeks</InputLabel>
                                                    <Select
                                                        id="Weeks"
                                                        value={(weeks || 0).toString()}
                                                        label="Weeks"
                                                        size="small"
                                                        sx={{bgcolor: 'common.white'}}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            dispatch(setWeeks(parseInt(event.target.value)));
                                                            dispatch(generateCapitalBudget());
                                                        }}
                                                        disabled={(loading || calculating)}
                                                    >
                                                        {Array.from({length: 12}).map((x, n) => (
                                                            <MenuItem value={(n + 1).toString()} key={n}>
                                                                {n + 1}
                                                            </MenuItem>)
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} padding={0.5}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Months</InputLabel>
                                                    <Select
                                                        id="Months"
                                                        value={(months || 0).toString()}
                                                        label="Months"
                                                        size="small"
                                                        sx={{bgcolor: 'common.white'}}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            dispatch(setMonths(parseInt(event.target.value)));
                                                            dispatch(generateCapitalBudget());
                                                        }}
                                                        disabled={(loading || calculating)}
                                                    >
                                                        {Array.from({length: 12}).map((x, n) => (
                                                            <MenuItem value={(n + 1).toString()} key={n}>
                                                                {n + 1}
                                                            </MenuItem>)
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                    </Popover>
                                </Grid>
                                {/* Large view */}
                                <Grid
                                    item
                                    display={{sm: 'none', md: "none", lg: 'flex'}}
                                    sx={{
                                        flexDirection: 'row',
                                        justify: 'flexStart',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="h5" sx={{color: 'grey.600'}}>
                                        {'\xa0'}- Budget Forecast Data
                                        From: {forecast && formatDate((forecast.new ? forecast.forecastDataDate : forecast.forecastDate), 'dd-MMM-yyyy')}
                                    </Typography>
                                    {(warnings.alert > 0) ?
                                        <>
                                            <IconButton
                                                // size="large"
                                                color="error"
                                                onClick={() => setOpenForecast(true)}
                                            >
                                                <Badge badgeContent={warnings.alert} color={'error'}>
                                                    <WarningIcon/>
                                                </Badge>
                                            </IconButton>
                                        </>
                                        :
                                        (warnings.advise > 0) ?
                                            <>
                                                <IconButton
                                                    // size="large"
                                                    color="warning"
                                                    onClick={() => setOpenForecast(true)}
                                                >
                                                    <Badge badgeContent={warnings.advise} color={'warning'}>
                                                        <AlertIcon/>
                                                    </Badge>
                                                </IconButton>
                                            </>
                                            :
                                            <IconButton
                                                // size="large"
                                                color="primary"
                                                onClick={() => setOpenForecast(true)}
                                            >
                                                <InfoButton/>
                                            </IconButton>
                                    }

                                </Grid>
                            </Grid>
                        </>
                    }
                </Grid>
                {selectDate && !loading ?
                    <Grid container direction='row' item xs={12} justifyContent='space-between' alignItems='center'
                          sx={{mb: 2}} spacing={1}
                          display={{sm: 'none', md: 'none', lg: 'flex'}}
                    >
                        <Grid container item spacing={1} xs={6} md={7} direction='row' justifyContent='flex-start'
                              alignItems='center'>
                            <Grid item sm={12} md={4} justifyContent='center' alignItems='center'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label={'Forecast Date'}
                                        value={selectDate}
                                        onChange={event => handleDateChange(event)}
                                        format="dd/MM/yyyy"
                                        shouldDisableDate={checkDisable}
                                        sx={{bgcolor: 'common.white'}}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true
                                            },
                                            day: {
                                                sx: {
                                                    '&.Mui-disabled': {
                                                        bgcolor: 'white'
                                                    },
                                                    '&.Mui-selected': {
                                                        bgcolor: 'primary.main',
                                                        opacity: 1
                                                    },
                                                    '&.MuiButtonBase-root-MuiPickersDay-root': {
                                                        opacity: 1
                                                    },
                                                    '&.MuiPickersDay-today': {
                                                        border: 'none'
                                                    },
                                                    bgcolor: 'grey.200',
                                                    opacity: '1 !important',
                                                    fontWeight: 'bold',
                                                    color: 'primary.main',
                                                    borderRadius: 1,
                                                    '&:hover': {
                                                        bgcolor: 'primary.light',
                                                        color: 'white'
                                                    }
                                                }
                                            }
                                        }}
                                    />

                                </LocalizationProvider>
                            </Grid>
                            <Grid item sm={12} md={4} justifyContent='center' alignItems='center'>
                                {loading ?
                                    <CircularProgress/>
                                    :
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Select Fund</InputLabel>
                                        <Select
                                            size='small'
                                            label='Select Fund'
                                            value={selectedFund}
                                            sx={{bgcolor: 'common.white'}}
                                            onChange={(event) => dispatch(setFund(event.target.value))}
                                        >
                                            <MenuItem value={'ALL'}>All Funds</MenuItem>
                                            {funds.map(fund => (
                                                <MenuItem value={fund} key={fund}>{fund}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item md={2} display={{sm: "none", md: "flex"}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Weeks</InputLabel>
                                    <Select
                                        id="Weeks"
                                        value={(weeks || 0).toString()}
                                        label="Weeks"
                                        size="small"
                                        sx={{bgcolor: 'common.white'}}
                                        onChange={(event: SelectChangeEvent) => {
                                            dispatch(setWeeks(parseInt(event.target.value)));
                                            dispatch(generateCapitalBudget());
                                        }}
                                        disabled={(loading || calculating)}
                                    >
                                        {Array.from({length: 12}).map((x, n) => (
                                            <MenuItem value={(n + 1).toString()} key={n}>
                                                {n + 1}
                                            </MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={2} display={{sm: "none", md: "flex"}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Months</InputLabel>
                                    <Select
                                        id="Months"
                                        value={(months || 0).toString()}
                                        label="Months"
                                        size="small"
                                        sx={{bgcolor: 'common.white'}}
                                        onChange={(event: SelectChangeEvent) => {
                                            dispatch(setMonths(parseInt(event.target.value)));
                                            dispatch(generateCapitalBudget());
                                        }}
                                        disabled={(loading || calculating)}
                                    >
                                        {Array.from({length: 12}).map((x, n) => (
                                            <MenuItem value={(n + 1).toString()} key={n}>
                                                {n + 1}
                                            </MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} xs={6} sm={6} md={5} xl={4} direction='row'
                              justifyContent='flex-end' alignItems='center'>
                            <Grid item sm={6} md={2} display={{sm: "flex", md: "none"}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Weeks</InputLabel>
                                    <Select
                                        id="Weeks"
                                        value={(weeks || 0).toString()}
                                        label="Weeks"
                                        size="small"
                                        sx={{bgcolor: 'common.white'}}
                                        onChange={(event: SelectChangeEvent) => {
                                            dispatch(setWeeks(parseInt(event.target.value)));
                                            dispatch(generateCapitalBudget());
                                        }}
                                        disabled={(loading || calculating)}
                                    >
                                        {Array.from({length: 12}).map((x, n) => (
                                            <MenuItem value={(n + 1).toString()} key={n}>
                                                {n + 1}
                                            </MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={6} md={2} display={{sm: "flex", md: "none"}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Months</InputLabel>
                                    <Select
                                        id="Months"
                                        value={(months || 0).toString()}
                                        label="Months"
                                        size="small"
                                        sx={{bgcolor: 'common.white'}}
                                        onChange={(event: SelectChangeEvent) => {
                                            dispatch(setMonths(parseInt(event.target.value)));
                                            dispatch(generateCapitalBudget());
                                        }}
                                        disabled={(loading || calculating)}
                                    >
                                        {Array.from({length: 12}).map((x, n) => (
                                            <MenuItem value={(n + 1).toString()} key={n}>
                                                {n + 1}
                                            </MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={5} md={3} xl={3}>
                                <StyledToggleButtonGroup
                                    fullWidth
                                    disabled={(loading || calculating)}
                                    value={millions}
                                    exclusive
                                    onChange={(e, value) => dispatch(setMillions(value))}
                                    size='small'
                                    sx={{bgcolor: 'common.white'}}
                                >
                                    <ToggleButton fullWidth value={false}>$</ToggleButton>
                                    <ToggleButton fullWidth value={true}>$m</ToggleButton>
                                </StyledToggleButtonGroup>
                            </Grid>
                            {configuration &&
                                <>
                                    <Grid item sm={7} md={4} xl={3}>
                                        {(loading || !forecast) ?
                                            <CircularProgress/>
                                            :
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => dispatch(checkInputs())}
                                                sx={{px: 1}}
                                            >
                                                Run Check
                                            </Button>
                                        }
                                    </Grid>
                                    <Grid item sm={7} md={3} xl={3}>
                                        {(loading || !forecast) ?
                                            <CircularProgress/>
                                            :
                                            <>
                                                {forecast.new &&
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{px: 1}}
                                                        onClick={() => dispatch(saveNewForecast())}
                                                    >
                                                        Save As
                                                    </Button>
                                                }
                                                {!forecast.new &&
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        disabled={!changes}
                                                        onClick={() => dispatch(saveForecast())}
                                                    >
                                                        Save
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </Grid>
                                </>
                            }
                        </Grid>

                    </Grid>
                    :
                    <Grid container direction='row' item xs={12} sx={{p: 1}}>
                        <LinearProgress/>
                    </Grid>
                }
            </Grid>
            {forecast &&
                <SideDrawer
                    title='Forecast Details'
                    open={openForecast}
                    onClose={() => setOpenForecast(false)}
                >
                    <ForecastSideBarDescription
                        forecast={forecast}
                        externalDataDates={externalDataDates}
                        setOpenForecast={setOpenForecast}
                        warnings={warnings}
                    />
                </SideDrawer>
            }
            {forecast && selectDate &&
                <AlertDialog
                    title='Are you sure you want to switch Dates?'
                    info='Switching dates will remove any unsaved changes'
                    open={dateChange}
                    handleClose={() => {
                        setDate(new Date(forecast.forecastDate))
                        setDateChange(false)
                    }}
                    handleConfirm={() => {
                        if (!!selectDate) {
                            dispatch(changeForecastDate(selectDate))
                        }
                        setDateChange(false)
                    }}
                />
            }
        </>
    )
}

export default memo(ForecastConfigBar)