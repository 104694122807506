import {RootState} from "../../store";
import {createSelector} from "@reduxjs/toolkit";
import {SaveStatus} from "../../../types/capitalBudgetEnums";


export const manualDealsSelector = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.manualDeals || [],
    (manualDeals) => {
        return manualDeals.filter(deal => deal.status !== SaveStatus.REMOVED);
    }
);