import {useState} from 'react';
import clsx from "clsx";
import {formatNameInitial} from "../../../utils/generalUtils";
import {warningsGeneralRow} from "./DataTableWarningsUtilts";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
// MUI
import {
    Box,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
//Components
import {Page} from "../../../layouts/components";
import ForecastConfigBar from "../ForecastConfigBar";
import {actionRow, AlertDialog, DataTable, SideDrawer, valueCurrency, valueDate} from "../../../components";
import FundFinanceForm from "../../../sections/Budget/Configurations/FundFinanceForm";
// Store
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    FundFinanceDetails,
    retrieveFundFinanceSelector
} from "../../../store/capitalBudget/selectors/fundFinanceSelector";
import {removeFundFinance} from "../../../store/capitalBudget/capitalBudgetSlice";
import {addValues} from "../../../utils/mathUtil";
import {fCurrency} from "../../../utils/formatNumber";

const fundFinanceClassName = (params: GridCellParams) => {
    return clsx('', {
        'selected-parent': (!!params.row.fundFinanceId && params.row.parent),
        'unselected-parent': (!params.row.fundFinanceId && params.row.parent),
        'unselected-child': (!params.row.fundFinanceId && !params.row.parent),
    }, ' status', {
        new: (params.row.status === SaveStatus.NEW),
        edited: (params.row.status === SaveStatus.EDITED)
    });
};

export const FundFinanceColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Deal',
        flex: 1,
        minWidth: 250,
        cellClassName: fundFinanceClassName
    },
    {
        field: 'adjustedMasterMaturity',
        headerName: 'Master Maturity',
        ...valueDate,
        width: 130,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'owner',
        headerName: 'Owner',
        width: 120,
        valueFormatter: (params) => formatNameInitial(params.value),
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'trancheId',
        headerName: 'Tranche ID',
        width: 90,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'currentBalance',
        headerName: 'Drawn',
        width: 120,
        ...valueCurrency,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'undrawnBalance',
        headerName: 'Undrawn',
        width: 120,
        ...valueCurrency,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'currentLimit',
        headerName: 'Commitment',
        width: 120,
        ...valueCurrency,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'maturity',
        headerName: 'Maturity',
        ...valueDate,
        width: 130,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'adjustedMasterLimit',
        headerName: 'Master Limit',
        width: 120,
        ...valueCurrency,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                'selected-parent': (!!params.row.fundFinanceId && params.row.parent),
                'unselected-parent': (!params.row.fundFinanceId && params.row.parent),
                'unselected-child': (!params.row.fundFinanceId && !params.row.parent),
                'master-border': true
            });
        },
        sortable: false
    },
    {
        field: 'unusedMaster',
        headerName: 'Unused Master',
        ...valueCurrency,
        width: 130,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
]

// Fund Finance Configuration Page
export default function FundFinanceConf() {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.forecast.loading);
    const fundFinances = useAppSelector(state => retrieveFundFinanceSelector(state, false));

    const [fundFinanceForm, setFundFinanceForm] = useState<FundFinanceDetails | null>(null)
    const [remove, setRemove] = useState<number | null>(null);
    const [showSelected, setShowSelected] = useState<boolean>(false);
    const [showLoans, setShowLoans] = useState<boolean>(true);

    let totals = {
        'Master Limit': 0,
        Drawn: 0,
        Undrawn: 0,
        Commitment: 0,
        'Unused Master': 0,
    }

    const data = fundFinances.filter(f => {
        if (f.fundFinanceId) {
            totals['Master Limit'] = addValues(totals['Master Limit'], f.adjustedMasterLimit);
            totals.Drawn = addValues(totals.Drawn, f.currentBalance);
            totals.Undrawn = addValues(totals.Undrawn, f.undrawnBalance);
            totals.Commitment = addValues(totals.Commitment, f.currentLimit);
            totals['Unused Master'] = addValues(totals['Unused Master'], f.unusedMaster);
        }

        return (!showSelected || f.fundFinanceId)
    }).sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }).reduce((finances: Array<any>, f) => {
        finances.push({
            ...f,
            parent: true
        });
        if (showLoans) {
            f.loans.forEach((loan: any) => {
                finances.push({
                    id: `${f.id}${loan.trancheId}` || loan.ncinoId,
                    ...loan
                })
            })
        }
        return finances;
    }, []);

    return (
        <>
            <Page title="Metrics - CB - Fund Finance">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Portfolio - Fund Finances' configuration/>
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <Grid container direction='column' sx={{width: '100%', height: '100%'}}>
                                <DataTable
                                    title='Fund Finances'
                                    columns={[
                                        ...FundFinanceColumns,
                                        {
                                            ...actionRow,
                                            ...warningsGeneralRow,
                                            align: 'center',
                                            cellClassName: fundFinanceClassName,
                                            renderCell: (params: GridCellParams) => {
                                                if (params.row.parent) {
                                                    if (params.row.fundFinanceId) {
                                                        return (
                                                            <>
                                                                <IconButton
                                                                    sx={{color: 'primary.main'}}
                                                                    style={{padding: '0px', outline: 'none'}}
                                                                    onClick={() => setFundFinanceForm(params.row)}
                                                                    size="small">
                                                                    <EditIcon/>
                                                                </IconButton>
                                                                <IconButton
                                                                    sx={{color: 'primary.main'}}
                                                                    style={{padding: '0px', outline: 'none'}}
                                                                    onClick={() => setRemove(params.row.fundFinanceId)}
                                                                    size="small">
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <>
                                                                <IconButton
                                                                    sx={{color: 'primary.main'}}
                                                                    style={{padding: '0px', outline: 'none'}}
                                                                    onClick={() => setFundFinanceForm(params.row)}
                                                                    size="small">
                                                                    <AddIcon/>
                                                                </IconButton>
                                                            </>
                                                        )
                                                    }
                                                } else {
                                                    return null
                                                }
                                            }
                                        }
                                    ]}
                                    data={data}
                                    loading={loading}
                                    showExport={false}
                                    additionalDataGridSx={{
                                        '& .selected-parent': {
                                            bgcolor: 'info.lighter',
                                            fontWeight: 'bold',
                                        },
                                        '& .unselected-parent': {
                                            bgcolor: 'grey.400',
                                            fontWeight: 'bold',
                                        },
                                        '& .unselected-child': {
                                            bgcolor: 'grey.300',
                                        },
                                        '& .master-border': {borderLeft: 2}
                                    }}
                                    customFilterComponent={
                                        <>
                                            <ShowTranches showLoans={showLoans} setShowLoans={setShowLoans}/>
                                            <SelectedFilter selected={showSelected} setSelected={setShowSelected}/>
                                        </>
                                    }
                                />
                                <Box sx={{bgcolor: 'primary.main', borderRadius: 1}}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                {FundFinanceColumns.map((column, c) => {
                                                    const sx = {color: 'common.white', ...(column.flex) ? {flex: column.flex} : {...(column.width) ? {width: column.width} : {}}, ...(column.minWidth) ? {minWidth: column.minWidth} : {}}
                                                    const totalCol = totals[column.headerName as keyof typeof totals];

                                                    return (
                                                        <TableCell
                                                            key={c}
                                                            align={column.align}
                                                            sx={sx}
                                                            style={{fontWeight: 'bold'}}
                                                        >
                                                            {c === 0 &&
                                                                <>Total (Selected)</>
                                                            }
                                                            {c > 2 && totalCol && (
                                                                <>
                                                                    {fCurrency(totalCol)}
                                                                </>
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}
                                                <TableCell sx={{width: 60}}/>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
            {fundFinanceForm &&
                <SideDrawer
                    title={'Fund Finance'}
                    open={!!fundFinanceForm}
                    onClose={() => setFundFinanceForm(null)}
                >
                    <FundFinanceForm
                        fundFinance={fundFinanceForm}
                        onClose={() => setFundFinanceForm(null)}
                    />
                </SideDrawer>
            }
            <AlertDialog
                title='Remove Fund Finance'
                info='Are you sure you want to remove this fund finance?'
                open={!!remove}
                handleClose={() => setRemove(null)}
                handleConfirm={() => {
                    dispatch(removeFundFinance(remove));
                    setRemove(null);
                }}
            />
        </>
    )
}

function SelectedFilter({selected, setSelected}: { selected: boolean, setSelected: (selected: boolean) => void }) {
    return (
        <>
            <Box sx={{px: 1}}>
                <FormControlLabel
                    control={<Checkbox checked={selected} onChange={() => setSelected(!selected)} color={'secondary'}/>}
                    label='See only selected:'
                    labelPlacement='start'
                />
            </Box>
        </>
    )
}

function ShowTranches({showLoans, setShowLoans}: { showLoans: boolean, setShowLoans: (showLoans: boolean) => void }) {
    return (
        <Box sx={{px: 1}}>
            <FormControlLabel
                control={<Checkbox checked={!showLoans} onChange={() => setShowLoans(!showLoans)} color={'secondary'}/>}
                label='Hide Loans:'
                labelPlacement='start'
            />
        </Box>
    )
}