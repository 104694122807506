import {memo} from "react";
import {fCurrency} from "../../../../utils/formatNumber";
import {ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {useAppSelector} from "../../../../store/store";
import {balancesSelector, FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";
import {Grid, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {DrillDownSection} from "../../../../components";
import {OtherTransactionTypes} from "../../../../types/capitalBudgetEnums";
import {OtherTransaction} from "../../../../types/forecastTypes";
import {addValues} from "../../../../utils/mathUtil";

// DRILLDOWN FROM FUM SUMMARY
function FUMDrillDown({fund, period}: { fund: FundDetails | null, period: ForecastPeriod }) {
    const balances = useAppSelector(state => balancesSelector(state));

    const adjustments = period.otherTransactions.reduce((total, a) => {
        if ((a.transactionType === OtherTransactionTypes.FUM_ADJUSTMENT) && ((fund && fund.label === a.fund) || !fund)) {
            total = addValues(total, a.amount);
        }
        return total;
    }, 0);

    const fundTotal = balances.balances.find(b => b.fund === fund?.label)?.fum || 0

    const total = (!fund ? balances.totals.fum : fundTotal) + adjustments;

    return (
        <>
            <Grid>
                <Table size="small">
                    <TableBody>
                        <DrillDownSection
                            header={'Funds Total'}
                            value={(!fund) ? balances.totals.fum : fundTotal}
                            retrieveValues={() => balances.balances.filter(b => (!fund || (fund && fund.label === b.fund))).map(b => {
                                return {
                                    id: b.fund,
                                    name: b.fund,
                                    date: null,
                                    value: b.fum
                                }
                            })}
                        />
                        <DrillDownSection
                            header={'Adjustments'}
                            value={adjustments}
                            retrieveValues={() => period.otherTransactions.filter((a: OtherTransaction) => ((a.transactionType === OtherTransactionTypes.FUM_ADJUSTMENT) && ((fund && fund.label === a.fund) || !fund))).map(a => {
                                return {
                                    id: a.id,
                                    name: a.fund,
                                    date: a.date,
                                    value: a.amount
                                }
                            })}
                        />
                        <TableRow sx={{bgcolor: 'info.lighter'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Investor/Unit Capital
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                >
                                    {fCurrency(total || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </>
    );
}

export default memo(FUMDrillDown)