import {useState} from "react";
import {fCurrency} from "../utils/formatNumber";
import {addValues} from "../utils/mathUtil";
import {dateSortComparator, formatDate} from "../utils/DateUtils";
import {IconButton, SxProps, TableCell, TableRow, Typography} from "@mui/material";
import {Add as AddIcon, Remove as RemoveIcon} from "@mui/icons-material";

export type DrillDownValue = {
    id: any,
    date: Date | number | null,
    name: string,
    value: number,
    sx?: SxProps
}

interface DrillDownSectionProps {
    header: string,
    value: number,
    retrieveValues: () => Array<DrillDownValue>
    noExpand?: boolean,
    sx?: any
}

export default function DrillDownSection({header, value, retrieveValues, noExpand, sx = {}}: DrillDownSectionProps) {
    const [expand, setExpand] = useState<boolean>(false);

    return (
        <>
            <TableRow sx={{height: 35}}>
                <TableCell
                    sx={{width: 5, py: 0, px: 0, ...sx}}
                    align='center'
                >
                    {(value !== 0 && (!noExpand)) &&
                        <IconButton
                            size='small'
                            onClick={() => setExpand(!expand)}
                        >
                            {expand ? <RemoveIcon/> : <AddIcon/>}
                        </IconButton>
                    }
                </TableCell>
                <TableCell colSpan={2} sx={{py: 0, ...sx}} align='left'>{header}</TableCell>
                <TableCell sx={{py: 0, ...(value < 0) ? {color: 'red'} : {}, ...sx}}
                           align='right'>{fCurrency(value)}</TableCell>
            </TableRow>
            {expand &&
                handleValueFunction(retrieveValues).map((row, id) => (
                    <TableRow key={id} sx={{...(row.sx) ? row.sx : {}}}>
                        <TableCell sx={{border: 'none'}}/>
                        <TableCell sx={{
                            border: 'none',
                            width: 110
                        }}><Typography>{(row.date) ? formatDate(row.date) : null}</Typography></TableCell>
                        <TableCell sx={{
                            border: 'none',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis'
                        }}><Typography>{row.name}</Typography></TableCell>
                        <TableCell sx={{border: 'none'}}
                                   align='right'><Typography
                            sx={{...(row.value < 0) ? {color: 'red'} : {}}}>{fCurrency(row.value)}</Typography></TableCell>
                    </TableRow>
                ))

            }
        </>
    )
}

function handleValueFunction(retriever: () => Array<{
    id: any,
    date: Date | number | null,
    name: string,
    value: number,
    sx?: SxProps
}>) {
    try {
        return [...retriever().reduce((r: any, o: any) => {
            const key = o.date + '-' + o.name;
            const item = r.get(key) || Object.assign({}, 0, {
                id: o.id,
                date: o.date,
                name: o.name,
                value: 0,
                sx: o?.sx || {}
            });

            item.value = addValues(item.value, o.value);

            return r.set(key, item);
        }, new Map()).values()].sort((a, b) => dateSortComparator(a.date, b.date)).filter(v => v.value !== 0);
    } catch (e) {
        return []
    }
}