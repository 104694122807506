import {useEffect, useState} from 'react';
import {Formik, FormikProps} from "formik";
import * as Yup from "yup";
import _ from "lodash";
// Utils
import {checkDateBefore, checkDateSameOrBefore, formatDate} from "../../../utils/DateUtils";
import {addValues} from "../../../utils/mathUtil";
import {fCurrency} from "../../../utils/formatNumber";
// Types
import {InputTypes} from "../../../types/InputTypes";
//Components
import {FormInput} from "../../../components";
// MUI
import {Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
// Store
import {useAppSelector} from "../../../store/store";
import {forecastedPortfolioMap} from "../../../store/capitalBudget/selectors/portfolioSelector";
import {retrieveGroupedPortfolioWMatChanges} from "../../../store/capitalBudget/selectors/maturitySelector";

interface FundValues {
    fund: string,
    percentage: number,
    commitment: number,
    amount: number,
    newTransferDate: Date | null,
}

type TrancheSelldownRepayFormProps = {
    onClose: () => void,
    submitTransactions: (values: any) => void,
}

const defaultTransactionValues = {
    portfolio: null,
    funds: [],
    total: 0,
    trancheId: null,
    date: null
}

const SelldownsSchema = Yup.object().shape({
    portfolio: Yup.object().required('Portfolio is required'),
    date: Yup.date().required('Date is required')
        .test({
            name: 'date-minimum',
            message: 'Date must be after tranche start date.',
            test: function (value) {
                const tranche = this.parent.portfolio;
                return checkDateSameOrBefore(tranche?.start_date, value);
            }
        })
        .test({
            name: 'date-maximum',
            message: 'Date must be before tranche maturity.',
            test: function (value) {
                const tranche = this.parent.portfolio;
                if (!tranche) return false;
                const maturity = tranche.maturityChange?.amendedMaturity || tranche.maturity;
                return checkDateSameOrBefore(value, maturity);
            }
        }),
    funds: Yup.array().of(Yup.object().shape({
        amount: Yup.number().required('Amount is required')
            .min(0, 'Amount must be greater than 0')
            .test({
                name: 'amount-check',
                message: 'Amount invalid. Amount exceeds expected commitment.',
                test: function (value) {
                    const fund = this.parent;
                    const grandparent = this.options.context;
                    const date = grandparent?.date;
                    // Checks if the new amount will exceed expected or later commitment changes

                    if (!fund) return false;

                    let commitment = fund?.commitment || 0;

                    // check if commitment is transferred in:
                    if (fund.newTransferDate && checkDateBefore(date, fund.newTransferDate)) {
                        commitment = 0;
                    }

                    return ((value || 0) <= (commitment));
                }
            }),
    })),
    total: Yup.number().min(0.01, 'Total Selldown must be greater than 0')
        .test({
            name: 'total-commitment-check',
            message: 'Total selldown must be less than total commitment',
            test: function (value) {
                const tranche = this.parent.portfolio;
                return (value || 0) <= tranche?.commitment;
            }
        })
})

// Selldowns Form at a Tranche level
export default function TrancheSelldownRepayForm({onClose, submitTransactions}: TrancheSelldownRepayFormProps) {

    const portfolio = useAppSelector(state => retrieveGroupedPortfolioWMatChanges(state));
    const portfolioMap = useAppSelector(state => forecastedPortfolioMap(state));

    return (
        <>
            <Formik
                initialValues={defaultTransactionValues}
                onSubmit={(v) => {
                    const values = _.cloneDeep(v);

                    const selldowns = v.funds.reduce((selldowns: Array<any>, s: FundValues) => {
                        if (s.amount > 0) {
                            selldowns.push({
                                trancheId: values.trancheId,
                                fund: s.fund,
                                date: values.date,
                                amount: s.amount,
                            })
                        }

                        return selldowns;
                    }, [])

                    submitTransactions(selldowns);
                }}
                validationSchema={SelldownsSchema}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        resetForm,
                        setFieldValue,
                        values
                    } = props;

                    try {
                        if (!values.portfolio && values.trancheId) resetForm();
                        else if (values.portfolio && (values.trancheId !== values.portfolio.tranche_id)) {
                            setFieldValue('trancheId', values.portfolio.tranche_id);

                            const tranche = portfolioMap.get(values.portfolio.tranche_id);
                            if (tranche) {
                                const funds = Array.from(tranche.keys()).map(f => {
                                    const fund = tranche.get(f);
                                    return {
                                        fund: f,
                                        commitment: fund.commitment,
                                        percentage: fund.commitment / values.portfolio.commitment,
                                        amount: 0,
                                        sellRepay: fund.sellRepay,
                                        transfersIn: fund.transfersIn,
                                        transfersOut: fund.transfersOut,
                                        newTransferDate: fund.newTransferDate
                                    }
                                });
                                setFieldValue('funds', funds);

                            } else {
                                // Reset form if tranche is not found
                                resetForm()
                            }
                        }

                    } catch (e) {
                        console.error(e);
                        resetForm();
                    }

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    <FormInput
                                        id='portfolio'
                                        label='Portfolio'
                                        fieldType={InputTypes.SEARCH}
                                        values={portfolio}
                                        labelFunc={(option) => `${option.tranche_id} - ${option.borrower}`}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    {/* Loan information */}
                                    {values.portfolio?.tranche_id &&
                                        <Grid container item xs={12}>
                                            <Grid item xs={12} sx={{px: 2, py: 1}}>
                                                <Typography variant='h6'
                                                            sx={{color: 'primary.main'}}><b>Loan:</b></Typography>
                                            </Grid>
                                            <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                                                <Typography variant='body1' sx={{color: 'primary.main'}}><b>Start
                                                    Date:</b></Typography><Typography variant='body1' sx={{
                                                pl: 5,
                                                fontSize: 16
                                            }}>{new Date(values.portfolio.start_date).toDateString()}</Typography>
                                            </Grid>
                                            <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                                                <Typography variant='body1' sx={{color: 'primary.main'}}><b>Maturity
                                                    Date:</b></Typography><Typography variant='body1' sx={{
                                                pl: 5,
                                                fontSize: 16, ...(values.portfolio.maturityChange ? {textDecoration: 'line-through'} : {})
                                            }}>{new Date(values.portfolio.maturity).toDateString()}</Typography>{values.portfolio.maturityChange &&
                                                <Typography variant='body1' sx={{
                                                    pl: 1,
                                                    fontSize: 16
                                                }}>{'  >  '}{values.portfolio.maturityChange && new Date(values.portfolio.maturityChange.amendedMaturity).toDateString()}</Typography>}
                                            </Grid>
                                            <Grid container item xs={12} sx={{px: 5, py: 1}} direction='row'>
                                                <Typography variant='body1' sx={{color: 'primary.main'}}>
                                                    <b>
                                                        Current Commitment:
                                                    </b></Typography><Typography variant='body1' sx={{
                                                pl: 5,
                                                fontSize: 16
                                            }}>{fCurrency(values.portfolio.commitment)}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    {values.portfolio?.tranche_id &&
                                        <FormInput
                                            id='date'
                                            label='Selldown/Repayment Date'
                                            fieldType={InputTypes.DATE}
                                            layout={{xs: 12, md: 12, lg: 12}}
                                            minDate={(!!values.portfolio.start_date) ? new Date(values.portfolio.start_date) : new Date()}
                                            maxDate={(new Date(values.portfolio.maturityChange?.amendedMaturity || values.portfolio.maturity))}
                                        />
                                    }
                                    {values.portfolio &&
                                        <TrancheFormTable values={values} formikProps={props}/>
                                    }
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Create Selldowns/Repayments
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}


const TrancheFormTable = ({values, formikProps}: { values: any, formikProps: FormikProps<any> }) => {

    const [prevValues, setValues] = useState(values);

    // Handle Equal division of tranche
    useEffect(() => {
        // work out total
        let total = 0;
        values.funds.forEach((fund: any) => {
            total += fund.amount
        })

        // Check if total is not equal to commitment (with tolerance
        // Second check is to prevent infinite loop
        if ((Math.abs(addValues(total, -values.total)) > 0.01) && (Math.abs(addValues(prevValues.total, -values.total)) > 0.1)) {
            values.funds.forEach((fund: FundValues, f: number) => {
                formikProps.setFieldValue(`funds[${f}].amount`, (values.total * fund.percentage))
            })
            setValues(values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.total])

    useEffect(() => {
        // Calculate total of individual funds
        if (formikProps.touched.funds) {
            let total = 0;
            values.funds.forEach((fund: FundValues) => {
                total += fund.amount
            })

            if (total !== values.total && prevValues.total !== total) {
                formikProps.setFieldValue(`total`, total);
                setValues(values);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.funds, formikProps.touched]);

    return (
        <>
            <Grid item md={12} sx={{p: 1}}>
                <Table sx={{border: 2, borderColor: 'primary.main'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{
                                p: '4px',
                                px: 2,
                                border: 'none',
                                bgcolor: 'primary.main',
                                borderColor: 'primary.main',
                                color: 'white',
                                width: 200
                            }}>
                                Fund
                            </TableCell>
                            <TableCell sx={{
                                p: '4px',
                                px: 2,
                                border: 'none',
                                bgcolor: 'primary.main',
                                borderColor: 'primary.main',
                                color: 'white'
                            }} align='right'>
                                Commitment
                            </TableCell>
                            <TableCell sx={{
                                p: '4px',
                                px: 2,
                                border: 'none',
                                bgcolor: 'primary.main',
                                borderColor: 'primary.main',
                                color: 'white'
                            }} align='right'>
                                Amount
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{p: 0, px: 1, borderBottom: 2}}>
                                Metrics Total
                            </TableCell>
                            <TableCell sx={{p: 0, px: 2, borderBottom: 2}} align='right'>
                                {fCurrency(values.portfolio.commitment)}
                            </TableCell>
                            <TableCell sx={{p: 0, px: 2, borderBottom: 2, bgcolor: 'grey.200'}} align='right'>
                                <FormInput
                                    id='total'
                                    label=''
                                    fieldType={InputTypes.CURRENCY}
                                    layout={{xs: 12, md: 12, lg: 12}}

                                    size='small'
                                    variant='standard'
                                    // noUnderline
                                    noLabel
                                />
                            </TableCell>
                        </TableRow>
                        {values.funds.map((fund: FundValues, f: number) => (
                            <TableRow key={f}>
                                <TableCell sx={{p: 0, px: 1}}>
                                    {fund.fund} {fund.newTransferDate && <>from {formatDate(fund.newTransferDate, 'dd-MM-yyyy')}</>}
                                </TableCell>
                                <TableCell sx={{p: 0, px: 2}} align='right'>
                                    {fCurrency(values.funds[f]?.commitment || 0)}
                                </TableCell>
                                <TableCell sx={{p: 0, px: 2, bgcolor: 'grey.200'}} align='right'>
                                    <FormInput
                                        id={`funds[${f}].amount`}
                                        label=''
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                        size='small'
                                        variant='standard'
                                        // noUnderline
                                        noLabel
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </>
    )
}