import {memo} from "react";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";
import {DrillDownsSimple} from "../../../../components";
import {spIndustries} from "../../../../utils/spIndustryUtils";

interface ExposureDrilldownProps {
    column: investmentReportColumn,
    avaCapToggle: boolean,
}

function ExposureDrilldown({column, avaCapToggle}: ExposureDrilldownProps) {
    return (
        <>
            <DrillDownsSimple
                data={[
                    ...Object.keys(spIndustries).reduce((sectors, sector) => {
                        if (!column[sector] && column[sector] !== 0) {
                            sectors.push({
                                label: sector,
                                value: ((avaCapToggle) ? column.analyAUM : column.totalCommitment) * (column[`sector${sector}`] || 0)
                            })
                        }
                        return sectors
                    }, [] as Array<{ label: string, value: number }>),
                    ...(avaCapToggle) ? [{
                        label: 'Available Capital',
                        value: column.availCap
                    }] : []
                ]}
            />
        </>
    )
}

export default memo(ExposureDrilldown)