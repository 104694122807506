import {memo} from "react";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";
import {Grid, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {fCurrency} from "../../../../utils/formatNumber";

interface PriorAdjustmentsDrilldownProps {
    data: Array<investmentReportColumn>,
    column: string,
    periodType: 'base' | 'week' | 'month',
    adjustmentTotal: number,
}

function PriorAdjustmentsDrilldown({data, column, periodType, adjustmentTotal}: PriorAdjustmentsDrilldownProps) {


    const periodIndex = data.findIndex((col) => col.label === column && col.type === periodType);

    const priorAdjustments = data.reduce((adjustments, col, c) => {
        if (col.type === periodType && c < periodIndex) {
            adjustments.push({
                label: `${col.label} ${col.labelTwo}`,
                amount: col.adjustments
            })
        }

        return adjustments
    }, [] as Array<{ label: string, amount: number }>)

    return (
        <>
            <Grid>
                <Table size="small">
                    <TableBody>
                        {priorAdjustments.map((adjustment, i) => (
                            <TableRow sx={{bgcolor: 'info.lighter'}} key={i}>
                                <TableCell colSpan={3}>
                                    <Typography
                                        variant='h6'
                                    >
                                        {adjustment.label}
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography
                                        variant='h6'
                                        sx={{...(((adjustment.amount || 0) < 0) ? {color: 'red'} : {})}}
                                    >
                                        {fCurrency(adjustment.amount || 0)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                        {/*Total Prior Adjustments*/}
                        <TableRow sx={{bgcolor: 'primary.light'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'common.white'}}
                                >
                                    Total Previous Adjustments
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'common.white'}}
                                >
                                    {fCurrency(adjustmentTotal || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </>
    )
}

export default memo(PriorAdjustmentsDrilldown);