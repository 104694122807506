import {CalculationLoanType} from "../../../types/capitalBudgetTypes";
import {Period} from "../../../types/GeneralTypes";
import {InvestmentType, LoanTags, PeriodType} from "../../../types/capitalBudgetEnums";
import {
    checkDateSameOrBefore,
    checkInPeriod,
    daysBetweenDates,
    findlastDayOfMonth,
    findNextDayOfTheWeek
} from "../../../utils/DateUtils";
import {addValues, roundToDecimal} from "../../../utils/mathUtil";

/**
 * Handling New Loans
 * @param loan
 * @param budgetDate
 * @param period
 * @param periodType
 * @param periodMultiplier
 * @param ctcWeekBuffer
 * @param periodTypeMultiplier
 */
function handleNewLoan(loan: CalculationLoanType, budgetDate: number | Date, period: Period, periodType: PeriodType, periodMultiplier: number, ctcWeekBuffer: number, periodTypeMultiplier: number) {
    /**
     * HANDLING NEW LOANS AND HANDLING CALCS INCLUDING
     * - WHEN LOANS BECOMES NEW COMMITMENT
     * - WHEN LOANS BECOME ACTIVE
     * - WHEN LOANS WILL BE REPAID
     * - CTC:
     *      - CRE
     *      - CORP
     */
    let updatedValue = loan.updatedValue;
    let updatedUndrawn = loan.updatedUndrawn;
    let updatedDrawn = loan.updatedDrawn;

    let updatedDrawnPercentage = loan.updatedDrawnPercentage;

    let updatedTenor = loan.updatedTenor;
    let ctcDrawdown = 0;
    let tags: Array<LoanTags> = [];

    // HANDLE WHEN LOAN INITIALISES IN THE BOOK
    if (checkInPeriod(loan.startDate, period)) {
        tags.push(LoanTags.NEW_LOAN);

        if (loan.drawn > 0) {
            tags.push(LoanTags.FIRST_DRAW);
        }
    }

    // CRE CTC STRAIGHT LINE DRAWDOWN - FOR:
    // - Loan Starts before a periods end date,
    // - Period Start Date is before loan end date
    // - That are not of type: 'Revolving', 'Capex', 'Equity', and
    // - Undrawn or partially drawn
    if (checkDateSameOrBefore(loan.startDate, period.lastDate) && checkDateSameOrBefore(period.startDate, loan.endDate)) {
        const firstPeriod = (periodType === PeriodType.WEEK) ? findNextDayOfTheWeek(new Date(loan.startDate), 7) : findlastDayOfMonth(new Date(loan.startDate));
        const startingPeriod = (periodType === 'week') ?
            Math.floor((daysBetweenDates(budgetDate, firstPeriod) / 7) + 1)
            :
            Math.floor((daysBetweenDates(budgetDate, firstPeriod) / 365) * periodTypeMultiplier + 1);
        const lastPeriod = (periodType === 'week') ? findNextDayOfTheWeek(new Date(loan.endDate), 7) : findlastDayOfMonth(new Date(loan.endDate));
        const endingPeriod = (periodType === 'week') ?
            Math.floor((daysBetweenDates(budgetDate, lastPeriod) / 7) - ctcWeekBuffer)
            :
            Math.floor((daysBetweenDates(budgetDate, lastPeriod) / 365) * periodTypeMultiplier - 1);

        const numberOfPeriods = endingPeriod - startingPeriod;

        const periodCTC = roundToDecimal(updatedUndrawn / numberOfPeriods);

        if (startingPeriod <= periodMultiplier && periodMultiplier < endingPeriod) {
            const totalCTCDraw = periodCTC * (periodMultiplier + 1 - startingPeriod)
            updatedUndrawn = addValues(updatedUndrawn, -(totalCTCDraw));
            // For Initial period will capture all CTC incurred previous to period start
            if (periodMultiplier === 0) {
                ctcDrawdown = totalCTCDraw;
                if (loan.investmentType === InvestmentType.REAL_ESTATE) {
                    tags.push(LoanTags.CRE_CTC);
                    tags.push(LoanTags.CRE_CTC_NEW);
                } else {
                    tags.push(LoanTags.CORP_CTC_NEW);
                }
            } else { // Captures all CTC in between periods
                ctcDrawdown = periodCTC;
                if (loan.investmentType === InvestmentType.REAL_ESTATE) {
                    tags.push(LoanTags.CRE_CTC);
                } else {
                    tags.push(LoanTags.CORP_CTC_NEW);
                }
            }
        }
        if (periodMultiplier >= endingPeriod) {
            if (loan.investmentType === InvestmentType.REAL_ESTATE) {
                updatedUndrawn = 0;
            }
        }
    }

    updatedDrawn = addValues(updatedValue, -updatedUndrawn);
    updatedDrawnPercentage = updatedDrawn / updatedValue;

    if (checkDateSameOrBefore(loan.startDate, period.lastDate)) {
        tags.push(LoanTags.ACTIVE);

        updatedTenor = (daysBetweenDates(new Date(period.lastDate), new Date(loan.endDate)) / 365);
        if (updatedTenor < 0) {
            updatedTenor = 0;
        }
    }

    if (checkInPeriod(loan.endDate, period)) {
        loan.tags.push(LoanTags.REPAYMENT);
    }

    return {
        ...loan,
        updatedValue,
        updatedDrawnPercentage,
        updatedUndrawn,
        updatedDrawn,
        updatedTenor,
        ctcDrawdown,
        tags
    }
}

export default handleNewLoan;