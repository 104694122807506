import {Navigate, Route, Routes} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";
// REDUX STORE
import {useEffect} from "react";
import {useAppSelector, useAppDispatch} from "../../store/store";
import {initialBudgetRetrieval} from "../../store/forecast/forecastFunctions";
import {checkInputs, generateCapitalBudget} from "../../store/capitalBudget/capitalBudgetFunctions";
// Pages
import Page404 from "../Page404";
import Summary from "./Summary";
import SourcesUses from "./SourcesUses";
import ForecastCapital from "./ForecastCapital";
import CapitalForecast from "./CapitalForecast";
import CapitalTransactions from "./CapitalTransactions";
import Portfolio from "./Portfolio";
import NewCommitments from "./NewCommitments";
import AccountBalances from "./AccountBalances";
import OtherData from "./OtherData";
import {
    Allocations,
    CapitalChanges,
    FundFinanceConf,
    MaturityChange,
    OtherTransactions,
    RefinanceConf,
    SellRepay,
    Transfers,
    WIP
} from './Configuration'
import FundForecast from "./FundForecast";
import Refinances from "./Refinances";
import FundFinance from "./FundFinance";
import DebtMaturityProfile from "./DebtMaturityProfile";

// ----------------------------------------------------------------------

// Landing Page
export default function Main() {
    const dispatch = useAppDispatch();
    const forecast = useAppSelector(state => state.forecast.forecast);
    const forecastData = useAppSelector(state => state.capitalBudget.forecastData);
    const loading = useAppSelector(state => state.forecast.loading);
    const checksLoading = useAppSelector(state => state.capitalBudget.loading);
    const user = useAppSelector(state => state.user.user);
    const calculating = useAppSelector(state => state.capitalBudget.calculating);
    const warnings = useAppSelector(state => state.capitalBudget.warnings);
    const changes = useAppSelector(state => state.capitalBudget.changes);

    const millions = useAppSelector(state => state.general.millions);

    const prevForecastData = usePrevious(forecastData);

    if (process.env.REACT_APP_ENV !== 'LOCAL_DEV' && user && changes) {
        window.onbeforeunload = () => {
            return ''
        }
    }

    // Retrieves Forecasts
    useEffect(() => {
        if (user && !forecast && !loading) {
            dispatch(initialBudgetRetrieval());
        }
    }, [dispatch, user, forecast, loading])

    // Checks Inputs
    useEffect(() => {
        if (!!forecast && !calculating && !warnings && !loading && !checksLoading) {
            console.log('INPUT CHECK 1')
            dispatch(checkInputs());
        }
    }, [forecast, calculating, warnings, dispatch, loading, checksLoading])

    // INITIAL CALC
    useEffect(() => {
        if (!!forecast && !calculating && !!forecastData && forecastData !== prevForecastData) {
            console.log('CALCULATE 1')
            dispatch(generateCapitalBudget())
        }
    }, [forecast, calculating, forecastData, prevForecastData, dispatch])

    useEffect(() => {
    }, [millions]);

    return (
        <Routes>
            <Route path='/' element={<Navigate to='home'/>}/>

            <Route path='home' element={<Summary/>}/>
            <Route path='/sourcesUses' element={<SourcesUses/>}/>
            <Route path='/forecastCapital' element={<ForecastCapital/>}/>
            <Route path='/fundForecast' element={<FundForecast/>}/>
            <Route path='/debt-profile' element={<DebtMaturityProfile/>}/>
            <Route path='/capital' element={<Navigate to='forecast' />}/>
            <Route path='/capital/forecast' element={<CapitalForecast/>}/>
            <Route path='/capital/transactions' element={<CapitalTransactions/>}/>

            <Route path='/portfolio' element={<Portfolio/>}/>
            <Route path='/newCommitments' element={<NewCommitments/>}/>
            <Route path='/balances' element={<AccountBalances/>}/>
            <Route path='/refinance' element={<Refinances/>}/>
            <Route path='/fund-finance' element={<FundFinance/>}/>

            <Route path='/data' element={<OtherData/>}/>
            <Route path='/configuration' element={<Navigate to='portfolio-changes'/>}/>

            <Route path='/configuration/portfolio-changes' element={<MaturityChange/>}/>
            <Route path='/configuration/wip' element={<WIP/>}/>
            <Route path='/configuration/allocations' element={<Allocations/>}/>
            <Route path='/configuration/refinance' element={<RefinanceConf/>}/>
            <Route path='/configuration/fund-finance' element={<FundFinanceConf/>}/>
            <Route path='/configuration/transfers' element={<Transfers/>}/>
            <Route path='/configuration/sell-repay' element={<SellRepay/>}/>
            <Route path='/configuration/capital' element={<CapitalChanges/>}/>
            <Route path='/configuration/other' element={<OtherTransactions/>}/>

            <Route path='/*' element={<Page404/>}/>

        </Routes>
    );
}
