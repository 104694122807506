
// Add 2 values with precession
import {FXRate} from "../types/externalDataTypes";

export function addValues(vOne: number, vTwo: number, precision: number = 10): number {
    const multiplier = Math.pow(10, precision);

    return (((vOne) ? vOne : 0) * multiplier + ((vTwo) ? vTwo : 0)  * multiplier)/multiplier;
}

export function addArrayValues(values: Array<number>, precision: number = 10): number {
    let total = 0;

    values.forEach(value => total = addValues(total, value, precision));

    return total
}

export function roundToDecimal(value: number, decimals: number = 2): number {
    if (isNaN(value)) {
        return NaN
    } else {
        return Number(value.toFixed(decimals));
    }
}

export function convertToAUD(fxRates: Array<FXRate>, amount: number, currency: string): number | null {
    try {
        if (currency === 'AUD') {
            return amount;
        }
        const fxRate = fxRates.find(f => f.currency === currency);
        if (fxRate) {
            return roundToDecimal(amount * (1 / fxRate.rate)); // Need to take the inverse to convert to AUD
        } else {
            throw new Error("Currency " + currency + " could not be mapped against FX rates list.")
        }
    } catch (e) {
        console.log("Could not convert currency from " + currency + " to AUD.")
        console.log(e);
        return null;
    }
}