import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {InvestmentType, SaveStatus} from "../../../types/capitalBudgetEnums";
import {
    extractFacilityFee,
    extractnCinoBase,
    extractnCinoSpread,
    groupDebtSeniority
} from "../../../utils/CapitalBudgetUtils";
import {EditableTableRow} from "../../../components";
import {ratesSelector, underlyingFundsSelector} from "./generalSelectors";
import {CalculationLoanType} from "../../../types/capitalBudgetTypes";
import {addMonthsToDate} from "../../../utils/DateUtils";
import {NewDeal} from "../../../types/forecastTypes";
import {convertToAUD, roundToDecimal} from "../../../utils/mathUtil";
import {spIndustryLookupFromIndustry, spIndustryLookupFromSubIndustry} from "../../../utils/spIndustryUtils";


// Retrieves all wip loans in pipeline and if they are selected new loans
export const retrieveAllWIPLoans = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.ncinoWip?.deals || [],
    (state: RootState) => state.capitalBudget.forecastData?.newDeals || [],
    (wip, newLoans) => {

        return wip.map(w => {
            let selected = newLoans.findIndex(d => d.ncinoId === w.ncino_id && d.status !== SaveStatus.REMOVED);

            let lineFee: null | number = null;
            let establishmentFee: null | number = null;

            w.llc_bi_fees.forEach(f => {
                if (f.llc_bi_fee_type === 'Line Fee') {
                    lineFee = f.llc_bi_percentage / 100;
                }
                if (f.llc_bi_fee_type === 'Establishment Fee') {
                    establishmentFee = f.llc_bi_percentage / 100;
                }
            })

            return {
                ...w,
                dealName: w.llc_bi_product_package?.name,
                owner: w.llc_bi_product_package?.primary_origination_director?.name,
                industry: w.llc_bi_product_package?.industry,
                ranking: groupDebtSeniority(w.ranking_debt_seniority),
                rating: w.metrics_rating,
                tenor: (w.llc_bi_term_months || 0) / 12,
                upfront: establishmentFee,
                margin: extractnCinoSpread(w),
                lineFee: lineFee,
                currency: w.currency_iso_code,
                selected: !(selected === -1)
            }
        })
    }
)


// Retrieves loans that have been selected for funding
export const retrieveNewLoans = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.ncinoWip?.deals || [],
    (state: RootState) => state.capitalBudget.forecastData?.manualDeals || [],
    (state: RootState) => state.capitalBudget.forecastData?.newDeals || [],
    (state: RootState) => state.capitalBudget.warnings?.wipCheck.advise || [],
    (state: RootState) => state.capitalBudget.warnings?.wipCheck.alert || [],
    (wip, manualDeals, newLoans, wipAdvise, wipAlert) => {
        const loans: Array<EditableTableRow> = [];

        newLoans.forEach(loan => {
            if (loan.status !== SaveStatus.REMOVED) {
                if (loan.manual) {
                    const deal = manualDeals.find(d => d.id === loan.manual);
                    if (deal) {
                        loans.push({
                            ...deal,
                            ...loan,
                            name: `MANUAL - ${deal.name}`,
                            dealName: deal.client,
                            llc_bi_product: deal.trancheType,
                            llc_bi_cra_type_code: deal.investmentType,
                            llc_bi_amount: deal.commitment,
                            expected_drawdown_at_close: deal.drawdownAtClose,
                            llc_bi_close_date: deal.closeDate,
                            adjustedClose: deal.closeDate,
                            tenor: deal.tenor / 12,
                            disableEdit: true
                        })
                    }
                } else {
                    const wipLoan = wip.find(w => w.ncino_id === loan.ncinoId);

                    const alert = wipAlert.find(a => a.id === loan.id);
                    const advise = wipAdvise.find(a => a.id === loan.id);

                    if (wipLoan) {

                        loans.push({
                            ...wipLoan,
                            ...loan,
                            adjustedClose: (loan.amendedCloseDate) ? loan.amendedCloseDate : wipLoan.llc_bi_close_date,
                            amendedTenor: loan.amendedTenor || '',
                            dealName: wipLoan.llc_bi_product_package?.name,
                            owner: wipLoan.llc_bi_product_package?.primary_origination_director?.name,
                            tenor: (wipLoan.llc_bi_term_months || 0) / 12,
                            selected: true,
                            ...(advise) ? {advise: advise.flag} : {},
                            ...(alert) ? {alert: alert.flag} : {},
                        })
                    }
                }
            }
        })
        return loans;
    }
)

// Retrieves New Loan deals with flags and converted to AUD
export const retrieveNewLoansConfigFx = createSelector(
    retrieveNewLoans,
    ratesSelector,
    (loans, rates,) => {
        return loans.map(loan => {
            if (loan.currency_iso_code !== 'AUD') {
                return {
                    ...loan,
                    amendedCommitment: convertToAUD(rates, loan.amendedCommitment, loan.currency_iso_code),
                    amendedDrawdown: convertToAUD(rates, loan.amendedDrawdown, loan.currency_iso_code),
                    commitment: convertToAUD(rates, loan.commitment, loan.currency_iso_code),
                    llc_bi_amount: convertToAUD(rates, loan.llc_bi_amount, loan.currency_iso_code),

                    allocations: Object.keys(loan.allocation).reduce((allocations: {
                        [x: string]: number | null
                    }, fund) => {
                        allocations[fund] = convertToAUD(rates, loan.allocation[fund], loan.currency_iso_code);
                        return allocations;
                    }, {})
                }
            } else return loan
        })
    }
)

// Retrieves loans that have been selected for funding
export const retrieveNewLoansAllocations = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.ncinoWip?.deals || [],
    (state: RootState) => state.capitalBudget.forecastData?.manualDeals || [],
    (state: RootState) => state.capitalBudget.forecastData?.newDeals || [],
    (state: RootState) => state.capitalBudget.warnings?.wipCheck.advise || [],
    (state: RootState) => state.capitalBudget.warnings?.wipCheck.alert || [],
    underlyingFundsSelector,
    (_state: RootState, includeStatus: boolean) => includeStatus,
    (wip, manualDeals, newLoans, wipAdvise, wipAlert, funds, includeStatus) => {
        const loans: Array<any> = [];

        const fundAllocations = funds.reduce((allocations: { [x: string]: number }, fund) => {
            allocations[fund.label] = 0;
            return allocations;
        }, {})

        newLoans.forEach(loan => {
            if (loan.status !== SaveStatus.REMOVED) {
                if (loan.manual) {
                    const deal = manualDeals.find(d => d.id === loan.manual);
                    if (deal) {

                        loans.push({
                            id: loan.id,
                            ncinoId: loan.ncinoId,
                            name: deal.name,
                            dealName: deal.client,
                            llc_bi_lookupkey: null,
                            llc_bi_product: deal.trancheType,
                            llc_bi_cra_type_code: deal.investmentType,
                            owner: deal.name,

                            industry: deal.industry,
                            ranking: deal.ranking,
                            rating: deal.rating,

                            upfront: null,
                            margin: deal.margin,
                            lineFee: deal.facilityFeeRate,
                            currency: deal.baseCurrency,

                            ...fundAllocations,
                            ...loan.allocation,
                            //
                            adjustedCommitment: deal.commitment,
                            adjustedDrawdown: deal.drawdownAtClose,
                            adjustedCloseDate: deal.closeDate,
                            adjustedTenor: deal.tenor,

                            percentageUndrawn: ((deal.commitment - deal.drawdownAtClose) / deal.commitment),

                            ...(includeStatus) ? {status: loan.status} : {},
                            manual: loan.manual
                        })

                    }
                } else {
                    const wipLoan = wip.find(w => w.ncino_id === loan.ncinoId)

                    if (wipLoan) {

                        let lineFee: null | number = null;
                        let establishmentFee: null | number = null;

                        wipLoan.llc_bi_fees.forEach(f => {
                            if (f.llc_bi_fee_type === 'Line Fee') {
                                lineFee = f.llc_bi_percentage / 100;
                            }
                            if (f.llc_bi_fee_type === 'Establishment Fee') {
                                establishmentFee = f.llc_bi_percentage / 100;
                            }
                        })

                        const commitment = (loan.amendedCommitment) ? loan.amendedCommitment : wipLoan.llc_bi_amount || 0;
                        const drawdown = (loan.amendedDrawdown) ? loan.amendedDrawdown : wipLoan.expected_drawdown_at_close;

                        const alert = wipAlert.find(a => a.id === loan.id);
                        const advise = wipAdvise.find(a => a.id === loan.id);

                        loans.push({
                            id: loan.id,
                            ncinoId: loan.ncinoId,
                            name: wipLoan.name,
                            dealName: wipLoan.llc_bi_product_package?.name,
                            llc_bi_lookupkey: wipLoan.llc_bi_lookupkey,
                            llc_bi_product: wipLoan.llc_bi_product,
                            llc_bi_cra_type_code: wipLoan.llc_bi_cra_type_code,
                            owner: wipLoan.llc_bi_product_package?.primary_origination_director?.name,

                            industry: wipLoan.llc_bi_product_package?.industry,
                            ranking: groupDebtSeniority(wipLoan.ranking_debt_seniority),
                            rating: wipLoan.metrics_rating,

                            upfront: establishmentFee,
                            margin: extractnCinoSpread(wipLoan),
                            lineFee: lineFee,
                            currency: wipLoan.currency_iso_code,

                            ...fundAllocations,
                            ...loan.allocation,
                            //
                            adjustedCommitment: commitment,
                            adjustedDrawdown: drawdown,
                            adjustedCloseDate: (loan.amendedCloseDate) ? loan.amendedCloseDate : wipLoan.llc_bi_close_date,
                            adjustedTenor: (loan.amendedTenor) ? loan.amendedTenor : loan.tenor,

                            percentageUndrawn: ((commitment - drawdown) / commitment),

                            ...(includeStatus) ? {status: loan.status} : {},
                            ...(advise) ? {advise: advise.flag} : {},
                            ...(alert) ? {alert: alert.flag} : {},
                        })
                    }
                }
            }

        })

        return loans;
    }
)

// Retrieves all loans that have been selected for funding formatted for calculations
export const retrieveNewLoansForCalcs = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.ncinoWip?.deals || [],
    (state: RootState) => state.capitalBudget.forecastData?.manualDeals || [],
    (state: RootState) => state.capitalBudget.forecastData?.newDeals || [],
    ratesSelector,
    (_state: RootState, base?: boolean) => (base),
    (wip, manualDeals, newLoans, rates, base) => {
        let loans: Array<CalculationLoanType> = [];


        newLoans.reduce((nl: Array<NewDeal>, l) => {
            if (base) {
                if (l.status === SaveStatus.NEW) return nl;
                else if (l.status === SaveStatus.EDITED && l.previous) nl.push(l.previous);
                else nl.push(l)
            } else {
                if (l.status === SaveStatus.REMOVED) return nl;
                else nl.push(l)
            }
            return nl;
        }, []).forEach(deal => {
            if (deal.manual) {
                const manual = manualDeals.find(md => md.id === deal.manual);

                if (manual) {
                    const totalCommitment = manual.commitment;
                    const totalDrawdown = manual.drawdownAtClose;
                    const drawnPercentage = (totalDrawdown === 0) ? 0 : (totalDrawdown / totalCommitment);

                    const tenor = manual.tenor / 12;

                    const startDate = manual.closeDate;
                    const endDate = addMonthsToDate(startDate, tenor * 12);
                    const facilityFee = manual.facilityFeeRate;

                    const industries = spIndustryLookupFromIndustry(manual.industry);

                    Object.keys(deal.allocation).forEach((a) => {
                        const allocationAmount = convertToAUD(rates, deal.allocation[a], manual.baseCurrency) || 0;

                        let loan: CalculationLoanType = {
                            id: deal.id,

                            loanType: 'NCINO',

                            trancheId: null,
                            client: manual.client,
                            asset: manual.ncinoId as string,
                            name: manual.client,
                            assetName: manual.name,
                            tranche: manual.name,
                            fund: a,

                            value: allocationAmount,
                            updatedValue: allocationAmount,
                            drawn: roundToDecimal(allocationAmount * drawnPercentage),
                            updatedDrawn: roundToDecimal(allocationAmount * drawnPercentage),
                            drawnPercentage: drawnPercentage,
                            updatedDrawnPercentage: drawnPercentage,
                            undrawn: roundToDecimal(allocationAmount * (1 - drawnPercentage)),
                            updatedUndrawn: roundToDecimal(allocationAmount * (1 - drawnPercentage)),
                            tenor: tenor,
                            updatedTenor: tenor,

                            startDate: startDate,
                            endDate: endDate,
                            amendedMaturity: null,
                            maturityAmendmentType: null,
                            transferInDate: null,

                            ctcDrawdown: 0, // TODO: CHECK NECESSITY

                            margin: manual.margin,
                            facilityFee: facilityFee ? "Facility Limit" : null,
                            facilityFeeRate: facilityFee,
                            baseRate: manual.baseRate || 0,
                            baseRateFloor: null,

                            baseCurrency: manual.baseCurrency,
                            domicile: manual.domicile,

                            rating: manual.rating,
                            ranking: manual.ranking,
                            interestType: manual.interestType,
                            investmentType: manual.investmentType as InvestmentType,
                            industrySegment: manual.industry,
                            trancheType: manual.trancheType,
                            pricingType: manual.interestType,

                            sector: industries.sector,
                            industryGroup: industries.industryGroup,
                            industry: industries.industry,

                            selldowns: [],
                            transfersIn: [],
                            transfersOut: [],

                            tags: []
                        }

                        loans.push(loan)
                    })
                }
            } else {
                const wipLoan = wip.find(w => w.ncino_id === deal.ncinoId);

                if (wipLoan) {
                    const totalCommitment = (deal.amendedCommitment) ? deal.amendedCommitment : wipLoan.llc_bi_amount || 0;
                    const totalDrawdown = (deal.amendedDrawdown) ? deal.amendedDrawdown : wipLoan.expected_drawdown_at_close || 0;
                    const drawnPercentage = (totalDrawdown === 0) ? 0 : (totalDrawdown / totalCommitment);

                    const tenor = (deal.amendedTenor) ? deal.amendedTenor : ((wipLoan.llc_bi_term_months || 0) / 12);

                    const startDate = (deal.amendedCloseDate) ? deal.amendedCloseDate : wipLoan.llc_bi_close_date;
                    const endDate = addMonthsToDate(startDate, tenor * 12);
                    const facilityFee = extractFacilityFee(wipLoan);

                    const industries = spIndustryLookupFromSubIndustry(wipLoan.llc_bi_product_package?.industry)

                    Object.keys(deal.allocation).forEach((a) => {

                        const allocationAmount = convertToAUD(rates, deal.allocation[a], wipLoan.currency_iso_code) || 0;

                        let loan: CalculationLoanType = {
                            id: deal.id,

                            loanType: 'NCINO',

                            trancheId: null,
                            client: wipLoan.llc_bi_product_package.name,
                            asset: wipLoan.ncino_id,
                            name: wipLoan.name,
                            assetName: wipLoan.llc_bi_product_package.name,
                            tranche: wipLoan.tranche_name,
                            fund: a,

                            value: allocationAmount,
                            updatedValue: allocationAmount,
                            drawn: roundToDecimal(allocationAmount * drawnPercentage),
                            updatedDrawn: roundToDecimal(allocationAmount * drawnPercentage),
                            drawnPercentage: drawnPercentage,
                            updatedDrawnPercentage: drawnPercentage,
                            undrawn: roundToDecimal(allocationAmount * (1 - drawnPercentage)),
                            updatedUndrawn: roundToDecimal(allocationAmount * (1 - drawnPercentage)),
                            tenor: tenor,
                            updatedTenor: tenor,

                            startDate: startDate,
                            endDate: endDate,
                            amendedMaturity: null,
                            maturityAmendmentType: null,
                            transferInDate: null,

                            ctcDrawdown: 0, // TODO: CHECK NECESSITY

                            margin: extractnCinoSpread(wipLoan) || 0,
                            facilityFee: facilityFee ? "Facility Limit" : null,
                            facilityFeeRate: facilityFee,
                            baseRate: extractnCinoBase(wipLoan) || 0,
                            baseRateFloor: null,

                            baseCurrency: wipLoan.currency_iso_code,
                            domicile: (wipLoan.llc_bi_product_package?.state_of_exposure === 'Offshore') ? wipLoan.llc_bi_product_package?.offshore_location || "Other" : 'Australia',

                            rating: wipLoan.metrics_rating,
                            ranking: groupDebtSeniority(wipLoan.ranking_debt_seniority),
                            interestType: (wipLoan.llc_bi_pricing_streams[0]?.llc_bi_pricing_rate_components[0]?.llc_bi_interest_rate_type || null),
                            investmentType: wipLoan.llc_bi_cra_type_code,
                            industrySegment: wipLoan.llc_bi_product_package?.industry,
                            trancheType: wipLoan.llc_bi_product,
                            pricingType: (wipLoan.llc_bi_pricing_streams[0]?.llc_bi_pricing_rate_components[0]?.llc_bi_interest_rate_type || null),

                            sector: industries.sector,
                            industryGroup: industries.industryGroup,
                            industry: industries.industry,

                            selldowns: [],
                            transfersIn: [],
                            transfersOut: [],

                            tags: []
                        }

                        loans.push(loan)
                    })
                }
            }
        })

        return loans;
    }
)

// Retrieves wip comment for specific nCino loan via Id
export const retrieveWIPComment = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.wipComments || [],
    (_state: RootState, ncinoId: string) => ncinoId,
    (comments, ncinoId) => {
        return comments.find(c => c.ncinoId === ncinoId && c.status !== SaveStatus.REMOVED) || null;
    }
)