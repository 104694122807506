import {memo} from "react";
import {Grid, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {DrillDownSection, DrillDownValue} from "../../../../components";
import {fCurrency} from "../../../../utils/formatNumber";
import {FundFinanceDetails} from "../../../../store/capitalBudget/selectors/fundFinanceSelector";

// Drill down to show total Bank Facilities
function FundFinanceDrilldown({unusedFF, fundFinanceTotal, fundFinances, manualFF}: { unusedFF: number, fundFinanceTotal: number, fundFinances: Array<FundFinanceDetails>, manualFF: number }) {

    return (
        <>
            <Grid>
                <Table size="small">
                    <TableBody>
                        <DrillDownSection
                            header={'Unused Fund Finances'}
                            value={fundFinanceTotal}
                            retrieveValues={() => {
                                let values: Array<DrillDownValue> = [];

                                values = [...values, ...fundFinances.map((f) => {
                                    return {
                                        id: f.id,
                                        date: null,
                                        name: f.name,
                                        value: -f.unusedMaster
                                    }
                                })];

                                if (manualFF) {
                                    values.push({
                                        id: 'manual',
                                        date: null,
                                        name: '(Manual Fund Finance Adjustment)',
                                        value: manualFF
                                    })
                                }

                                return values;
                            }}
                        />
                        <TableRow sx={{bgcolor: 'info.lighter'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Total Unused Fund Finance
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                >
                                    {fCurrency(unusedFF || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </>
    )
}

export default memo(FundFinanceDrilldown);