import {memo} from "react";
import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";
import {FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";
import {valueCurrency} from "../../../../components";
import LoansDrilldownTable from "./LoansDrilldownTable";
import {Box} from "@mui/material";
import DrillDownSimple from "../../../../components/DrillDownsSimple";


interface IndustryDrillDownProps {
    id: string,
    header: string,
    field: string,
    value: number,
    period: ForecastPeriod,
    column: investmentReportColumn,
    avaCapToggle: boolean,
    fund: FundDetails | null,
    filter: (loan: CalculationLoanType) => boolean
}

function IndustryDrilldown({id, header, field, value, period, column, avaCapToggle, fund, filter}: IndustryDrillDownProps) {

    return (
        <>
            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', minHeight: 800}}>
                <Box>
                    <DrillDownSimple
                        data={[
                            {
                                label: field,
                                value,
                            },
                        ]}
                    />
                </Box>
                <Box sx={{py: 1, flex: 1, minHeight: 200}}>
                    <Box sx={{height: '99%'}}>
                        <LoansDrilldownTable
                            title={field}
                            period={period}
                            fund={fund}
                            additionalColumns={[
                                {
                                    field: id,
                                    headerName: header,
                                    width: 120
                                },
                                {
                                    field: 'value',
                                    headerName: 'Commitment',
                                    ...valueCurrency,
                                    width: 120
                                }
                            ]}
                            filterFunction={filter}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default memo(IndustryDrilldown)