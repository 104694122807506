import {Loan} from "../../../types/externalDataTypes";
import {MaturityChanges, SelldownRepayment, Transfer} from "../../../types/forecastTypes";
import {checkDateBefore, checkDateSame} from "../../../utils/DateUtils";
import {CheckFlags} from "../../../types/GeneralTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {MaturityChecks, SellRepayChecks, TransferChecks} from "../../../types/capitalBudgetTypes";

// Checks for warnings, advises, and removals for maturity changes.
export function maturityCheck(forecastDate: Date | number, loans: Map<number, Map<string, Loan>>, maturityChanges: Array<MaturityChanges>) {
    let maturityChecks: MaturityChecks = {
        remove: [],
        advise: [],
        modification: []
    }
    maturityChanges.forEach((mc: MaturityChanges) => {
        if (mc.status !== SaveStatus.REMOVED) {
            const tranche = loans.get(mc.trancheId);
            // REMOVE IF TRANCHE NO LONGER EXISTS
            if (!tranche) maturityChecks.remove.push(mc.id)
            else {
                const firstFund = tranche.values().next().value;
                // Remove if amended maturity matches updated maturity
                if (firstFund && checkDateSame(mc.amendedMaturity, firstFund.maturity)) {
                    maturityChecks.remove.push(mc.id)
                    // Advise if amended maturity is different from original maturity
                } else if (!checkDateSame(mc.originalMaturity, firstFund.maturity)) {
                    maturityChecks.advise.push({
                        id: mc.id,
                        flag: CheckFlags.UPDATED_MATURITY
                    })
                    maturityChecks.modification.push({id: mc.id, attribute: 'originalMaturity', value: firstFund.maturity})
                    // Advise if amended maturity is before forecast date
                } else if (checkDateBefore(mc.amendedMaturity, forecastDate)) {
                    maturityChecks.advise.push({id: mc.id, flag: CheckFlags.PRE_FORECAST})
                }
            }
        }
    })

    return maturityChecks;
}

export type ForecastLoan = Loan & {
    maturityChange: MaturityChanges | null
}

// Checks for warnings, advises, and removals for selldown repayments.
export function sellRepayCheck(forecastDate: Date | number, loans: Map<number, Map<string, ForecastLoan>>, sellRepayments: Array<SelldownRepayment>) {
    let sellRepayChecks: SellRepayChecks = {
        remove: [],
        advise: [],
        alert: []
    }
    sellRepayments.forEach((sr: SelldownRepayment) => {
        if (sr.status !== SaveStatus.REMOVED) {
            const tranche = loans.get(sr.trancheId);
            // REMOVE IF TRANCHE NO LONGER EXISTS
            if (!tranche) sellRepayChecks.remove.push(sr.id);
            else {
                const fund = tranche.get(sr.fund);
                // REMOVE IF FUND NO LONGER EXISTS
                if (!fund) sellRepayChecks.remove.push(sr.id);
                else {
                    const loanMaturity = fund.maturityChange ? fund.maturityChange.amendedMaturity : fund.maturity;
                    // IF MATURITY IS BEFORE SELLDOWN DATE
                    if (checkDateBefore(loanMaturity, sr.date)) sellRepayChecks.alert.push({
                        id: sr.id,
                        flag: CheckFlags.AFTER_MATURITY
                    });
                    // IF REDUCTION IS LESS THAN REMAINING BALANCE
                    if (fund.commitment < sr.amount) sellRepayChecks.alert.push({
                        id: sr.id,
                        flag: CheckFlags.EXCEEDED
                    });
                    // IF DOWNSIZE IS BEFORE FORECAST DATE
                    if (checkDateBefore(sr.date, forecastDate)) sellRepayChecks.advise.push({
                        id: sr.id,
                        flag: CheckFlags.PRE_FORECAST
                    });
                }
            }
        }
    })

    return sellRepayChecks;
}

// TODO: NEEDS TO BE CHECKED
// Checks for warnings, advises, and removals for transfers.
export function transferCheck(forecastDate: Date | number, loans: Map<number, Map<string, ForecastLoan>>, transfers: Array<Transfer>) {
    let transferChecks: TransferChecks = {
        remove: [],
        advise: [],
        alert: []
    }

    transfers.forEach((t: Transfer) => {
        if (t.status !== SaveStatus.REMOVED) {
            const tranche = loans.get(t.trancheId);
            if (!tranche) transferChecks.remove.push(t.id)
            else {
                const fund = tranche.get(t.fromFund);
                // REMOVE IF FUND NO LONGER EXISTS
                if (!fund) transferChecks.remove.push(t.id);
                else {
                    const loanMaturity = fund.maturityChange ? fund.maturityChange.amendedMaturity : fund.maturity;
                    // IF MATURITY IS BEFORE TRANSFER DATE
                    if (checkDateBefore(loanMaturity, t.transferDate)) transferChecks.alert.push({
                        id: t.id,
                        flag: CheckFlags.AFTER_MATURITY
                    });
                    // IF REDUCTION IS LESS THAN REMAINING BALANCE
                    if (fund.commitment < t.amount) transferChecks.alert.push({
                        id: t.id,
                        flag: CheckFlags.EXCEEDED
                    });
                    // IF DOWNSIZE IS BEFORE FORECAST DATE
                    if (checkDateBefore(t.transferDate, forecastDate)) transferChecks.advise.push({
                        id: t.id,
                        flag: CheckFlags.PRE_FORECAST
                    });
                }
            }
        }
    })

    return transferChecks;
}