import {useState} from "react";
import clsx from "clsx";
// MUI
import {Grid, Container} from '@mui/material';
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {formatNameInitial, removeHtmlTags} from "../../utils/generalUtils";
import {DataTable, SideDrawer, valueCurrency, valueDate} from "../../components";
import {checkDateSame, checkDateSameOrBefore} from "../../utils/DateUtils";
import {useAppSelector} from "../../store/store";
import {RefinanceInfoType, retrieveRefinances} from "../../store/capitalBudget/selectors/refinanceSelector";
import RefinanceInfo from "../../sections/Budget/RefinanceInfo";

export const RefinanceColumns: GridColDef[] = [
    {
        field: 'dealName',
        headerName: 'Loan Name',
        flex: 1,
        minWidth: 325,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    },
    {
        field: 'owner',
        headerName: 'Owner',
        width: 120,
        valueFormatter: (params) => formatNameInitial(params.value),
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    },
    {
        field: 'closeDate',
        headerName: 'Proj. Close Date',
        ...valueDate,
        width: 130,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    },
    {
        field: 'tenor',
        headerName: 'Tenor',
        width: 60,
        valueFormatter: (params) => params.value ? Number(params.value).toFixed(2) : '',
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    },
    {
        field: 'incremental',
        headerName: 'Type',
        width: 110,
        valueFormatter: (params) => (params.value !== undefined) ? (params.value ? 'Incremental' : 'Updated') : '',
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    },
    {
        field: 'commitment',
        headerName: 'Commitment',
        width: 120,
        ...valueCurrency,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    },
    {
        field: 'borrower',
        headerName: 'Borrower - Existing Tranche',
        flex: 1,
        minWidth: 250,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    },
    {
        field: 'maturity',
        headerName: 'Upd. Maturity',
        ...valueDate,
        width: 125,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    },
    {
        field: 'existingCommitment',
        headerName: 'Exist. Commit.',
        width: 125,
        ...valueCurrency,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    },
    {
        field: 'totalCommitment',
        headerName: 'Total Commit.',
        width: 125,
        ...valueCurrency,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    },
    {
        field: 'comments',
        headerName: 'Comment',
        flex: 1.5,
        minWidth: 250,
        valueFormatter: (params) => removeHtmlTags(params.value),
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            });
        },
        sortable: false
    }
];


// Capital Budget Refinances
export default function Refinances() {
    const loading = useAppSelector(state => state.forecast.loading);
    const refinances = useAppSelector(state => retrieveRefinances(state));

    const [selectedRefinance, setSelectedRefinance] = useState<RefinanceInfoType | null>(null);

    const handleRefinanceSelection = (row: number | string | null) => {
        if (!row) setSelectedRefinance(null);
        else {
            const id = (row.toString() || '').match(/^\d+/)?.[0];
            if (id) {
                const refinance = refinances.find(r => r.id === parseInt(id));
                setSelectedRefinance(refinance || null);
            }
        }
    }

    return (
        <>
            <Page title="Metrics - CB - Refinances">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Refinances'/>
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                <DataTable
                                    title='Refinances'
                                    columns={RefinanceColumns}
                                    data={refinances.sort((a, b) => {
                                        if (checkDateSameOrBefore(a.closeDate, b.closeDate)) {
                                            if (checkDateSame(a.closeDate, b.closeDate)) {
                                                return a.dealName < b.dealName ? -1 : 1;
                                            } else return -1;
                                        } else return 1;
                                    }).reduce((refinances: Array<any>, r) => {
                                        refinances.push({
                                            ...r,
                                            existingCommitment: r.currentCommitment,
                                            parent: true,
                                        });
                                        r.tranches.forEach((tranche: any) => {
                                            refinances.push({
                                                id: `${r.id}_${tranche.trancheId}`,
                                                borrower: `${tranche.trancheId} - ${tranche.tranche}`,
                                                existingCommitment: tranche.commitment,
                                                maturity: tranche.maturity
                                            })
                                        })
                                        return refinances;
                                    }, [])}
                                    loading={loading}

                                    selectRow={handleRefinanceSelection}

                                    additionalDataGridSx={{
                                        '& .parent': {
                                            bgcolor: 'info.lighter',
                                            fontWeight: 'bold',
                                        }
                                    }}

                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
            {selectedRefinance &&
                <SideDrawer
                    title={'Refinance'}
                    open={!!selectedRefinance}
                    onClose={() => setSelectedRefinance(null)}
                >

                    <RefinanceInfo
                        refinance={selectedRefinance}
                        onClose={() => setSelectedRefinance(null)}
                    />
                </SideDrawer>
            }
        </>
    )
}