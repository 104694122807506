import {Refinance} from "../../../types/forecastTypes";
import {WIPLoan} from "../../../types/externalDataTypes";
import {RefinanceChecks} from "../../../types/capitalBudgetTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";

// Checks for refinances that no longer correspond with a pipeline loan.
export function refinanceChecks(refinances: Array<Refinance>, pipeline: Array<WIPLoan>): RefinanceChecks {
    const checks: RefinanceChecks = {
        remove: []
    };

    refinances.forEach((refinance) => {
        if (refinance.status === SaveStatus.REMOVED) return;
        const loan = pipeline.find(l => l.ncino_id === refinance.ncinoId);
        if (!loan) checks.remove.push(refinance.id);
    });

    return checks;
}