import {Formik, FormikProps} from "formik";
import * as Yup from "yup";
import {fCurrency} from "../../../utils/formatNumber";
import {checkDateSame, formatDate} from "../../../utils/DateUtils";
import {addValues} from "../../../utils/mathUtil";
// @MUI
import {Button, Card, CardContent, Divider, Grid, IconButton, Typography} from "@mui/material";
import {
    Clear as ClearIcon
} from "@mui/icons-material";
// Store
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    FundFinanceDetails,
    retrieveLoansForFundFinance
} from "../../../store/capitalBudget/selectors/fundFinanceSelector";
import {FormInput} from "../../../components";
import {InputTypes} from "../../../types/InputTypes";
import {addFundFinance, updateFundFinance} from "../../../store/capitalBudget/capitalBudgetSlice";

type FundFinanceFormProps = {
    fundFinance: FundFinanceDetails,
    onClose: () => void
}

export default function FundFinanceForm({fundFinance, onClose}: FundFinanceFormProps) {
    const dispatch = useAppDispatch();

    const loanPortfolio = useAppSelector(state => retrieveLoansForFundFinance(state));

    const fundFinanceValues = {
        dealId: fundFinance.dealId,
        name: fundFinance.name, // Display purpose
        // IF EDITING AND MASTER DATE OR LIMIT HAS BEEN AMENDED
        amendedMasterDate: (fundFinance.fundFinanceId && !checkDateSame(fundFinance.masterMaturity, fundFinance.adjustedMasterMaturity)) ? fundFinance.adjustedMasterMaturity : null,
        amendedMasterLimit: (fundFinance.fundFinanceId && (fundFinance.masterLimit !== fundFinance.adjustedMasterLimit)) ? fundFinance.adjustedMasterLimit : null,
        loans: fundFinance.loans.map(loan => ({
            id: (loan.type === 'AXCESS') ? loan.trancheId : loan.ncinoId,
            type: loan.type,
            limit: loan.currentLimit,
            balance: loan.currentBalance,
            maturity: loan.maturity,
            name: loan.name
        })),
        newTranche: null // loan selection purpose
    }

    return (
        <>
            <Formik
                initialValues={fundFinanceValues}
                onSubmit={(values) => {
                    const newFundFinance = {
                        dealId: values.dealId,
                        masterDate: fundFinance.masterMaturity,
                        masterLimit: fundFinance.masterLimit,
                        amendedMasterDate: values.amendedMasterDate,
                        amendedMasterLimit: values.amendedMasterLimit,
                        loans: values.loans.map(loan => ({
                            id: loan.id,
                            type: loan.type
                        }))
                    }

                    if (fundFinance.fundFinanceId) {
                        dispatch(updateFundFinance({
                            id: fundFinance.fundFinanceId,
                            ...newFundFinance
                        }));
                    } else {
                        dispatch(addFundFinance(newFundFinance));
                    }
                    onClose();
                }}
                validationSchema={
                    Yup.object().shape({
                        dealId: Yup.mixed().test(
                            'is-string-or-number',
                            'Deal is required.',
                            value => typeof value === 'string' || typeof value === 'number'
                        ),
                        amendedMasterLimit: Yup.number().test(
                            'within-limit',
                            'Amended Limit Exceeds active commitment amount',
                            (value, context) => {
                                if (!value) return true
                                const { loans } = context.parent;

                                const combinedLimit: number = loans.reduce((total: number, loan: any) => {
                                    return addValues(total, loan.limit || 0)
                                }, 0);

                                return (value >= combinedLimit)
                            }
                        ).nullable()
                    })
                }
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        setFieldValue,
                        values,
                    } = props;

                    const removeTranche = (tranche: number) => {
                        const tranches = values.loans;
                        const index = tranches.findIndex((t: any) => t.id === tranche);
                        if (index !== -1) {
                            tranches.splice(index, 1);
                            setFieldValue('loans', tranches)
                        }
                    }

                    // Convert selected loan and add to loans array
                    if (values.newTranche) {
                        const newTranche = values.newTranche;
                        setFieldValue('newTranche', null);

                        const check = values.loans.find((l: any) => l.id === newTranche.id);

                        if (!check) {
                            setFieldValue('loans', [...values.loans, {
                                id: (newTranche.type === 'AXCESS') ? parseInt(newTranche.id) : newTranche.id,
                                type: newTranche.type,
                                limit: newTranche.currentLimit,
                                balance: newTranche.currentBalance,
                                maturity: newTranche.maturity,
                                name: (newTranche.type === 'AXCESS') ? newTranche.tranche : newTranche.name
                            }]);
                        }

                    }

                    let unusedMaster = (values.amendedMasterLimit) ? values.amendedMasterLimit : fundFinance.masterLimit;

                    values.loans.forEach((loan: any) => {
                        unusedMaster = addValues(unusedMaster, -loan.limit)
                    })

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    <FormInput
                                        id='name'
                                        label='Deal Name'
                                        fieldType={InputTypes.STRING}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                        disabled={true}
                                    />
                                    <Grid container item xs={12} sx={{px: 2, py: 1}} direction='row'
                                          alignItems='center'>
                                        <Typography variant='subtitle1' sx={{color: 'primary.main'}}>
                                            <b>Original Master Maturity:</b>
                                        </Typography>
                                        <Typography variant='body1' sx={{
                                            pl: 5,
                                            fontSize: 16
                                        }}>
                                            {(fundFinance.masterMaturity) ? formatDate(fundFinance.masterMaturity, 'dd-MMM-yyyy') : ''}
                                        </Typography>
                                    </Grid>
                                    <FormInput
                                        id='amendedMasterDate'
                                        label='Amended Master Date'
                                        fieldType={InputTypes.DATE}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <Grid container item xs={12} sx={{px: 2, py: 1}} direction='row'
                                          alignItems='center'>
                                        <Typography variant='subtitle1' sx={{color: 'primary.main'}}>
                                            <b>Original Master Limit:</b>
                                        </Typography>
                                        <Typography variant='body1' sx={{
                                            pl: 5,
                                            fontSize: 16
                                        }}>
                                            {(fundFinance.masterLimit) ? fCurrency(fundFinance.masterLimit) : ''}
                                        </Typography>
                                    </Grid>
                                    <FormInput
                                        id={'amendedMasterLimit'}
                                        key={'Amended Master Limit'}
                                        label={'Amended Master Limit'}
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid container item xs={12} sx={{px: 2, py: 1}} direction='row'
                                          alignItems='center'>
                                        <Typography variant='subtitle1' sx={{color: 'primary.main'}}>
                                            <b>Fund Finance Loans:</b>
                                        </Typography>
                                    </Grid>
                                    <FormInput
                                        id='newTranche'
                                        label='Add Loan'
                                        fieldType={InputTypes.SEARCH}
                                        values={loanPortfolio}
                                        labelFunc={(option) => (option.type === 'AXCESS') ? `${option.trancheId} - ${option.borrower} - ${option.name}` : `NEW - ${option.name}`}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <Grid container item xs={12}>
                                        {values.loans.map((loan: any) => (
                                            <Card
                                                key={loan.id}
                                                sx={{
                                                    width: '100%',
                                                    p: 0,
                                                    m: 1,
                                                    border: 1,
                                                    borderColor: 'primary.main'
                                                }} elevation={2}
                                            >
                                                <CardContent sx={{padding: 0, paddingBottom: 0, m: 0}}>
                                                    <Grid container item xs={12} padding={1}
                                                          margin={1} justifyContent={'space-between'}
                                                    >
                                                        <Grid>
                                                            <Typography
                                                                variant='subtitle1'
                                                                sx={{color: 'primary.main'}}
                                                            >
                                                                {(loan.type === 'AXCESS') ? loan.id : `NEW -  ${loan.name}`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <IconButton
                                                                sx={{color: 'primary.main', py: 0}}
                                                                style={{outline: 'none'}}
                                                                onClick={() => removeTranche(loan.id)}
                                                                size="small"
                                                            >
                                                                <ClearIcon/>
                                                            </IconButton>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item xs={12} md={8} padding={0} margin={0}>
                                                        <Grid item
                                                              sx={{
                                                                  px: 5,
                                                                  display: 'flex',
                                                                  justifyContent: 'space-between'
                                                              }}
                                                        >
                                                            <Typography
                                                                variant='subtitle1'
                                                                sx={{color: 'primary.main'}}
                                                            >
                                                                <b>Limit:</b>
                                                            </Typography>
                                                            <Typography
                                                                variant='body1'
                                                                sx={{
                                                                    pl: 5,
                                                                    fontSize: 16
                                                                }}
                                                            >
                                                                {fCurrency(loan.limit)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item
                                                              sx={{
                                                                  px: 5,
                                                                  display: 'flex',
                                                                  justifyContent: 'space-between'
                                                              }}
                                                        >
                                                            <Typography
                                                                variant='subtitle1'
                                                                sx={{color: 'primary.main'}}
                                                            >
                                                                <b>Balance:</b>
                                                            </Typography>
                                                            <Typography
                                                                variant='body1'
                                                                sx={{
                                                                    pl: 5,
                                                                    fontSize: 16
                                                                }}
                                                            >
                                                                {fCurrency(loan.balance)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item
                                                              sx={{
                                                                  px: 5,
                                                                  display: 'flex',
                                                                  justifyContent: 'space-between'
                                                              }}
                                                        >
                                                            <Typography
                                                                variant='subtitle1'
                                                                sx={{color: 'primary.main'}}
                                                            >
                                                                <b>Maturity:</b>
                                                            </Typography>
                                                            <Typography
                                                                variant='body1'
                                                                sx={{
                                                                    pl: 5,
                                                                    fontSize: 16
                                                                }}
                                                            >
                                                                {formatDate(loan.maturity)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </Grid>
                                    <Grid container item xs={12} sx={{px: 2, py: 1}} direction='row'
                                          alignItems='center'>
                                        <Typography variant='subtitle1' sx={{color: 'primary.main'}}>
                                            <b>Unused Master Limit:</b>
                                        </Typography>
                                        <Typography variant='body1' sx={{
                                            pl: 5,
                                            fontSize: 16
                                        }}>
                                            {fCurrency(unusedMaster)}
                                        </Typography>
                                    </Grid>
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', p: 1}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width: '50%', p: 1}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                {fundFinance.fundFinanceId ?
                                                    'Update Fund Finance' : 'Add Fund Finance'
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }
                }
            </Formik>
        </>
    )
}