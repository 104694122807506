import {memo} from "react";
import {Capital} from "../../../../types/forecastTypes";
import {DataTable, valueCurrency, valueDate} from "../../../../components";


interface CapitalDrillDownTableProps {
    title: string,
    data: Array<Capital>
}

// Drill down information for Capital Transactions
function CapitalDrillDownTable({title, data}: CapitalDrillDownTableProps) {
    return (
        <>
            <DataTable
                title={title}
                columns={[
                    {
                        field: 'date',
                        headerName: 'Maturity',
                        ...valueDate,
                        width: 100,
                    },
                    {
                        field: 'fund',
                        headerName: 'Fund',
                        width: 100,
                    },
                    {
                        field: 'name',
                        headerName: 'Facility',
                        flex: 1,
                        minWidth: 300,
                    },
                    {
                        field: 'amount',
                        headerName: 'Amount',
                        ...valueCurrency,
                        width: 120
                    }
                ]}
                data={data}
                loading={false}
                showExport={false}
                search
                sort={{field: 'date', sort: 'asc'}}
            />
        </>
    )
}

export default memo(CapitalDrillDownTable);