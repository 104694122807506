import {EditableTableBodyProps} from "./index";
import {Box, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import UnEditTableRow from "./UnEditTableRow";
import EditableRow from "./EditableTableRow";
import {fCurrency} from "../../utils/formatNumber";
import {memo} from "react";


function EditableTableBody(props: EditableTableBodyProps) {
    const {
        sorted,
        columns,
        editRow,
        handleRemove,
        handleRowSelection,
        handleEditRequest,
        selectedRow,
        handleRowEdit,
        validationSchema,
        formRowValueFunction,
        loading,
        totalRow,
        colIndex,
        rowSelect,
        totals,
        customColorFn
    } = props;

    return (
        <>
            <TableBody sx={{flex: 1, overflowY: 'scroll'}}>
                {sorted.map((row, i) => {
                    if (row.id !== editRow) {
                        return (
                            <UnEditTableRow
                                key={i}
                                row={row}
                                columns={columns}
                                handleRemove={handleRemove}
                                handleRowSelection={handleRowSelection}
                                setEditRow={handleEditRequest}
                                customColorFn={customColorFn}
                            />
                        )
                    }

                    return (
                        <EditableRow
                            key={i}
                            row={row}
                            columns={columns}
                            handleRemove={handleRemove}
                            selectedRow={selectedRow}
                            handleRowSelection={handleRowSelection}
                            handleRowEdit={handleRowEdit}
                            validationSchema={validationSchema}
                            formRowValueFunction={formRowValueFunction}
                            setEditRow={handleEditRequest}
                        />
                    )
                })}
                {sorted.length === 0 &&
                    <TableRow>
                        <TableCell colSpan={columns.length + 1} align='center'>
                            No Data to Display.
                        </TableCell>
                    </TableRow>
                }
                {/* TOTAL ROW */}
                {!loading && totalRow && (colIndex !== -1) ?
                    <>
                        <TableRow>
                            <TableCell colSpan={columns.length + 1} sx={{height: '100%'}}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    position: 'sticky',
                                    bottom: 0,
                                    zIndex: 10,
                                    bgcolor: 'primary.light',
                                    border: 1,
                                    borderColor: 'primary.light',
                                    px: 1
                                }}
                                colSpan={2}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: 'common.white',
                                    }}
                                >
                                    Count:{sorted.length}
                                </Typography>
                            </TableCell>
                            <TableCell
                                align='right'
                                sx={{
                                    position: 'sticky',
                                    bottom: 0,
                                    zIndex: 10,
                                    bgcolor: 'primary.light',
                                    border: 1,
                                    borderColor: 'primary.light',
                                    px: 1
                                }}
                                colSpan={(colIndex + ((!!rowSelect) ? -1 : -2))}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: 'common.white',
                                    }}
                                >
                                    Total
                                </Typography>
                            </TableCell>
                            {columns.slice(colIndex).map((column, c) => (
                                <TableCell
                                    key={c}
                                    align='right'
                                    sx={{
                                        position: 'sticky',
                                        bottom: 0,
                                        zIndex: 10,
                                        bgcolor: 'primary.light',
                                        border: 1,
                                        borderColor: 'primary.light',

                                        px: 1
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            color: 'common.white',
                                        }}
                                    >
                                        {fCurrency(totals[column.id])}
                                    </Typography>
                                </TableCell>
                            ))}
                            {(handleRemove) &&
                                <TableCell
                                    sx={{
                                        position: 'sticky',
                                        bottom: 0,
                                        zIndex: 2,
                                        bgcolor: 'primary.light',
                                        border: 1,
                                        borderColor: 'primary.light',
                                        px: 1,
                                    }}
                                >
                                </TableCell>
                            }
                        </TableRow>
                    </>
                    :
                    <>
                        <TableRow/>
                    </>
                }

            </TableBody>
        </>
    )
}

export default memo(EditableTableBody)