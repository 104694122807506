import {Period} from "../../../types/GeneralTypes";
import {CalculationLoanType, ForecastPeriod} from "../../../types/capitalBudgetTypes";
import {Capital, OtherTransaction} from "../../../types/forecastTypes";
import calculatePeriod from "./calculatePeriod";
import {PeriodType} from "../../../types/capitalBudgetEnums";
import {FundFinanceDetails} from "../selectors/fundFinanceSelector";

/**
 * Generates capital Budget Portfolios for set periods
 * @param forecastDate
 * @param periods
 * @param portfolio
 * @param newLoans
 * @param capital
 * @param otherTransactions
 * @param fundFinance
 */
function generateBudgetForecast(forecastDate: Date | number, periods: {base: Period, weeks: Array<Period>, months: Array<Period>}, portfolio: Array<CalculationLoanType>, newLoans: Array<CalculationLoanType>, capital: Array<Capital>, otherTransactions: Array<OtherTransaction>, fundFinance: Array<FundFinanceDetails>) {
    let base;
    let weeks: Array<ForecastPeriod> = [];
    let months: Array<ForecastPeriod> = [];

    base = calculatePeriod(periods.base, PeriodType.WEEK, 0, forecastDate, portfolio, newLoans, capital, otherTransactions, fundFinance, true);

    periods.weeks.forEach((week: Period, w: number) => {
        weeks.push(calculatePeriod(week, PeriodType.WEEK, w, forecastDate, portfolio, newLoans, capital, otherTransactions, fundFinance));
    })

    periods.months.forEach((month: Period, m: number) => {
        months.push(calculatePeriod(month, PeriodType.MONTH, m, forecastDate, portfolio, newLoans, capital, otherTransactions, fundFinance));
    })

    return {
        base,
        weeks,
        months
    }
}

export default generateBudgetForecast;