import {MouseEvent, useState} from "react";
import clsx from "clsx";
import {checkDateSame, checkDateSameOrBefore} from "../../../utils/DateUtils";
import {formatNameInitial, removeHtmlTags} from "../../../utils/generalUtils";
// MUI
import {Grid, Container, IconButton, ToggleButtonGroup, ToggleButton} from '@mui/material';
import {
    Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon,
} from "@mui/icons-material";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
// Components
import {actionRow, AlertDialog, DataTable, SideDrawer, valueCurrency, valueDate} from "../../../components";
import {Page} from "../../../layouts/components";
import ForecastConfigBar from "../ForecastConfigBar";
import RefinanceForm from "../../../sections/Budget/Configurations/RefinanceForm";
// STORE
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    RefinanceSuggestion,
    retrieveRefinances,
    selectSuggestedRefinances
} from "../../../store/capitalBudget/selectors/refinanceSelector";
import {removeRefinance} from "../../../store/capitalBudget/capitalBudgetSlice";
import {warningsGeneralRow} from "./DataTableWarningsUtilts";
import {SaveStatus} from "../../../types/capitalBudgetEnums";

export const RefinanceColumns: GridColDef[] = [
    {
        field: 'dealName',
        headerName: 'Loan Name',
        flex: 1,
        minWidth: 250,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    },
    {
        field: 'owner',
        headerName: 'Owner',
        width: 120,
        valueFormatter: (params) => formatNameInitial(params.value),
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    },
    {
        field: 'closeDate',
        headerName: 'Proj. Close Date',
        ...valueDate,
        width: 130,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    },
    {
        field: 'tenor',
        headerName: 'Tenor',
        width: 80,
        valueFormatter: (params) => params.value ? Number(params.value).toFixed(2) : '',
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    },
    {
        field: 'incremental',
        headerName: 'Type',
        width: 110,
        valueFormatter: (params) => (params.value !== undefined) ? (params.value ? 'Incremental' : 'Updated') : '',
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    },
    {
        field: 'commitment',
        headerName: 'Commitment',
        width: 120,
        ...valueCurrency,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    },
    {
        field: 'borrower',
        headerName: 'Borrower - Existing Tranche',
        flex: 1,
        minWidth: 250,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    },
    {
        field: 'maturity',
        headerName: 'Upd. Maturity',
        ...valueDate,
        width: 125,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    },
    {
        field: 'existingCommitment',
        headerName: 'Exist. Commit.',
        width: 125,
        ...valueCurrency,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    },
    {
        field: 'totalCommitment',
        headerName: 'Total Commit.',
        width: 125,
        ...valueCurrency,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    },
    {
        field: 'comments',
        headerName: 'Comment',
        flex: 1.5,
        minWidth: 250,
        valueFormatter: (params) => removeHtmlTags(params.value),
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                parent: (params.row.parent)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        },
        sortable: false
    }
];

export const RefinanceSuggestionColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Loan Name',
        flex: 1,
        minWidth: 250
    },
    {
        field: 'owner',
        headerName: 'Owner',
        width: 120,
        valueFormatter: (params) => formatNameInitial(params.value)
    },
    {
        field: 'closeDate',
        headerName: 'Proj. Close Date',
        ...valueDate,
    },
    {
        field: 'tenor',
        headerName: 'Tenor',
        width: 80,
        valueFormatter: (params) => Number(params.value).toFixed(2)
    },
    {
        field: 'value',
        headerName: 'Commitment',
        width: 120,
        ...valueCurrency,
    },
    {
        field: 'modificationId',
        headerName: 'Mod. Tranche',
        width: 120
    },
    {
        field: 'borrower',
        headerName: 'Existing Borrower',
        flex: 1,
        minWidth: 250,
    },
    {
        field: 'comment',
        headerName: 'nCino Comment',
        flex: 1.5,
        minWidth: 250,
        valueFormatter: (params) => removeHtmlTags(params.value),
    }
];

// Capital Budget Configuration - Refinances
export default function RefinanceConf() {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.forecast.loading);
    const refinances = useAppSelector(state => retrieveRefinances(state));
    const suggestions = useAppSelector(state => selectSuggestedRefinances(state));

    const [shownTables, setTables] = useState<Array<'refinances' | 'suggestion'>>(['refinances', 'suggestion']);

    const [refinanceForm, setRefinanceForm] = useState<RefinanceSuggestion | {} | null>(null)
    const [remove, setRemove] = useState<number | null>(null)

    const handleShowTables = (event: MouseEvent<HTMLElement>, tables: Array<'refinances' | 'suggestion'>) => {
        if (tables.length === 0) {
            setTables(['refinances', 'suggestion'])
        } else {
            setTables(tables);
        }
    }

    return (
        <>
            <Page title="Metrics - CB - Refinances">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Portfolio - Refinances' configuration/>
                        </Grid>
                        <Grid item sx={{display: 'flex', justifyContent: 'center'}}>
                            <ToggleButtonGroup
                                value={shownTables}
                                onChange={handleShowTables}
                                color='primary'
                            >
                                <ToggleButton value={'refinances'} sx={{py: 0.5}}>Refinances</ToggleButton>
                                <ToggleButton value={'suggestion'} sx={{py: 0.5}}>Suggestions</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        {(shownTables.includes('refinances')) &&
                            <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                                <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                    <DataTable
                                        title='Refinances'
                                        columns={[
                                            ...RefinanceColumns,
                                            {
                                                ...actionRow,
                                                ...warningsGeneralRow,
                                                cellClassName: (params: GridCellParams) => {
                                                    return clsx('', {
                                                        parent: (params.row.parent)
                                                    }, ' status', {
                                                        new: (params.row.status === SaveStatus.NEW),
                                                        edited: (params.row.status === SaveStatus.EDITED)
                                                    });
                                                },
                                                renderCell: (params: GridCellParams) => {
                                                    if (params.row.parent) {
                                                        return (
                                                            <>
                                                                <IconButton
                                                                    sx={{color: 'primary.main'}}
                                                                    style={{padding: '0px', outline: 'none'}}
                                                                    onClick={() => setRefinanceForm(params.row)}
                                                                    size="small">
                                                                    <EditIcon/>
                                                                </IconButton>
                                                                <IconButton
                                                                    sx={{color: 'primary.main'}}
                                                                    style={{padding: '0px', outline: 'none'}}
                                                                    onClick={() => setRemove(params.row.id)}
                                                                    size="small">
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                }
                                            }
                                        ]}
                                        data={refinances.sort((a, b) => {
                                            if (checkDateSameOrBefore(a.closeDate, b.closeDate)) {
                                                if (checkDateSame(a.closeDate, b.closeDate)) {
                                                    return a.dealName < b.dealName ? -1 : 1;
                                                } else return -1;
                                            } else return 1;
                                        }).reduce((refinances: Array<any>, r) => {
                                            refinances.push({
                                                ...r,
                                                existingCommitment: r.currentCommitment,
                                                parent: true,
                                            });
                                            r.tranches.forEach((tranche: any) => {
                                                refinances.push({
                                                    id: `${r.id}${tranche.trancheId}`,
                                                    borrower: `${tranche.trancheId} - ${tranche.tranche}`,
                                                    existingCommitment: tranche.commitment,
                                                    maturity: tranche.maturity
                                                })
                                            })
                                            return refinances;
                                        }, [])}
                                        loading={loading}

                                        create={() => setRefinanceForm({})}

                                        search
                                        showExport={false}

                                        additionalDataGridSx={{
                                            '& .parent': {
                                                bgcolor: 'info.lighter',
                                                fontWeight: 'bold',
                                            }
                                        }}

                                    />
                                </Grid>
                            </Grid>
                        }
                        {(shownTables.includes('suggestion')) &&
                            <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                                <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                    <DataTable
                                        title='Possible Refinances'
                                        columns={[
                                            ...RefinanceSuggestionColumns,
                                            {
                                                ...actionRow,
                                                renderCell: (params: GridCellParams) => (
                                                    <>
                                                        <IconButton
                                                            color='primary'
                                                            onClick={() => {
                                                                setRefinanceForm({
                                                                    ...params.row,
                                                                    id: null,
                                                                    suggestive: true,
                                                                });
                                                            }}
                                                            size="small"
                                                        >
                                                            <AddIcon/>
                                                        </IconButton>
                                                    </>
                                                ),
                                            }
                                        ]}
                                        data={suggestions}
                                        loading={loading}


                                        search
                                        showExport={false}
                                    />
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                </Container>
            </Page>
            <SideDrawer
                title={'Refinance'}
                open={!!refinanceForm}
                onClose={() => setRefinanceForm(null)}
            >
                <RefinanceForm
                    editValues={refinanceForm}
                    onClose={() => setRefinanceForm(null)}
                />
            </SideDrawer>
            <AlertDialog
                title='Delete Refinance'
                info='Are you sure you want to delete this refinace?'
                open={!!remove}
                handleClose={() => setRemove(null)}
                handleConfirm={() => {
                    dispatch(removeRefinance(remove));
                    setRemove(null);
                }}
            />
        </>
    )
}