import {RootState} from "../../store";
import {createSelector} from "@reduxjs/toolkit";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {fundSelector} from "./generalSelectors";
import {OtherTransaction} from "../../../types/forecastTypes";

// Retrieve All Other Transactions
export const retrieveAllOtherTransactions = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.otherTransactions || [],
    (state: RootState) => state.capitalBudget.warnings?.otherTransactions.advise || [],
    (otherTransactions, checks) => {
        return otherTransactions.reduce((transactions: Array<OtherTransaction>, t) => {
            if (t.status === SaveStatus.REMOVED) return transactions;

            const check = checks.find(c => c.id === t.id);
            transactions.push({
                ...t,
                ...(check) ? {advise: check.flag} : {}
            })

            return transactions;
        }, [])
    }
)

// Retrieve Other Transactions and filter by fund if necessary
export const retrieveOtherTransactions = createSelector(
    retrieveAllOtherTransactions,
    fundSelector,
    (otherTransactions, fund) => {
        if (fund) {
            return otherTransactions.filter(t => t.fund === fund.label)
        } else {
            return otherTransactions;
        }
    }
)

// Retrieves Capital Transactions for calculations whether base or current
export const retrieveOtherTransactionsForCalc = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.otherTransactions || [],
    (_state: RootState, base?: boolean) => (base),
    (capital, base) => {
        return capital.reduce((transactions: Array<OtherTransaction>, t) => {
            if (base) {
                if (t.status === SaveStatus.NEW) return transactions;
                else if ((t.status === SaveStatus.EDITED && t.previous) || (t.status === SaveStatus.REMOVED && t.previous)) transactions.push(t.previous);
                else transactions.push(t);
            } else {
                if (t.status === SaveStatus.REMOVED) return transactions;
                else transactions.push(t)
            }
            return transactions;
        }, []);
    }
)