import {Order} from "../types/GeneralTypes";

import _ from 'lodash';
import {SaveStatus} from "../types/capitalBudgetEnums";

// Removes all special characters in search String
export function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

// Sort Comparator
function descendingComparator<T>(a: T, b: T, orderBy: keyof T, secondaryKey?: keyof T, secondaryOrder?: Order) {
    if (_.get(b, orderBy) === 0 && _.get(a, orderBy) === null) {
        return 1;
    }
    if (_.get(b, orderBy) < _.get(a, orderBy)) {
        return -1;
    }
    if (_.get(b, orderBy) > _.get(a, orderBy)) {
        return 1;
    }
    if (secondaryKey && orderBy !== secondaryKey) {
        const secondary = (secondaryOrder === 'desc') ? 1 : -1;
        return (_.get(a, secondaryKey) !== null && _.get(b, secondaryKey) < _.get(a, secondaryKey)) ? (-1 * secondary) : (1 * secondary);
    } else {
        return 0;
    }
}

// Determine sort function by direction
export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
    secondarySort?: Key,
    secondaryOrder?: Order
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, secondarySort, secondaryOrder)
        : (a, b) => -descendingComparator(a, b, orderBy, secondarySort, secondaryOrder);
}

// Returns Save Status Coloring for Table Cells
export function generateStatusColor(status: SaveStatus) {
    switch (status) {
        case SaveStatus.EDITED:
            return {
                bgcolor: 'warning.lighter'
            }

        case SaveStatus.NEW:
            return {
                bgcolor: 'success.lighter'
            }

        default:
            return {}
    }
}

// Checks objects if defined attributes have changed.
// Limitations for date checks
export function checkObjectChanged(prevObj: any, newObj: any, checkAttr: Array<string>): boolean {
    let changed = false;
    checkAttr.forEach(attr => {
        if (prevObj[attr] !== newObj[attr]) changed = true;
    })

    return changed;
}

// Removes any HTML formatting.
export function removeHtmlTags(text: string | null): string {
    if (!text) return '';
    return text.replace(/<[^>]*>?/gm, '');
}

// Formats names to first name initial - John Smith -> J. Smith
export function formatNameInitial(fullName: string): string {
    try {
        const [firstName, lastName] = fullName.split(' ');
        const initial = firstName.charAt(0);
        return `${initial}. ${lastName}`;
    } catch (err) {
        return ''
    }
}

// Santisise String HTML to only allow certain elements
export function cleanHTML(htmlString: string): string {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the HTML string into a Document
    const doc = parser.parseFromString(htmlString, 'text/html');

    // List of allowed tags
    const allowedTags = ['P', 'BR', 'UL', 'OL', 'LI', 'B', 'I', 'U', 'STRONG', 'EM'];

    // Remove all tags except the allowed ones
    const elements = doc.body.querySelectorAll('*');
    elements.forEach(el => {
        if (!allowedTags.includes(el.tagName)) {
            while (el.firstChild) {
                el.parentNode?.insertBefore(el.firstChild, el);
            }
            el.remove();
        }
    });

    // Remove all <style> elements
    const styles = doc.querySelectorAll('style');
    styles.forEach(style => style.remove());

    // Remove all class attributes
    const elementsWithClass = doc.querySelectorAll('[class]');
    elementsWithClass.forEach(el => el.removeAttribute('class'));

    // Serialize the cleaned document back into an HTML string
    return doc.body.innerHTML;
}