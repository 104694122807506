// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {OtherTransaction} from "../../../types/forecastTypes";
import {checkDateSame} from "../../../utils/DateUtils";
import {checkObjectChanged} from "../../../utils/generalUtils";

// Reducer Related to Capital Budget - Other Transactions
const otherTransactionsReducer: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Create new Other Transaction
    addOtherTransaction: {
        prepare(otherTransaction: OtherTransaction) {
            return {
                payload: {
                    ...otherTransaction,
                    id: nanoid(),
                    status: SaveStatus.NEW
                }
            }
        },
        reducer(state, action: PayloadAction<OtherTransaction>) {
            if (state.forecastData) {
                state.forecastData.otherTransactions = [action.payload, ...state.forecastData.otherTransactions];
                state.changes = true;
            }
        }
    },
    // Update Other Transaction
    updateOtherTransactions: (state, action) => {
        if (state.forecastData) {
            const index = state.forecastData.otherTransactions.findIndex(t => t.id === action.payload.id && t.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;

                if (state.forecastData.otherTransactions[index].status !== SaveStatus.NEW) {
                    const existing = state.forecastData.otherTransactions[index];
                    if (!checkDateSame(existing.date, action.payload.date)) changed = true;
                    changed = (checkObjectChanged(existing, action.payload, ['transactionType', 'amount', 'notes', 'capital', 'cash', 'fund']) ? true : changed);
                    if (changed) {
                        action.payload.previous = (!existing.previous) ? existing : existing.previous;
                        action.payload.status = SaveStatus.EDITED;
                        state.forecastData.otherTransactions[index] = action.payload;
                        state.changes = true;
                    }
                } else {
                    state.forecastData.otherTransactions[index] = {...state.forecastData.otherTransactions[index], ...action.payload};
                }
            }
        }
    },
    // Remove an Other Transaction
    removeOtherTransactions: (state, action: PayloadAction<number>) => {
        if (state.forecastData) {
            const index = state.forecastData?.otherTransactions.findIndex(t => t.id === action.payload && t.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.forecastData.otherTransactions[index].status !== SaveStatus.NEW) {
                    state.forecastData.otherTransactions[index].status = SaveStatus.REMOVED;
                } else {
                    const transactions = state.forecastData.otherTransactions;
                    transactions.splice(index, 1);
                    state.forecastData.otherTransactions = transactions;
                    state.changes = true;
                }
            }
        }
    },
}

export default otherTransactionsReducer;
