import {useState} from 'react';
import {ForecastPeriod} from "../../types/capitalBudgetTypes";
// MUI
import {Grid, Container} from '@mui/material';
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
import {ForecastCapitalDrillDown} from "../../sections/Budget/DrillDowns/ForecastCapitalDrillDown";
// Store
import {useAppSelector} from "../../store/store";
import {HorizontalTable, HorizontalTableColumn, HorizontalTableRow, SideDrawer} from "../../components";
import {
    forecastCapitalColumn,
    forecastCapitalReport
} from "../../store/capitalBudget/selectors/Reports/forecastCapitalReport";
import {budgetPeriodArraySelector} from "../../store/capitalBudget/selectors/generalSelectors";

// Capital Budget Summary App Page
export default function ForecastCapital() {
    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const calculating = useAppSelector(state => state.capitalBudget.calculating);
    const {data, rows} = useAppSelector(state => forecastCapitalReport(state));
    const budget = useAppSelector(state => budgetPeriodArraySelector(state))

    const [column, selectCol] = useState<forecastCapitalColumn | null>(null);
    const [row, selectRow] = useState<HorizontalTableRow | null>(null);
    const [budgetData, setBudgetData] = useState<ForecastPeriod | null>(null);

    const handleSelectColumn = (col: HorizontalTableColumn | null) => {
        if (!col) {
            selectCol(null);
            selectRow(null)
        }
        else {
            if (!!budget) {
                const periodData = budget.find(b => b.label === col.label);
                if (periodData) {
                    setBudgetData(periodData);
                    selectCol(col as forecastCapitalColumn);
                }
            }
        }
    }

    return (
        <Page title="Metrics - Capital Budget">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                <Grid container direction='column' sx={{height: 1, flex: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <ForecastConfigBar title={'Forecast Capital'}/>
                    </Grid>
                    <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                        <HorizontalTable
                            rows={rows}
                            data={(data) ? data : []}
                            loading={dataLoading || calculating}

                            selectedRow={row}
                            selectedCol={column}
                            rowSelect={selectRow}
                            colSelect={handleSelectColumn}

                            selectableCell
                        />
                    </Grid>
                </Grid>
            </Container>
            {column && budgetData &&
                <SideDrawer
                    title={`Forecast Capital - ${column.label} ${column.labelTwo}`}
                    open={(!!column && !!budgetData)}
                    onClose={() => {
                        selectCol(null);
                        setBudgetData(null);
                    }}
                    size="x-large"
                >
                    <ForecastCapitalDrillDown
                        forecastPeriod={budgetData}
                        periodForecastCapital={column}
                    />
                </SideDrawer>
            }
        </Page>
    )
}