// MUI
import {
    Grid,
    Container,
    Button,
    TableBody,
    Table,
    TableCell,
    TableRow, Box, useMediaQuery
} from '@mui/material';
import {GridColDef} from "@mui/x-data-grid";
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
import {DataTable, SideDrawer, valueCurrency, valuePercentage} from "../../components";
// Store
import {RootState, useAppSelector} from "../../store/store";
import {balancesSelector, ratesSelector} from "../../store/capitalBudget/selectors/generalSelectors";
import {generalRow} from "../../components/DataTable/DataTableUtils";
import {useState} from "react";
import UpdateFUM from "../../sections/Budget/Configurations/UpdateFUM";
import {fCurrency} from "../../utils/formatNumber";
import {
    retrieveBankFacilities
} from "../../store/capitalBudget/selectors/Reports/facilitiesSelector";
import {createSelector} from "@reduxjs/toolkit";
import {FundType} from "../../types/capitalBudgetEnums";
import {useTheme} from "@mui/material/styles";

// Data
let fundColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', ...generalRow, hide: true},
    {
        field: 'fund',
        headerName: 'Fund',
        flex: 1,
        minWidth: 90,
        sortable: true,
        ...generalRow,
    },
    {
        ...valueCurrency,
        field: 'cash',
        headerName: 'Cash',
        flex: 0.5,
        minWidth: 140,
        sortable: true,
    },
    {
        ...valueCurrency,
        field: 'capital',
        headerName: 'Capital',
        flex: 0.5,
        minWidth: 140,
        sortable: true,
    },
    {
        ...valueCurrency,
        field: 'fum',
        headerName: 'FUM',
        flex: 0.5,
        minWidth: 140,
        sortable: true,
    },
];

let fxColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', ...generalRow, hide: true},
    {
        field: 'currency',
        headerName: 'Currency',
        flex: 1,
        minWidth: 90,
        sortable: true,
        ...generalRow,
    },
    {
        field: 'rate',
        headerName: 'Rate',
        flex: 1,
        minWidth: 90,
        sortable: true,
        align: 'right',
        ...generalRow,
    },
];

let externalFacilityColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', ...generalRow, hide: true},
    {
        field: 'fund',
        headerName: 'Fund',
        width: 80,
        ...generalRow
    },
    {
        field: 'trancheName',
        headerName: 'Facility',
        flex: 1,
        ...generalRow
    },
    {
        ...valueCurrency,
        field: 'facilityLimit',
        headerName: 'Facility Limit',
        flex: 0.5,
        minWidth: 140,
        sortable: true,
    },
    {
        ...valueCurrency,
        field: 'drawn',
        headerName: 'Drawn',
        flex: 0.5,
        minWidth: 140,
        sortable: true,
    },
];

let internalFacilityColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', ...generalRow, hide: true},
    {
        field: 'fund',
        headerName: 'Fund',
        width: 90,
        ...generalRow
    },
    {
        field: 'trancheName',
        headerName: 'Facility',
        flex: 1,
        ...generalRow
    },
    {
        field: 'tranche',
        headerName: 'TrancheId',
        width: 90,
        ...generalRow
    },
    {
        ...valueCurrency,
        field: 'facilityLimit',
        headerName: 'Facility Limit',
        flex: 0.5,
        minWidth: 140,
        sortable: true,
    },
    {
        ...valueCurrency,
        field: 'drawn',
        headerName: 'Drawn',
        flex: 0.5,
        minWidth: 140,
        sortable: true,
    },
]

const lookthroughTable = createSelector(
    (state: RootState) => state.capitalBudget?.forecastData?.funds || [],
    (state: RootState) => state.capitalBudget?.externalData?.holding?.holding || [],
    (funds, holdings) => {
        const fundsList = funds.reduce((funds: Array<string>, fund) => {
            if (fund.type === FundType.UNDERLYING) {
                funds.push(fund.label)
            }
            return funds
        }, [])
        let underlyFunds: Array<string> = [];
        let feederFunds: Array<{ fund: string, [x: string]: number | string }> = [];

        holdings.forEach(holding => {
            let holder: { fund: string, [x: string]: number | string } = {
                fund: holding.holder
            };

            holding.holdings.forEach(({fund, percentage}) => {
                if (fundsList.includes(fund)) {
                    if (!underlyFunds.includes(fund)) underlyFunds.push(fund)
                    holder[fund] = percentage;
                }
            })

            feederFunds.push(holder)
        })

        return {
            underlyFunds: underlyFunds.sort((a: string, b: string) => a > b ? 1 : -1),
            feederFunds: feederFunds
        }
    }
)

// Capital Budget Summary App Page
export default function OtherData() {
    const theme = useTheme();

    const loading = useAppSelector((state) => state.capitalBudget.loading);

    const fx = useAppSelector((state) => ratesSelector(state));
    const fundBalances = useAppSelector(state => balancesSelector(state));
    const facilities = useAppSelector(state => retrieveBankFacilities(state));

    const [update, setUpdate] = useState<boolean>(false)

    const lookthrough = useAppSelector(state => lookthroughTable(state));

    return (
        <>
            <Page title="Metrics - CB - New Commitments">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title={'Other Data'}/>
                        </Grid>
                        <Grid container item sx={{flex: 1, overflowY: 'auto', width: '100%', py: 1}} spacing={1}>
                            <Grid item xs={12} md={8} lg={8} xl={4}
                                  sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                                <DataTable
                                    title='Funds balance'
                                    columns={fundColumns}
                                    data={fundBalances.balances}
                                    loading={loading}
                                    showExport={false}
                                    customFilterComponent={<Button variant="outlined" color='info'
                                                                   onClick={() => setUpdate(!update)}>Update</Button>}
                                />
                                <Box sx={{bgcolor: 'primary.main', borderRadius: 1, mt: 1}}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                {fundColumns.slice(1).map((column, c) => (
                                                    <TableCell key={c} sx={{
                                                        flex: column.flex,
                                                        width: column.width,
                                                        color: 'common.white',
                                                        fontWeight: 'bold', ...(c !== 0) ? {textAlign: 'right'} : {}
                                                    }}>
                                                        {(c !== 0) ?
                                                            fCurrency(fundBalances.totals[column.field as keyof typeof fundBalances.totals])
                                                            :
                                                            'Total'
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4} xl={2} sx={{height: '100%'}}>
                                <DataTable
                                    title='FX Rates'
                                    columns={fxColumns}
                                    data={fx}
                                    loading={loading}
                                    showExport={false}
                                />
                            </Grid>
                            {/*Show when smaller than md*/}
                            {useMediaQuery(theme.breakpoints.down('lg')) ?
                                <>
                                    <Grid item xs={12}
                                          sx={{height: '100%'}}>
                                        <DataTable
                                            title='External Facilities'
                                            columns={externalFacilityColumns}
                                            data={[...facilities.external.map((f, i) => {
                                                return {...f, id: `${f.trancheName}${f.fund}${i}`}
                                            })]}
                                            loading={loading}
                                            showExport={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}
                                          sx={{height: '100%'}}>
                                        <DataTable
                                            title='Internal Facilities'
                                            columns={internalFacilityColumns}
                                            data={facilities.internal.map((f, i) => {
                                                return {...f, id: `${f.trancheName}${f.fund}${i}`}
                                            })}
                                            loading={loading}
                                            showExport={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}
                                          sx={{height: '100%'}}>
                                        <DataTable
                                            title='Fund Look Through'
                                            columns={[
                                                {field: 'id', headerName: 'id', ...generalRow, hide: true},
                                                {field: 'fund', headerName: 'Fund', ...generalRow, flex: 1},
                                                ...lookthrough.underlyFunds.map(f => {
                                                    return {
                                                        field: f,
                                                        headerName: f,
                                                        flex: 1,
                                                        ...valuePercentage
                                                    }
                                                })
                                            ]}
                                            data={lookthrough.feederFunds.map((f) => {
                                                return {...f, id: f.fund}
                                            })}
                                            loading={loading}
                                            showExport={false}
                                        />
                                    </Grid>
                                </>
                                :
                                <>
                                    {/* Show when larger than md*/}
                                    <Grid container direction='column' item xs={12} md={12} lg={12} xl={6}
                                          sx={{height: '100%', minHeight: 700}}
                                          spacing={1}>
                                        {/*<Grid item sx={{flex: 1}}>*/}
                                        {/*</Grid>*/}
                                        <Grid item sx={{flex: 1}}>
                                            <DataTable
                                                title='External Facilities'
                                                columns={externalFacilityColumns}
                                                data={facilities.external.map((f, i) => {
                                                    return {...f, id: `${f.trancheName}${f.fund}${i}`}
                                                })}
                                                loading={loading}
                                                showExport={false}
                                            />
                                        </Grid>
                                        <Grid item sx={{flex: 1}}>
                                            <DataTable
                                                title='Internal Facilities'
                                                columns={internalFacilityColumns}
                                                data={facilities.internal.map((f, i) => {
                                                    return {...f, id: `${f.trancheName}${f.fund}${i}`}
                                                })}
                                                loading={loading}
                                                showExport={false}
                                            />
                                        </Grid>
                                        <Grid item sx={{flex: 1}}>
                                            <DataTable
                                                title='Fund Look Through'
                                                columns={[
                                                    {field: 'id', headerName: 'id', ...generalRow, hide: true},
                                                    {field: 'fund', headerName: 'Fund', ...generalRow, flex: 1},
                                                    ...lookthrough.underlyFunds.map(f => {
                                                        return {
                                                            field: f,
                                                            headerName: f,
                                                            flex: 1,
                                                            ...valuePercentage
                                                        }
                                                    })
                                                ]}
                                                data={lookthrough.feederFunds.map((f) => {
                                                    return {...f, id: f.fund}
                                                })}
                                                loading={loading}
                                                showExport={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Page>
            {update &&
                <SideDrawer title='Update FUM' open={update} onClose={() => setUpdate(false)}>
                    <UpdateFUM balances={fundBalances.balances.map(f => ({label: f.fund, fum: f.fum}))}
                               onClose={() => setUpdate(false)}/>
                </SideDrawer>
            }
        </>
    )
}