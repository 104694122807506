import {memo, useState} from "react";
import {Box, MenuItem, Select} from "@mui/material";
import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";
import {FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";
import {valueCurrency} from "../../../../components";
import {LoanTags} from "../../../../types/capitalBudgetEnums";
import LoansDrilldownTable from "./LoansDrilldownTable";
import DrillDownSimple from "../../../../components/DrillDownsSimple";

interface AudDrillDownProps {
    period: ForecastPeriod,
    column: investmentReportColumn,
    avaCapToggle: boolean,
    fund: FundDetails | null
}

// DRILL DOWN FOR CURRENCY LOANS
function AudDrillDown({period, column, avaCapToggle, fund}: AudDrillDownProps) {

    const [aud, setAUD] = useState<boolean>(true)

    return (
        <>
            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', minHeight: 800}}>
                <Box>
                    <DrillDownSimple
                        data={[
                            {
                                label: 'AUD Loans',
                                value: column.aud * ((avaCapToggle) ? column.analyAUM : column.totalCommitment),
                            },
                            {
                                label: 'Other Currencies',
                                value: (1 - column.aud) * ((avaCapToggle) ? column.analyAUM : column.totalCommitment),
                            },
                            {
                                label: `Total`,
                                value: (avaCapToggle) ? column.analyAUM : column.totalCommitment,
                                textColor: 'white',
                                sx: {bgcolor: 'primary.light'}
                            }
                        ]}
                    />
                </Box>
                <Box sx={{py: 1, flex: 1, minHeight: 200}}>
                    <Box sx={{height: '99%'}}>
                        <LoansDrilldownTable
                            title={`Loans`}
                            period={period}
                            fund={fund}
                            additionalColumns={[
                                {
                                    field: 'baseCurrency',
                                    headerName: 'Currency',
                                },
                                {
                                    field: 'value',
                                    headerName: 'Commitment',
                                    ...valueCurrency,
                                    width: 120
                                }
                            ]}
                            filterFunction={(loan: CalculationLoanType) => ((loan.tags.includes(LoanTags.ACTIVE) && (aud ? (loan.baseCurrency === 'AUD') : (loan.baseCurrency !== 'AUD'))))}
                            customFilterComponent={<CurrencyFilter aud={aud} setAUD={setAUD}/>}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

function CurrencyFilter({aud, setAUD}: { aud: boolean, setAUD: (aud: boolean) => void }) {
    console.log(aud)
    return (
        <>
            <Box sx={{px: 1}}>
                Currency:
                <Select
                    value={aud ? 'aud' : 'other'}
                    onChange={(e) => setAUD(e.target.value === 'aud')}
                    sx={{minWidth: 100, color: 'common.white'}}
                    label={'Seniority'}
                    size={"small"}
                >
                    <MenuItem value={'aud'}>AUD</MenuItem>
                    <MenuItem value={'other'}>Other Curency</MenuItem>
                </Select>
            </Box>
        </>
    )
}

export default memo(AudDrillDown);