import {Forecast} from "../../../types/forecastTypes";
import {Box, IconButton, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {DataDisplay} from "../../../components";
import {formatDate} from "../../../utils/DateUtils";
import {Link} from "react-router-dom";
import {
    Alarm as AlarmIcon,
    AttachMoney as AttachMoneyIcon,
    CurrencyExchange as CurrencyExchangeIcon,
    EditNote as EditNoteIcon,
    EventRepeat as EventRepeatIcon,
    Launch as LaunchIcon,
    MoneyOff as MoneyOffIcon,
    QuestionMark as QuestionMarkIcon,
    Schedule as ScheduleIcon
} from "@mui/icons-material";
import {WarningsSummary} from "../../../store/capitalBudget/selectors/warningsSelector";
import {ReactElement} from "react";

interface SideBarDescrProps {
    forecast: Forecast,
    externalDataDates: {
        accountBalance: string | null,
        axcess: string | null,
        debtFacilities: string | null,
        nCino: string | null,
        holding: string | null,
        fx: string | null,
    },
    setOpenForecast: (open: boolean) => void,
    warnings: WarningsSummary
}

function ForecastSideBarDescription({forecast, externalDataDates, setOpenForecast, warnings}: SideBarDescrProps) {

    return (
        <>
            <Typography variant="h6">Forecast Information:</Typography>
            <DataDisplay
                dataValues={[
                    ...(forecast.new ? [{
                                label: 'Forecasted Data From',
                                data: formatDate(forecast.forecastDataDate, 'dd-MMM-yyyy')
                            }] :
                            [{
                                label: 'Forecast Date',
                                data: formatDate(forecast.forecastDate, 'dd-MMM-yyyy')
                            }]
                    ),
                    {label: 'Created By', data: `${forecast?.creator.firstName} ${forecast?.creator.lastName}`},
                    {label: 'Created', data: formatDate(forecast.creationTimestamp, 'dd-MMM-yyyy hh:mm:ss b')},

                    {
                        label: 'Modified By',
                        data: (forecast.modifier) ? `${forecast?.modifier?.firstName} ${forecast?.modifier?.lastName}` : null
                    },
                    {
                        label: 'Modified',
                        data: (forecast.modifier && forecast.modifiedTimestamp) ? formatDate(forecast.modifiedTimestamp, 'dd-MMM-yyyy hh:mm:ss b') : null
                    },
                ]}
            />
            <Typography variant="h6">Data Information:</Typography>
            <DataDisplay
                dataValues={[
                    {label: 'Account Balances', data: externalDataDates.accountBalance},
                    {label: 'Axcess Portfolio', data: externalDataDates.axcess},
                    {label: 'Debt Facilities', data: externalDataDates.debtFacilities},
                    {label: 'nCino - Salesforce', data: externalDataDates.nCino},
                    {label: 'Portfolio Holding', data: externalDataDates.holding},
                    {label: 'FX', data: externalDataDates.fx}
                ]}
            />
            <Typography variant="h6">Warnings:</Typography>
            {(warnings.maturity.preForecast > 0 || warnings.maturity.updatedMaturity > 0) &&
                <WarningsBreakdownSection
                    section='Portfolio Changes (Maturity Adjustments):'
                    link={`/budget/configuration/portfolio-changes`}
                    fields={[
                        {
                            label: 'Pre Forecast',
                            icon: <ScheduleIcon color='info'/>,
                            value: warnings.maturity.preForecast,
                            plural: 'Loans'
                        },
                        {
                            label: 'Updated Maturity',
                            icon: <EventRepeatIcon color='info'/>,
                            value: warnings.maturity.updatedMaturity,
                            plural: 'Loans'
                        }
                    ]}
                    setOpen={() => setOpenForecast(false)}
                />
            }
            {(warnings.wipCheck.preForecast > 0 || warnings.wipCheck.updatedWip > 0) &&
                <WarningsBreakdownSection
                    section='WIP'
                    link={`/budget/configuration/wip`}
                    fields={[
                        {
                            label: 'Pre Forecast',
                            icon: <ScheduleIcon color='info'/>,
                            value: warnings.wipCheck.preForecast,
                            plural: 'Loans'
                        },
                        {
                            label: 'Updated nCino Loans',
                            icon: <EditNoteIcon color='info'/>,
                            value: warnings.wipCheck.updatedWip,
                            plural: 'Loans'
                        },
                        {
                            label: 'Missing Data',
                            icon: <QuestionMarkIcon color='error'/>,
                            value: warnings.wipCheck.missingData,
                            plural: 'Loans'
                        }
                    ]}
                    setOpen={() => setOpenForecast(false)}
                />
            }
            {(warnings.wipCheck.misallocated > 0 || warnings.wipCheck.unallocated > 0) &&
                <WarningsBreakdownSection
                    section='Allocations'
                    link={`/budget/configuration/allocations`}
                    fields={[
                        {
                            label: 'Not Allocated',
                            icon: <MoneyOffIcon color='error'/>,
                            value: warnings.wipCheck.unallocated,
                            plural: 'Loans'
                        },
                        {
                            label: 'Misallocated',
                            icon: <CurrencyExchangeIcon color='error'/>,
                            value: warnings.wipCheck.misallocated,
                            plural: 'Loans'
                        }
                    ]}
                    setOpen={() => setOpenForecast(false)}
                />
            }
            {(warnings.transfer.preForecast > 0 || warnings.transfer.afterMaturity > 0 || warnings.transfer.exceed > 0) &&
                <WarningsBreakdownSection
                    section='Transfers'
                    link={`/budget/configuration/sell-repay`}
                    fields={[
                        {
                            label: 'Pre Forecast',
                            icon: <ScheduleIcon color='info'/>,
                            value: warnings.transfer.preForecast,
                            plural: 'Transfers'
                        },
                        {
                            label: 'After Maturity',
                            icon: <AlarmIcon color='error'/>,
                            value: warnings.transfer.afterMaturity,
                            plural: 'Transfers'
                        },
                        {
                            label: 'Exceed Loan Amount',
                            icon: <AttachMoneyIcon color='error'/>,
                            value: warnings.transfer.exceed,
                            plural: 'Transfers'
                        }
                    ]}
                    setOpen={() => setOpenForecast(false)}
                />
            }
            {(warnings.sellRepay.preForecast > 0 || warnings.sellRepay.afterMaturity > 0 || warnings.sellRepay.exceed > 0) &&
                <WarningsBreakdownSection
                    section='Selldowns and Repayments'
                    link={`/budget/configuration/sell-repay`}
                    fields={[
                        {
                            label: 'Pre Forecast',
                            icon: <ScheduleIcon color='info'/>,
                            value: warnings.sellRepay.preForecast,
                            plural: 'Transfers'
                        },
                        {
                            label: 'After Maturity',
                            icon: <AlarmIcon color='error'/>,
                            value: warnings.sellRepay.afterMaturity,
                            plural: 'Transfers'
                        },
                        {
                            label: 'Exceed Loan Amount',
                            icon: <AttachMoneyIcon color='error'/>,
                            value: warnings.sellRepay.exceed,
                            plural: 'Transfers'
                        }
                    ]}
                    setOpen={() => setOpenForecast(false)}
                />
            }
            {(warnings.capital.preForecast > 0) &&
                <WarningsBreakdownSection
                    section='Capital Transactions'
                    link={`/budget/configuration/capital`}
                    fields={[
                        {
                            label: 'Pre Forecast',
                            icon: <ScheduleIcon color='info'/>,
                            value: warnings.capital.preForecast,
                            plural: 'Transactions'
                        }
                    ]}
                    setOpen={() => setOpenForecast(false)}
                />
            }
            {(warnings.otherTransactions.preForecast > 0) &&
                <WarningsBreakdownSection
                    section='Other Transactions'
                    link={`/budget/configuration/other`}
                    fields={[
                        {
                            label: 'Pre Forecast',
                            icon: <ScheduleIcon color='info'/>,
                            value: warnings.otherTransactions.preForecast,
                            plural: 'Transactions'
                        }
                    ]}
                    setOpen={() => setOpenForecast(false)}
                />
            }
        </>
    )
}

export default ForecastSideBarDescription;

interface WarningBreakdownSectionProps {
    section: string,
    link: string,
    fields: Array<{
        label: string,
        icon: ReactElement,
        value: number,
        plural: string
    }>,
    setOpen: () => void
}

function WarningsBreakdownSection({section, link, fields, setOpen}: WarningBreakdownSectionProps) {
    return (
        <>
            <Box sx={{px: 1}}>
                <Typography sx={{fontSize: 16, color: 'secondary.light', fontWeight: 'bold'}}>
                    {section}
                    <IconButton
                        size="small"
                        sx={{color: 'secondary.light'}}
                        onClick={() => setOpen}
                        component={Link}
                        to={`${process.env.REACT_APP_CB_LOCAL_APP}${link}`}
                    >
                        <LaunchIcon/>
                    </IconButton>
                </Typography>
                <Table>
                    <TableBody>
                        {fields.map((field, i) => {
                            if (field.value) {
                                return (
                                    <TableRow key={i} sx={{display: 'flex', alignItems: 'center'}}>
                                        <TableCell align='right' sx={{
                                            p: 0,
                                            m: 0,
                                            border: 0,
                                            width: 0.5,

                                        }}>
                                            <Typography
                                                sx={{
                                                    color: 'primary.main',
                                                    fontWeight: 'bold',
                                                    p: 0,
                                                    m: 0,
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center'
                                                }}>
                                                {field.label} {field.icon}:
                                            </Typography>

                                        </TableCell>
                                        <TableCell align='left' sx={{p: 0.5, m: 0, border: 0, width: 0.5}}>
                                            <Typography>
                                                {field.value} {field.plural}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            } else return null
                        })}
                    </TableBody>
                </Table>
            </Box>
        </>
    )
}