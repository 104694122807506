import {ExternalData} from "../../types/externalDataTypes";
import {ForecastData, MCPFund} from "../../types/forecastTypes";
import {CapitalBudget, ForecastChecks} from "../../types/capitalBudgetTypes";
import {default as capitalBudgetReducers} from './reducers'
import {changeForecastDate, initialBudgetRetrieval, saveForecast, saveNewForecast} from "../forecast/forecastFunctions";
import {createSlice} from "@reduxjs/toolkit";
import {checkInputs, generateCapitalBudget} from "./capitalBudgetFunctions";

export interface CapitalBudgetState {
    externalData: ExternalData | null,
    forecastData: ForecastData | null,
    misc: {
        // periods: { weeks: Array<Period>, months: Array<Period> },
        weeks: number,
        months: number,
        fund: null | MCPFund
    },
    capitalBudget:{
        budget: CapitalBudget | null,
        calculating: boolean
    },
    base: {
        budget: CapitalBudget | null,
        calculating: boolean
    },
    changes: boolean,
    warnings: null | ForecastChecks,
    loading: boolean,
    calculating: boolean,
}

const initialState = {
    externalData: null,
    forecastData: null,
    misc: {
        weeks: 8,
        months: 3,
        fund: null
    },
    capitalBudget: {
        budget: null,
        calculating: false
    },
    base: {
        budget: null,
        calculating: false
    },
    changes: false,
    warnings: null,
    loading: false,
    calculating: false
}

export const capitalBudgetSlice = createSlice({
    name: 'capitalBudget',
    initialState,
    reducers: capitalBudgetReducers,
    extraReducers(builder) {
        builder
            // INITIAL FORECAST LOAD
            .addCase(initialBudgetRetrieval.pending, (state) => {
                state.loading = true;
                state.externalData = null;
                state.forecastData = null;
                state.capitalBudget = {budget: null, calculating: false};
                state.base = {budget: null, calculating: false};
            })
            .addCase(initialBudgetRetrieval.fulfilled, (state, action) => {
                state.loading = false;
                state.externalData = action.payload.externalData;
                state.forecastData = action.payload.forecastData;
                state.changes = false;
            })
            // CHANGE FORECAST
            .addCase(changeForecastDate.pending, (state) => {
                state.loading = true;
                state.externalData = null;
                state.forecastData = null;
                state.capitalBudget = {budget: null, calculating: false};
                state.base = {budget: null, calculating: false};
                state.warnings = null;
            })
            .addCase(changeForecastDate.fulfilled, (state, action) => {
                state.loading = false;
                state.externalData = action.payload.externalData;
                state.forecastData = action.payload.forecastData;
                state.changes = false;
                state.warnings = null;
            })
            // CREATE FORECASTS
            .addCase(saveNewForecast.pending, (state) => {
                state.loading = true;
                state.externalData = null;
                state.capitalBudget = {budget: null, calculating: false};
                state.base = {budget: null, calculating: false};
            })
            .addCase(saveNewForecast.fulfilled, (state, action) => {
                state.loading = false;
                state.changes = false;
                state.warnings = null;
                if (action.payload) {
                    state.externalData = action.payload.externalData;
                    state.forecastData = action.payload.forecastData;
                }
            })

            // UPDATE FORECASTS
            .addCase(saveForecast.pending, (state) => {
                state.loading = true;
                state.externalData = null;
                state.capitalBudget = {budget: null, calculating: false};
                state.base = {budget: null, calculating: false};
            })
            .addCase(saveForecast.fulfilled, (state, action) => {
                state.loading = false;
                state.changes = false;
                state.warnings = null;
                if (action.payload) {
                    state.externalData = action.payload.externalData;
                    state.forecastData = action.payload.forecastData;
                }
            })
            // GENERATE BUDGET
            .addCase(generateCapitalBudget.pending, (state) => {
                state.capitalBudget.calculating = true;
                state.capitalBudget.budget = null
            })
            .addCase(generateCapitalBudget.fulfilled, (state, action) => {
                state.capitalBudget.calculating = false;
                state.capitalBudget.budget = action.payload;
                console.log('DONE')
            })
            .addCase(generateCapitalBudget.rejected, (state) => {
                state.capitalBudget.calculating = false;
            })

            // RUN CHECKS
            .addCase(checkInputs.pending, (state) => {
                state.loading = true;
                state.warnings = null;
            })
            .addCase(checkInputs.fulfilled, (state, action) => {
                state.loading = false;
                state.warnings = action.payload;
            })
    }
})

export const {
    setFund,
    setWeeks,
    setMonths,
    // Capital Transacitons
    addCapitalTransaction,
    updateCapitalTransaction,
    removeCapitalTransaction,
    // Fund Finance
    addFundFinance,
    updateFundFinance,
    removeFundFinance,
    // FUM
    updateFUM,
    // ManualDealReducer
    addNewManualLoan,
    updateManualDeal,
    deleteManualDeal,
    // MaturityActions
    addMaturityChange,
    removeMaturityChange,
    removeMaturityChangeBulk,
    updateMaturityChanges,
    // New Deals,
    addNewLoan,
    updateNewLoan,
    updateAllocation,
    removeNewLoan,
    removeNewLoanBulk,
    bulkUpdateChanges,
    // Other Transactions
    addOtherTransaction,
    updateOtherTransactions,
    removeOtherTransactions,
    // Refinances
    addRefinance,
    updateRefinances,
    removeRefinance,
    bulkRemoveRefinance,
    // Selldowns Repayments
    addSelldownRepayment,
    addMultipleSelldownRepayment,
    updateSelldownRepayment,
    removeSelldownRepayment,
    removeSellRepayBulk,
    // Transfers
    addTransfer,
    updateTransfer,
    removeTransfer,
    removeTransferBulk,
    // WIP Comments
    addWIPComment,
    updateWIPComment,
    removeWIPComment,
    bulkRemoveWIPComments
} = capitalBudgetSlice.actions;

export default capitalBudgetSlice.reducer;