import {useState} from 'react';
import {actionRow} from "../../../components";
// MUI
import {Grid, Container, IconButton, Box, FormControlLabel, Checkbox} from '@mui/material';
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {
    Delete as DeleteIcon,
    Edit as EditIcon
} from "@mui/icons-material";
// Components
import {Page} from "../../../layouts/components";
import ForecastConfigBar from "../ForecastConfigBar";
import MaturityChangeForm from "../../../sections/Budget/Configurations/MaturityChangeForm";
import {DataTable, SideDrawer, WarningsIcon} from "../../../components";
// Store
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {retrieveMaturityChanges} from "../../../store/capitalBudget/selectors/maturitySelector";
import {removeMaturityChange} from "../../../store/capitalBudget/capitalBudgetSlice";
import {warningsGeneralRow, warningValueCurrency, warningValueDate} from "./DataTableWarningsUtilts";
import {warningDataGridSx} from "./DataTableWarningsUtilts";

let columns: GridColDef[] = [
    {
        field: 'advise',
        headerName: '',
        align: 'center',
        ...warningsGeneralRow,
        width: 50,
        renderCell: (params: GridCellParams) => (
            <WarningsIcon value={params.value} />
        ),
        sortable: false,
    },
    {field: 'trancheId', headerName: 'Tranche ID', width: 100, ...warningsGeneralRow},
    {
        field: 'asset',
        headerName: 'Asset',
        flex: 1,
        ...warningsGeneralRow
    },
    {
        field: 'borrower',
        headerName: 'Borrower',
        flex: 1,
        ...warningsGeneralRow
    },
    {
        field: 'tranche',
        headerName: 'Tranche',
        flex: 0.75,
        ...warningsGeneralRow
    },
    {
        field: 'amendment',
        headerName: 'Amendment',
        ...warningsGeneralRow,
        width: 150,
    },
    {
        field: 'maturity',
        headerName: 'Maturity',
        ...warningValueDate,
        width: 100,
    },
    {
        field: 'amendedMaturity',
        headerName: 'New Maturity',
        ...warningValueDate,
        width: 120,
    },
    {
        ...warningValueCurrency,
        field: 'commitment',
        headerName: 'Commitment',
        width: 150,
    },
    {
        ...warningValueCurrency,
        field: 'drawn',
        headerName: 'Drawn',
        width: 150,
    },
    {
        ...warningValueCurrency,
        field: 'undrawn',
        headerName: 'Undrawn',
        width: 150,
    },
];

// Capital Budget Maturity Changes
export default function MaturityChanges() {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.forecast.loading);
    const maturityChanges = useAppSelector(state => retrieveMaturityChanges(state));

    // Handling Edit and Creation of Maturity Changes
    const [form, setFormOpen] = useState<any | null>(null);

    const [filterWarnings, setFilter] = useState<boolean>(false)

    return (
        <Page title="Metrics - CB - Maturity Changes">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1}}>
                <Grid container direction='column' sx={{height: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <ForecastConfigBar title='Portfolio - Maturity Changes' configuration/>
                    </Grid>
                    <Grid item container sx={{width: '100%', flex: 1}} spacing={1}>
                        <DataTable
                            title={'Extensions/Early Repayments'}
                            columns={[...columns, {
                                ...actionRow,
                                ...warningsGeneralRow,
                                renderCell: (params: GridCellParams) =>
                                    <>
                                        <IconButton
                                            sx={{color: 'primary.main'}}
                                            style={{padding: '0px', outline: 'none'}}
                                            onClick={() => setFormOpen(params.row)}
                                            size="small">
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton
                                            sx={{color: 'primary.main'}}
                                            style={{padding: '0px', outline: 'none'}}
                                            onClick={() => {
                                                dispatch(removeMaturityChange(params.row.id))
                                            }}
                                            size="small">
                                            <DeleteIcon/>
                                        </IconButton>
                                    </>
                            }]}
                            data={maturityChanges.filter(mc => {
                                if (filterWarnings) return !!mc.advise;
                                return true;
                            })}
                            loading={loading}
                            showExport={false}
                            sort={{field: 'maturity', sort: 'asc'}}
                            search
                            create={() => setFormOpen({})}
                            customFilterComponent={
                                <WarningsFilter filterWarnings={filterWarnings} setFilter={setFilter}/>
                            }
                            additionalDataGridSx={warningDataGridSx}
                        />
                    </Grid>
                </Grid>
            </Container>
            <SideDrawer
                title="Maturity Change"
                open={(!!form)}
                onClose={() => setFormOpen(null)}
            >
                <MaturityChangeForm values={form} onClose={() => setFormOpen(null)}/>
            </SideDrawer>
        </Page>
    )
}

function WarningsFilter({filterWarnings, setFilter}: {
    filterWarnings: boolean,
    setFilter: (filterWarnings: boolean) => void
}) {
    return (
        <>
            <Box sx={{px: 1}}>
                <FormControlLabel
                    control={<Checkbox checked={filterWarnings} onChange={() => setFilter(!filterWarnings)} color={'secondary'}/>}
                    label='Show only Warnings:'
                    labelPlacement='start'
                />
            </Box>
        </>
    )
}