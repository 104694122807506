import {useState} from 'react';
// MUI
import {Grid, Container} from '@mui/material';
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
import {HorizontalTable, HorizontalTableColumn, HorizontalTableRow, SideDrawer} from "../../components";
// Store
import {useAppSelector} from "../../store/store";
import {sourcesAndUsesColumn, sourcesUsesReport} from "../../store/capitalBudget/selectors/Reports/sourcesUseReport";
import {budgetPeriodArraySelector} from "../../store/capitalBudget/selectors/generalSelectors";
import {ForecastPeriod} from "../../types/capitalBudgetTypes";
import {SourcesUsesDrillDown} from "../../sections/Budget/DrillDowns/SourcesUsesDrillDown";
// Data

// Capital Budget Summary App Page
export default function SourcesUses() {
    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const calculating = useAppSelector(state => state.capitalBudget.calculating);
    const {data, rows} = useAppSelector(state => sourcesUsesReport(state))
    const budget = useAppSelector(state => budgetPeriodArraySelector(state))

    const [column, selectCol] = useState<sourcesAndUsesColumn | null>(null);
    const [row, selectRow] = useState<HorizontalTableRow | null>(null);
    const [budgetData, setBudgetData] = useState<ForecastPeriod | null>(null);

    const handleSelectColumn = (col: HorizontalTableColumn | null) => {
        if (!col) {
            selectCol(null);
            selectRow(null)
        }
        else {
            if (!!budget) {
                const periodData = budget.find(b => b.label === col.label);
                if (periodData) {
                    setBudgetData(periodData);
                    selectCol(col as sourcesAndUsesColumn);
                }
            }
        }
    }

    return (
        <Page title="Metrics - Sources and Uses">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                <Grid container direction='column' sx={{height: 1, flex: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <ForecastConfigBar title='Sources and Uses'/>
                    </Grid>
                    <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                        <HorizontalTable
                            rows={rows}
                            data={(data) ? data : []}
                            loading={dataLoading || calculating}

                            selectedRow={row}
                            selectedCol={column}
                            rowSelect={selectRow}
                            colSelect={handleSelectColumn}


                            selectableCell
                        />
                    </Grid>
                </Grid>
            </Container>
            {column && budgetData &&
                <SideDrawer
                    title={`Sources and Uses - ${column.label} ${column.labelTwo}`}
                    open={(!!column && !!budgetData)}
                    onClose={() => {
                        selectCol(null);
                        setBudgetData(null);
                    }}
                    size="x-large"
                >
                    <SourcesUsesDrillDown
                        forecastPeriod={budgetData}
                        periodSourcesUses={column}
                    />
                </SideDrawer>
            }
        </Page>
    )
}