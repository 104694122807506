import {useState} from "react";
// Local Imports
import {SelectionFieldProps, InputTypes} from "../../../types/InputTypes";
import {handleFormValueChange} from "../inputUtils";
// MUI
import {styled} from "@mui/material/styles";
import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent,
    SelectProps,
    SxProps,
    Theme,
    Tooltip
} from "@mui/material";
import {
    HelpOutline as HelpOutlineIcon,
    Error as ErrorIcon
} from "@mui/icons-material"

// Custom styling
const GridStyle = styled(Grid)(({theme}: { theme: Theme }) => ({
    padding: theme.spacing(1),
    width: '100%'
}))

/**
 * Selection Field Option, takes a list of options and allows for choosing between options.
 */
export default function SelectionField(props: SelectionFieldProps) {
    const {
        id,
        label,
        info = null,
        helperText,
        error,
        disabled = false,
        handleChange,
        layout = {xs: 12, md: 12, lg: 12},
        size,
        variant,
        noUnderline,
        noLabel,
        errorInIcon,
        handleBlur,

        value,
        values,
        displayNone = true,
        limit = false
    } = props;

    const [maxDisplayMulti, setMulti] = useState<number>(1)

    const selectionInfoStyle: SxProps<Theme> = (info) ?
        {
            '.MuiSvgIcon-root': {
                marginRight: 4
            }
        }
        :
        {}

    let selectProps: SelectProps = {}

    // Info and Tooltips
    if (info) {
        selectProps.endAdornment =
            <InputAdornment position="end">
                <Tooltip title={info} placement='right'>
                    <IconButton edge="end" size={size} sx={{pl: 0}}>
                        <HelpOutlineIcon/>
                    </IconButton>
                </Tooltip>
            </InputAdornment>
    }

    // If error display in Icon
    if (errorInIcon && error) {
        selectProps.endAdornment =
            <InputAdornment position="end">
                <Tooltip title={helperText} placement='right'>
                    <IconButton edge="end" sx={{pl: 0}} size={size}>
                        <ErrorIcon sx={{color: 'red'}}/>
                    </IconButton>
                </Tooltip>
            </InputAdornment>
    }

    if ((variant === 'standard' && noUnderline)) {
        selectProps.disableUnderline = true;
    }

    let displayValues = values;

    if (limit) {
        const index = values.findIndex(selected => selected.value === value);
        if (index) {
            const current = {...displayValues[index]}
            displayValues.splice(index, 1);
            displayValues.unshift(current);
        }
        displayValues = values.slice(0, (limit ? maxDisplayMulti * 20 : values.length - 1))
    }

    // Stops the value being set as undefined when increasing limit
    const handleSelectionChange = (event: SelectChangeEvent<any>) => {
        if (event.target.value === undefined) {
            event.stopPropagation();
            return
        } else {
            handleFormValueChange(id, InputTypes.SELECTION, handleChange)(event)
        }
    }

    return (
        <GridStyle
            item
            {...layout}
        >
            <FormControl sx={{width: '100%'}} size={size} variant={variant}>
                {!noLabel &&
                    <InputLabel error={error} htmlFor={label}>{label}</InputLabel>
                }
                <Select
                    sx={{
                        zIndex: 1000,
                        ...selectionInfoStyle,
                        ...(noLabel ? {
                            '.MuiSelect-standard': {
                                paddingBottom: 0,
                                px: 0,
                            },
                        } : {})
                    }}
                    onBlur={handleBlur}
                    label={label}
                    error={error}
                    value={value}
                    {...selectProps}
                    onChange={(event) => handleSelectionChange(event)}
                    disabled={disabled}
                    defaultValue=''
                >
                    {displayNone &&
                        <MenuItem value={''} sx={{zIndex: 3000}}>
                            <em>None</em>
                        </MenuItem>
                    }
                    {values && values.length > 0 && displayValues.map(m => {
                        return (
                            <MenuItem key={m.value} value={m.value} sx={{zIndex: 3000}}>
                                {m.label}
                            </MenuItem>
                        )
                    })}
                    {limit && ((maxDisplayMulti * 20) < values.length) &&
                        <MenuItem onClick={() => setMulti(maxDisplayMulti + 1)} sx={{zIndex: 3000}}>
                            ...Show more...
                        </MenuItem>
                    }

                </Select>
                {
                    !errorInIcon && helperText != null &&
                    <FormHelperText error={error}>{helperText}</FormHelperText>
                }
            </FormControl>
        </GridStyle>
    )
}