import {RootState} from "../../store";
import {createSelector} from "@reduxjs/toolkit";
import {checkFundAlternative, groupDebtSeniority} from "../../../utils/CapitalBudgetUtils";
import {Loan} from "../../../types/externalDataTypes";
import {addValues, convertToAUD} from "../../../utils/mathUtil";
import {fundSelector, ratesSelector} from "./generalSelectors";
import {CalculationLoanType} from "../../../types/capitalBudgetTypes";
import {checkDateBefore,} from "../../../utils/DateUtils";
import {AmendmentType, SaveStatus} from "../../../types/capitalBudgetEnums";
import {spIndustryLookupFromSubIndustry} from "../../../utils/spIndustryUtils";

const summedFields = [
    'accrued_fees',
    'accrued_interest',
    'commitment',
    'drawn',
    'total_accruals',
    'undrawn'
]

// Selector to get the portfolio array
const portfolioSelector = (state: RootState) => state.capitalBudget.externalData?.portfolio?.portfolio || [];

type RawPortfolio = Loan & {
    commitmentAUD: number,
    drawnAUD: number,
    undrawnAUD: number,
    adjustedMaturity: Date | number,
    amendment: AmendmentType | null
};

// Group Portfolio by Tranche
export const groupPortfolioByTranche = (portfolio: Array<Loan>): Array<Loan> => {
    return [...portfolio.reduce((r: any, o: any) => {
        const key = o.tranche_id;

        let itemObject: any = {};

        summedFields.forEach((field: string) => {
            itemObject[field] = 0;
        })

        const item = r.get(key) || Object.assign({}, o, itemObject);

        summedFields.forEach(field => {
            item[field] = addValues(item[field], o[field]);
        })

        return r.set(key, item);
    }, new Map()).values()] as Array<Loan>
}

// Retrieve Grouped Portfolio
export const groupedPortfolioSelector = createSelector(
    portfolioSelector,
    (portfolio) => {
        return groupPortfolioByTranche(portfolio);

    }
)

// Retrieves Portfolio if fund is selected
export const fundGpPortfolioSelector = createSelector(
    portfolioSelector,
    fundSelector,
    (portfolio, fund) => {
        if (!fund) {
            return groupPortfolioByTranche(portfolio);
        } else {
            return portfolio.filter(p => (p.fund === fund.label || checkFundAlternative(fund, p.fund))) as Array<Loan>
        }
    }
)

const retrieveRawMaturityChanges = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.maturityChanges || [],
    maturityChanges => maturityChanges.filter(mc => mc.status !== SaveStatus.REMOVED)
)

// Retrieves all Grouped Portfolio filtered by fund if necessary
export const rawPortfolioGpSelector = createSelector(
    fundGpPortfolioSelector,
    retrieveRawMaturityChanges,
    ratesSelector,
    (portfolio, maturityChanges, rates) => {
        return portfolio.reduce((loans: Array<RawPortfolio>, p: Loan) => {
            let commitmentAUD = p.commitment;
            let undrawnAUD = p.undrawn;
            let drawnAUD = p.drawn;
            if (p.base_currency !== 'AUD') {
                commitmentAUD = convertToAUD(rates, p.commitment, p.base_currency) || p.commitment;
                undrawnAUD = convertToAUD(rates, p.undrawn, p.base_currency) || p.undrawn;
                drawnAUD = convertToAUD(rates, p.drawn, p.base_currency) || p.drawn;
            }

            const maturityChange = maturityChanges.find(mc => mc.trancheId === p.tranche_id)

            const adjustedMaturity = maturityChange?.amendedMaturity || p.maturity;

            loans.push({
                ...p,
                adjustedMaturity,
                amendment: maturityChange?.amendment || null,
                commitmentAUD,
                undrawnAUD,
                drawnAUD
            })

            return loans;
        }, [])
    }
)

// RetrievePortfolio with any amendments (maturity, selldowns, transfers)
export const forecastedPortfolioMap = createSelector(
    portfolioSelector,
    (state: RootState) => state.capitalBudget.forecastData?.maturityChanges || [],
    (state: RootState) => state.capitalBudget.forecastData?.selldownRepayments || [],
    (state: RootState) => state.capitalBudget.forecastData?.transfers || [],
    (portfolio, maturityChanges, sellRepay, transfers) => {
        const portfolioMap = portfolio.reduce((pMap, p) => {
            // return pMap.set(`${p.tranche_id}${p.fund}`, {...p, maturityChanges: [], sellRepay: [], transfers: []});
            const tranche = pMap.get(p.tranche_id);

            if (tranche) {
                const fund = tranche.get(p.fund);
                if (!fund) {
                    tranche.set(p.fund, {...p, maturityChange: null, sellRepay: [], transfersIn: [], transfersOut: []})
                }
            } else {
                const fundMap = new Map();
                fundMap.set(p.fund, {...p, maturityChange: null, sellRepay: [], transfersIn: [], transfersOut: []});

                pMap.set(p.tranche_id, fundMap);
            }

            return pMap;
        }, new Map())

        maturityChanges.forEach(mc => {
            if (mc.status !== SaveStatus.REMOVED) {
                let tranche = portfolioMap.get(mc.trancheId);
                if (tranche) {
                    for (let key of tranche.keys()) {
                        const fund = tranche.get(key);
                        tranche.set(key, {...fund, maturityChange: mc});
                    }
                }
            }
        })

        sellRepay.forEach(sr => {
            if (sr.status !== SaveStatus.REMOVED) {
                let fund = portfolioMap.get(sr.trancheId)?.get(sr.fund);
                if (fund) {
                    fund.sellRepay = [...fund.sellRepay, sr]
                }
            }
        })

        transfers.forEach(t => {
            if (t.status !== SaveStatus.REMOVED) {
                if (portfolioMap.get(t.trancheId)) {

                    let fundOut = portfolioMap.get(t.trancheId)?.get(t.fromFund);
                    let fundIn = portfolioMap.get(t.trancheId)?.get(t.toFund);
                    if (fundIn) {
                        fundIn.transfersIn = [...fundIn.transfersIn, t]
                    } else {
                        portfolioMap.get(t.trancheId).set(t.toFund, {
                            ...fundOut,
                            commitment: t.amount,
                            newTransferDate: t.transferDate,
                            fund: t.toFund
                        })
                        portfolioMap.get(t.trancheId).get(t.toFund).transfersIn = [t]
                    }
                    if (fundOut) {
                        fundOut.transfersOut = [...fundOut.transfersOut, t]
                    }
                }
            }
        })

        return portfolioMap;
    }
)

// Retrieves Portfolio for converting all foreign currencies to AUD
export const retrieveAUDPortfolio = createSelector(
    portfolioSelector,
    ratesSelector,
    (portfolio, rates) => {
        return portfolio.reduce((loans: Array<Loan>, p: Loan) => {
            let commitment = p.commitment;
            let drawn = p.drawn;
            let undrawn = p.undrawn;

            if (p.base_currency !== 'AUD') {
                commitment = convertToAUD(rates, p.commitment, p.base_currency) || p.commitment;
                drawn = convertToAUD(rates, p.drawn, p.base_currency) || p.drawn;
                undrawn = convertToAUD(rates, p.undrawn, p.base_currency) || p.undrawn;
            }

            loans.push({
                ...p,
                commitment,
                drawn,
                undrawn
            })

            return loans;
        }, [])
    }
)

// Convert Tranche Grouped by Portfolio to AUD
export const retrieveGpAUDPortfolio = createSelector(
    groupedPortfolioSelector,
    ratesSelector,
    (portfolio, rates) => {
        return portfolio.reduce((loans: Array<Loan & { commitmentAUD: number, undrawnAUD: number, drawnAUD: number }>, p: Loan) => {
            let commitmentAUD = p.commitment;
            let undrawnAUD = p.drawn;
            let drawnAUD = p.undrawn;

            if (p.base_currency !== 'AUD') {
                commitmentAUD = convertToAUD(rates, p.commitment, p.base_currency) || p.commitment;
                undrawnAUD = convertToAUD(rates, p.undrawn, p.base_currency) || p.undrawn;
                drawnAUD = convertToAUD(rates, p.drawn, p.base_currency) || p.drawn;
            }

            loans.push({
                ...p,
                commitmentAUD,
                undrawnAUD,
                drawnAUD
            })

            return loans;
        }, [])
    }
)

// Retrieve All Portfolio loans for calculation
export const retrievePortfolioLoansForCalc = createSelector(
    portfolioSelector,
    (state: RootState) => state.capitalBudget.forecastData?.maturityChanges || [],
    (state: RootState) => state.capitalBudget.forecastData?.selldownRepayments || [],
    (state: RootState) => state.capitalBudget.forecastData?.transfers || [],
    ratesSelector,
    (_state: RootState, base?: boolean) => (base),
    (portfolio, maturityChanges, selldownRepayments, transfers, rates, base) => {
        try {
            const portfolioMap = portfolio.reduce((pMap, p) => {
                const industries = spIndustryLookupFromSubIndustry(p.sp_sub_industry);

                let loan: CalculationLoanType = {
                    id: p.id,

                    loanType: 'AXCESS',

                    trancheId: p.tranche_id,
                    client: p.client_id,
                    asset: p.asset_id,
                    assetName: p.asset,
                    name: p.borrower,
                    tranche: p.tranche,
                    fund: p.fund,

                    value: p.commitment,
                    updatedValue: p.commitment,
                    drawn: p.drawn,
                    updatedDrawn: p.drawn,
                    undrawn: p.undrawn,
                    updatedUndrawn: p.undrawn,
                    drawnPercentage: p.drawn_p,
                    updatedDrawnPercentage: p.drawn_p,
                    tenor: p.remaining_tenor_yrs,
                    updatedTenor: p.remaining_tenor_yrs,

                    startDate: p.start_date,
                    endDate: p.maturity,
                    amendedMaturity: null,
                    maturityAmendmentType: null,
                    transferInDate: null,

                    ctcDrawdown: 0,

                    margin: p.drawn_margin,
                    facilityFee: p.facility_fee_type,
                    facilityFeeRate: p.facility_fee_rate_p,
                    baseRate: p.base_rate,
                    baseRateFloor: p.base_rate_floor,

                    baseCurrency: p.base_currency,
                    domicile: (p.state === 'Offshore') ? 'Offshore' : 'Australia',

                    rating: p.rating_mcp,
                    ranking: groupDebtSeniority(p.seniority_rank),

                    // sector: p.sp_sector,
                    // industry: p.sp_industry,
                    // industryGroup: p.sp_industry_group,
                    sector: industries.sector || p.sp_sector,
                    industryGroup: industries.industryGroup || p.sp_industry_group,
                    industry: industries.industry || p.sp_industry,

                    interestType: p.pricing_type,
                    investmentType: p.investment_type,
                    industrySegment: p.sp_sub_industry,
                    trancheType: p.tranche_type,
                    pricingType: p.pricing_type,

                    selldowns: [],
                    transfersIn: [],
                    transfersOut: [],

                    tags: []
                }

                const tranche = pMap.get(loan.trancheId);

                if (tranche) {
                    const fund = tranche.get(loan.fund);
                    if (!fund) {
                        tranche.set(loan.fund, loan)
                    }
                } else {
                    const fundMap = new Map();
                    fundMap.set(loan.fund, loan);

                    pMap.set(loan.trancheId, fundMap);
                }

                return pMap;
            }, new Map())

            // Find and attach Maturity change to loan
            maturityChanges.forEach(mc => {
                if (base) {
                    if (mc.status === SaveStatus.NEW) return;
                    if ((mc.status === SaveStatus.EDITED && mc.previous) || (mc.status === SaveStatus.REMOVED && mc.previous)) mc = mc.previous;
                } else {
                    if (mc.status === SaveStatus.REMOVED) return;
                }
                let tranche = portfolioMap.get(mc.trancheId);
                if (tranche) {
                    for (let key of tranche.keys()) {
                        const fund = tranche.get(key);
                        tranche.set(key, {
                            ...fund,
                            amendedMaturity: mc.amendedMaturity,
                            maturityAmendmentType: mc.amendment
                        });
                    }
                }

            })

            selldownRepayments.forEach(sr => {
                if (base) {
                    if (sr.status === SaveStatus.NEW) return;
                    if ((sr.status === SaveStatus.EDITED && sr.previous) || (sr.status === SaveStatus.REMOVED && sr.previous)) sr = sr.previous;
                } else {
                    if (sr.status === SaveStatus.REMOVED) return;
                }
                let fund = portfolioMap.get(sr.trancheId).get(sr.fund);
                if (fund) {
                    fund.selldowns = [...fund.selldowns, sr]
                }
            })

            transfers.forEach(t => {
                if (base) {
                    if (t.status === SaveStatus.NEW) return;
                    if ((t.status === SaveStatus.EDITED && t.previous) || (t.status === SaveStatus.REMOVED && t.previous)) t = t.previous;
                } else {
                    if (t.status === SaveStatus.REMOVED) return;
                }

                if (portfolioMap.get(t.trancheId)) {

                    let fundOut = portfolioMap.get(t.trancheId)?.get(t.fromFund) || null;
                    let fundIn = portfolioMap.get(t.trancheId)?.get(t.toFund) || null;

                    if (fundIn) {
                        if (checkDateBefore(t.transferDate, fundIn.transferInDate)) {
                            fundIn.transfersInDate = t.transferDate;
                        }
                        fundIn.transfersIn = [...fundIn.transfersIn, t]
                    } else {
                        portfolioMap.get(t.trancheId).set(t.toFund, {
                            ...fundOut,
                            value: 0,
                            updatedValue: fundOut.value,
                            drawn: 0,
                            updatedDrawn: fundOut.updatedDrawn,
                            undrawn: 0,
                            updatedUndrawn: fundOut.updatedUndrawn,
                            transferInDate: t.transferDate,
                            fund: t.toFund
                        })
                        portfolioMap.get(t.trancheId).get(t.toFund).transfersIn = [t]
                    }
                    if (fundOut) {
                        fundOut.transfersOut = [...fundOut.transfersOut, t]
                    }

                }

            })

            let loans: Array<CalculationLoanType> = [];

            portfolioMap.forEach((tranche: Map<string, CalculationLoanType>) => {
                tranche.forEach((loan: CalculationLoanType) => {

                    if (loan.baseCurrency !== 'AUD') {
                        loan.value = convertToAUD(rates, loan.value, loan.baseCurrency) || loan.value;
                        loan.updatedValue = convertToAUD(rates, loan.updatedValue, loan.baseCurrency) || loan.value;
                        loan.drawn = convertToAUD(rates, loan.drawn, loan.baseCurrency) || loan.drawn;
                        loan.updatedDrawn = convertToAUD(rates, loan.updatedDrawn, loan.baseCurrency) || loan.updatedDrawn;
                        loan.undrawn = convertToAUD(rates, loan.undrawn, loan.baseCurrency) || loan.undrawn;
                        loan.updatedUndrawn = convertToAUD(rates, loan.updatedUndrawn, loan.baseCurrency) || loan.updatedUndrawn;

                        loan.selldowns?.forEach(sr => {
                            sr.amount = convertToAUD(rates, sr.amount, loan.baseCurrency) || sr.amount;
                        })

                        loan.transfersOut?.forEach(sr => {
                            sr.amount = convertToAUD(rates, sr.amount, loan.baseCurrency) || sr.amount;
                        })
                    }

                    loans.push(loan);
                })
            })

            return loans;
        } catch (e) {
            console.error(e);
            return [];
        }
    }
)