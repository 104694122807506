import {useState} from "react";
import {Formik, FormikProps} from "formik";
import * as Yup from "yup";
import {InputTypes} from "../../../types/InputTypes";
import {removeHtmlTags} from "../../../utils/generalUtils";
import {fCurrency} from "../../../utils/formatNumber";
import {addValues} from "../../../utils/mathUtil";
import {Loan} from "../../../types/externalDataTypes";
// Components
import {FormInput} from "../../../components";
// @MUI
import {Button, Card, CardContent, Grid, Typography} from "@mui/material";
// Store
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    retrieveLoansBorrowersWithRefinance,
} from "../../../store/capitalBudget/selectors/refinanceSelector";
import {addRefinance, updateRefinances} from "../../../store/capitalBudget/capitalBudgetSlice";

type RefinanceFormProps = {
    editValues: any | null,
    onClose: () => void
}

export default function RefinanceForm({editValues, onClose}: RefinanceFormProps) {
    const dispatch = useAppDispatch();

    // FIND ALL NEW LOANS
    const {newLoans, borrowers} = useAppSelector(state => retrieveLoansBorrowersWithRefinance(state))

    const refinanceValues = {
        newLoan: null,
        borrower: null,
        trancheIds: {},
        incremental: true,
        comments: ''
    }

    // IF refinance is an edit or based on suggestive refinance, find relevant new loan and borrower information
    const selectedNewLoan = (editValues?.id || editValues?.suggestive) ? (newLoans.find(l => l.ncino_id === editValues.ncinoId) || null ) : null;
    const selectedBorrower = (editValues?.id  || editValues?.suggestive) ? (borrowers.find(b => b.clientId === editValues.clientId) || null) : null;

    const [previousNewLoanId, setNewLoanId] = useState<null | string>(editValues?.id ? editValues.ncinoId : null);

    return (
        <>
            <Formik
                initialValues={(editValues?.id && selectedNewLoan && selectedBorrower) ? {
                    newLoan: selectedNewLoan,
                    borrower: selectedBorrower,
                    // For editing refinance - create trancheId object and find those tranches active in refinance.
                    trancheIds: selectedBorrower.loans.reduce((tranches: {[x: number]: boolean}, l: Loan) => {
                        tranches[l.tranche_id] = (editValues.tranches.findIndex((t: {trancheId: number}) => t.trancheId === l.tranche_id) !== -1) || false;
                        return tranches;
                    }, {}),
                    incremental: editValues.incremental,
                    comments: editValues.comments
                } : (editValues?.suggestive && selectedNewLoan && selectedBorrower) ?
                    {
                        newLoan: selectedNewLoan,
                        borrower: selectedBorrower,
                        // for suggestive refinance - find if available a modification tranche id to map to refinance.
                        trancheIds: selectedBorrower.loans.reduce((tranches: {[x: number]: boolean}, l: Loan) => {
                            tranches[l.tranche_id] = (Number(editValues.modificationId) === l.tranche_id);
                            return tranches;
                        }, {}),
                        incremental: true,
                        comments: ''
                    }
                    : refinanceValues}

                validationSchema={
                    Yup.object().shape({
                        newLoan: Yup.object().required('New Loan is required.').test(
                            'check-existing-for-new',
                            'Loan already has an existing refinance',
                            (value: any) => {
                                return (editValues?.id || !value.refinance);
                            }
                        ),
                        borrower: Yup.object().required('Borrower to be refinances is required.'),
                        trancheIds: Yup.object().required().test(
                            'at-least-one-true',
                            'At least one tranche must be selected.',
                            (value) => value && Object.values(value).some(v => v === true)
                        ),
                    })
                }

                onSubmit={(values: {newLoan: any, borrower: any, trancheIds: {[x: string]: boolean}, incremental: boolean, comments: string}) => {
                    const refinance = {
                        ncinoId: values.newLoan.ncino_id,
                        clientId: values.borrower.clientId,
                        trancheIds: Object.keys(values.trancheIds).filter(key => values.trancheIds[key]).map(trancheId => Number(trancheId)),
                        incremental: values.incremental,
                        comments: values.comments
                    }
                    if (editValues.id) {
                        dispatch(updateRefinances({
                            id: editValues.id,
                            ...refinance
                        }))
                    } else {
                        dispatch(addRefinance(refinance));
                    }
                    onClose();
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        setFieldValue,
                        submitCount,
                        values,
                        errors,
                        touched
                    } = props;

                    try {
                        // if New Loan has been selected
                        if (values.newLoan.ncinoId !== previousNewLoanId) {
                            if ((!!values.newLoan.account?.client_id && !values.borrower) || (values.newLoan.account?.client_id && values.newLoan.account?.client_id !== values.borrower?.clientId)) {
                                setFieldValue('borrower', borrowers.find(b => b.clientId === values.newLoan.account?.client_id))
                            }
                            if (values.comments === '' && values.newLoan.narrative_comments) {
                                setFieldValue('comments', removeHtmlTags(values.newLoan.narrative_comments))
                            }
                            setNewLoanId(values.newLoan.ncinoId);
                        }
                        // if borrower as been selected:
                        if (values.borrower) {
                            if (values.trancheIds.length === 0 || !values.trancheIds.hasOwnProperty([values.borrower.loans[0].tranche_id])) {
                                const trancheObject = values.borrower.loans.reduce((obj: any, l: Loan) => {
                                    obj[l.tranche_id] = false;

                                    return obj;
                                }, {})

                                setFieldValue('trancheIds', trancheObject)

                            }
                        }
                        // If new Loan reset clear fields
                        if (!values.newLoan && values.borrower) {
                            setFieldValue('borrower', null)
                            setFieldValue('trancheIds', {})
                            setFieldValue('comment', '')
                        }
                    } catch (e) {
                        console.log(e)
                        // resetForm();
                    }

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    <FormInput
                                        id='newLoan'
                                        label='New Loan'
                                        fieldType={InputTypes.SEARCH}
                                        values={newLoans}
                                        labelFunc={(option) => `${option.name}`}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    {values.newLoan &&
                                        <>
                                            <Grid container item xs={12}>
                                                <Grid container item xs={12} sx={{px: 2}} direction='row'
                                                      alignItems='center'>
                                                    <Typography variant='subtitle1' sx={{color: 'primary.main'}}>
                                                        <b>Loan Amount:</b>
                                                    </Typography>
                                                    <Typography variant='body1' sx={{
                                                        pl: 5,
                                                        fontSize: 16
                                                    }}>
                                                        {fCurrency(values.newLoan.commitment)}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </>

                                    }
                                    <FormInput
                                        id='borrower'
                                        label='Borrower'
                                        fieldType={InputTypes.SEARCH}
                                        values={borrowers}
                                        labelFunc={(option) => `${option.name}`}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    {values.borrower &&
                                        <>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} sx={{px: 2, py: 1}}>
                                                    <Typography variant='h6'
                                                                sx={{color: 'primary.main'}}><b>Borrower:</b></Typography>
                                                </Grid>
                                                <Grid container item xs={12} sx={{px: 2}} direction='row'
                                                      alignItems='center'>
                                                    <Typography variant='subtitle1' sx={{color: 'primary.main'}}>
                                                        <b>Total Commitment:</b>
                                                    </Typography>
                                                    <Typography variant='body1' sx={{
                                                        pl: 5,
                                                        fontSize: 16
                                                    }}>
                                                        {fCurrency(values.borrower.loans.reduce((commitment: number, l: Loan) => addValues(commitment, l.commitment), 0))}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </>

                                    }
                                    {values.borrower &&
                                        <>
                                            <Grid item xs={12} sx={{px: 2, py: 1}}>
                                                <Typography variant='h6'
                                                            sx={{color: 'primary.main'}}><b>Tranches:</b></Typography>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                {values.borrower.loans.map((tranche: Loan) => (
                                                    <Card
                                                        key={tranche.tranche_id}
                                                        sx={{
                                                            width: '100%',
                                                            p: 0,
                                                            m: 1,
                                                            border: 1,
                                                            borderColor: 'primary.main'
                                                        }} elevation={2}
                                                    >
                                                        <CardContent sx={{padding: 0, paddingBottom: 0, m: 0}}>
                                                            <Grid item xs={12} padding={0}
                                                                  margin={0}>
                                                                <FormInput
                                                                    id={`trancheIds.${tranche.tranche_id}`}
                                                                    label={`${tranche.tranche_id.toString()} - ${tranche.tranche}`}
                                                                    fieldType={InputTypes.CHECKBOX}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} padding={0}
                                                                  margin={0}>
                                                                <Grid container item sx={{px: 5}} direction='row'
                                                                      alignItems='center'>
                                                                    <Typography
                                                                        variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}
                                                                    >
                                                                        <b>Commitment:</b>
                                                                    </Typography>
                                                                    <Typography
                                                                        variant='body1'
                                                                        sx={{
                                                                            pl: 5,
                                                                            fontSize: 16
                                                                        }}
                                                                    >
                                                                        {fCurrency(tranche.commitment)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid container item sx={{px: 5}} direction='row'
                                                                      alignItems='center'>
                                                                    <Typography
                                                                        variant='subtitle1'
                                                                        sx={{color: 'primary.main'}}
                                                                    >
                                                                        <b>Maturity:</b>
                                                                    </Typography>
                                                                    <Typography
                                                                        variant='body1'
                                                                        sx={{
                                                                            pl: 5,
                                                                            fontSize: 16
                                                                        }}
                                                                    >
                                                                        {fCurrency(tranche.commitment)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                ))}
                                            </Grid>
                                            {errors.trancheIds && touched.trancheIds && submitCount > 0 &&
                                                <>
                                                    <Grid container item sx={{p: 1, pt: 0}} direction='row'
                                                          alignItems='center'>
                                                        <Typography
                                                            variant='caption'
                                                            sx={{color: 'red'}}
                                                        >
                                                            <>{errors.trancheIds}</>
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                        </>
                                    }
                                    <FormInput
                                        id='incremental'
                                        label='Refinance Type'
                                        fieldType={InputTypes.SELECTION}
                                        values={[
                                            {id: 'true', label: 'Incremental', value: true},
                                            {id: 'false', label: 'Updated', value: false}
                                        ]}
                                        displayNone={false}
                                    />
                                    <FormInput
                                        id='comments'
                                        label='Comments'
                                        fieldType={InputTypes.TEXT_BOX}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                {editValues?.id ?
                                                    'Update Refinance' : 'Add Refinance'
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    );
}