import {memo, useState} from "react";
import {Box, MenuItem, Select} from "@mui/material";
import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";
import {FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";
import {valueCurrency} from "../../../../components";
import {LoanTags} from "../../../../types/capitalBudgetEnums";
import LoansDrilldownTable from "./LoansDrilldownTable";
import DrillDownSimple from "../../../../components/DrillDownsSimple";

interface AudDrillDownProps {
    period: ForecastPeriod,
    column: investmentReportColumn,
    avaCapToggle: boolean,
    fund: FundDetails | null
}

// DRILL DOWN FOR CURRENCY LOANS
function DomicileDrillDown({period, column, avaCapToggle, fund}: AudDrillDownProps) {

    const [domicile, setDomicile] = useState<boolean>(true)

    return (
        <>
            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', minHeight: 800}}>
                <Box>
                    <DrillDownSimple
                        data={[
                            {
                                label: 'Australian Loans',
                                value: column.domicile * ((avaCapToggle) ? column.analyAUM : column.totalCommitment),
                            },
                            {
                                label: 'Offshore',
                                value: (1 - column.domicile) * ((avaCapToggle) ? column.analyAUM : column.totalCommitment),
                            },
                            {
                                label: `Total`,
                                value: (avaCapToggle) ? column.analyAUM : column.totalCommitment,
                                textColor: 'white',
                                sx: {bgcolor: 'primary.light'}
                            }
                        ]}
                    />
                </Box>
                <Box sx={{py: 1, flex: 1, minHeight: 200}}>
                    <Box sx={{height: '99%'}}>
                        <LoansDrilldownTable
                            title={`Loans`}
                            period={period}
                            fund={fund}
                            additionalColumns={[
                                {
                                    field: 'domicile',
                                    headerName: 'Domicile',
                                },
                                {
                                    field: 'value',
                                    headerName: 'Commitment',
                                    ...valueCurrency,
                                    width: 120
                                }
                            ]}
                            filterFunction={(loan: CalculationLoanType) => ((loan.tags.includes(LoanTags.ACTIVE) && (domicile ? (loan.domicile === 'Australia') : (loan.domicile !== 'Australia'))))}
                            customFilterComponent={<CurrencyFilter domicile={domicile} setDomicile={setDomicile}/>}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

function CurrencyFilter({domicile, setDomicile}: { domicile: boolean, setDomicile: (aud: boolean) => void }) {
    console.log(domicile)
    return (
        <>
            <Box sx={{px: 1}}>
                Location:
                <Select
                    value={domicile ? 'aud' : 'other'}
                    onChange={(e) => setDomicile(e.target.value === 'aud')}
                    sx={{minWidth: 100, color: 'common.white'}}
                    label={'Seniority'}
                    size={"small"}
                >
                    <MenuItem value={'aud'}>Australian</MenuItem>
                    <MenuItem value={'other'}>Offshore</MenuItem>
                </Select>
            </Box>
        </>
    )
}

export default memo(DomicileDrillDown);