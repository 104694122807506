import {useState} from 'react';
// MUI
import {Grid, Container, IconButton} from '@mui/material';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
} from "@mui/icons-material";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
// Components
import {Page} from "../../../layouts/components";
import ForecastConfigBar from "../ForecastConfigBar";
import {actionRow, AlertDialog, DataTable, SideDrawer, WarningsIcon} from "../../../components";
// Store
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    retrieveAllCapitalTransactions
} from "../../../store/capitalBudget/selectors/capitalSelectors";
import CapitalTransactionForm from "../../../sections/Budget/Configurations/CapitalTransactionForm";
import {
    addCapitalTransaction,
    removeCapitalTransaction,
    updateCapitalTransaction
} from "../../../store/capitalBudget/capitalBudgetSlice";
import {warningsGeneralRow, warningValueCurrency, warningValueDate} from "./DataTableWarningsUtilts";
import {warningCellClassName, warningDataGridSx} from "./DataTableWarningsUtilts";


// Data

// ----------------------------------------------------------------------
let columns: GridColDef[] = [
    {
        field: 'advise',
        headerName: '',
        align: 'center',
        ...warningsGeneralRow,
        width: 50,
        renderCell: (params: GridCellParams) => (
            <WarningsIcon value={params.value} />
        ),
    },
    {
        field: 'name',
        headerName: 'Name',
        ...warningsGeneralRow,
        minWidth: 200,
        flex: 1
    },
    {
        field: 'investorType',
        headerName: 'Investor Type',
        ...warningsGeneralRow,
        minWidth: 150,
        flex: 0.25
    },
    {
        field: 'transactionType',
        headerName: 'Transaction Type',
        ...warningsGeneralRow,
        minWidth: 150,
        flex: 0.25
    },
    {
        field: 'date',
        headerName: 'Date',
        ...warningValueDate,
    },
    {
        field: 'fund',
        headerName: 'Fund',
        ...warningsGeneralRow,
        minWidth: 125,
        flex: 0.25
    },
    {
        field: 'amount',
        headerName: 'Amount',
        ...warningValueCurrency,
        minWidth: 150,
    },
    {
        field: 'category',
        headerName: 'Category',
        ...warningsGeneralRow,
        minWidth: 150,
        flex: 0.25
    },
]

// Capital Budget Summary App Page
export default function CapitalChanges() {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.forecast.loading);
    const capitalTransaction = useAppSelector(state => retrieveAllCapitalTransactions(state));

    // Create and Update capital
    const [form, setFormOpen] = useState<any | null>(null);

    // Sends an editable version on capital Entity to update form
    const handleEditTransaction = (row: number) => {
        const transaction = capitalTransaction.find(ct => ct.id === row);
        if (transaction) {
            setFormOpen({
                ...transaction,
                name: {value: transaction.name, label: transaction.name}
            })
        }
    }

    // Remove Capital
    const [openDelete, setOpenDelete] = useState<number | null>(null)

    return (
        <>
            <Page title="Metrics - CB - Capital Changes">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Capital Movements' configuration/>
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                <DataTable
                                    title='Capital Transactions'
                                    data={capitalTransaction}
                                    loading={loading}
                                    sort={{field: 'date', sort: 'asc'}}
                                    columns={[
                                        ...columns,
                                        {
                                            ...actionRow,
                                            renderCell: (params: GridCellParams) => (
                                                <>
                                                    <IconButton
                                                        sx={{color: 'primary.main'}}
                                                        style={{padding: '0px', outline: 'none'}}
                                                        onClick={() => handleEditTransaction(params.row.id)}
                                                        size="small">
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{color: 'primary.main'}}
                                                        style={{padding: '0px', outline: 'none'}}
                                                        onClick={() => setOpenDelete(params.row.id)}
                                                        size="small">
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </>
                                            ),
                                            cellClassName: warningCellClassName
                                        }
                                    ]}
                                    search
                                    create={() => setFormOpen(true)}
                                    showExport={false}
                                    additionalDataGridSx={warningDataGridSx}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
            <SideDrawer
                title='Create Capital'
                open={(!!form)}
                onClose={() => setFormOpen(null)}
                size={'large'}
            >
                <CapitalTransactionForm
                    editValues={form}
                    onClose={() => setFormOpen(null)}
                    submitTransaction={(values) => {
                        dispatch(addCapitalTransaction(values));
                        setFormOpen(null);
                    }}
                    editTransaction={(values) => {
                        dispatch(updateCapitalTransaction(values));
                        setFormOpen(null);
                    }}
                />
            </SideDrawer>
            <AlertDialog
                title='Delete Capital Movement'
                open={!!openDelete}
                handleClose={() => setOpenDelete(null)}
                handleConfirm={() => {
                    dispatch(removeCapitalTransaction(openDelete))
                    setOpenDelete(null)
                }}
            />
        </>
    )
}