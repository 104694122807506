// import {useState} from 'react';
import {HorizontalTable} from "../../components";
// MUI
import {Grid, Container} from '@mui/material';
// Component
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
// Store
import {useAppSelector} from "../../store/store";
import {capitalTableSelector} from "../../store/capitalBudget/selectors/capitalSelectors";

// Capital Budget Summary App Page
export default function CapitalForecast() {
    const capitalForecast = useAppSelector(state => capitalTableSelector(state))

    return (
        <Page title="Metrics - CB - Capital">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                <Grid container direction='column' sx={{height: 1, flex: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <ForecastConfigBar title='Capital'/>
                    </Grid>
                    <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                        <HorizontalTable
                            rows={capitalForecast.rows}
                            data={capitalForecast.columns}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}