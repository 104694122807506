import * as Yup from "yup";
import {Formik, FormikProps} from "formik";
import {CapitalAction, CapitalCategory, CapitalTypes} from "../../../types/capitalBudgetEnums";
import {MCPFund} from "../../../types/forecastTypes";
import {formatDate} from "../../../utils/DateUtils";
import {InputTypes} from "../../../types/InputTypes";
import {FormInput} from "../../../components";
// MUI
import {Button, Divider, Grid} from "@mui/material";
// Store
import {useAppSelector} from "../../../store/store";
import {fundSelector, fundsStringSelector} from "../../../store/capitalBudget/selectors/generalSelectors";
import {retrieveAllCapitalEntities} from "../../../store/capitalBudget/selectors/capitalSelectors";

type CapitalTransactionFormProps = {
    editValues: any | null,
    onClose: () => void,
    submitTransaction: (values: any) => void,
    editTransaction: (values: any) => void
}

export default function CapitalTransactionForm({
                                                   editValues,
                                                   onClose,
                                                   submitTransaction,
                                                   editTransaction
                                               }: CapitalTransactionFormProps) {

    const fund = useAppSelector(state => fundSelector(state));
    const funds = useAppSelector(state => fundsStringSelector(state));
    const names = useAppSelector(state => retrieveAllCapitalEntities(state));


    const transactionValues = {
        name: '',
        investorType: CapitalTypes.INVESTOR,
        transactionType: '',
        date: '',
        fund: (fund) ? fund.label : '',
        amount: '',
        category: CapitalCategory.EXPECTED
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const TransactionSchema = Yup.object().shape({
        name: Yup.object().required('Name is required'),
        investorType: Yup.mixed<CapitalTypes>().oneOf(Object.values(CapitalTypes)).required('Investor Type is required'),
        transactionType: Yup.mixed<CapitalAction>().oneOf(Object.values(CapitalAction)).required('Transaction Type is required'),
        date: Yup.date().typeError('Valid date required.').required('Transaction date is required'),
        amount: Yup.number().required('Amount is required'),
        category: Yup.mixed<CapitalCategory>().oneOf(Object.values(CapitalCategory)).required('Category is required'),
        fund: Yup.mixed<MCPFund>().required('Fund is required'),
    });

    return (
        <>
            <Formik
                initialValues={editValues?.id ? editValues : transactionValues}
                validationSchema={TransactionSchema}
                onSubmit={(values: any) => {
                    if (editValues?.id) {
                        editTransaction({
                            ...values,
                            name: values.name.value,
                            date: formatDate(values.date, 'yyyy-MM-dd'),
                            amount: ([CapitalAction.CANCELLATION, CapitalAction.REDEMPTION].includes(values.transactionType)) ? -Math.abs(values.amount) : values.amount
                        })
                    } else {
                        submitTransaction({
                            ...values,
                            name: values.name.value,
                            date: formatDate(values.date, 'yyyy-MM-dd'),
                            amount: ([CapitalAction.CANCELLATION, CapitalAction.REDEMPTION].includes(values.transactionType)) ? -Math.abs(values.amount) : values.amount
                        })
                    }
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        values
                    } = props;

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    <FormInput
                                        id='name'
                                        label='Name'
                                        fieldType={InputTypes.SUGGESTION}
                                        values={names.map(name => {
                                            return {value: name, label: name}
                                        })}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                        disabled={!!(editValues && editValues.id)}
                                    />
                                    <FormInput
                                        id='investorType'
                                        label='Investor Type'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        values={[
                                            {label: 'Investor', value: CapitalTypes.INVESTOR},
                                            {label: 'Lender', value: CapitalTypes.LENDER},
                                        ]}
                                        disabled={!!(editValues && editValues.id)}
                                    />
                                    <FormInput
                                        id={`date`}
                                        label='Date'
                                        fieldType={InputTypes.DATE}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    {values.investorType === CapitalTypes.INVESTOR ?
                                        <FormInput
                                            id={`transactionType`}
                                            label='Transaction Type'
                                            fieldType={InputTypes.SELECTION}
                                            layout={{xs: 6, md: 6, lg: 6}}

                                            values={[
                                                {label: 'Subscription', value: CapitalAction.SUBSCRIPTION},
                                                {label: 'Redemption', value: CapitalAction.REDEMPTION}
                                            ]}
                                        />
                                        :
                                        <FormInput
                                            id={`transactionType`}
                                            label='Transaction Type'
                                            fieldType={InputTypes.SELECTION}
                                            layout={{xs: 6, md: 6, lg: 6}}

                                            values={[
                                                {label: 'Commitment', value: CapitalAction.COMMITMENT},
                                                {label: 'Cancellation', value: CapitalAction.CANCELLATION}
                                            ]}
                                        />

                                    }
                                    <FormInput
                                        id='fund'
                                        label='Fund'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        values={funds.map(f => {
                                            return {label: f, value: f}
                                        })}
                                    />
                                    <FormInput
                                        id='amount'
                                        label='Amount'
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        numFormatProps={{allowNegative: false, decimalScale: 2}}
                                    />
                                    <FormInput
                                        id='category'
                                        label='Category'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}

                                        values={[
                                            {label: 'Expected', value: CapitalCategory.EXPECTED},
                                            {label: 'Target', value: CapitalCategory.TARGET},
                                            {label: 'Potential', value: CapitalCategory.POTENTIAL},
                                        ]}
                                        info={'Values are expected or targeted.'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item container direction='row'>
                                    <Grid item sx={{width: '50%', p: 2}}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{width: '50%', p: 2}}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            {`${(editValues?.id) ? 'Update' : 'Create'} Transaction`}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}