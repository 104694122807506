import {InputTypes} from "../../../types/InputTypes";
import {fCurrency, fPercent} from "../../../utils/formatNumber";
import {formatDate} from "../../../utils/DateUtils";
// MUI
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
// Components
import {
    EditableTableColumn,
    valueCurrency,
    valueDate, WarningsIcon
} from "../../../components";
import clsx from "clsx";
import {warningsGeneralRow} from "./DataTableWarningsUtilts";
import {AssignmentTurnedIn as AssignmentTurnedInIcon} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import {CheckFlags} from "../../../types/GeneralTypes";

// Data
export const NewLoansColumns: (EditableTableColumn & {original?: boolean})[] = [
    {
        id: 'warning',
        label: '',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 22,
            width: 50,
        },
        sx: {
            position: 'sticky',
            left: 0,
            zIndex: 10,
            bgcolor: 'grey.200'
        },
        formatter: (value: any, row) => {
            if (row.alert) {
                return (
                    <WarningsIcon value={row.alert} />
                )
            } else if (row.advise) {
                return (
                    <WarningsIcon value={row.advise} />
                )
            } else {
                return ''
            }
        }
    },
    {
        id: 'name',
        label: 'Loan Name',
        align: 'left',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 20,
        },
        sx: {
            position: 'sticky',
            left: 40,
            zIndex: 8,
            bgcolor: 'grey.200'
        },
        formatter: (value: string, row) => {
            if (row.manual) return <span style={{color: 'red'}}>{value}</span>
            return value
        }
    },
    {
        id: 'dealName',
        label: 'Deal Name',
        align: 'left',
        headSX: {
            // bgcolor: 'white'
        },
        sx: {
        },
        formatter: (value: string, row) => {
            if (row.manual) return <span style={{color: 'red'}}>{value}</span>
            return value
        }
    },
    {
        id: 'llc_bi_lookupkey',
        label: 'Axcess Tranche',
        align: 'left',
        headSX: {
        },
        sx: {
            width: 120
        },
        formatter: (value: string, row) => {
            if (row.manual) return <span style={{color: 'red'}}>{value}</span>
            return value
        }
    },
    {
        id: 'llc_bi_product',
        label: 'Product',
        align: 'left',
        headSX: {

        },
        sx: {
            width: 120
        },
        formatter: (value: string, row) => {
            if (row.manual) return <span style={{color: 'red'}}>{value}</span>
            return value
        }
    },
    {
        id: 'owner',
        label: 'Owner',
        align: 'left',
        headSX: {
            // bgcolor: 'white'

        },
        sx: {
            width: 120
        },
        formatter: (value: string, row) => {
            if (row.manual) return <span style={{color: 'red'}}>{value}</span>
            return value
        }
    },
    {
        id: 'llc_bi_cra_type_code',
        label: 'Deal Type',
        align: 'left',
        headSX: {
            // bgcolor: 'white'

        },
        sx: {
            width: 120
        },
        formatter: (value: string, row) => {
            if (row.manual) return <span style={{color: 'red'}}>{value}</span>
            return value
        }
    },
    {
        id: 'previous.commitment',
        label: 'Orig. Commitment',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        sx: {
            minWidth: '110px',
            bgcolor: 'grey.200'
        },
        formatter: (value: number, row) => {
            const commitment = (row.previous?.commitment !== row.commitment) ? row.previous?.commitment : undefined;
            if (!commitment) return undefined;
            const formatted = fCurrency(commitment);
            if (row.manual) return <span style={{color: 'red', textDecoration: 'line-through'}}>{formatted}</span>
            return <span style={{textDecoration: 'line-through'}}>{formatted}</span>;
        },
        customSx: (row) => {
            if (row.advise && row.advise === CheckFlags.UPDATED_WIP && (row.previous?.commitment !== row.commitment)) {
                return {
                    bgcolor: 'warning.main',
                }
            }
            return {}
        },
        original: true
    },
    {
        id: 'llc_bi_amount',
        label: 'Commitment',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        sx: {
            minWidth: '110px'
        },
        formatter: (value: number, row) => {
            const formatted = (value === null) ? 'N/A' : fCurrency(value);
            if (row.manual) return <span style={{color: 'red'}}>{formatted}</span>
            return formatted;
        },
        customSx: (row) => {
            if (row.advise && row.advise === CheckFlags.UPDATED_WIP && (row.previous?.commitment !== row.commitment)) {
                return {
                    bgcolor: 'warning.main',
                }
            }
            return {}
        }
    },
    {
        id: 'amendedCommitment',
        label: 'Amended Commitment',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        edit: {
            fieldType: InputTypes.CURRENCY,
            numFormatProps: {
                allowNegative: true,
                decimalScale: 2,
                fixedDecimalScale: true
            },
        },
        sx: {
            minWidth: '110px'
        },
        formatter: (value: number, row) => {
            const formatted = (value === null) ? 'N/A' : fCurrency(value);
            if (row.manual) return <span style={{color: 'red'}}>{formatted}</span>
            return formatted;
        }
    },
    {
        id: 'previous.drawdown',
        label: 'Orig. Exp. Drawdown @ Close',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        sx: {
            minWidth: '110px',
            bgcolor: 'grey.200'
        },
        formatter: (value: number, row) => {
            const drawdown = (row.previous?.drawdown !== row.drawdown) ? row.previous?.drawdown : undefined;
            if (!drawdown) return undefined;
            const formatted = fCurrency(drawdown);
            if (row.manual) return <span style={{color: 'red', textDecoration: 'line-through'}}>{formatted}</span>
            return <span style={{textDecoration: 'line-through'}}>{formatted}</span>;
        },
        customSx: (row) => {
            if (row.advise && row.advise === CheckFlags.UPDATED_WIP && (row.previous?.drawdown !== row.drawdown)) {
                return {
                    bgcolor: 'warning.main',
                }
            }
            return {}
        },
        original: true
    },
    {
        id: 'expected_drawdown_at_close',
        label: 'Expected Drawdown @ Close',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        sx: {
            minWidth: '110px'
        },
        formatter: (value: number, row) => {
            const formatted = (value === null) ? 'N/A' : fCurrency(value);
            if (row.manual) return <span style={{color: 'red'}}>{formatted}</span>
            return formatted;
        },
        customSx: (row) => {
            if (row.advise && row.advise === CheckFlags.UPDATED_WIP && (row.previous?.drawdown !== row.drawdown)) {
                return {
                    bgcolor: 'warning.main',
                }
            }
            return {}
        }
    },
    {
        id: 'amendedDrawdown',
        label: 'Amended First Drawdown',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        edit: {
            fieldType: InputTypes.CURRENCY,
            numFormatProps: {
                allowNegative: true,
                decimalScale: 2,
                fixedDecimalScale: true
            },
        },
        sx: {
            minWidth: '110px'
        },
        formatter: (value: number, row) => {
            const formatted = (value === null) ? 'N/A' : fCurrency(value);
            if (row.manual) return <span style={{color: 'red'}}>{formatted}</span>
            return formatted;
        },
    },
    {
        id: 'previous.closeDate',
        label: 'Orig. Exp. Close',
        align: 'right',
        sx: {
            maxWidth: '120px',
            align: 'right',
            bgcolor: 'grey.200'
        },
        formatter: (value: string, row) => {
            const closeDate = (row.previous?.closeDate !== row.closeDate) ? row.previous?.closeDate : undefined;
            if (!closeDate) return undefined;
            const formatted = formatDate(closeDate, 'dd-MM-yyyy');
            if (row.manual) return <span style={{color: 'red', textDecoration: 'line-through'}}>{formatted}</span>
            return <span style={{textDecoration: 'line-through'}}>{formatted}</span>;
        },
        customSx: (row) => {
            if (row.advise && row.advise === CheckFlags.UPDATED_WIP && (row.previous?.closeDate !== row.closeDate)) {
                return {
                    bgcolor: 'warning.main',
                }
            }
            return {}
        },
        original: true
    },
    {
        id: 'llc_bi_close_date',
        label: 'Expected Close',
        align: 'right',
        sx: {
            maxWidth: '120px',
            align: 'right'
        },
        formatter: (value: string, row) => {
            const formatted = (value === null) ? 'N/A' : formatDate(new Date(value), 'dd-MM-yyyy');
            if (row.manual) return <span style={{color: 'red'}}>{formatted}</span>
            return formatted;
        },
        customSx: (row) => {
            if (row.advise && row.advise === CheckFlags.UPDATED_WIP && (row.previous?.closeDate !== row.closeDate)) {
                return {
                    bgcolor: 'warning.main',
                    // borderLeft: '1px solid '
                }
            }
            return {}
        }
    },
    {
        id: 'amendedCloseDate',
        label: 'Amended Close',
        align: 'right',
        sx: {
            maxWidth: '120px',
            align: 'right'
        },
        edit: {
            fieldType: InputTypes.DATE
        },
        formatter: (value: string, row) => {
            const formatted = (value === null) ? 'N/A' : formatDate(new Date(value), 'dd-MM-yyyy');
            if (row.manual) return <span style={{color: 'red'}}>{formatted}</span>
            return formatted;
        }
    },
    {
        id: 'adjustedClose',
        label: 'Adjusted Close',
        align: 'right',
        sx: {
            maxWidth: '120px',
            align: 'right'
        },
        edit: {
            fieldType: InputTypes.DATE
        },
        formatter: (value: string, row) => {
            const formatted = (value === null) ? 'N/A' : formatDate(new Date(value), 'dd-MM-yyyy');
            if (row.manual) return <span style={{color: 'red'}}>{formatted}</span>
            return formatted;
        }
    },
    {
        id: 'previous.tenor',
        label: 'Orig. Tenor',
        align: 'right',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0
        },
        sx: {
            position: 'sticky',
            left: 0,
            width: 50,
            bgcolor: 'grey.200',
        },
        formatter: (value: string, row) => {
            const tenor = (row.previous?.tenor !== row.tenor) ? row.previous?.tenor : undefined;
            if (!tenor) return undefined;
            const formatted = Number(tenor).toFixed(2);
            if (row.manual) return <span style={{color: 'red', textDecoration: 'line-through'}}>{formatted}</span>
            return <span style={{textDecoration: 'line-through'}}>{formatted}</span>;
        },
        customSx: (row) => {
            if (row.advise && row.advise === CheckFlags.UPDATED_WIP && (row.previous?.tenor !== row.tenor)) {
                return {
                    bgcolor: 'warning.main',
                }
            }
            return {}
        },
        original: true
    },
    {
        id: 'tenor',
        label: 'Tenor',
        align: 'right',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0
        },
        sx: {
            position: 'sticky',
            left: 0,
            width: 50
        },
        formatter: (value: string, row) => {
            if (row.manual) return <span style={{color: 'red'}}>{Number(value).toFixed(2)}</span>
            return Number(value).toFixed(2)
        },
        customSx: (row) => {
            if (row.advise && row.advise === CheckFlags.UPDATED_WIP && (row.previous?.tenor !== row.tenor)) {
                return {
                    bgcolor: 'warning.main',
                }
            }
            return {}
        }
    },
    {
        id: 'amendedTenor',
        label: 'Amended Tenor',
        align: 'right',
        headSX: {
            fixedDecimalScale: true
        },
        edit: {
            fieldType: InputTypes.NUMBER,
            numFormatProps: {
                allowNegative: true,
                decimalScale: 2,
                fixedDecimalScale: true
            },
        },
        sx: {
            minWidth: '110px'
        }
    },
];

export const WIPcolumns: GridColDef[] = [
    {
        field: 'approved',
        headerName: '',
        align: 'center',
        ...warningsGeneralRow,
        width: 50,
        renderCell: (params: GridCellParams) => {
            if (params.row.llc_bi_stage === 'Approval / Loan Committee') {
                return (
                    <Tooltip title={'Loan is at Approval Stage.'} sx={{typography: 'h5'}} placement="right"><AssignmentTurnedInIcon sx={{p: 0}} color='info'/></Tooltip>
                )
            }
            return <></>
        },
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }

    },
    {
        field: 'name',
        headerName: 'Loan Name',
        flex: 1,
        minWidth: 250,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }
    },
    {
        field: 'dealName',
        headerName: 'Deal Name',
        flex: 1,
        minWidth: 250,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }
    },
    {
        field: 'llc_bi_lookupkey',
        headerName: 'Axcess Tranche',
        width: 125,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }
    },
    {
        field: 'llc_bi_product',
        headerName: 'Product',
        width: 150,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }
    },
    {
        ...valueCurrency,
        field: 'llc_bi_amount',
        headerName: 'Commitment',
        width: 150,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }
    },
    {
        ...valueCurrency,
        field: 'expected_drawdown_at_close',
        headerName: 'Exp. Drawdown @ Close',
        width: 200,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }
    },
    {
        field: 'llc_bi_close_date',
        headerName: 'Projected Close',
        width: 120,
        ...valueDate,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }
    },
    {
        field: 'owner',
        headerName: 'Owner',
        width: 120,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }
    },
    {
        field: 'llc_bi_cra_type_code',
        headerName: 'Deal Type',
        width: 120,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }
    },
    {
        field: 'llc_bi_stage',
        headerName: 'Stage',
        width: 120,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
            });
        }
    }
];

export const AllocationColumns: EditableTableColumn[] = [
    {
        id: 'warning',
        label: '',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 20,
            width: 50,
        },
        sx: {
            position: 'sticky',
            left: 0,
            zIndex: 9,
            bgcolor: 'grey.300',
        },
        formatter: (value: any, row) => {
            if (row.alert) {
                return (
                    <WarningsIcon value={row.alert} />
                )
            } else if (row.advise) {
                return (
                    <WarningsIcon value={row.advise} />
                )
            } else {
                return ''
            }
        }
    },
    {
        id: 'name',
        label: 'Loan Name',
        align: 'left',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 20,
            // bgcolor: 'white'

        },
        sx: {
            position: 'sticky',
            left: 40,
            zIndex: 8,
            bgcolor: 'grey.300'
        }
    },
    {
        id: 'dealName',
        label: 'Deal Name',
        align: 'left',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,
            // bgcolor: 'white'

        },
        sx: {
            position: 'sticky',
            left: 0,
            bgcolor: 'grey.300'
        }
    },
    {
        id: 'llc_bi_lookupkey',
        label: 'Axcess Tranche',
        align: 'left',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,
            // bgcolor: 'white'

        },
        sx: {
            position: 'sticky',
            left: 0,
            bgcolor: 'grey.300',
            width: 120
        }
    },
    {
        id: 'llc_bi_product',
        label: 'Product',
        align: 'left',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,
            // bgcolor: 'white'

        },
        sx: {
            position: 'sticky',
            left: 0,
            bgcolor: 'grey.300',
            width: 120
        }
    },
    {
        id: 'owner',
        label: 'Owner',
        align: 'left',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,
            // bgcolor: 'white'

        },
        sx: {
            position: 'sticky',
            left: 0,
            bgcolor: 'grey.300',
            width: 120
        }
    },
    {
        id: 'industry',
        label: 'Industry',
        align: 'left',
        sx: {
            bgcolor: 'grey.300',
        }
    },
    {
        id: 'currency',
        label: 'Base Currency',
        align: 'left',
        sx: {
            bgcolor: 'grey.300',
        }
    },
    {
        id: 'ranking',
        label: 'Ranking',
        align: 'left',
        sx: {
            bgcolor: 'grey.300',
        }
    },
    {
        id: 'rating',
        label: 'Rating',
        align: 'left',
        formatter: (value) => value?.toUpperCase() || null,
        sx: {
            bgcolor: 'grey.300',
        }
    },
    {
        id: 'upfront',
        label: 'Upfront Fees',
        align: 'right',
        formatter: fPercent,
        sx: {
            bgcolor: 'grey.300',
        }
    },
    {
        id: 'margin',
        label: 'Margin (%)',
        align: 'right',
        formatter: fPercent,
        sx: {
            bgcolor: 'grey.300',
        }
    },
    {
        id: 'lineFee',
        label: 'Line Fee (%)',
        align: 'right',
        formatter: fPercent,
        sx: {
            bgcolor: 'grey.300',
        }
    },
    {
        id: 'llc_bi_cra_type_code',
        label: 'Deal Type',
        align: 'left',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,
            // bgcolor: 'white'

        },
        sx: {
            position: 'sticky',
            left: 0,
            bgcolor: 'grey.300',
            width: 120
        }
    },
    {
        id: 'adjustedCommitment',
        label: 'Commitment',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        sx: {
            minWidth: '110px',
            bgcolor: 'grey.300'
        },
        formatter: (value) => {
            if (value === null) return 'N/A'
            return fCurrency(value)
        }
    },
    {
        id: 'adjustedDrawdown',
        label: 'Expected Drawdown at Close',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        sx: {
            minWidth: '110px',
            bgcolor: 'grey.300'
        },
        formatter: (value: number) => {
            if (value === null) return 'N/A'
            return fCurrency(value)
        }
    },
    {
        id: 'adjustedCloseDate',
        label: 'Expected Close',
        align: 'right',
        sx: {
            maxWidth: '120px',
            align: 'right',
            bgcolor: 'grey.300'
        },
        formatter: (value: string) => {
            if (value === null) return 'N/A'
            else return formatDate(new Date(value), 'dd-MM-yyyy')
        },

    },
    {
        id: 'adjustedTenor',
        label: 'Tenor',
        align: 'right',
        headSX: {
            position: 'sticky',
            left: 0,
            top: 0,

        },
        sx: {
            position: 'sticky',
            left: 0,
            bgcolor: 'grey.300',
            width: 50
        },
        formatter: (value) => Number(value).toFixed(2)
    },
    {
        id: 'DASLF',
        label: 'DASLF',
        align: 'right',
        total: true,
        headSX: {
            fixedDecimalScale: true
        },
        edit: {
            fieldType: InputTypes.CURRENCY,
            numFormatProps: {
                allowNegative: true,
                decimalScale: 2,
                fixedDecimalScale: true
            },
            disabled: true
        },
        sx: {
            minWidth: '110px',
            bgcolor: 'info.light',
        },
        formatter: (value: number) => {
            if (value === null) return 'N/A'
            return fCurrency(value)
        }
    },
]