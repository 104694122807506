import {Grid, SxProps, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {fCurrency} from "../utils/formatNumber";

interface  DrillDownSimpleProps {
    data: Array<{
        label: string,
        value: number,
        sx?: SxProps,
        textColor?: string,
        formatter?: (value: number) => string
    }>
}


// Simplified drill down component to show linear information
export default function DrillDownSimple({data}: DrillDownSimpleProps) {

    return (
        <>
            <Grid>
                <Table size="small">
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow sx={{bgcolor: 'info.lighter', ...row.sx}} key={index}>
                                <TableCell colSpan={3}>
                                    <Typography
                                        variant='h6'
                                        sx={{color: (row.textColor || 'black')}}
                                    >
                                        {row.label}
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography
                                        variant='h6'
                                        sx={{...(((row.value || 0) < 0) ? {color: 'red'} : {color: (row.textColor || 'black')})}}
                                    >
                                        {(!!row.formatter) ? row.formatter(row.value || 0) : fCurrency(row.value || 0)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </>
    )
}