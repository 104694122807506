import {memo, useState} from "react";
import {Grid, IconButton, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {fCurrency} from "../../../../utils/formatNumber";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";
import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";
import {DrillDownSection} from "../../../../components";
import {CapitalAction, LoanTags} from "../../../../types/capitalBudgetEnums";
import {addValues} from "../../../../utils/mathUtil";
import {Capital} from "../../../../types/forecastTypes";
import {KeyboardArrowDown as ArrowDownIcon, KeyboardArrowRight as ArrowRightIcon} from "@mui/icons-material";
import {checkInPeriod} from "../../../../utils/DateUtils";

interface AdjustmentsDrillDownProps {
    period: ForecastPeriod,
    fund: FundDetails | null,
    column: investmentReportColumn
}

// DRILL DOWN SUMMARY FOR ALL ADJUSTMENTS
function AdjustmentsDrillDown({period, column, fund}: AdjustmentsDrillDownProps) {

    const [openRepayments, setOpenRepayments] = useState(false);

    let repaymentsTotal = 0;

    const repayments = period.book.filter((l: CalculationLoanType) => (
        (l.tags.includes(LoanTags.REPAYMENT) && !l.amendedMaturity)
        || l.tags.includes(LoanTags.EXTENSION) || l.tags.includes(LoanTags.EARLY_REPAYMENT)
    )).map((l: CalculationLoanType) => {
            repaymentsTotal = addValues(repaymentsTotal, l.value)
            return {
                id: l.id,
                date: l.endDate,
                name: `${(l.trancheId) ? (l.trancheId) : 'NEW'} - ${l.name}`,
                value: l.value,
            }
        }
    )

    let selldownsTotal = 0;

    const selldowns = period.book.filter((l: CalculationLoanType) =>
        l.tags.includes(LoanTags.SELLDOWN)).reduce((values: Array<any>, loan: CalculationLoanType) => {
        loan.selldowns?.forEach(selldown => {
            if (checkInPeriod(selldown.date, period)) {
                let value = selldown.amount;

                if (!fund || (fund.label === loan.fund || fund.holdMap)) {

                    if (fund && fund.label !== loan.fund) {
                        const portion = fund.holdMap?.get(loan.fund)
                        if (portion) {
                            value = portion as number * value
                        } else {
                            return values;
                        }
                    }
                    selldownsTotal = addValues(selldownsTotal, value);
                    values.push({
                        id: selldown.id,
                        date: selldown.date,
                        name: `${(loan.trancheId) ? (loan.trancheId) : 'NEW'} - ${loan.name}`,
                        value: value,
                    })
                }
            }
        })
        return values;
    }, []);

    let transfersTotal = 0;

    const transfers = period.book.filter((l: CalculationLoanType) =>
        l.tags.includes(LoanTags.TRANSFER_IN) || l.tags.includes(LoanTags.TRANSFER_OUT)
    ).reduce((values: Array<any>, loan: CalculationLoanType) => {
        loan.transfersIn?.forEach(transfer => {
            if (checkInPeriod(transfer.transferDate, period)) {
                let value = transfer.amount;

                if (fund && fund.holdMap) {
                    if (fund.holdMap.get(loan.fund)) {
                        value = fund.holdMap.get(loan.fund) as number * value
                    }
                }

                transfersTotal = addValues(transfersTotal, -value);

                values.push({
                    id: transfer.id,
                    date: transfer.transferDate,
                    name: `${(loan.trancheId) ? (loan.trancheId) : 'NEW'} - ${loan.name}`,
                    value: -value,
                })

            }
        })
        loan.transfersOut?.forEach(transfer => {
            if (checkInPeriod(transfer.transferDate, period)) {
                let value = transfer.amount;

                if (fund && fund.holdMap) {
                    if (fund.holdMap.get(loan.fund)) {
                        value = fund.holdMap.get(loan.fund) as number * value
                    }
                }

                transfersTotal = addValues(transfersTotal, value);

                values.push({
                    id: transfer.id,
                    date: transfer.transferDate,
                    name: `${(loan.trancheId) ? (loan.trancheId) : 'NEW'} - ${loan.name}`,
                    value: value,
                })

            }
        })

        return values;
    }, []);


    return (
        <>
            <Grid>
                <Table size="small">
                    <TableBody>
                        {/*New Loan Commitments*/}
                        <DrillDownSection
                            header={'New Loan Commitments'}
                            value={column.newCommitments}
                            retrieveValues={() => {
                                return period.book.filter((l: CalculationLoanType) =>
                                    l.tags.includes(LoanTags.NEW_LOAN)).map((l: CalculationLoanType) => {
                                    return {
                                        id: l.id,
                                        date: l.startDate,
                                        name: `${l.name}`,
                                        value: -l.value
                                    }
                                })
                            }}
                        />
                        {/*Investor Subscriptions*/}
                        <DrillDownSection
                            header={'Investor Subscriptions'}
                            value={column.subscriptions}
                            retrieveValues={() => {
                                return period.capital.filter((c: Capital) =>
                                    c.transactionType === CapitalAction.SUBSCRIPTION).map((ct: Capital) => {
                                    return {
                                        id: ct.id,
                                        date: ct.date,
                                        name: ct.name,
                                        value: ct.amount
                                    }
                                })
                            }}
                        />
                        {/*Investor Redemptions*/}
                        <DrillDownSection
                            header={'Investor Redemptions'}
                            value={column.redemptions}
                            retrieveValues={() => {
                                return period.capital.filter((c: Capital) =>
                                    c.transactionType === CapitalAction.REDEMPTION).map((ct: Capital) => {
                                    return {
                                        id: ct.id,
                                        date: ct.date,
                                        name: ct.name,
                                        value: ct.amount
                                    }
                                })
                            }}
                        />
                        {/*REPAYMENTS -----------------------------------------------------------------------------*/}
                        <>
                            <TableRow>
                                <TableCell
                                    sx={{width: 5, py: 0, px: 0}}
                                    align='center'
                                >
                                    <IconButton
                                        size='small'
                                        onClick={() => setOpenRepayments(!openRepayments)}
                                    >
                                        {openRepayments ? <ArrowDownIcon/> : <ArrowRightIcon/>}
                                    </IconButton>
                                </TableCell>
                                <TableCell colSpan={2} sx={{py: 0}} align='left'>Net Repayments</TableCell>
                                <TableCell
                                    sx={{py: 0, ...(column.repayments < 0) ? {color: 'red'} : {}}}
                                    align='right'>{fCurrency(column.repayments)}</TableCell>
                            </TableRow>
                            {openRepayments &&
                                <>
                                    {/* REPAYMENTS ---------------------------------------------*/}
                                    <DrillDownSection
                                        header={'Repayments'}
                                        value={repaymentsTotal}
                                        retrieveValues={() => repayments}
                                        sx={{borderTop: 2}}
                                    />
                                    {selldownsTotal !== 0 &&
                                        <DrillDownSection
                                            header={'Selldowns'}
                                            value={selldownsTotal}
                                            retrieveValues={() => selldowns}
                                        />
                                    }
                                    {transfersTotal !== 0 &&
                                        <DrillDownSection
                                            header={'Transfers'}
                                            value={transfersTotal}
                                            retrieveValues={() => transfers}
                                        />
                                    }
                                </>
                            }
                        </>
                        {/*Total Adjustments*/}
                        <TableRow sx={{bgcolor: 'primary.light'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'common.white'}}
                                >
                                    Total Adjustments
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                    sx={{color: 'common.white'}}
                                >
                                    {fCurrency(column.adjustments || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </>
    )
}

export default memo(AdjustmentsDrillDown)