import {Routes} from "react-router-dom";
// import AssetAllocationHelper from "./AssetAllocationHelper";


const InfoPages = () => {

    return (
        <Routes>
            {/*<Route path={'/assetAllocationComparison'} element={<AssetAllocationHelper/>}/>*/}
        </Routes>
    )
}

export default InfoPages;