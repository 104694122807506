import {memo, useState} from "react";
import {Box, MenuItem, Select} from "@mui/material";
import DrillDownSimple from "../../../../components/DrillDownsSimple";
import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";
import {fPercent} from "../../../../utils/formatNumber";
import {valueCurrency} from "../../../../components";
import LoansDrilldownTable from "./LoansDrilldownTable";
import {LoanTags} from "../../../../types/capitalBudgetEnums";
import {FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";

interface DebtSeniorityDrillDownProps {
    period: ForecastPeriod,
    column: investmentReportColumn,
    avaCapToggle: boolean,
    fund: FundDetails | null
}

type Ranking = 'Senior' | 'Subordinated' | 'Equity' | 'Other'

// DRILL DOWN FOR DEBT SENIORITY FOR EACH PERIOD
function DebtSeniorityDrillDown({period, column, avaCapToggle, fund}: DebtSeniorityDrillDownProps) {

    const [seniority, setSeniority] = useState<Ranking>('Senior');

    return (
        <>
            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', minHeight: 800}}>
                <Box>
                    <DrillDownSimple
                        data={[
                            {
                                label: `Senior - ${fPercent(column.senior)}`,
                                value: column.senior * ((avaCapToggle) ? column.analyAUM : column.totalCommitment),
                            },
                            {
                                label: `Subordinated - ${fPercent(column.subordinated)}`,
                                value: column.subordinated * ((avaCapToggle) ? column.analyAUM : column.totalCommitment),
                            },
                            {
                                label: `Equity - ${fPercent(column.equity)}`,
                                value: column.equity * ((avaCapToggle) ? column.analyAUM : column.totalCommitment),
                            },
                            {
                                label: `Other - ${fPercent(column.other)}`,
                                value: column.other * ((avaCapToggle) ? column.analyAUM : column.totalCommitment),
                            },
                            ...(avaCapToggle) ? [{
                                label: `Available Capital - ${fPercent(column.capPer)}`,
                                value: column.capPer * column.analyAUM,
                            }] : [],
                            {
                                label: `Total`,
                                value: (avaCapToggle) ? column.analyAUM : column.totalCommitment,
                                textColor: 'white',
                                sx: {bgcolor: 'primary.light'}
                            }
                        ]}
                    />
                </Box>
                <Box sx={{py: 1, flex: 1, minHeight: 200}}>
                    <Box sx={{height: '99%'}}>
                        <LoansDrilldownTable
                            title={`${seniority} Loans`}
                            period={period}
                            fund={fund}
                            additionalColumns={[
                                {
                                    field: 'value',
                                    headerName: 'Commitment',
                                    ...valueCurrency,
                                    width: 120
                                }
                            ]}
                            filterFunction={(loan: CalculationLoanType) => ((loan.tags.includes(LoanTags.ACTIVE) && (loan.ranking === seniority)))}
                            customFilterComponent={<SeniorityFilter rank={seniority} setRank={setSeniority}/>}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

function SeniorityFilter({rank, setRank}: { rank: Ranking, setRank: (rank: Ranking) => void }) {
    return (
        <>
            <Box sx={{px: 1}}>
                Debt Seniority:
                <Select
                    value={rank}
                    onChange={(e) => setRank(e.target.value as Ranking)}
                    sx={{minWidth: 100, color: 'common.white'}}
                    label={'Seniority'}
                    size={"small"}
                >
                    <MenuItem value={'Senior'}>Senior</MenuItem>
                    <MenuItem value={'Subordinated'}>Subordinated</MenuItem>
                    <MenuItem value={'Equity'}>Equity</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
            </Box>
        </>
    )
}

export default memo(DebtSeniorityDrillDown);