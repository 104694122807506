import {RootState} from "../../../store";
import {createSelector} from "@reduxjs/toolkit";
import {DebtFacilities} from "../../../../types/externalDataTypes";
import {
    checkDateBefore,
    checkDateSame,
    findlastDayOfMonth,
    monthsBetweenDates
} from "../../../../utils/DateUtils";
import {addValues} from "../../../../utils/mathUtil";
import {
    valueCurrency,
} from "../../../../components";
import {fCurrency} from "../../../../utils/formatNumber";

// Retrieve Facilities and Providers for Debt Maturity Graph and repor
const retrievedDebtMaturityFacilitiesAndProviders = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.debtFacilities?.debtFacilities || [],
    (debtFacilities: Array<DebtFacilities>) => {
        // Register for provider colours
        const providers: { [x: string]: string } = {};

        // Filter list of debt facilities and providers
        const facilities = [...debtFacilities.reduce((facilities, f) => {
            if (!f.flag_internal) {
                const key = f.tranche_id;

                const client = (f.client).replace(/^MCP\s/, '');

                // Provider summary for each facility
                const fProviders: { [x: string]: number } = {};
                let total = 0;

                // Checks list of providers
                f.providers.forEach(p => {
                    // ADD color if not exisitng
                    if (!providers[p.provider]) {
                        providers[p.provider] = `#${p.color}`;
                    }
                    if (!!p.maturity) {
                        // PUSH to facilities
                        facilities.set(`${key}${p.maturity}`, {
                            id: `${client} - ${f.tranche}(${p.provider})`,

                            date: p.maturity,
                            fund: f.fund,
                            tranche_id: f.tranche_id,
                            name: `${client} - ${f.tranche}(${p.provider})`,
                            providers: {[p.provider]: (p.amount / 1000000)},
                            total: p.amount / 1000000,

                            maturity: p.maturity,
                            margin: f.ref_margin,
                            fee: f.fee_rate,
                        })
                    } else {
                        fProviders[p.provider] = (p.amount / 1000000);
                        total = addValues(total, p.amount / 1000000);
                    }
                })

                const facility = facilities.get(key) || null;

                // Find and add to facility or create new facility
                if (facility) {
                    facility.providers = {...facility.providers, ...fProviders};
                } else {
                    facilities.set(key, {
                        id: `${client} - ${f.tranche}`,

                        date: f.maturity_date,
                        fund: f.fund,
                        tranche_id: f.tranche_id,
                        name: `${client} - ${f.tranche}`,
                        providers: fProviders,
                        total: total,

                        maturity: f.maturity_date,
                        margin: f.ref_margin,
                        fee: f.fee_rate,
                    })
                }
            }
            return facilities;
        }, new Map()).values()].sort((a, b) => checkDateBefore(a.date, b.date) ? -1 : 1);

        return {
            facilities: facilities,
            providers: providers
        }
    }
)

// Extracts the debt maturity graph data from the external data
// The data is extracted to match the needs of the graph and providers colors
export const retrieveDebtMaturityGraphData = createSelector(
    retrievedDebtMaturityFacilitiesAndProviders,
    ({facilities, providers}) => {

        const chartedFacilities = facilities.reduce((facilities, f) => {
            const month = findlastDayOfMonth(f.date);

            if (facilities.length === 0) {
                facilities.push({
                    date: month,
                    loans: [f.name],
                    [f.name]: f.providers
                })
            } else {
                if (checkDateSame(facilities[facilities.length - 1].date, month)) {
                    facilities[facilities.length - 1][f.name] = f.providers;
                    facilities[facilities.length - 1].loans.push(f.name)
                } else {
                    // const monthsBetween = monthsBetweenDates(facilities[facilities.length - 1].date, month);
                    if (monthsBetweenDates(facilities[facilities.length - 1].date, new Date(month)) > 1) {
                        // for (let mb = 0; mb < monthsBetween / 6; mb++) {
                        facilities.push({date: ''})
                        // }
                    }
                    facilities.push({
                        date: month,
                        loans: [f.name],
                        [f.name]: f.providers
                    })
                }
            }

            return facilities
        }, []);

        return {
            facilities: chartedFacilities,
            providers: providers
        }
    }
)



// Retrieve Debt Maturity Table data and columns
export const retrieveDebtMaturityTable = createSelector(
    retrievedDebtMaturityFacilitiesAndProviders,
    ({facilities, providers}) => {

        const sortedProviders = Object.keys(providers).sort((a, b) => {
            if (a === 'Other Lenders') return 1;
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        })

        let total = 0;

        const providerTotals: {[x: string]: number} = Object.keys(providers).reduce((provs: {[x: string]: number}, p) => {
            provs[p] = 0;
            return provs
        }, {})

        facilities.forEach(f => {
            total = addValues(total, f.total);
            Object.keys(f.providers).forEach(p => {
                providerTotals[p] = addValues(providerTotals[p], f.providers[p]);
            })
        })

        return {
            facilities: facilities.map(f => ({...f, ...f.providers})),
            columns: [
                ...sortedProviders.map(p => ({
                    field: p,
                    headerName: p,
                    ...valueCurrency,
                    valueFormatter: (params: any) => fCurrency(params.value, 2),
                    width: 100,
                    ...(p === 'Other Lenders' ? {cellClassName: () => 'other-lender'} : {})
                }))
            ],
            providers: providerTotals,
            total
        }
    }
)

