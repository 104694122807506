import {Box, Button, IconButton} from "@mui/material";
import {Close as CloseIcon, Launch as LaunchIcon} from "@mui/icons-material";
import {DataDisplay} from "../../../components";
import {formatDate} from "../../../utils/DateUtils";
import {fCurrency, fPercent} from "../../../utils/formatNumber";
import {ManualDeal} from "../../../types/forecastTypes";


export default function ManualLoanDataDisplay({loan, onClose}: {loan: ManualDeal, onClose: () => void}) {

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <IconButton size='small' onClick={() => onClose()}>
                    <CloseIcon/>
                </IconButton>
            </Box>
            <Box sx={{px: 5, pt: 1, mt: -4}}>
                <DataDisplay
                    dataValues={[
                        {label: 'Owner', data: loan.owner},
                        {
                            label: 'Deal Financial Close',
                            data: (loan.closeDate) ? formatDate(new Date(loan.closeDate), 'dd-MM-yyyy') : null
                        },
                        {label: 'Asset Name', data: loan.name},
                        {label: 'Tranche Type', data: loan.trancheType},
                        {label: 'Investment Type', data: loan.investmentType},
                        {label: 'Ranking', data: loan.ranking},
                        {
                            label: 'Commitment',
                            data: fCurrency(loan.commitment)
                        },
                        {
                            label: 'Expected Drawdown at Close',
                            data: fCurrency(loan.drawdownAtClose)
                        },
                        {
                            label: 'Tenor',
                            data: loan.tenor.toString()
                        },
                        {
                            label: 'Rating',
                            data: loan.rating
                        },
                        {label: 'Industry Segment', data: loan.industry},
                        ...((loan.facilityFeeRate) ? [{
                            label: 'Fees',
                            subData: [
                                [
                                    {label: '', data: ''},
                                        {label: 'Percentage', data: fPercent(loan.facilityFeeRate)},
                                ]
                            ]
                        }] : []),
                        ...((loan.margin) ? [{
                            label: 'Pricing',
                            subData: [[
                                {label: 'Interest Rate Type', data: loan.interestType},
                                {label: 'Margin', data: fPercent(loan.margin)},
                            ]]
                        }] : []),

                        {
                            label: 'Domicile',
                            data: loan.domicile
                        },
                        {label: 'Base Currency', data: loan.baseCurrency},
                    ]}
                />
            </Box>
            {loan.ncinoId &&
                <Box sx={{px: 5, pt: 5, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <Button
                        endIcon={<LaunchIcon/>}
                        onClick={() => window.open(`https://metricscp.lightning.force.com/${loan.ncinoId}`, '_blank')}
                    >
                        Go To nCino/Salesforce
                    </Button>
                </Box>
            }
        </>
    )
}