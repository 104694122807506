import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {retrieveNewLoansAllocations} from "./wipSelector";
import {
    rawPortfolioGpSelector,
    retrieveGpAUDPortfolio
} from "./portfolioSelector";
import {addValues} from "../../../utils/mathUtil";
import {fundSelector} from "./generalSelectors";

export type FundFinanceDetails = {
    id: number,
    dealId: string,
    director: string,
    owner: string,
    name: string,
    loans: Array<FundFinanceLoan>,
    masterMaturity: Date | number,
    masterLimit: number,
    adjustedMasterMaturity: Date | number,
    adjustedMasterLimit: number,
    currentBalance: number,
    undrawnBalance: number,
    currentLimit: number,
    unusedMaster: number,
    fundFinanceId: number | null
}

// Retrieves a consolidated list of selected and unselected Fund Finances.
export const retrieveFundFinanceSelector = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.fundFinance?.fundFinances || [],
    (state: RootState) => state.capitalBudget.forecastData?.fundFinance || [],
    retrieveGpAUDPortfolio,
    (state: RootState) => retrieveNewLoansAllocations(state, false),
    (_state: RootState, onlySelected: boolean) => onlySelected || false,
    (fundFinanceRaw, financeFacilities, portfolio, deals, onlySelected) => {

        const fundFinances: Array<FundFinanceDetails> = [];

        fundFinanceRaw.forEach((fundFinance: any) => {
            const selected = financeFacilities.find((f: any) => f.dealId === fundFinance.deal_id && f.status !== 'REMOVED');

            if (onlySelected && !selected) return;

            const loans: any[] = [];

            let totalLimit = 0;
            let totalDrawn = 0;
            let totalUndrawn = 0;

            // FOR ADJUSTED MASTER LIMIT AND DATE
            let adjustedMasterDate;
            let adjustedMasterLimit;

            // IF FUND FINANCE HAS ALREADY BEEN SELECTED
            if (selected) {
                selected.loans.forEach((loan: { type: 'AXCESS', id: number } | { type: 'NCINO', id: string }) => {
                    if (loan.type === 'AXCESS') {
                        const tranche = portfolio.find((p: any) => p.tranche_id === loan.id);

                        if (tranche) {
                            totalLimit = addValues(totalLimit, tranche.commitmentAUD);
                            totalDrawn = addValues(totalDrawn, tranche.drawnAUD);
                            totalUndrawn = addValues(totalUndrawn, tranche.undrawnAUD);
                            loans.push({
                                trancheId: tranche.tranche_id,
                                // status: tranche.status,
                                name: tranche.tranche,
                                currentBalance: tranche.drawnAUD,
                                undrawnBalance: tranche.undrawnAUD,
                                currentLimit: tranche.commitmentAUD,
                                maturity: tranche.maturity,
                                fundFinanceId: selected.id,
                                type: 'AXCESS'
                            })
                        }
                    }
                    if (loan.type === 'NCINO') {
                        const deal = deals.find((d: any) => d.ncinoId === loan.id);

                        if (deal) {
                            totalLimit = addValues(totalLimit, deal.adjustedCommitment || 0);
                            totalUndrawn = addValues(totalUndrawn, deal.adjustedCommitment || 0);
                            loans.push({
                                trancheId: 'NEW',
                                name: deal.name,
                                currentBalance: 0,
                                undrawnBalance: deal.adjustedCommitment,
                                currentLimit: deal.adjustedCommitment,
                                fundFinanceId: selected.id,
                                type: 'NCINO',
                            })
                        }
                    }
                })

                // Set Adjusted Values if any
                if (selected.amendedMasterLimit) adjustedMasterLimit = selected.amendedMasterLimit;
                if (selected.amendedMasterDate) adjustedMasterDate = selected.amendedMasterDate;

            } else {
                // IF FUND FINANCE HAS NOT BEEN SELECTED RETRIEVE DEFAULT INFO
                fundFinance.loans.forEach((loan: any) => {
                    let fundFinanceLoan = {
                        ncinoId: loan.ncino_id,
                        trancheId: loan.tranche_id,
                        status: loan.status,
                    }

                    // Find Axcess Loan
                    if (/^\d{5}$/.test(loan.tranche_id)) {
                        const tranche = portfolio.find((p: any) => p.tranche_id.toString() === loan.tranche_id);

                        if (tranche) {
                            totalLimit = addValues(totalLimit, tranche.commitmentAUD);
                            totalDrawn = addValues(totalDrawn, tranche.drawnAUD);
                            totalUndrawn = addValues(totalUndrawn, tranche.undrawnAUD);
                            loans.push({
                                ...fundFinanceLoan,
                                trancheId: tranche.tranche_id,
                                name: tranche.tranche,
                                currentBalance: tranche.drawnAUD,
                                undrawnBalance: tranche.undrawnAUD,
                                currentLimit: tranche.commitmentAUD,
                                maturity: tranche.maturity,
                                type: 'AXCESS'
                            })
                        }
                        // If not existing loan find nCino Loan
                    } else if (loan.ncino_id) {

                        const ncinoLoan = deals.find((d: any) => d.ncinoId === loan.ncino_id);

                        if (ncinoLoan) {

                            totalLimit = addValues(totalLimit, ncinoLoan.adjustedCommitment);
                            totalUndrawn = addValues(totalUndrawn, ncinoLoan.adjustedCommitment);
                            loans.push({
                                ...fundFinanceLoan,
                                trancheId: 'NEW',
                                name: ncinoLoan.name,
                                currentBalance: 0,
                                undrawnBalance: ncinoLoan.adjustedCommitment,
                                currentLimit: ncinoLoan.adjustedCommitment,
                                type: 'NCINO'
                            })
                        }
                    }
                })
            }

            fundFinances.push({
                id: fundFinance.id,
                dealId: fundFinance.deal_id,
                director: fundFinance.director,
                owner: fundFinance.owner,
                loans: loans,
                name: fundFinance.name,

                masterMaturity: fundFinance.master_maturity,
                masterLimit: fundFinance.master_limit,

                adjustedMasterMaturity: adjustedMasterDate || fundFinance.master_maturity,
                adjustedMasterLimit: adjustedMasterLimit || fundFinance.master_limit,

                currentBalance: totalDrawn,
                undrawnBalance: totalUndrawn,
                currentLimit: totalLimit,
                unusedMaster: addValues(adjustedMasterLimit || fundFinance.master_limit, -totalLimit),
                fundFinanceId: selected ? selected.id : null,
                ...(selected && selected.status) ? {status: selected.status} : {}
            })

        })

        return fundFinances;
    }
)

type FundFinanceLoan = {
    id: number | string,
    trancheId: number | string,
    ncinoId?: string,
    name: string,
    borrower: string,
    currentBalance: number,
    undrawnBalance: number,
    currentLimit: number,
    maturity?: Date | number,
    fundFinanceId: number | null,
    type: 'AXCESS' | 'NCINO'
}

export const retrieveLoansForFundFinance = createSelector(
    rawPortfolioGpSelector,
    (state: RootState) => retrieveNewLoansAllocations(state, false),
    (portfolio, deals) => {
        const loans: FundFinanceLoan[] = [];

        portfolio.forEach(loan => {
            loans.push({
                id: loan.tranche_id,
                trancheId: loan.tranche_id,
                name: loan.tranche,
                borrower: loan.borrower,
                currentBalance: loan.drawnAUD,
                undrawnBalance: loan.undrawnAUD,
                currentLimit: loan.commitmentAUD,
                maturity: loan.maturity,
                fundFinanceId: null,
                type: 'AXCESS'
            })
        })

        deals.forEach(loan => {
            loans.push({
                id: loan.ncinoId,
                trancheId: 'NEW',
                name: loan.name,
                borrower: loan.borrower,
                currentBalance: 0,
                undrawnBalance: loan.adjustedCommitment,
                currentLimit: loan.adjustedCommitment,
                fundFinanceId: null,
                type: 'NCINO'
            })
        })

        return loans;
    }
)

// Retrieve Unused Master Fund Facility limit
export const retrieveUnusedFundFacility = createSelector(
    (state: RootState) => retrieveFundFinanceSelector(state, true),
    fundSelector,
    (fundFinances, fund) => {
        // IF FUND IS SELECTED AND IT IS NOT DASLF RETURN 0
        if (fund && fund.label !== 'DASLF') return 0;

        let unusedMaster = 0;

        fundFinances.forEach(f => {
            if (f.fundFinanceId) {
                unusedMaster = addValues(unusedMaster, f.unusedMaster);
            }
        })

        return unusedMaster;
    }
)