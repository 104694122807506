import {memo} from "react";
import {Box, Grid} from "@mui/material";
import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";
import {FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";
import DrillDownSimple from "../../../../components/DrillDownsSimple";
import {fPercent} from "../../../../utils/formatNumber";
import LoansDrilldownTable from "./LoansDrilldownTable";
import {valueCurrency, valuePercentage} from "../../../../components";

interface RatesDrillDownProps {
    period: ForecastPeriod,
    column: investmentReportColumn,
    fund: FundDetails | null
}


function RatesDrillDown({period, column, fund}: RatesDrillDownProps) {
    return (
        <>
            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', minHeight: 800}}>
                <Box>
                    <Grid>
                        <DrillDownSimple
                            data={[
                                {
                                    label: 'Weighted Average Margin (%)',
                                    value: column.wavMargin,
                                    formatter: fPercent
                                },
                                {
                                    label: 'Base Rate (%)',
                                    value: column.baseRate,
                                    formatter: fPercent
                                },
                                {
                                    label: 'Drawn Rate (%)',
                                    value: column.wavYield,
                                    formatter: fPercent
                                }
                            ]}
                        />
                    </Grid>
                </Box>
                <Box sx={{py: 1, flex: 1, minHeight: 200}}>
                    <Box sx={{height: '99%'}}>
                        <LoansDrilldownTable
                            title={`Loans`}
                            period={period}
                            fund={fund}
                            additionalColumns={[
                                {
                                    field: 'interestType',
                                    headerName: 'Interest Type',
                                    valueFormatter: (params) => (params.value === 'Floating Rate - BBSY' ? 'Floating Rate' : params.value),
                                    width: 120
                                },
                                {
                                    field: 'margin',
                                    headerName: 'Margin',
                                    ...valuePercentage,
                                    width: 80
                                },
                                {
                                    field: 'facilityFee',
                                    headerName: 'Facility Fee',
                                    ...valuePercentage,
                                    width: 100
                                },
                                {
                                    field: 'value',
                                    headerName: 'Commitment',
                                    ...valueCurrency,
                                    width: 120
                                }
                            ]}
                            filterFunction={(loan: CalculationLoanType) => (!['Equity', 'Other'].includes(loan.ranking) || (['Equity', 'Other'].includes(loan.ranking) && loan.margin > 0))}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default memo(RatesDrillDown)