import {memo} from "react";
import {useAppSelector} from "../../../../store/store";
import {
    retrieveBankFacilities
} from "../../../../store/capitalBudget/selectors/Reports/facilitiesSelector";
import {FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";
import {Grid, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {DrillDownSection} from "../../../../components";
import {fCurrency} from "../../../../utils/formatNumber";

// Drill down to show total Bank Facilities
function FacilitiesDrillDown({fund, totalFacilities, externalFacilities, internalFacilities}: { fund: FundDetails | null, totalFacilities: number, externalFacilities: number, internalFacilities: number }) {

    const facilities = useAppSelector(state => retrieveBankFacilities(state));

    return (
        <>
            <Grid>
                <Table size="small">
                    <TableBody>
                        <DrillDownSection
                            header={'External Facilities'}
                            value={externalFacilities}
                            retrieveValues={() => facilities.external.filter((f) => (!fund || (fund.label === f.fund))).map((facility, f) => {
                                return {
                                    id: f,
                                    name: `${facility.trancheName} (${facility.fund})`,
                                    date: null,
                                    value: facility.facilityLimit
                                }
                            })}
                        />
                        <DrillDownSection
                            header={'Internal Facilities'}
                            value={internalFacilities}
                            retrieveValues={() => facilities.internal.filter((f) => (!fund || (fund.label === f.fund))).map((facility, f) => {
                                return {
                                    id: f,
                                    name: `${facility.trancheName} (${facility.fund})`,
                                    date: null,
                                    value: facility.facilityLimit
                                }
                            })}
                        />
                        <TableRow sx={{bgcolor: 'info.lighter'}}>
                            <TableCell colSpan={3}>
                                <Typography
                                    variant='h6'
                                >
                                    Bank Facilities Limit
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography
                                    variant='h6'
                                >
                                    {fCurrency(totalFacilities || 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </>
    )
}

export default memo(FacilitiesDrillDown);