import {NewDeal} from "../../../types/forecastTypes";
import {WIPLoan} from "../../../types/externalDataTypes";
import {WipCheck} from "../../../types/capitalBudgetTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {checkDateBefore, checkDateSame} from "../../../utils/DateUtils";
import {CheckFlags} from "../../../types/GeneralTypes";
import {addValues} from "../../../utils/mathUtil";


export function wipCheck(forecastDate: Date | number, newDeals: Array<NewDeal>, pipeline: Array<WIPLoan>): WipCheck {
    const checks: WipCheck = {
        remove: [],
        advise: [],
        alert: [],
        modification: []
    }

    newDeals.forEach((nd: NewDeal) => {
        if (nd.status !== SaveStatus.REMOVED) {
            const deal = pipeline.find((p: WIPLoan) => p.ncino_id === nd.ncinoId);
            // CHECK IF MATCHING DEAL CAN BE FOUND - IF NOT REMOVE
            if (!deal) checks.remove.push(nd.id);
            else {
                // CHECK UPDATED ATTRIBUTES: Close Date | Commitment | Tenor | Expected Drawdown
                // UPDATED
                if (!checkDateSame(nd.closeDate, deal.llc_bi_close_date) || nd.commitment !== deal.llc_bi_amount || nd.tenor !== deal.llc_bi_term_months / 12 || nd.drawdown !== deal.expected_drawdown_at_close) {
                    checks.advise.push({id: nd.id, flag: CheckFlags.UPDATED_WIP})
                    if (!checkDateSame(nd.closeDate, deal.llc_bi_close_date)) {
                        checks.modification.push({id: nd.id, attribute: 'closeDate', value: deal.llc_bi_close_date});
                        if (nd.amendedCloseDate && checkDateSame(nd.amendedCloseDate, deal.llc_bi_close_date)) checks.modification.push({
                            id: nd.id,
                            attribute: 'amendedCloseDate',
                            value: null
                        })
                    }
                    if (nd.commitment !== deal.llc_bi_amount) {
                        checks.modification.push({id: nd.id, attribute: 'commitment', value: deal.llc_bi_amount});
                        if (nd.amendedCommitment && nd.amendedCommitment === deal.llc_bi_amount) checks.modification.push({
                            id: nd.id,
                            attribute: 'amendedCommitment',
                            value: null
                        })
                    }
                    if (nd.tenor !== deal.llc_bi_term_months / 12) {
                        checks.modification.push({id: nd.id, attribute: 'tenor', value: deal.llc_bi_term_months / 12});
                        if (nd.amendedTenor && nd.amendedTenor === deal.llc_bi_term_months / 12) checks.modification.push({
                            id: nd.id,
                            attribute: 'amendedTenor',
                            value: null
                        })
                    }
                    if (nd.drawdown !== deal.expected_drawdown_at_close) {
                        checks.modification.push({
                            id: nd.id,
                            attribute: 'drawdown',
                            value: deal.expected_drawdown_at_close
                        });
                        if (nd.amendedDrawdown && nd.amendedDrawdown === deal.expected_drawdown_at_close) checks.modification.push({
                            id: nd.id,
                            attribute: 'amendedDrawdown',
                            value: null
                        })
                    }
                }
                // CHECK MISSING DATA FROM NCINO
                else if ((!nd.amendedCommitment && !deal.llc_bi_amount) || (!nd.amendedDrawdown && !deal.expected_drawdown_at_close) || (!nd.amendedTenor && !deal.llc_bi_term_months) || (!nd.amendedCloseDate && !deal.llc_bi_close_date)) {
                    if (!nd.amendedCommitment && !deal.llc_bi_amount) checks.alert.push({
                        id: nd.id,
                        flag: CheckFlags.WIP_MISSING_COMMITMENT
                    });
                    if (!nd.amendedDrawdown && !deal.expected_drawdown_at_close) checks.advise.push({
                        id: nd.id,
                        flag: CheckFlags.WIP_MISSING_DRAWDOWN
                    });
                    if (!nd.amendedTenor && !deal.llc_bi_term_months) checks.alert.push({
                        id: nd.id,
                        flag: CheckFlags.WIP_MISSING_TENOR
                    });
                    if (!nd.amendedCloseDate && !deal.llc_bi_close_date) checks.alert.push({
                        id: nd.id,
                        flag: CheckFlags.WIP_MISSING_CLOSE_DATE
                    });
                }
                // PRE FORECAST
                else if (checkDateBefore((nd.amendedCloseDate) ? nd.amendedCloseDate : deal.llc_bi_close_date, forecastDate)) checks.advise.push({
                    id: nd.id,
                    flag: CheckFlags.PRE_FORECAST
                })
                // else {
                const allocation = Object.values(nd.allocation).reduce((a: number, b: number) => addValues(a, b), 0);
                const commitment = nd.amendedCommitment ? nd.amendedCommitment : nd.commitment;
                // UNALLOCATED
                if (allocation === 0) {
                    checks.alert.push({id: nd.id, flag: CheckFlags.UNALLOCATED})
                }
                // MIS-ALLOCATED
                else if (allocation !== commitment) {
                    checks.alert.push({id: nd.id, flag: CheckFlags.MISALLOCATED})
                }
                // }
            }
        }
    })

    return checks;
}