import {MCPFund} from "../types/forecastTypes";
import {CalculationLoanType} from "../types/capitalBudgetTypes";
import {addValues} from "./mathUtil";
import {LoanTags} from "../types/capitalBudgetEnums";
import {FundDetails} from "../store/capitalBudget/selectors/generalSelectors";
import {WIPLoan} from "../types/externalDataTypes";

const MCPRatingIndex = {
    'AA+': 4,
    'AA': 5,
    'AA-': 6,
    'A+': 7,
    'A': 8,
    'A-': 9,
    'BBB+': 10,
    'BBB': 11,
    'BBB-': 12,
    'BB+': 13,
    'BB': 14,
    'BB-': 15,
    'B+': 16,
    'B': 17,
    'B-': 18,
    'CCC+': 19,
    'CCC': 20,
    'D': 21,
    'NR': null
}

export const ratingSelection = [
    {label: 'AA+', value: 'aa+'},
    {label: 'AA', value: 'aa'},
    {label: 'AA-', value: 'aa-'},
    {label: 'A+', value: 'a+'},
    {label: 'A', value: 'a'},
    {label: 'A-', value: 'a-'},
    {label: 'BBB+', value: 'bbb+'},
    {label: 'BBB', value: 'bbb'},
    {label: 'BBB-', value: 'bbb-'},
    {label: 'BB+', value: 'bb+'},
    {label: 'BB', value: 'bb'},
    {label: 'BB-', value: 'bb-'},
    {label: 'B+', value: 'b+'},
    {label: 'B', value: 'b'},
    {label: 'B-', value: 'b-'},
    {label: 'CCC+', value: 'ccc+'},
    {label: 'CCC', value: 'ccc'},
    {label: 'D', value: 'd'},
    {label: 'NR', value: 'NR'}
];

export function convertRatingToValue(rating: string): number | null {
    let ratingValue;
    try {
        //@ts-ignore
        ratingValue = (MCPRatingIndex[`${rating.toUpperCase()}`])

        return ratingValue || 0
    } catch (err) {
        return 0
    }
}

export function convertValueToRating(rating: number): string | null {
    try {
        //@ts-ignore
        const ratingValue = Object.keys(MCPRatingIndex).find((key: string) => MCPRatingIndex[`${key}`] === Math.floor(rating));
        return ratingValue || null
    } catch (err) {
        return null
    }
}

export function groupDebtSeniority(seniority: string) {
    if (!seniority) return 'No Seniority'
    try {
        switch (seniority.toLowerCase()) {
            case 'senior':
            case 'super senior':
            case 'stretch senior':
                return 'Senior';

            case 'subordinated':
            case 'mezzanine':
                return 'Subordinated';

            case 'equity':
            case 'hybrid':
            case 'preferred equity':
            case 'preferred':
            case 'shareholder loan':
                return 'Equity';

            case 'other':
                return 'Other';

            default:
                return seniority

        }
    } catch (e) {
        return 'No Seniority'
    }
}

export function extractnCinoBase(loan: WIPLoan): null | number {
    const productPackage = loan.llc_bi_pricing_streams[0];

    if (productPackage) {
        const rateComponents = productPackage.llc_bi_pricing_rate_components[0];
        if (rateComponents) {
            return rateComponents.llc_bi_rate / 100
        }
    }
    return null
}

export function extractnCinoSpread(loan: WIPLoan): null | number {
    const productPackage = loan.llc_bi_pricing_streams[0];

    if (productPackage) {
        const rateComponents = productPackage.llc_bi_pricing_rate_components[0];
        if (rateComponents) {
            return rateComponents.llc_bi_spread / 100
        }
    }
    return null
}

export function extractFacilityFee(loan: WIPLoan): null | number {
    const fees = loan.llc_bi_fees;

    if (fees) {
        const lineFee = fees.find(f => f.llc_bi_fee_type === 'Line Fee')
        if (lineFee) {
            return lineFee.llc_bi_percentage / 100;
        }
    }

    return null
}

// CHECKS FUND label against alternative labels.
export function checkFundAlternative(fund: MCPFund, value: string) {
    return fund.alternatives?.includes(value);
}

// FILTERS BOOK by fund if necessary and retrieves percentages of loans as necessary:
export function createFilteredPortfolioBook(book: Array<CalculationLoanType>, fund: FundDetails | null, filterActive: boolean = true) {
    return book.reduce((filtered: Array<CalculationLoanType>, loan) => {
        if (filterActive && !loan.tags.includes(LoanTags.ACTIVE)) return filtered;

        if (fund) {
            if (fund.label === loan.fund) {
                filtered.push(loan);
            } else if (fund.holdMap) {
                const issuer = (loan.fund === 'FST') ? 'CT' : loan.fund
                const percentage = fund.holdMap.get(issuer);
                if (percentage) {
                    filtered.push({
                        ...loan,
                        value: loan.value * percentage,
                        updatedValue: loan.updatedValue * percentage,
                        undrawn: loan.undrawn * percentage,
                        updatedUndrawn: loan.updatedUndrawn * percentage,
                        drawn: loan.drawn * percentage,
                        updatedDrawn: loan.updatedDrawn * percentage,
                        tags: [...loan.tags, LoanTags.LOOKTHROUGH],
                    })
                }
            }
        } else {
            filtered.push(loan);
        }

        return filtered;
    }, [])
}


// GROUP Loans BY TRANCHE TODO: Check necessity
export function groupLoansByTranche(book: Array<CalculationLoanType>): Array<CalculationLoanType> {
    const bookMap = book.reduce((bookMap: Map<number, CalculationLoanType>, loan: CalculationLoanType) => {

        const trancheId = (loan.trancheId) ? loan.trancheId : loan.asset as number;
        let tranche = bookMap.get(trancheId);

        if (!!tranche) {
            const value = addValues(tranche.value, loan.value);
            const updatedValue = addValues(tranche.updatedValue, loan.updatedValue);
            const drawn = addValues(tranche.drawn, loan.drawn);
            const updatedDrawn = addValues(tranche.updatedDrawn, loan.updatedDrawn);
            const undrawn = addValues(tranche.undrawn, loan.undrawn);
            const updatedUndrawn = addValues(tranche.updatedUndrawn, loan.updatedUndrawn);

            bookMap.set(trancheId, {...tranche, value, updatedValue, drawn, updatedDrawn, undrawn, updatedUndrawn})

        } else {
            bookMap.set(trancheId, loan)
        }

        return bookMap;
    }, new Map())

    return Array.from(bookMap.values())
}

export const InvestmentTypeSelection = [
    { label: 'Corporate', value: 'Corporate' },
    { label: 'Equity', value: 'Equity' },
    { label: 'Fund Finance', value: 'Fund Finance' },
    { label: 'Leveraged', value: 'Leveraged' },
    { label: 'Other', value: 'Other' },
    { label: 'Project Finance & Infrastructure', value: 'Project Finance & Infrastructure' },
    { label: 'Real Estate', value: 'Real Estate' },
    { label: 'Structured', value: 'Structured' }
];

export const TrancheTypeSelection = [
    { label: 'Capex', value: 'Capex' },
    { label: 'Construction', value: 'Construction' },
    { label: 'Equity', value: 'Equity' },
    { label: 'Guarantee', value: 'Guarantee' },
    { label: 'Hybrids', value: 'Hybrids' },
    { label: 'Other', value: 'Other' },
    { label: 'Revolving', value: 'Revolving' },
    { label: 'Revolving Multi-currency', value: 'Revolving Multi-currency' },
    { label: 'Term Amortising', value: 'Term Amortising' },
    { label: 'Term Bullet', value: 'Term Bullet' }
];