import {Tooltip} from "@mui/material";
import {
    Alarm as AlarmIcon,
    AttachMoney as AttachMoneyIcon,
    CurrencyExchange as CurrencyExchangeIcon,
    EditNote as EditNoteIcon,
    Event as EventIcon,
    EventBusy as EventBusyIcon,
    EventRepeat as EventRepeatIcon,
    MonetizationOn as MonetizationOnIcon,
    MoneyOff as MoneyOffIcon,
    PriceChange as PriceChangeIcon,
    Schedule as ScheduleIcon
} from "@mui/icons-material";
import {CheckFlags} from "../types/GeneralTypes";
import {ReactElement} from "react";

export default function WarningsIcon({value}: {value: CheckFlags | null }) {

    const flag = (value) ? CheckFlagsMap[value] : null;

    return (
        <>
            {flag &&
                <>
                    <Tooltip title={flag.text} sx={{typography: 'h5'}} placement="right">{flag.icon}</Tooltip>
                </>
            }
        </>
    )
}

const CheckFlagsMap: { [key in CheckFlags]: { text: string, icon: ReactElement } } = {
    [CheckFlags.AFTER_MATURITY]: {text: "Occurs after loan matures.", icon: <AlarmIcon sx={{p: 0}} color='error'/>},
    [CheckFlags.EXCEEDED]: {
        text: "Reduction exceeds remaining balance.",
        icon: <AttachMoneyIcon sx={{p: 0}} color='error'/>
    },
    [CheckFlags.MISALLOCATED]: {
        text: "Loan allocations does not match loan commitment.",
        icon: <CurrencyExchangeIcon sx={{p: 0}} color='error'/>
    },
    [CheckFlags.PRE_FORECAST]: {text: "Occurs before the forecast date.", icon: <ScheduleIcon sx={{p: 0}} color='info'/>},
    [CheckFlags.UNALLOCATED]: {
        text: "Loan has not be allocated to funds.",
        icon: <MoneyOffIcon sx={{p: 0}} color='error'/>
    },
    [CheckFlags.UPDATED_MATURITY]: {
        text: "Original Maturity on loan has been updated.",
        icon: <EventRepeatIcon sx={{p: 0}} color='info'/>
    },
    [CheckFlags.UPDATED_WIP]: {text: "Loan has been updated.", icon: <EditNoteIcon sx={{p: 0}} color='info'/>},
    [CheckFlags.WIP_MISSING_COMMITMENT]: {
        text: "Missing commitment from loan.",
        icon: <MonetizationOnIcon sx={{p: 0}} color='error'/>
    },
    [CheckFlags.WIP_MISSING_DRAWDOWN]: {
        text: "No First Drawdown from amount.",
        icon: <PriceChangeIcon sx={{p: 0}} color='info'/>
    },
    [CheckFlags.WIP_MISSING_CLOSE_DATE]: {
        text: "Missing Close Date from loan.",
        icon: <EventBusyIcon sx={{p: 0}} color='error'/>
    },
    [CheckFlags.WIP_MISSING_TENOR]: {text: "Missing tenor from loan.", icon: <EventIcon sx={{p: 0}} color='error'/>}
};