// Packages
import {createAsyncThunk} from "@reduxjs/toolkit";
// Local
//Store
import {forecastedPortfolioMap, retrievePortfolioLoansForCalc} from "./selectors/portfolioSelector";
import {RootState} from "../store";
import {retrieveNewLoansForCalcs} from "./selectors/wipSelector";
import {retrieveCapitalTransactionsForCalc} from "./selectors/capitalSelectors";
import {retrieveOtherTransactionsForCalc} from "./selectors/otherTransactionSelectors";
import {generatePeriods} from "../../utils/DateUtils";
import {getForecastDate} from "./selectors/generalSelectors";
import generateBudgetForecast from "./generateBudgetForecast";
import runInputCheck from "./runInputCheck";
import {
    bulkRemoveRefinance, bulkRemoveWIPComments,
    bulkUpdateChanges,
    removeMaturityChangeBulk, removeNewLoanBulk,
    removeSellRepayBulk,
    removeTransferBulk,
    updateMaturityChanges
} from "./capitalBudgetSlice";
import {retrieveFundFinanceSelector} from "./selectors/fundFinanceSelector";

// CALCuLATE CAPITAL BUDGET PORTFOLIO
export const generateCapitalBudget = createAsyncThunk('capitalBudget/generate', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    //
    /**
     * INITIALISE DATA TO CALCULATE BUDGET
     * - Portfolio
     * - New Loans
     * - Capital Movements
     * - Other Transactions
     * - Generate Periods
     */
    const forecastDate = getForecastDate(state) || new Date();
    const portfolio = retrievePortfolioLoansForCalc(state);
    const newLoans = retrieveNewLoansForCalcs(state)
    const capital = retrieveCapitalTransactionsForCalc(state);
    const otherTransactions = retrieveOtherTransactionsForCalc(state);
    const fundFinance = retrieveFundFinanceSelector(state, true);

    const periods = generatePeriods(state.capitalBudget.misc.weeks, state.capitalBudget.misc.months, forecastDate);

    return generateBudgetForecast(forecastDate, periods, portfolio, newLoans, capital, otherTransactions, fundFinance);
})

export const checkInputs = createAsyncThunk('capitalBudget/checkInputs', async (_, thunkAPI) => {
    const date = new Date();
    const state = thunkAPI.getState() as RootState;

    const forecastDate = getForecastDate(state) || new Date();
    const forecastData = state.capitalBudget.forecastData;
    const externalData = state.capitalBudget.externalData;

    const portfolio = forecastedPortfolioMap(state);

    if (!forecastData || !externalData || !portfolio) return null;

    const checks = runInputCheck(forecastDate, portfolio, forecastData, externalData);

    if (checks.maturity.remove.length > 0) {
        thunkAPI.dispatch(removeMaturityChangeBulk(checks.maturity.remove))
    }
    if (checks.maturity.modification.length > 0) {
        thunkAPI.dispatch(updateMaturityChanges(checks.maturity.modification))
    }
    if (checks.sellRepay.remove.length > 0) {
        thunkAPI.dispatch(removeSellRepayBulk(checks.sellRepay.remove))
    }

    if (checks.transfer.remove.length > 0) {
        thunkAPI.dispatch(removeTransferBulk(checks.transfer.remove))
    }

    if (checks.wipCheck.remove.length > 0) {
        thunkAPI.dispatch(removeNewLoanBulk(checks.wipCheck.remove));
    }

    if (checks.wipCheck.modification.length > 0) {
        thunkAPI.dispatch(bulkUpdateChanges(checks.wipCheck.modification));
    }

    if (checks.refinance.remove.length > 0) {
        thunkAPI.dispatch(bulkRemoveRefinance(checks.refinance.remove));
    }

    if (checks.wipComment.remove.length > 0) {
        thunkAPI.dispatch(bulkRemoveWIPComments(checks.wipComment.remove));
    }

    console.log(new Date().getTime() - date.getTime())
    return checks;
})