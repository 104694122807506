import { memo, MouseEvent } from 'react';
// Local Import
import { EditableTableColumn } from ".";
import { Order } from '../../types/GeneralTypes';
// MUI
import {
    Box,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from "@mui/material"
import { visuallyHidden } from '@mui/utils';

// ----------------------------------------------------------------------
// Props

interface TableHeaderProps {
    onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
    columns: Array<EditableTableColumn>;
    selectable: boolean;
    removeOpt: boolean;
}

// ----------------------------------------------------------------------

// Editable Table Header
function EditableTableHead(props: TableHeaderProps) {
    const { order, orderBy, onRequestSort, columns, selectable, removeOpt } = props

    const createSortHandler =
        (property: string) => (event: MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {selectable &&
                    <TableCell
                        sx={{
                            position: "sticky",
                            top: 0,
                            bgcolor: 'primary.main',
                            border: '1px',
                            zIndex: 1,
                            p: 0,
                            width: 50
                        }}
                    />
                }
                {columns.map((column, d) => (
                    <TableCell
                        key={column.label + d}
                        align={column.align ? column.align : 'center'}
                        sortDirection={orderBy === column.id ? order : false}
                        sx={{
                            position: "sticky",
                            top: 0,
                            bgcolor: 'primary.main',
                            color: 'common.white',
                            border: '1px',
                            px: 1,
                            zIndex: 10,
                            "&:hover": {
                                bgcolor: 'secondary.light',
                                cursor: "pointer"
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'common.white'
                            },
                            ...column.headSX,
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler(column.id)}
                        >
                            <Typography sx={{ color: 'common.white', fontWeight: 'bold' }}>{column.label}</Typography>

                            {orderBy === column.id ? (
                                <Box
                                    component="span"
                                    sx={{
                                        ...visuallyHidden,
                                    }}
                                >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {removeOpt &&
                    <TableCell
                        sx={{
                            position: "sticky",
                            top: 0,
                            bgcolor: 'primary.main',
                            border: '1px',
                            zIndex: 10,
                            p: 0,
                        }}
                    />
                }
            </TableRow>
        </TableHead>
    )
}

export default memo(EditableTableHead);