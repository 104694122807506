// Local Imports
import {SearchFieldProps} from "../../../types/InputTypes";
// MUI
import {styled} from "@mui/material/styles";
import {
    Autocomplete,
    Grid, IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
    Theme,
    Tooltip
} from "@mui/material";
import {
    HelpOutline as HelpOutlineIcon,
    Error as ErrorIcon
} from "@mui/icons-material"

// Custom styling
const GridStyle = styled(Grid)(({theme}: { theme: Theme }) => ({
    padding: theme.spacing(1),
    width: '100%'
}))


export default function SearchField(props: SearchFieldProps) {
    const {
        id,
        label,
        placeholder,
        info = null,
        helperText,
        error,
        disabled = false,
        handleBlur,
        handleChange,
        layout = {xs: 12, md: 12, lg: 12},
        size,
        variant,
        noUnderline,
        errorInIcon,

        labelFunc,

        value,
        values
    } = props;

    const defaultProps: TextFieldProps = {
        id,
        label,
        placeholder,
        error,
        disabled,
        value,
        variant,
        onBlur: handleBlur,
        size
    }

    if (!errorInIcon) {
        defaultProps.helperText = helperText;
    }

    let adornments = (<></>)

    // Insert Info Tooltip if necessary
    if (info) {
        adornments = (
            <Tooltip title={info} placement='right'>
                <IconButton edge="end" size={size}>
                    <HelpOutlineIcon/>
                </IconButton>
            </Tooltip>
        )
    }

    // If error display in Icon
    if (errorInIcon && error) {
        adornments = (
            <Tooltip title={helperText} placement='right'>
                <IconButton edge="end" sx={{pl: 0}} size={size}>
                    <ErrorIcon sx={{color: 'red'}}/>
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <GridStyle
            item
            {...layout}
        >
            <Autocomplete
                value={value}
                disablePortal
                options={values}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={labelFunc}
                onChange={(event: any, value: any) => handleChange(value, id)}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {labelFunc(option)}
                    </li>
                )}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        InputProps={{
                            ...((variant === 'standard' && noUnderline) ? {disableUnderline: true} : {}),
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {adornments}
                                    {params.InputProps.endAdornment}
                                </InputAdornment>
                            ),
                        }}
                        {...defaultProps}
                    />
                }
            />
        </GridStyle>
    )
}