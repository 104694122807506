import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {SaveStatus} from "../../../types/capitalBudgetEnums";

// Retrieves all Selldowns and Repayments
export const retrieveSelldownRepayments = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.portfolio?.portfolio || [],
    (state: RootState) => state.capitalBudget.forecastData?.selldownRepayments || [],
    (state: RootState) => state.capitalBudget.warnings?.sellRepay.advise || [],
    (state: RootState) => state.capitalBudget.warnings?.sellRepay.alert || [],
    (portfolio, selldownRepayments, checkAdvise, checkAlert) => {
        return selldownRepayments.reduce((sellRepays: Array<any>, sr) => {
            if (sr.status === SaveStatus.REMOVED) return sellRepays;
            const loan = portfolio.find(p => (p.tranche_id === sr.trancheId && p.fund === sr.fund));

            if (loan) {
                const alert = checkAlert.find(c => c.id === sr.id);
                const advise = checkAdvise.find(c => c.id === sr.id);

                sellRepays.push({
                    ...sr,
                    borrower: loan.borrower,
                    tranche: loan.tranche,
                    commitment: loan.commitment,
                    ...(advise) ? {advise: advise.flag} : {},
                    ...(alert) ? {alert: alert.flag} : {},
                })
            }

            return sellRepays;
        }, [])
    }
)