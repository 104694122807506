import {useState, MouseEvent} from 'react';
import * as Yup from 'yup';
import {NewLoansColumns, WIPcolumns} from "./WIpTableColumns";
import {WIPLoan} from "../../../types/externalDataTypes";
import {formatDate} from "../../../utils/DateUtils";
// MUI
import {GridCellParams} from "@mui/x-data-grid";
import {
    Grid,
    Container,
    IconButton,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Box,
    FormControlLabel, Checkbox
} from '@mui/material';
import {
    Edit as EditIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon
} from "@mui/icons-material";
// Components
import {Page} from "../../../layouts/components";
import ForecastConfigBar from "../ForecastConfigBar";
import {
    actionRow, AlertDialog,
    DataTable,
    EditableTable,
    EditableTableRow, SideDrawer,
} from "../../../components";
import WIPDataDisplay from "../../../sections/Budget/Configurations/WIPDataDisplay";
// Store
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {retrieveAllWIPLoans, retrieveNewLoans} from "../../../store/capitalBudget/selectors/wipSelector";
import {
    addNewLoan,
    addNewManualLoan, deleteManualDeal,
    removeNewLoan, updateManualDeal,
    updateNewLoan
} from "../../../store/capitalBudget/capitalBudgetSlice";
import ManualLoanForm from "../../../sections/Budget/Configurations/ManualLoanForm";
import {manualDealsSelector} from "../../../store/capitalBudget/selectors/manualDealSelector";
import {ManualDeal} from "../../../types/forecastTypes";
import ManualLoanDataDisplay from "../../../sections/Budget/Configurations/ManualLoanDataDisplay";
import clsx from "clsx";
import {editableTableCustomColoring} from "./DataTableWarningsUtilts";

// Capital Budget Summary App Page
export default function WIP() {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.forecast.loading);
    const wip = useAppSelector(state => retrieveAllWIPLoans(state));
    const newLoans = useAppSelector(state => retrieveNewLoans(state));
    const manualLoans = useAppSelector(state => manualDealsSelector(state));

    const [createManual, setCreateManual] = useState<any>(null)
    const [showSelected, setShow] = useState(false);
    const [selectedLoan, setSelected] = useState<WIPLoan | null>(null);
    const [selectedManualLoan, setSelectedManualLoan] = useState<ManualDeal | null>(null);
    const [remove, setRemove] = useState<number | string | null>(null); // set for removal
    const [removeManual, setRemoveManual] = useState<number | string | null>(null); // set for removal

    const [shownTables, setTables] = useState<Array<'newLoans' | 'wip'>>(['newLoans', 'wip']);

    const [showColumns, setColumns] = useState<boolean>(false);

    const handleShowTables = (event: MouseEvent<HTMLElement>, tables: Array<'newLoans' | 'wip'>) => {
        if (tables.length === 0) {
            setTables(['newLoans', 'wip'])
        } else {
            setTables(tables);
        }
    }

    // HANDLES Selection of loan to show data
    const handleSelectNewLoan = (row: EditableTableRow | null) => {
        if (!row) {
            setSelected(null);
            setSelectedManualLoan(null)
        }
        if (row?.manual) {
            const loan = manualLoans.find(l => l.id === row?.manual);
            setSelectedManualLoan(loan as any)
        } else {
            const loan = wip.find(l => l.ncino_id === row?.ncino_id);
            setSelected(loan as any)
        }
    }

    const handleRemoveLoan = (id: number) => {
        const ncino = newLoans.find(nl => nl.id === id);
        if (ncino) {
            if (ncino.manual) {
                setRemoveManual(ncino.manual)
            } else {
                setRemove(ncino.ncino_id)
            }
        }
    }

    // Validation Schema
    const NewLoanSchema = Yup.object().shape({
        amendedCommitment: Yup.number()
            .min(0)
            .nullable(),
        amendedDrawdown: Yup.number()
            .test(
                'Commitment Check', 'Drawdown must not exceed commitment',
                (value, context) => {
                    const commitment = (context.parent.amendedCommitment) ? context.parent.amendedCommitment : context.parent.commitment;
                    if (value) {
                        return value <= commitment;
                    }
                    return true;
                }
            )
            .min(0)
            .nullable(),
        amendedTenor: Yup.number()
            .min(0)
            .nullable(),
    })

    // Handle EditManualLoan Request
    const editManualLoan = (row: any) => {
        const loan = manualLoans.find(l => row.manual === l.id);
        if (loan) setCreateManual(loan);
    }

    return (
        <>
            <Page title="Metrics - CB - WIP">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Portfolio - WIP' configuration/>
                        </Grid>
                        <Grid item sx={{display: 'flex', justifyContent: 'center'}} >
                            <ToggleButtonGroup
                                value={shownTables}
                                onChange={handleShowTables}
                                color='primary'
                            >
                                <ToggleButton value={'newLoans'} sx={{py: 0.5}}>New Loans</ToggleButton>
                                <ToggleButton value={'wip'} sx={{py: 0.5}}>WIP Pipeline</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        {(shownTables.includes('newLoans')) &&
                            <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                                <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                    <EditableTable
                                        title='New Loans'
                                        columns={[
                                            ...NewLoansColumns.filter(column => showColumns || !column.original),
                                            {
                                                id: 'manual',
                                                label: '',
                                                align: 'left',
                                                headSX: {
                                                    position: 'sticky',
                                                    left: 0,
                                                    top: 0,
                                                },
                                                sx: {
                                                    position: 'sticky',
                                                    left: 0,
                                                    bgcolor: 'white'
                                                },
                                                formatter: (value: number, row) => {
                                                    if (value) {
                                                        return (<>
                                                            <IconButton sx={{p: 0}}
                                                                        onClick={() => editManualLoan(row)}
                                                            >
                                                                <EditIcon color='primary'/>
                                                            </IconButton>
                                                        </>)
                                                    } else {
                                                        return <></>
                                                    }
                                                }

                                            },
                                        ]}
                                        data={newLoans}

                                        defaultSort='adjustedClose'
                                        defaultOrder='asc'

                                        loading={loading}

                                        handleRemove={(value) => handleRemoveLoan(value as number)}
                                        handleRowEdit={(values) => {
                                            dispatch(updateNewLoan({
                                                id: values.id,
                                                amendedCloseDate: (values.amendedCloseDate) ? formatDate(values.amendedCloseDate, 'yyyy-MM-dd') : null,
                                                amendedCommitment: values.amendedCommitment || null,
                                                amendedTenor: (values.amendedTenor === '') ? null : values.amendedTenor,
                                                amendedDrawdown: values.amendedDrawdown || null
                                            }))
                                        }}

                                        rowSelect={handleSelectNewLoan}

                                        validationSchema={NewLoanSchema}

                                        create={() => setCreateManual(true)}

                                        search
                                        totalRow

                                        customFilter={
                                            <>
                                                <Box sx={{px: 1}}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={showColumns} onChange={() => setColumns(!showColumns)} color={'primary'}/>}
                                                        label='Show Previous Values:'
                                                        labelPlacement='start'
                                                    />
                                                </Box>
                                            </>
                                        }

                                        customColorFn={editableTableCustomColoring}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {(shownTables.includes('wip')) &&
                            <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                                <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                    <DataTable
                                        title='WIP Transactions'
                                        columns={[
                                            {
                                                ...actionRow,
                                                field: ' ',

                                                width: 50,
                                                renderCell: (params: GridCellParams) => (
                                                    <>
                                                        <IconButton
                                                            style={{padding: '0px', outline: 'none'}}
                                                            onClick={() => handleSelectNewLoan(params.row)}
                                                            size="small">
                                                            <KeyboardArrowRightIcon/>
                                                        </IconButton>
                                                    </>
                                                ),
                                                cellClassName: (params: GridCellParams) => {
                                                    return clsx('', {
                                                        approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
                                                    });
                                                }
                                            },
                                            ...WIPcolumns,
                                            {
                                                ...actionRow,
                                                width: 120,
                                                renderCell: (params: GridCellParams) => (
                                                    <>
                                                        {params.row.selected ?
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => {
                                                                    setRemove(params.row.ncino_id)
                                                                }}
                                                            >
                                                                Remove Loan
                                                            </Button>
                                                            :
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => {
                                                                    dispatch(addNewLoan(params.row.ncino_id))
                                                                }}
                                                            >
                                                                Add Loan
                                                            </Button>
                                                        }
                                                    </>
                                                ),
                                                cellClassName: (params: GridCellParams) => {
                                                    return clsx('', {
                                                        approved: (params.row.llc_bi_stage === 'Approval / Loan Committee'),
                                                    });
                                                }
                                            }]}
                                        data={wip.filter(w => showSelected || !w.selected)}
                                        loading={loading}
                                        showExport={false}
                                        sort={{field: 'name', sort: 'asc'}}
                                        search
                                        customFilter={{
                                            label: 'Show Only Unselected',
                                            active: !showSelected,
                                            setFilter: () => setShow(!showSelected)
                                        }}
                                        additionalDataGridSx={{
                                            '& .approved': {
                                                bgcolor: 'info.lighter'
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Container>
            </Page>
            {selectedLoan &&
                <SideDrawer
                    title='Details'
                    open={!!selectedLoan}
                    onClose={() => setSelected(null)}
                >
                    <WIPDataDisplay
                        loan={selectedLoan}
                        onClose={() => setSelected(null)}
                    />
                </SideDrawer>
            }
            {selectedManualLoan &&
                <SideDrawer
                    title='Details'
                    open={!!selectedManualLoan}
                    onClose={() => setSelectedManualLoan(null)}
                >
                    <ManualLoanDataDisplay
                        loan={selectedManualLoan}
                        onClose={() => setSelectedManualLoan(null)}
                    />
                </SideDrawer>
            }
            {createManual &&
                <SideDrawer
                    title='Create Manual Loan'
                    open={!!createManual}
                    onClose={() => setCreateManual(null)}
                    size='large'
                >
                    <ManualLoanForm
                        editValues={createManual}
                        onClose={() => setCreateManual(null)}
                        submitLoan={(values) => {
                            dispatch(addNewManualLoan(values))
                            setCreateManual(null)
                        }}
                        editLoan={(values) => {
                            dispatch(updateManualDeal(values));
                            setCreateManual(null)
                        }}
                    />
                </SideDrawer>
            }
            <AlertDialog
                title="Delete New Deal"
                info='Are you sure you want to delete this Deal? This will remove any attached allocations.'
                open={!!remove}
                handleClose={() => setRemove(null)}
                handleConfirm={() => {
                    if (!!remove) dispatch(removeNewLoan(remove))
                    setRemove(null);
                }}
            />
            <AlertDialog
                title="Delete Manual Deal and Allocationas"
                info='Are you sure you want to delete this manual Deal? This will also remove any attached allocations.'
                open={!!removeManual}
                handleClose={() => setRemoveManual(null)}
                handleConfirm={() => {
                    if (!!removeManual) dispatch(deleteManualDeal(removeManual))
                    setRemoveManual(null);
                }}
            />
        </>
    )
}
