import {Helmet} from 'react-helmet-async';
import {forwardRef} from 'react';
// @mui
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({children, title = '', meta, ...other}: { children: any, title: string, meta?: any }, ref) => (
    <>
        <Helmet>
            <title>{`${title}`}</title>
            {meta}
        </Helmet>

        <Box ref={ref} {...other}
             sx={{
                 padding: 0,
                 overflow: 'none',
                 flex: 1,
                 display: 'flex',
                 flexDirection: 'column',
                 alignItems: 'center',
                 width: '100vh',
                 height: '100%',
                 overflowX: 'visible'
             }}
        >
            {children}
        </Box>
    </>
));

export default Page;
