import {useState} from 'react';
import clsx from "clsx";
import {warningDataGridSx, warningsGeneralRow, warningValueCurrency, warningValueDate} from "./DataTableWarningsUtilts";
import {OtherTransactionsTypeInputLabels, SaveStatus} from "../../../types/capitalBudgetEnums";
// MUI
import {Grid, Container, IconButton} from '@mui/material';
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
} from "@mui/icons-material";
// Components
import {Page} from "../../../layouts/components";
import ForecastConfigBar from "../ForecastConfigBar";
import {
    actionRow, AlertDialog, DataTable,
    SideDrawer, WarningsIcon,
} from "../../../components";
// Store
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {retrieveAllOtherTransactions} from "../../../store/capitalBudget/selectors/otherTransactionSelectors";
import OtherTransactionsForm from "../../../sections/Budget/Configurations/OtherTransactionsForm";
import {
    addOtherTransaction,
    removeOtherTransactions,
    updateOtherTransactions
} from "../../../store/capitalBudget/capitalBudgetSlice";


export const warningCellClassName = (params: GridCellParams) => {
    return clsx('status', 'warning', {
        new: (params.row.status === SaveStatus.NEW),
        edited: (params.row.status === SaveStatus.EDITED),
        advise: (!!params.row.advise),
        alert: (!!params.row.alert)
    });
};

// ----------------------------------------------------------------------
// Column definitions
let columns: GridColDef[] = [
    {
        field: 'advise',
        headerName: '',
        align: 'center',
        ...warningsGeneralRow,
        width: 50,
        renderCell: (params: GridCellParams) => (
            <WarningsIcon value={params.value} />
        ),
    },
    {
        field: 'date',
        headerName: 'Date',
        ...warningValueDate,
        width: 120,
    },
    {
        field: 'transactionType',
        headerName: 'Transaction Type',
        ...warningsGeneralRow,
        valueFormatter: (params) => {
            return OtherTransactionsTypeInputLabels.find(t => t.id === params.value)?.label || params.value
        },
        flex: 0.5,
    },
    {
        field: 'fund',
        headerName: 'Fund',
        ...warningsGeneralRow,
    },
    {
        ...warningValueCurrency,
        field: 'amount',
        headerName: 'Amount',
        minWidth: 150,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        ...warningsGeneralRow,
        minWidth: 300,
        flex: 1
    },
    {
        field: 'cash',
        headerName: 'Cash',
        ...warningsGeneralRow,
        width: 100,
        valueFormatter: (params) => (params.value ? 'Yes' : 'No')
    },
    {
        field: 'capital',
        headerName: 'Capital',
        ...warningsGeneralRow,
        width: 100,
        valueFormatter: (params) => (params.value ? 'Yes' : 'No')
    },
]
// ----------------------------------------------------------------------

// Capital Budget Summary App Page
export default function OtherTransactions() {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.forecast.loading);
    const otherTransactions = useAppSelector(state => retrieveAllOtherTransactions(state));

    // Create and Update capital
    const [form, setFormOpen] = useState<any | null>(null);

    // Sends an editable version on Other Transactions to update form
    const handleEditTransaction = (row: number) => {
        const transaction = otherTransactions.find(ct => ct.id === row);
        if (transaction) {
            setFormOpen(transaction)
        }
    }

    // Remove Capital
    const [openDelete, setOpenDelete] = useState<number | null>(null);

    return (
        <>
            <Page title="Metrics - CB - Other Transactions">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Other Transactions' configuration/>
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                <DataTable
                                    title='Other Transactions'
                                    data={otherTransactions}
                                    loading={loading}
                                    sort={{field: 'date', sort: 'asc'}}
                                    columns={[
                                        ...columns,
                                        {
                                            ...actionRow,
                                            renderCell: (params: GridCellParams) => (
                                                <>
                                                    <IconButton
                                                        sx={{color: 'primary.main'}}
                                                        style={{padding: '0px', outline: 'none'}}
                                                        onClick={() => handleEditTransaction(params.row.id)}
                                                        size="small">
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{color: 'primary.main'}}
                                                        style={{padding: '0px', outline: 'none'}}
                                                        onClick={() => setOpenDelete(params.row.id)}
                                                        size="small">
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </>
                                            ),
                                            cellClassName: warningCellClassName
                                        }
                                    ]}
                                    search
                                    create={() => setFormOpen(true)}
                                    showExport={false}
                                    additionalDataGridSx={warningDataGridSx}
                                />
                            </Grid>s
                        </Grid>
                    </Grid>
                </Container>
            </Page>
            <SideDrawer
                title='Create Transaction'
                open={(!!form)}
                onClose={() => setFormOpen(null)}
                size={'large'}
            >
                <>
                    <OtherTransactionsForm
                        editValues={form}
                        onClose={() => setFormOpen(null)}
                        submitTransaction={(values) => {
                            dispatch(addOtherTransaction(values));
                            setFormOpen(null);
                        }}
                        editTransaction={(values) => {
                            dispatch(updateOtherTransactions(values))
                            setFormOpen(null);
                        }}
                    />
                </>
            </SideDrawer>
            <AlertDialog
                title='Delete Transaction'
                open={!!openDelete}
                handleClose={() => setOpenDelete(null)}
                handleConfirm={() => {
                    dispatch(removeOtherTransactions(openDelete))
                    setOpenDelete(null)
                }}
            />
        </>
    )
}