import {memo} from "react";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";

import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {Box} from "@mui/material";
import DrillDownSimple from "../../../../components/DrillDownsSimple";
import {fPercent} from "../../../../utils/formatNumber";
import {addValues} from "../../../../utils/mathUtil";
import {LoanTags} from "../../../../types/capitalBudgetEnums";
import {DataTable, valueCurrency, valuePercentage} from "../../../../components";

interface AssetsDrillDownProps {
    period: ForecastPeriod,
    column: investmentReportColumn
}

function AssetsDrillDown({period, column}: AssetsDrillDownProps) {

    const assets = groupBookByAsset(period.book);

    return (
        <>
            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', minHeight: 800}}>
                <Box>
                    <DrillDownSimple
                        data={[
                            {
                                label: 'No. of Assets',
                                value: column.assets,
                                formatter: (value: number) => value.toString()
                            },
                            {
                                label: 'Average Asset Value',
                                value: column.avgSize,
                            },
                            {
                                label: 'Largest Exposure ($)',
                                value: column.largestExposure,
                            },
                            {
                                label: 'Largest Exposure (%)',
                                value: column.largestExposurePer,
                                formatter: fPercent
                            },
                            {
                                label: 'Top Ten Exposure ($)',
                                value: column.topTenExposure,
                            },
                            {
                                label: 'Top Ten Exposure (%)',
                                value: column.topTenExposurePer,
                                formatter: fPercent
                            },
                        ]}
                    />
                </Box>
                <Box sx={{py: 1, flex: 1, minHeight: 200}}>
                    <Box sx={{height: '99%'}}>
                        <DataTable
                            title={'Assets'}
                            columns={[
                                {field: 'asset', headerName: 'Asset', flex: 1, minWidth: 150},
                                {field: 'borrower', headerName: 'Borrower', flex: 1, minWidth: 150},
                                {
                                    field: 'valuePer',
                                    headerName: 'Percentage',
                                    width: 100, ...valuePercentage,
                                    valueGetter: (params) => params.row.value / column.totalCommitment
                                },
                                {field: 'value', headerName: 'Commitment', width: 200, ...valueCurrency},
                            ]}
                            data={assets}
                            loading={false}
                            showExport={false}
                            sort={{field: 'value', sort: 'desc'}}
                            search
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default memo(AssetsDrillDown)

function groupBookByAsset(book: Array<CalculationLoanType>) {
    const assets = book.reduce((assets, loan) => {
        if (loan.tags.includes(LoanTags.ACTIVE)) {
            if (!assets[loan.asset]) {
                assets[loan.asset] = {
                    id: loan.asset,
                    value: loan.updatedValue,
                    borrower: loan.name,
                    asset: loan.assetName,
                }
            } else {
                assets[loan.asset].value = addValues(assets[loan.asset].value, loan.updatedValue)
            }
        }
        return assets
    }, {} as { [key: string]: { id: number | string, value: number, borrower: string, asset: string } });

    return Object.values(assets)
}


