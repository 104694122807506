import {Period} from "../../../types/GeneralTypes";
import {OtherTransactionTypes, PeriodType} from "../../../types/capitalBudgetEnums";
import {CalculationLoanType, ForecastPeriod} from "../../../types/capitalBudgetTypes";
import {Capital, OtherTransaction} from "../../../types/forecastTypes";
import {checkInPeriod} from "../../../utils/DateUtils";
import handlePortfolioLoan from "./handlePortfolioLoan";
import handleNewLoan from "./handleNewLoan";
import {FundFinanceDetails} from "../selectors/fundFinanceSelector";

/**
 * Handles Portfolio for set period
 * @param period
 * @param periodType
 * @param periodMultiplier
 * @param budgetDate
 * @param portfolio
 * @param newLoans
 * @param capital
 * @param otherTransactions
 * @param fundFinances
 * @param base
 */
function calculatePeriod(period: Period, periodType: PeriodType, periodMultiplier: number, budgetDate: number | Date, portfolio: Array<CalculationLoanType>, newLoans: Array<CalculationLoanType>, capital: Array<Capital>, otherTransactions: Array<OtherTransaction>, fundFinances: Array<FundFinanceDetails>, base: boolean = false) {
    let results: ForecastPeriod = {
        book: [],
        capital: [],
        otherTransactions: [],
        fundFinances: fundFinances,
        ...period
    }

    const periodTypeMulti = (periodType === PeriodType.WEEK) ? 52 : 12;
    const ctcWeekBuffer = 10; // Buffer used for CTC Drawdown calculations

    // handle portfolio
    portfolio.forEach(p => {
        results.book.push(handlePortfolioLoan(p, budgetDate, period, periodType, periodMultiplier, ctcWeekBuffer, periodTypeMulti, base));
    })

    // console.log(`${periodType}-${periodMultiplier} ${formatDate(period.startDate)} - ${formatDate(period.lastDate)} BOOK - 18595`, results.book.filter(p => p.trancheId === 18595));
    // console.log(`${periodType}-${periodMultiplier} ${formatDate(period.startDate)} - ${formatDate(period.lastDate)}`);

    if (!base) {
        newLoans.forEach(n => {
            results.book.push(handleNewLoan(n, budgetDate, period, periodType, periodMultiplier, ctcWeekBuffer, periodTypeMulti));
        })

        capital.forEach(c => {
            if (checkInPeriod(c.date, period)) {
                results.capital.push(c);
            }
        });

        otherTransactions.forEach(t => {
            if (checkInPeriod(t.date, period)) {
                results.otherTransactions.push(t);
            }
        })
    }

    otherTransactions.forEach(t => {
        if (t.transactionType === OtherTransactionTypes.FUM_ADJUSTMENT) {
            if (checkInPeriod(t.date, period)) {
                results.otherTransactions.push(t);
            }
        }
    })

    return results;
}

export default calculatePeriod;