import {configureStore} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import capitalBudgetReducer from './capitalBudget/capitalBudgetSlice';
import externalDataReducer from './externalData/externalDataSlice';
import forecastDataReducer from './forecast/forecastSlice';
import generalReducer from './general/generalSlice';
import notificationReducer from './notifications/notificationSlice';
import userReducer from './user/userSlice';

export const store = configureStore({
    reducer: {
        capitalBudget: capitalBudgetReducer,
        externalData: externalDataReducer,
        forecast: forecastDataReducer,
        general: generalReducer,
        notifications: notificationReducer,
        user: userReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector