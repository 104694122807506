
// This describes GICS industries as Sector -> Industry Group -> Industry -> Sub-Industry
export const spIndustries: {[x: string]: {[x: string]: {[x: string]: Array<string>}}} = {
    "Energy": {
        "Energy": {
            "Energy Equipment & Services": [
                "Oil & Gas Drilling",
                "Oil & Gas Equipment & Services",
            ],
            "Oil, Gas & Consumable Fuels": [
                "Integrated Oil & Gas",
                "Oil & Gas Exploration & Production",
                "Oil & Gas Refining & Marketing",
                "Oil & Gas Storage & Transportation",
                "Coal & Consumable Fuels"
            ]
        }
    },
    "Materials": {
        "Materials": {
            "Chemicals": [
                "Commodity Chemicals",
                "Diversified Chemicals",
                "Fertilizers & Agricultural Chemicals",
                "Industrial Gases",
                "Specialty Chemicals"
            ],
            "Construction Materials": [
                "Construction Materials"
            ],
            "Containers & Packaging": [
                "Metal & Glass Containers",
                "Paper Packaging"
            ],
            "Metals & Mining": [
                "Aluminum",
                "Diversified Metals & Mining",
                "Copper",
                "Gold",
                "Precious Metals & Minerals",
                "Silver",
                "Steel",
            ],
            "Paper & Forest Products": [
                "Forest Products",
                "Paper Products"
            ],
        }
    },
    "Industrials": {
        "Capital Goods": {
            "Aerospace & Defense": [
                "Aerospace & Defense"
            ],
            "Building Products": [
                "Building Products"
            ],
            "Construction & Engineering": [
                "Construction & Engineering"
            ],
            "Electrical Equipment": [
                "Electrical Components & Equipment",
                "Heavy Electrical Equipment",
            ],
            "Industrial Conglomerates": [
                "Industrial Conglomerates"
            ],
            "Machinery": [
                "Construction Machinery & Heavy Trucks",
                "Agricultural & Farm Machinery",
                "Industrial Machinery",
            ],
            "Trading Companies & Distributors": [
                "Trading Companies & Distributors"
            ],
        },
        "Commercial & Professional Services": {
            "Commercial Services & Supplies": [
                "Commercial Printing",
                "Environmental & Facilities Services",
                "Office Services & Supplies",
                "Diversified Support Services",
                "Security & Alarm Services"
            ],
            "Professional Services": [
                "Human Resource & Employment Services",
                "Research & Consulting Services",
            ],
        },
        "Transportation": {
            "Air Freight & Logistics": [
                "Air Freight & Logistics"
            ],
            "Airlines": [
                "Airlines"
            ],
            "Marine": [
                "Marine"
            ],
            "Road & Rail": [
                "Railroads",
                "Trucking",
            ],
            "Transportation Infrastructure": [
                "Airport Services",
                "Highways & Railtracks",
                "Marine Ports & Services"
            ],
        }
    },
    "Consumer Discretionary": {
        "Automobiles & Components": {
            "Automobile Components": [
                "Auto Parts & Equipment",
                "Tires & Rubber",
            ],
            "Automobiles": [
                "Automobile Manufacturers",
                "Motorcycle Manufacturers"
            ]
        },
        "Consumer Durables & Apparel": {
            "Household Durables": [
                "Consumer Electronics",
                "Home Furnishings",
                "Homebuilding",
                "Household Appliances",
                "Housewares & Specialties",
            ],
            "Leisure Products": [
                "Leisure Products",
            ],
            "Textiles, Apparel & Luxury Goods": [
                "Apparel, Accessories & Luxury Goods",
                "Footwear",
                "Textiles",
            ]
        },
        "Consumer Services": {
            "Hotels, Restaurants & Leisure": [
                "Casinos & Gaming",
                "Hotels, Resorts & Cruise Lines",
                "Leisure Facilities",
                "Restaurants",
            ],
            "Diversified Consumer Services": [
                "Education Services",
                "Specialized Consumer Services",
            ],
        },
        "Retailing": {
            "Distributors": [
                "Distributors",
            ],
            "Internet & Direct Marketing Retail": [
                "Internet & Direct Marketing Retail",
            ],
            "Multiline Retail": [
                "Department Stores",
                "General Merchandise Stores",
                "Specialty Stores",
            ],
            "Specialty Retail": [
                "Apparel Retail",
                "Computer & Electronics Retail",
                "Home Improvement Retail",
                "Specialty Stores",
                "Automotive Retail",
                "Homefurnishing Retail",
            ]
        }
    },
    "Consumer Staples": {
        "Food & Staples Retailing": {
            "Food & Staples Retailing": [
                "Drug Retail",
                "Food Distributors",
                "Food Retail",
                "Hypermarkets & Super Centers",
            ]
        },
        "Food, Beverage & Tobacco": {
            "Beverages": [
                "Brewers",
                "Distillers & Vintners",
                "Soft Drinks",
            ],
            "Food Products": [
                "Agricultural Products",
                "Packaged Foods & Meats",
            ],
            "Tobacco": [
                "Tobacco",
            ]
        },
        "Household & Personal Products": {
            "Household Products": [
                "Household Products",
            ],
            "Personal Products": [
                "Personal Products",
            ]
        }
    },
    "Health Care": {
        "Health Care Equipment & Services": {
            "Health Care Equipment & Supplies": [
                "Health Care Equipment",
                "Health Care Supplies",
            ],
            "Health Care Providers & Services": [
                "Health Care Distributors",
                "Health Care Facilities",
                "Health Care Services",
            ],
            "Health Care Technology": [
                "Health Care Technology",
            ]
        },
        "Pharmaceuticals, Biotechnology & Life Sciences": {
            "Biotechnology": [
                "Biotechnology",
            ],
            "Pharmaceuticals": [
                "Pharmaceuticals",
            ],
            "Life Sciences Tools & Services": [
                "Life Sciences Tools & Services",
            ]
        }
    },
    "Financials": {
        "Banks": {
            "Banks": [
                "Diversified Banks",
                "Regional Banks",
            ],
            "Thrifts & Mortgage Finance": [
                "Thrifts & Mortgage Finance",
            ],
        },
        "Diversified Financials": {
            "Diversified Financial Services": [
                "Other Diversified Financial Services",
                "Multi-Sector Holdings",
                "Specialized Finance",
            ],
            "Consumer Finance": [
                "Consumer Finance",
            ],
            "Capital Markets": [
                "Asset Management & Custody Banks",
                "Investment Banking & Brokerage",
                "Diversified Capital Markets",
                "Financial Exchanges & Data",
            ],
            "Mortgage Real Estate Investment Trusts (REITs)": [
                "Mortgage REITs"
            ]
        },
        "Insurance": {
            "Insurance": [
                "Insurance Brokers",
                "Life & Health Insurance",
                "Multi-line Insurance",
                "Property & Casualty Insurance",
                "Reinsurance",
            ]
        }
    },
    "Information Technology": {
        "Software & Services": {
            "IT Services": [
                "IT Consulting & Other Services",
                "Data Processing & Outsourced Services",
                "Internet Services & Infrastructure",
            ],
            "Software": [
                "Application Software",
                "Systems Software",
            ],
            "Internet Software & Services": [] // OLD GICS INDUSTRY
        },
        "Technology Hardware & Equipment": {
            "Communications Equipment": [
                "Communications Equipment",
            ],
            "Technology Hardware, Storage & Peripherals": [
                "Technology Hardware, Storage & Peripherals"
            ],
            "Electronic Equipment, Instruments & Components": [
                "Electronic Equipment, Instruments",
                "Electronic Components",
                "Electronic Manufacturing Services",
                "Technology Distributors"
            ],
        },
        "Semiconductors & Semiconductor Equipment": {
            "Semiconductors & Semiconductor Equipment": [
                "Semiconductor Equipment",
                "Semiconductors",
            ]
        }
    },
    "Telecommunication Services": {
        "Telecommunication Services": {
            "Diversified Telecommunication Services": [
                "Alternative Carriers",
                "Integrated Telecommunication Services",
            ],
            "Wireless Telecommunication Services": [
                "Wireless Telecommunication Services",
            ]
        },
        "Media & Entertainment": {
            "Media": [
                "Advertising",
                "Broadcasting",
                "Cable & Satellite",
                "Publishing",
            ],
            "Entertainment": [
                "Movies & Entertainment",
                "Interactive Home Entertainment",
            ],
            "Interactive Media & Services": [
                "Interactive Media & Services",
            ]
        }
    },
    "Utilities": {
        "Utilities": {
            "Electric Utilities": [
                "Electric Utilities",
            ],
            "Gas Utilities": [
                "Gas Utilities",
            ],
            "Multi-Utilities": [
                "Multi-Utilities",
            ],
            "Water Utilities": [
                "Water Utilities",
            ],
            "Independent Power and Renewable": [
                "Independent Power Producers & Energy Traders",
                "Renewable Electricity",
            ]
        }
    },
    "Real Estate": {
        "Real Estate": {
            "Real Estate Investment Trusts (REITs)": [
                "Health Care REITs",
                "Hotel & Resort REITs",
                "Industrial REITs",
                "Office REITs",
                "Residential REITs",
                "Retail REITs",
                "Specialized REITs",
                "Diversified REITs",
            ],
            "Real Estate Management & Development": [
                "Diversified Real Estate Activities",
                "Real Estate Operating Companies",
                "Real Estate Development",
                "Real Estate Services",
            ]
        }
    }
};

// This is a list of all industries in the spIndustries object For Selection Array
export const spIndustriesSelection = Object.values(spIndustries).reduce((acc: Array<{
    label: string,
    value: string
}>, sector) => {
    Object.values(sector).forEach(industryGroup => {
        Object.keys(industryGroup).forEach(industry => {
            acc.push({label: industry, value: industry});
        });
    });
    return acc;
}, []).sort((a, b) => a.label.localeCompare(b.label));

// Find the GICS details from a subIndustry
export const spIndustryLookupFromSubIndustry = (subIndustry: string): {sector: string | null, industryGroup: string | null, industry: string | null} => {
    for (const [sectorName, sector] of Object.entries(spIndustries)) {
        for (const [industryGroupName, industryGroup] of Object.entries(sector)) {
            for (const [industryName, industry] of Object.entries(industryGroup)) {
                if (industry.includes(subIndustry)) {
                    return {
                        sector: sectorName,
                        industryGroup: industryGroupName,
                        industry: industryName
                    };
                }
            }
        }
    }
    return {
        sector: null,
        industryGroup: null,
        industry: null
    }
}

// Find the GICS details from an industry
export const spIndustryLookupFromIndustry = (industry: string): {sector: string | null, industryGroup: string | null, industry: string | null} => {
    for (const [sectorName, sector] of Object.entries(spIndustries)) {
        for (const [industryGroupName, industryGroup] of Object.entries(sector)) {
            for (const [industryName] of Object.entries(industryGroup)) {
                if (industry === industryName) {
                    return {
                        sector: sectorName,
                        industryGroup: industryGroupName,
                        industry
                    }
                }
            }
        }
    }
    return {
        sector: null,
        industryGroup: null,
        industry: null
    }
}