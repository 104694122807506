import {Formik, FormikProps} from "formik";
import * as Yup from 'yup';
// Store
import {useAppDispatch} from "../../../store/store";
import {Button, Grid} from "@mui/material";
import {FormInput} from "../../../components";
import {InputTypes} from "../../../types/InputTypes";
import {updateFUM} from "../../../store/capitalBudget/capitalBudgetSlice";

export default function UpdateFUM({balances, onClose}: {
    // balances: { funds: Array<{ label: string, fum: number }> },
    balances: any,
    onClose: () => void
}) {
    const dispatch = useAppDispatch();

    const FUMSchema: Record<string, Yup.NumberSchema> = {}
    const values: { [x: string]: number } = {};

    balances.forEach((balance: { label: string, fum: number }) => {
        FUMSchema[balance.label] = Yup.number().required();
        values[balance.label] = balance.fum;
    })

    return (
        <>
            <Formik
                initialValues={values}
                validationSchema={Yup.object().shape(FUMSchema)}
                onSubmit={(values: any) => {
                    dispatch(updateFUM(values));
                    onClose();
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit
                    } = props;

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{p: 2}}>
                                    {balances.map((fund: { label: string }) => (
                                        <FormInput
                                            id={fund.label}
                                            key={fund.label}
                                            label={fund.label}
                                            fieldType={InputTypes.CURRENCY}
                                            layout={{xs: 12, md: 12, lg: 12}}
                                        />
                                    ))}
                                    <Grid item container direction='row'>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width: '50%', p: 2}}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Confirm Balances
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}