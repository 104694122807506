import {createSelector} from "@reduxjs/toolkit";
import {addArrayValues, addValues} from "../../../../utils/mathUtil";
import {checkInPeriod} from "../../../../utils/DateUtils";
import {fCurrency} from "../../../../utils/formatNumber";
import {CellType} from "../../../../types/InputTypes";
import {ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {HorizontalTableColumn} from "../../../../components";
import {createFilteredPortfolioBook} from "../../../../utils/CapitalBudgetUtils";
import {CapitalAction, LoanTags, OtherTransactionTypes} from "../../../../types/capitalBudgetEnums";

import {RootState} from "../../../store";
import {FundDetails, fundHolderSelector, initialBalance} from "../generalSelectors";

const BudgetForecastRows = [
    {
        id: 'startingAvailableCap',
        label: 'Opening Available Capital',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY,
        sx: {bgcolor: 'info.light'}
    },
    {
        id: 'expectedRepayments',
        label: 'Contractual Loan Repayments',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'contractualPos',
        label: 'Contractual Surplus/Deficit',
        formatter: fCurrency,
        sx: {minWidth: 120, bgcolor: 'info.lighter'},
        type: CellType.CURRENCY
    },
    {
        id: 'newCommitments',
        label: 'New Loan Commitments',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'extensionOffset',
        label: 'Loan Extension - Contracted Maturity Offset',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'earlyRepayment',
        label: 'Early Loan Repayments',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {id: 'selldowns', label: 'Asset Sell Downs', formatter: fCurrency, sx: {minWidth: 120}, type: CellType.CURRENCY},
    {
        id: 'transfersOut',
        label: 'Interfund Transfers (Out)',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'transfersIn',
        label: 'Interfund Transfers (In)',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'adjustment',
        label: 'Other Adjustments',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'subscriptions',
        label: 'Forecasted Investor Subscriptions',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'redemptions',
        label: 'Forecasted Investor Redemptions',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'extension',
        label: 'Loan Extension - Repayment at New Maturity Date',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'earlyRepOffset',
        label: 'Early Loan Repayments - Contracted Maturity Offset',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'selldownOffset',
        label: 'Asset Sell Downs - Contracted Maturity Offset',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'transfersOutOffset',
        label: 'Interfund Transfers Offset (Out)',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'transfersInOffset',
        label: 'Interfund Transfers Offset (In)',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'debtFacilities',
        label: 'Debt Facilities',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'repaymentAdjustments',
        label: 'Contractual Loan Repayments - Adjustments',
        formatter: fCurrency,
        sx: {minWidth: 120, bgcolor: 'info.lighter'},
        type: CellType.CURRENCY
    },
    {
        id: 'forecastAdjustmentCompound',
        label: 'Adjust - Contractual to Forecast',
        formatter: fCurrency,
        sx: {minWidth: 120},
        type: CellType.CURRENCY
    },
    {
        id: 'closingAvailableCap',
        label: 'Surplus/Deficit Available Capital',
        formatter: fCurrency,
        sx: {minWidth: 120, bgcolor: 'info.light'},
        type: CellType.CURRENCY
    },
];

export interface fundForecastColumn extends HorizontalTableColumn {
    startingAvailableCap: number,
    expectedRepayments: number,
    contractualPos: number,
    newCommitments: number,
    extensionOffset: number,
    earlyRepayment: number,
    selldowns: number,
    transfersOut: number,
    transfersIn: number,
    adjustment: number,
    subscriptions: number,
    redemptions: number,
    extension: number,
    earlyRepOffset: number,
    selldownOffset: number,
    transfersOutOffset: number,
    transfersInOffset: number,
    debtFacilities: number,
    repaymentAdjustments: number,
    forecastAdjustment: number,
    forecastAdjustmentCompound: number,
    closingAvailableCap: number
}

export const fundForecastDataSelector = createSelector(
    (state: RootState) => state.capitalBudget.capitalBudget.budget,
    fundHolderSelector,
    initialBalance,
    (budget, fund, balance) => {
        if (!budget) return null

        const base = extractFundForecastPeriod(budget.base, balance.capitalAvailability, 0, fund);

        // Keeps Running Balance carried throughout each period
        let weeklyStartingCap = balance.capitalAvailability;
        let weeklyForecastAdjustmentCompound = 0;
        const weeks: Array<fundForecastColumn> = budget.weeks.map(w => {
            const result = extractFundForecastPeriod(w, weeklyStartingCap, weeklyForecastAdjustmentCompound, fund);
            weeklyForecastAdjustmentCompound = addValues(result.forecastAdjustment, weeklyForecastAdjustmentCompound);
            weeklyStartingCap = result.contractualPos;
            return result;
        })

        // Keeps Running Balance carried throughout each period
        let monthlyStartingCap = balance.capitalAvailability;
        let monthlyForecastAdjustmentCompound = 0;
        const months: Array<fundForecastColumn> = budget.months.map(m => {
            const result = extractFundForecastPeriod(m, monthlyStartingCap, monthlyForecastAdjustmentCompound, fund);
            monthlyForecastAdjustmentCompound = addValues(result.forecastAdjustment, monthlyForecastAdjustmentCompound);
            monthlyStartingCap = result.contractualPos;
            return result;
        })

        return {
            base,
            weeks,
            months
        }
    }
)

export const fundForecastReport = createSelector(
    fundForecastDataSelector,
    (fundForecastData) => {
        // Return blank if no values
        if (!fundForecastData) return {
            rows: BudgetForecastRows,
            data: []
        }

        let {
            base,
            weeks,
            months
        } = fundForecastData;

        weeks[0].sx = {borderLeft: 2, borderLeftColor: 'primary.main'};
        months[0].sx = {borderLeft: 2, borderLeftColor: 'primary.main'};

        return {
            data: [base, ...weeks, ...months],
            rows: BudgetForecastRows
        }
    }
);

function extractFundForecastPeriod(period: ForecastPeriod, startingAvailableCap: number, forecastAdjustmentCompound: number, fund: FundDetails | null): fundForecastColumn {
    let results = {
        startingAvailableCap: startingAvailableCap,
        expectedRepayments: 0,
        contractualPos: 0,
        newCommitments: 0,
        extensionOffset: 0,
        earlyRepayment: 0,
        selldowns: 0,
        transfersOut: 0,
        transfersIn: 0,
        adjustment: 0,
        subscriptions: 0,
        redemptions: 0,
        extension: 0,
        earlyRepOffset: 0,
        selldownOffset: 0,
        transfersOutOffset: 0,
        transfersInOffset: 0,
        debtFacilities: 0,
        repaymentAdjustments: 0,
        forecastAdjustment: 0,
        closingAvailableCap: 0
    };

    // FILTER BOOK FOR THOSE ACTIVE OR IF SELECTED FUND
    const filteredBook = createFilteredPortfolioBook(period.book, fund, false);

    // filter loans and extract necessary information;
    filteredBook.forEach(loan => {
        loan.tags.forEach(tag => {

            switch (tag) {
                case LoanTags.REPAYMENT:
                    results.expectedRepayments = addValues(results.expectedRepayments, loan.value);
                    break;

                case LoanTags.NEW_LOAN:
                    results.newCommitments = addValues(results.newCommitments, -loan.value)
                    break;

                case LoanTags.OFFSET_EXTENSION:
                    results.extensionOffset = addValues(results.extensionOffset, -loan.value)
                    break;

                case LoanTags.EARLY_REPAYMENT:
                    results.earlyRepayment = addValues(results.earlyRepayment, loan.value);
                    break;

                case LoanTags.SELLDOWN:
                    const selldown = (loan.selldowns || []).reduce((total, s) => {
                        if (checkInPeriod(s.date, period)) {
                            total = addValues(total, s.amount);
                        }
                        return total;
                    }, 0);
                    results.selldowns = addValues(results.selldowns, selldown || 0);
                    break;

                case LoanTags.TRANSFER_OUT:
                    const transferOut = (loan.transfersOut || []).reduce((total, t) => {
                        if (checkInPeriod(t.transferDate, period)) {
                            total = addValues(total, t.amount);
                        }
                        return total;
                    }, 0);
                    results.transfersOut = addValues(results.transfersOut, transferOut);
                    break;

                case LoanTags.TRANSFER_IN:
                    const transferIn = (loan.transfersIn || []).reduce((total, t) => {
                        if (checkInPeriod(t.transferDate, period)) {
                            total = addValues(total, t.amount);
                        }
                        return total;
                    }, 0);
                    results.transfersIn = addValues(results.transfersIn, -transferIn);
                    break;

                case LoanTags.EXTENSION:
                    results.extension = addValues(results.extension, loan.value)
                    break;

                case LoanTags.OFFSET_EARLY:
                    results.earlyRepOffset = addValues(results.earlyRepOffset, -loan.value);
                    break;

                case LoanTags.OFFSET_SELLDOWN:
                    const selldownOffset = (loan.selldowns || []).reduce((total, s) => {
                        total = addValues(total, s.amount);
                        return total;
                    }, 0);
                    results.selldownOffset = addValues(results.selldownOffset, -selldownOffset);
                    break;

                case LoanTags.OFFSET_TRANSFER_IN:
                    const transfersInOffset = (loan.transfersIn || []).reduce((total, t) => {
                        total = addValues(total, t.amount);
                        return total;
                    }, 0);
                    results.transfersInOffset = addValues(results.transfersInOffset, transfersInOffset)
                    break;

                case LoanTags.OFFSET_TRANSFER_OUT:
                    const transfersOutOffset = (loan.transfersOut || []).reduce((total, t) => {
                        total = addValues(total, t.amount);
                        return total;
                    }, 0);
                    results.transfersOutOffset = addValues(results.transfersOutOffset, -transfersOutOffset)
                    break;

                default:
                    break;
            }
        })
    })

    // Include Other Transactions
    period.otherTransactions.forEach(ot => {
        if (ot.transactionType === OtherTransactionTypes.ADJUSTMENT) {
            if (ot.capital && (!fund || (fund.label === ot.fund))) {
                results.adjustment = addValues(results.adjustment, ot.amount);
            }
        }
    })

    period.capital.forEach(c => {
        let amount = 0;
        if (fund) {
            if (fund.label === c.fund) {
                amount = c.amount;
            } else {
                const portion = fund.inheritance.get(c.fund);
                if (portion) {
                    amount = c.amount * portion;
                }
            }
        } else {
            amount = c.amount
        }
        switch (c.transactionType) {
            case CapitalAction.SUBSCRIPTION:
                results.subscriptions = addValues(results.subscriptions, amount);
                break;

            case CapitalAction.REDEMPTION:
                results.redemptions = addValues(results.redemptions, amount);
                break;

            case CapitalAction.COMMITMENT:
                if (!fund || (fund.label === c.fund)) {
                    results.debtFacilities = addValues(results.debtFacilities, c.amount);
                }
                break;

            case CapitalAction.CANCELLATION:
                if (!fund || (fund.label === c.fund)) {
                    results.debtFacilities = addValues(results.debtFacilities, -c.amount);
                }
                break;

            default:
                break;
        }
    })

    const contractualPosition = addValues(startingAvailableCap, results.expectedRepayments)

    const sumOfValues = addArrayValues([
        results.newCommitments,
        results.extensionOffset,
        results.earlyRepayment,
        results.selldowns,
        results.transfersOut,
        results.transfersIn,
        results.adjustment,
        results.subscriptions,
        results.redemptions,
        results.extension,
        results.earlyRepOffset,
        results.selldownOffset,
        results.transfersOutOffset,
        results.transfersInOffset,
        results.debtFacilities
    ]);

    return {
        ...results,

        contractualPos: contractualPosition,
        repaymentAdjustments: addArrayValues([results.extension, results.earlyRepOffset, results.selldownOffset, results.transfersOutOffset, results.transfersInOffset]),
        forecastAdjustment: sumOfValues,
        forecastAdjustmentCompound,
        closingAvailableCap: addArrayValues([contractualPosition, sumOfValues, forecastAdjustmentCompound]),

        label: period.label,
        labelTwo: period.labelTwo,
        tooltip: period.tooltip,
    }
}