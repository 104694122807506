// MUI
import {
    AccountBalance as AccountBalanceIcon,
    AttachMoney as AttachMoneyIcon,
    CreditCard as CreditCardIcon,
    CurrencyExchange as CurrencyExchangeIcon,
    Dashboard as DashboardIcon,
    DomainAdd as DomainAddIcon,
    Book as BookIcon,
    PieChart as PieChartIcon,
    ReceiptLong as ReceiptLongIcon,
    SsidChart as SsidChartIcon,
    Settings as SetingsIcon,
    ShowChart as ShowChartIcon,
    Source as SourceIcon,
    StackedBarChart as StackedBarChartIcon,
    SwapHoriz as SwapHorizIcon,
    SwapVert as SwapVertIcon,
    SyncAlt as SyncAltIcon,
    TrendingUpOutlined as TrendingUpOutlinedIcon,
    Tune as TuneIcon,
    PriceChange as PriceChangeIcon,
    WorkHistory as WorkHistoryIcon
} from '@mui/icons-material';
// Components
import {Item} from './NavSection';

// ----------------------------------------------------------------------

const navConfig: Item[] = [
    {
        title: 'Summary',
        path: '/budget/home',
        icon: <DashboardIcon/>,
    },
    {
        title: 'Sources and Uses',
        path: '/budget/sourcesUses',
        icon: <CurrencyExchangeIcon/>,
    },
    {
        title: 'Forecast Capital',
        path: '/budget/forecastCapital',
        icon: <TrendingUpOutlinedIcon/>,
    },
    {
        title: 'Fund Forecast',
        path: '/budget/fundForecast',
        icon: <SsidChartIcon/>,
    },
    {
        title: 'Debt Maturity Profile',
        path: '/budget/debt-profile',
        icon: <StackedBarChartIcon/>,
    },
    {
        title: 'Capital',
        path: '/budget/capital',
        icon: <AttachMoneyIcon/>,
        children: [
            {
                title: 'Capital Forecast',
                path: '/budget/capital/forecast',
                icon: <ShowChartIcon/>,
            },
            {
                title: 'Capital Transactions',
                path: '/budget/capital/transactions',
                icon: <ReceiptLongIcon/>,
            },
        ]
    },
    {
        title: 'Portfolio',
        path: '/budget/portfolio',
        icon: <PieChartIcon/>,
    },
    {
        title: 'New Commitments',
        path: '/budget/newCommitments',
        icon: <DomainAddIcon/>,
    },
    {
        title: 'Refinances',
        path: '/budget/refinance',
        icon: <CurrencyExchangeIcon/>,
    },
    {
        title: 'Fund Finances',
        path: '/budget/fund-finance',
        icon: <CreditCardIcon/>,
    },
    {
        title: 'Other Data',
        path: '/budget/data',
        icon: <SourceIcon/>,
    },
    {
        title: 'Configuration',
        path: '/budget/configuration',
        icon: <SetingsIcon/>,
        children: [
            {
                title: 'Portfolio Changes',
                path: '/budget/configuration/portfolio-changes',
                icon: <SyncAltIcon/>,
            },
            {
                title: 'WIP',
                path: '/budget/configuration/wip',
                icon: <WorkHistoryIcon/>,
            },
            {
                title: 'New Allocations',
                path: '/budget/configuration/allocations',
                icon: <TuneIcon/>,
            },
            {
                title: 'Refinances',
                path: '/budget/configuration/refinance',
                icon: <CurrencyExchangeIcon/>,
            },
            {
                title: 'Fund Finance',
                path: '/budget/configuration/fund-finance',
                icon: <AccountBalanceIcon/>,
            },
            {
                title: 'Interfund Transfers',
                path: '/budget/configuration/transfers',
                icon: <SwapHorizIcon/>,
            },
            {
                title: 'Selldowns & Repayments',
                path: '/budget/configuration/sell-repay',
                icon: <SwapVertIcon/>,
            },
            {
                title: 'Capital Movements',
                path: '/budget/configuration/capital',
                icon: <PriceChangeIcon/>,
            },
            {
                title: 'Other Transactions',
                path: '/budget/configuration/other',
                icon: <BookIcon/>,
            },
            // {
            //     title: 'Forecasts',
            //     path: '/budget/configuration/forecast',
            //     icon: <ForkRightIcon/>,
            // }
        ]
    },



];

export default navConfig;
