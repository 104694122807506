import {useState} from 'react';
import * as Yup from 'yup';
import {FormikProps} from "formik";
import {WIPLoan} from "../../../types/externalDataTypes";
import {AllocationColumns} from "./WIpTableColumns";
import {InputTypes} from "../../../types/InputTypes";
import {fCurrency} from "../../../utils/formatNumber";
import {addValues} from "../../../utils/mathUtil";
// MUI
import {Grid, Container} from '@mui/material';
// Components
import {EditableTable, EditableTableColumn, EditableTableRow, SideDrawer} from "../../../components";
import {Page} from "../../../layouts/components";
import ForecastConfigBar from "../ForecastConfigBar";
import WIPDataDisplay from "../../../sections/Budget/Configurations/WIPDataDisplay";
// Store
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    retrieveAllWIPLoans,
    retrieveNewLoansAllocations
} from "../../../store/capitalBudget/selectors/wipSelector";

import {underlyingFundsSelector} from "../../../store/capitalBudget/selectors/generalSelectors";
import {updateAllocation} from "../../../store/capitalBudget/capitalBudgetSlice";
import {manualDealsSelector} from "../../../store/capitalBudget/selectors/manualDealSelector";
import {ManualDeal} from "../../../types/forecastTypes";
import ManualLoanDataDisplay from "../../../sections/Budget/Configurations/ManualLoanDataDisplay";
import {editableTableCustomColoring} from "./DataTableWarningsUtilts";

const fundColumns = {
    align: 'right',
    total: true,
    headSX: {
        fixedDecimalScale: true
    },
    edit: {
        fieldType: InputTypes.CURRENCY,
        numFormatProps: {
            allowNegative: true,
            decimalScale: 2,
            fixedDecimalScale: true
        }
    },
    sx: {
        minWidth: '110px'
    },
    formatter: (value: number) => {
        if (value === null) return 'N/A'
        return fCurrency(value)
    }
};

// Capital Budget Summary App Page
export default function Allocations() {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.forecast.loading);
    const wip = useAppSelector(state => retrieveAllWIPLoans(state));
    const newLoans = useAppSelector(state => retrieveNewLoansAllocations(state, true));
    const manualLoans = useAppSelector(state => manualDealsSelector(state));
    const funds = useAppSelector(state => underlyingFundsSelector(state));

    const [selectedLoan, setSelected] = useState<WIPLoan | null>(null);
    const [selectedManualLoan, setSelectedManualLoan] = useState<ManualDeal | null>(null);

    // HANDLES Selection of loan to show data
    const handleSelectNewLoan = (row: EditableTableRow | null) => {
        if (!row) {
            setSelected(null);
            setSelectedManualLoan(null);
        }
        if (row?.manual) {
            const loan = manualLoans.find(l => l.id === row?.manual);
            setSelectedManualLoan(loan as any)
        } else {
            const loan = wip.find(l => l.ncino_id === row?.ncinoId);
            setSelected(loan as any)
        }
    }

    // Validation Schema
    const TransactionSchema = Yup.object().shape({
        DASLF: Yup.number()
            .test(
                'DASLF check', 'Allocation amount lower than threshold.',
                (value, context) => {
                    const commitment = context.parent.adjustedCommitment;
                    if (value) {
                        return !(value !== 0 && commitment > 250000 && value < 250000);
                    }
                    return true
                }
            )
    });

    // DASLF Auto-Balance
    const autoBalanceDASLF = (values: any, props: FormikProps<any>) => {
        // console.log(values)
        let overflow = values.adjustedCommitment

        funds.forEach(f => {
            if (f.label !== 'DASLF') {
                overflow = addValues(overflow, -values[f.label]);
            }
        })

        if (overflow < 0) {
            if (!props.errors.adjustedCommitment) {
                props.setFieldError('adjustedCommitment', 'Sum of fund values is greater than commitment');
            }
        } else {
            if (values.DASLF !== overflow) {
                props.setFieldValue('DASLF', overflow)
            }
        }
    }

    const handleEditRow = (row: EditableTableRow) => {
        const values = funds.reduce((allocations: { [x: string]: number }, f) => {
            if (row[f.label] !== 0) {
                allocations[f.label] = row[f.label];
            }

            return allocations;
        }, {})
        dispatch(updateAllocation({
                id: row.id,
                allocation: values
            }
        ))
    }

    return (
        <>
            <Page title="Metrics - CB - Allocations">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Portfolio - New Loan Allocations' configuration/>
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                <EditableTable
                                    title='Loan Allocation'
                                    columns={[
                                        ...AllocationColumns,
                                        ...funds.filter(f => f.label !== 'DASLF').map(fund => (
                                            {
                                                ...fundColumns,
                                                id: fund.label,
                                                label: fund.label
                                            } as EditableTableColumn
                                        ))
                                    ]}
                                    data={newLoans}

                                    loading={loading}

                                    handleRowEdit={handleEditRow}

                                    rowSelect={handleSelectNewLoan}

                                    validationSchema={TransactionSchema}
                                    formRowValueFunction={autoBalanceDASLF}

                                    defaultSort='adjustedCloseDate'
                                    defaultOrder='asc'


                                    search
                                    totalRow

                                    customColorFn={editableTableCustomColoring}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
            {selectedLoan &&
                <SideDrawer
                    title='Details'
                    open={!!selectedLoan}
                    onClose={() => setSelected(null)}
                >
                    <WIPDataDisplay
                        loan={selectedLoan}
                        onClose={() => setSelected(null)}
                    />
                </SideDrawer>
            }
            {selectedManualLoan &&
                <SideDrawer
                    title='Details'
                    open={!!selectedManualLoan}
                    onClose={() => setSelectedManualLoan(null)}
                >
                    <ManualLoanDataDisplay
                        loan={selectedManualLoan}
                        onClose={() => setSelectedManualLoan(null)}
                    />
                </SideDrawer>
            }
        </>
    )
}