import {SxProps} from "@mui/material";
import {GridCellParams, GridColTypeDef} from "@mui/x-data-grid";
import clsx from "clsx";
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {currencyFormatter, dateFormatter} from "../../../components/DataTable/DataTableUtils";
import {EditableTableRow} from "../../../components";

export const warningDataGridSx: SxProps = {
    '& .warning.advise': {
        bgcolor: 'info.lighter'
    },
    '& .warning.alert': {
        bgcolor: 'error.lighter'
    },
}

export const warningCellClassName = (params: GridCellParams) => {
    return clsx('status', 'warning', {
        advise: (!!params.row.advise),
        alert: (!!params.row.alert),
        new: (params.row.status === SaveStatus.NEW),
        edited: (params.row.status === SaveStatus.EDITED),
    });
};

export const editableTableCustomColoring = (row: EditableTableRow) => {
    if (row.alert) return 'error';
    if (row.advise) return 'info';
    return undefined;
}

// ----------------------------------------------------------------------
// Data Table Types
export const warningsGeneralRow: GridColTypeDef = {
    cellClassName: (params: GridCellParams) => {
        return clsx('status', 'warning',{
            advise: (!!params.row.advise),
            alert: (!!params.row.alert),
            new: (params.row.status === SaveStatus.NEW),
            edited: (params.row.status === SaveStatus.EDITED),
        });
    }
}

export const warningValueDate: GridColTypeDef = {
    type: 'date',
    width: 150,
    align: 'right',
    valueFormatter: dateFormatter,
    cellClassName: (params: GridCellParams) => {
        if (params.isEditable) {
            return clsx('warning', {
                advise: (!!params.row.advise),
                alert: (!!params.row.alert)
            }, 'editable', ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            })
        } else {
            return clsx('warning', {
                advise: (!!params.row.advise),
                alert: (!!params.row.alert)
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        }
    }
}

export const warningValueCurrency: GridColTypeDef = {
    type: 'number',
    minWidth: 100,
    align: 'right',
    valueFormatter: currencyFormatter,
    cellClassName: (params: GridCellParams) => {
        if (params.isEditable) {
            return clsx('warning', {
                advise: (!!params.row.advise),
                alert: (!!params.row.alert)
            }, 'editable currency', {
                negative: (params.value as number) < 0,
                positive: (params.value as number) >= 0
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            })
        }
        return clsx('warning', {
            advise: (!!params.row.advise),
            alert: (!!params.row.alert)
        }, 'currency', {
            negative: (params.value as number) < 0,
            positive: (params.value as number) >= 0
        }, ' status', {
            new: (params.row.status === SaveStatus.NEW),
            edited: (params.row.status === SaveStatus.EDITED)
        }, 'warning', {
            advise: (!!params.row.advise),
            alert: (!!params.row.alert)
        })
    }
}