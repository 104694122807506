// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {ManualDeal, NewDeal} from "../../../types/forecastTypes";
import {checkObjectChanged} from "../../../utils/generalUtils";


const manualDealReducer: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Create new loan
    addNewManualLoan: {
        prepare(newLoan: ManualDeal) {
            return {
                payload: {
                    ...newLoan,
                    id: nanoid(),
                    status: SaveStatus.NEW
                }
            }
        },
        reducer(state, action: PayloadAction<ManualDeal>) {
            if (state.forecastData) {
                // ADD NEW MANUAL LOAN
                state.forecastData.manualDeals = [action.payload, ...state.forecastData.manualDeals];
                // ADD AND CREATE NEW DEAL
                const newDeal: NewDeal = {
                    id: nanoid(),
                    ncinoId: action.payload.ncinoId || null,
                    dealId: action.payload.dealId || null,
                    allocation: {},
                    closeDate: action.payload.closeDate,
                    amendedCloseDate: null,
                    commitment: action.payload.commitment,
                    amendedCommitment: null,
                    tenor: action.payload.tenor,
                    amendedTenor: null,
                    drawdown: action.payload.drawdownAtClose,
                    amendedDrawdown: null,
                    lastModified: new Date(),
                    manual: action.payload.id,
                    status: SaveStatus.NEW
                }
                state.forecastData.newDeals = [...state.forecastData.newDeals, newDeal];
                state.changes = true;
            }
        }
    },
    updateManualDeal: (state, action: PayloadAction<ManualDeal>) => {
        if (state.forecastData) {
            const index = state.forecastData.manualDeals.findIndex(md => md.id === action.payload.id && md.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;
                const existing = state.forecastData.manualDeals[index];

                // Update New Deal if certain attribtues are updated
                if (checkObjectChanged(existing, action.payload, ['ncinoId', 'dealId', 'closeDate', 'commitment', 'tenor', 'drawdownAtClose'])) {
                    const newDealIndex = state.forecastData.newDeals.findIndex(nd => nd.manual === action.payload.id);
                    if (newDealIndex !== -1) {
                        const currentNewDeal = state.forecastData.newDeals[index];
                        state.forecastData.newDeals[index] = {
                            ...state.forecastData.newDeals[index],
                            ncinoId: action.payload.ncinoId || null,
                            dealId: action.payload.dealId || null,
                            closeDate: action.payload.closeDate,
                            commitment: action.payload.commitment,
                            tenor: action.payload.tenor,
                            drawdown: action.payload.drawdownAtClose,
                            status: (state.forecastData.newDeals[index].status !== SaveStatus.NEW) ? SaveStatus.EDITED : SaveStatus.NEW,
                            previous: (state.forecastData.newDeals[index].status !== SaveStatus.NEW) ? ((!currentNewDeal.previous) ? currentNewDeal : currentNewDeal.previous) : undefined
                        }
                    }
                }

                // UPDATE MANUAL DEAL
                if (state.forecastData.manualDeals[index].status !== SaveStatus.NEW) {
                    changed = checkObjectChanged(existing, action.payload, ['ncinoId', 'dealId', 'client', 'owner', 'commitment', 'drawdownAtClose', 'tenor', 'closeDate', 'margin', 'facilityFeeRate', 'baseRate', 'baseCurrency', 'domicile', 'rating', 'ranking', 'industry', 'interestType', 'investmentType', 'trancheType', 'pricingType']);

                    if (changed) {
                        state.forecastData.manualDeals[index] = {
                            ...state.forecastData.manualDeals[index],
                            ...action.payload,
                            previous: (!existing.previous) ? existing : existing.previous,
                            status: SaveStatus.EDITED
                        }
                        state.changes = true
                    }
                } else {
                    state.forecastData.manualDeals[index] = {
                        ...state.forecastData.manualDeals[index],
                        ...action.payload,
                        status: SaveStatus.NEW
                    }
                }
            }
        }
    },
    deleteManualDeal: (state, action: PayloadAction<number>) => {
        if (state.forecastData) {
            const index = state.forecastData.manualDeals.findIndex(md => md.id === action.payload);
            if (index !== -1) {
                const deal = state.forecastData.manualDeals[index];
                const newDealIndex = state.forecastData.newDeals.findIndex(nd => nd.manual === deal.id);
                if (deal.status !== SaveStatus.NEW) {
                    state.forecastData.manualDeals[index].status = SaveStatus.REMOVED;

                    if (newDealIndex) state.forecastData.newDeals[newDealIndex].status = SaveStatus.REMOVED;

                    state.changes = true;
                } else {
                    const manualDeals = state.forecastData.manualDeals;
                    manualDeals.splice(index, 1);
                    state.forecastData.manualDeals = manualDeals;
                    if (newDealIndex) {
                        const newDeals = state.forecastData.newDeals;
                        newDeals.splice(newDealIndex, 1);
                        state.forecastData.newDeals = newDeals;
                    }
                }
            }
        }
    }
}

export default manualDealReducer;