import * as Yup from "yup";
import {Formik, FormikProps} from "formik";
import {Button, CircularProgress, Divider, Grid} from "@mui/material";
import {InputTypes} from "../../../types/InputTypes";
import {FormInput} from "../../../components";
import {
    InvestmentTypeSelection,
    ratingSelection,
    TrancheTypeSelection
} from "../../../utils/CapitalBudgetUtils";
import {formatDate} from "../../../utils/DateUtils";
import {useAppSelector} from "../../../store/store";
import {spIndustriesSelection} from "../../../utils/spIndustryUtils";


type ManualLoanFormProps = {
    editValues: any | null,
    onClose: () => void,
    submitLoan: (values: any) => void,
    editLoan: (values: any) => void
}

const initialValues = {
    name: '',
    ncinoId: '',
    dealId: '',
    client: '',
    owner: '',
    commitment: '',
    drawdownAtClose: '',
    tenor: '',
    closeDate: '',
    margin: '',
    facilityFeeRate: '',
    baseRate: '',
    baseCurrency: 'AUD',
    domicile: 'Australia',
    rating: '',
    ranking: 'Senior',
    industry: '',
    interestType: 'Floating Rate',
    investmentType: '',
    trancheType: '',
    pricingType: null
}

const ManualLoanSchema = Yup.object().shape({
    name: Yup.string().required('Loan Name is Required.'),
    client: Yup.string().required('Deal Name is Required.'),
    commitment: Yup.number().min(0, 'Commitment must be greater than 0.').required('Commitment Required.'),
    drawdownAtClose: Yup.number().min(-1, 'Commitment must be greater than 0.')
        .test(
            'less-than-commitment',
            'Drawdown must be equal or less than Commitment',
            function (value) {
                return !value || (value <= this.parent.commitment)
            }
        )
        .required('Drawdown Required.'),
    margin: Yup.number().min(0).max(100),
    facilityFeeRate: Yup.number().min(0).max(100),
    baseRate: Yup.number().min(0).max(100),

})

export default function ManualLoanForm({editValues, onClose, submitLoan, editLoan}: ManualLoanFormProps) {
    const loading = useAppSelector(state => state.capitalBudget.calculating);

    console.log(editValues)

    return (
        <>
            <Formik
                initialValues={(editValues && editValues.id) ? {
                    ...editValues,
                    ncinoId: editValues.ncinoId || '',
                    dealId: editValues.dealId || '',
                    margin: editValues.margin * 100 || '',
                    facilityFeeRate: editValues.facilityFeeRate * 100 || '',
                    baseRate: editValues.baseRate * 100 || '',
                    industry: editValues.industry || '',
                    investmentType: editValues.investmentType || '',
                    trancheType: editValues.trancheType || ''
                } : initialValues}
                validationSchema={ManualLoanSchema}
                onSubmit={(values) => {
                    const newValues = {
                        ...values,
                        closeDate: formatDate(values.closeDate, 'yyyy-MM-dd'),
                        pricingType: values.interestType,
                        ncinoId: (values.ncinoId !== '') ? values.ncinoId : null,
                        dealId: (values.dealId !== '') ? values.dealId : null,
                        margin: (values.margin !== '') ? (parseFloat(values.margin)/100) : null,
                        facilityFeeRate: (values.facilityFeeRate !== '') ? (parseFloat(values.facilityFeeRate)/100) : null,
                        baseRate: (values.baseRate !== '') ? (parseFloat(values.baseRate)/100) : null,
                        rating: (values.industry !== '') ? values.rating : 'NR',
                        industry: (values.industry !== '') ? values.industry : null,
                        investmentType: (values.investmentType !== '') ? values.investmentType : null,
                        trancheType: (values.trancheType !== '') ? values.trancheType : null
                    }
                    if (editValues.id) {
                        editLoan(newValues)
                    } else {
                        submitLoan(newValues)
                    }
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                    } = props;

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid item container direction='row'>
                                    <Grid item xs={12} padding={1}>
                                        <Divider>Loan Information</Divider>
                                    </Grid>
                                    <FormInput
                                        id='name'
                                        label='Name'
                                        fieldType={InputTypes.STRING}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <FormInput
                                        id='client'
                                        label='Deal'
                                        fieldType={InputTypes.STRING}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <FormInput
                                        id='ncinoId'
                                        label='Ncino Id'
                                        fieldType={InputTypes.STRING}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    <FormInput
                                        id='dealId'
                                        label='Deal Id'
                                        fieldType={InputTypes.STRING}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    <FormInput
                                        id='owner'
                                        label='Owner'
                                        fieldType={InputTypes.STRING}
                                        layout={{xs: 12, md: 12, lg: 12}}
                                    />
                                    <Grid item xs={12} padding={1}>
                                        <Divider>Loan Commitments</Divider>
                                    </Grid>
                                    <FormInput
                                        id='commitment'
                                        label='Commitment'
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    <FormInput
                                        id='drawdownAtClose'
                                        label='Drawdown At Close'
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    <Grid item xs={12} padding={1}>
                                        <Divider>Loan Duration</Divider>
                                    </Grid>
                                    <FormInput
                                        id='closeDate'
                                        label='Close Date'
                                        fieldType={InputTypes.DATE}
                                        layout={{xs: 8, md: 8, lg: 8}}
                                    />
                                    <FormInput
                                        id='tenor'
                                        label='Tenor (months)'
                                        fieldType={InputTypes.NUMBER}
                                        layout={{xs: 4, md: 4, lg: 4}}
                                    />
                                    <Grid item xs={12} padding={1}>
                                        <Divider>Loan Yield</Divider>
                                    </Grid>
                                    <FormInput
                                        id='margin'
                                        label='Margin (%)'
                                        fieldType={InputTypes.PERCENTAGE}
                                        layout={{xs: 4, md: 4, lg: 4}}
                                    />
                                    <FormInput
                                        id='facilityFeeRate'
                                        label='Facility Fee (%)'
                                        fieldType={InputTypes.PERCENTAGE}
                                        layout={{xs: 4, md: 4, lg: 4}}
                                    />
                                    <FormInput
                                        id='baseRate'
                                        label='Base Rate (%)'
                                        fieldType={InputTypes.PERCENTAGE}
                                        layout={{xs: 4, md: 4, lg: 4}}
                                    />
                                    <FormInput
                                        id='interestType'
                                        label='Interest Type'
                                        fieldType={InputTypes.SELECTION}
                                        values={[
                                            {label: 'Fixed', value: 'Fixed'},
                                            {label: 'Floating Rate', value: 'Floating Rate'},
                                            {label: 'Other', value: 'Other'}
                                        ]}
                                        layout={{xs: 4, md: 4, lg: 4}}
                                    />
                                    <Grid item xs={12} padding={1}>
                                        <Divider>Loan Details</Divider>
                                    </Grid>
                                    <FormInput
                                        id='domicile'
                                        label='Domicile'
                                        fieldType={InputTypes.SELECTION}
                                        values={[
                                            {label: 'Australia', value: 'Australia'},
                                            {label: 'Other', value: 'Other'}
                                        ]}
                                        layout={{xs: 8, md: 8, lg: 8}}
                                    />
                                    <FormInput
                                        id='baseCurrency'
                                        label='Currency'
                                        fieldType={InputTypes.SELECTION}
                                        values={[
                                            {label: 'AUD', value: 'AUD'},
                                            {label: 'GBP', value: 'GBP'},
                                            {label: 'NZD', value: 'NZD'},
                                            {label: 'USD', value: 'USD'}
                                        ]}
                                        layout={{xs: 4, md: 4, lg: 4}}
                                    />
                                    <FormInput
                                        id='rating'
                                        label='Rating'
                                        fieldType={InputTypes.SELECTION}
                                        values={ratingSelection}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    <FormInput
                                        id='ranking'
                                        label='Ranking'
                                        fieldType={InputTypes.SELECTION}
                                        values={[
                                            {label: 'Senior', value: 'Senior'},
                                            {label: 'Subordinated', value: 'Subordinated'},
                                            {label: 'Equity', value: 'Equity'},
                                            {label: 'Other', value: 'Other'},
                                        ]}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    <FormInput
                                        id='industry'
                                        label='Industry'
                                        fieldType={InputTypes.SELECTION}
                                        values={spIndustriesSelection}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    <FormInput
                                        id='investmentType'
                                        label='Investment Type'
                                        fieldType={InputTypes.SELECTION}
                                        values={InvestmentTypeSelection}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />
                                    <FormInput
                                        id='trancheType'
                                        label='Tranche Type'
                                        fieldType={InputTypes.SELECTION}
                                        values={TrancheTypeSelection}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                    />


                                    <Grid item xs={12} padding={1}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item sx={{width: '50%', p: 2}}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{width: '50%', p: 2}}>
                                        {loading ?
                                            <CircularProgress/>
                                            :
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                {`${(editValues?.id) ? 'Update' : 'Create'} Loan`}
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}