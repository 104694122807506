// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {CapitalBudgetState} from "../capitalBudgetSlice";

const wipCommentReducers: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Create WIP Comment
    addWIPComment: {
        prepare(newComment: { ncinoId: string, notes: string }) {
            return {
                payload: {
                    ...newComment,
                    id: nanoid(),
                    status: SaveStatus.NEW
                }
            }
        },
        reducer(state, action: PayloadAction<any>) {
            if (state.forecastData) {
                state.forecastData.wipComments = [action.payload, ...state.forecastData.wipComments];
                state.changes = true;
            }
        }
    },
    // Update WIP Comment
    updateWIPComment: (state, action) => {
        if (state.forecastData) {
            const index = state.forecastData.wipComments.findIndex(c => c.id === action.payload.id && c.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;

                if (state.forecastData.wipComments[index].status !== SaveStatus.NEW) {
                    const existing = state.forecastData.wipComments[index];
                    if (existing.notes !== action.payload.notes) changed = true;
                    if (changed) {
                        if (!existing.previous) action.payload.previous = existing;
                        action.payload.status = SaveStatus.EDITED;
                        state.forecastData.wipComments[index].notes = action.payload.notes;
                        state.forecastData.wipComments[index].status = SaveStatus.EDITED;
                        state.changes = true;
                    }
                } else {
                    state.forecastData.wipComments[index] = {...state.forecastData.wipComments[index], ...action.payload};
                }
            }
        }
    },
    // Remove WIP Comment
    removeWIPComment: (state, action: PayloadAction<number>) => {
        if (state.forecastData) {
            const index = state.forecastData?.wipComments.findIndex(c => c.id === action.payload && c.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.forecastData.wipComments[index].status !== SaveStatus.NEW) {
                    state.forecastData.wipComments[index].status = SaveStatus.REMOVED;
                    state.changes = true;
                } else {
                    state.forecastData.wipComments = state.forecastData.wipComments.filter(c => c.id !== action.payload);
                }
            }
        }
    },
    // Bulk remove WIP Comments
    bulkRemoveWIPComments: (state, action: PayloadAction<number[]>) => {
        action.payload.forEach(id => {
            if (state.forecastData) {
                const index = state.forecastData?.wipComments.findIndex(c => c.id === id && c.status !== SaveStatus.REMOVED);
                if (index !== -1) {
                    if (state.forecastData.wipComments[index].status !== SaveStatus.NEW) {
                        state.forecastData.wipComments[index].status = SaveStatus.REMOVED;
                        state.changes = true;
                    } else {
                        state.forecastData.wipComments = state.forecastData.wipComments.filter(c => c.id !== id);
                    }
                }
            }
        })
    }
}

export default wipCommentReducers;