// Local Imports
import {SuggestionFieldProps} from "../../../types/InputTypes";
// MUI
import {styled} from "@mui/material/styles";
import {
    Autocomplete,
    Grid, IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
    Theme,
    Tooltip
} from "@mui/material";
import {
    HelpOutline as HelpOutlineIcon,
    Error as ErrorIcon
} from "@mui/icons-material"

import {escapeRegExp} from "../../../utils/generalUtils";

// Custom styling
const GridStyle = styled(Grid)(({theme}: { theme: Theme }) => ({
    padding: theme.spacing(1),
    width: '100%'
}))


export default function SuggestionField(props: SuggestionFieldProps) {
    const {
        id,
        label,
        placeholder,
        info = null,
        helperText,
        error,
        disabled = false,
        handleBlur,
        handleChange,
        layout = {xs: 12, md: 12, lg: 12},
        size,
        variant,
        noUnderline,
        errorInIcon,

        value,
        values
    } = props;

    const defaultProps: TextFieldProps = {
        id,
        label,
        placeholder,
        error,
        disabled,
        value,
        variant,
        onBlur: handleBlur,
        size
    }

    if (!errorInIcon) {
        defaultProps.helperText = helperText;
    }

    let adornments = (<></>)

    // Insert Info Tooltip if necessary
    if (info) {
        adornments = (
            <Tooltip title={info} placement='right'>
                <IconButton edge="end" size={size}>
                    <HelpOutlineIcon />
                </IconButton>
            </Tooltip>
        )
    }

    // If error display in Icon
    if (errorInIcon && error) {
        adornments = (
            <Tooltip title={helperText} placement='right'>
                <IconButton edge="end" sx={{pl: 0}} size={size}>
                    <ErrorIcon sx={{color: 'red'}}/>
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <GridStyle
            item
            {...layout}
        >
            <Autocomplete
                disablePortal
                clearOnBlur
                options={values}
                // isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={(option: any) => {
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue || '';
                    }
                    // Regular option
                    return option.label || '';
                }}
                onChange={(event: any, value: any) => {
                    if (typeof value === 'string') {
                        handleChange(value, id)
                    } else if (value && value.inputValue) {
                        handleChange(value, id)
                    } else {
                        handleChange(value, id)
                    }
                }}
                filterOptions={(options: Array<{value: string, label: string, inputValue?: string}>, params) => {
                    // Filters option and determines if a new value needs to be added.
                    const { inputValue } = params;

                    const searchRegex = new RegExp(escapeRegExp(inputValue.toLowerCase()), 'i');

                    const filtered = options.filter(option => {
                        return searchRegex.test(option.value);
                    })

                    const isExisting = options.some(option => inputValue === option.label);

                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            value: inputValue,
                            inputValue,
                            label: `Add "${inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                freeSolo
                disabled={disabled}
                value={value}

                renderOption={(props, option: {value: string, label: string, inputValue?: string}) => <li {...props}>{option.label}</li>}

                renderInput={(params) =>
                    <TextField
                        value={value}
                        {...params}
                        InputProps={{
                            ...((variant === 'standard' && noUnderline) ? {disableUnderline: true} : {}),
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {adornments}
                                    {params.InputProps.endAdornment}
                                </InputAdornment>
                            ),
                        }}
                        {...defaultProps}
                    />
                }

                sx={{
                    zIndex: 10000
                }}
            />
        </GridStyle>
    )
}