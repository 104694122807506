import {NumericFormatProps} from "react-number-format"

export enum InputTypes {
    STRING = 'STRING',
    PASSWORD = 'PASSWORD',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    SELECTION = 'SELECTION',
    CHECKBOX = 'CHECKBOX',
    FORMATTED = 'FORMATTED',
    TEXT_BOX = 'TEXT_BOX',
    CURRENCY = 'CURRENCY',
    PERCENTAGE = 'PERCENTAGE',
    RADIO = 'RADIO',
    SEARCH = 'SEARCH',
    SUGGESTION = 'SUGGESTION',
    FILE = 'FILE'
}

export enum CellType {
    CURRENCY = 'CURRENCY',
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    DATE = 'DATE'
}

export type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 12

// Formik Field Wrapper Props
export type FormInputProps = {
    id: string,
    label: string,
    placeholder?: string,
    info?: string,
    disabled?: boolean,

    layout?: { xs?: GridSize, md?: GridSize, lg: GridSize },
    size?: 'small' | 'medium',
    variant?: 'outlined' | 'standard',
    noUnderline?: boolean,
    noLabel?: boolean,
    errorInIcon?: boolean,

    fieldType: InputTypes,

    // Date Specific 
    minDate?: Date,
    maxDate?: Date,
    nullable?: boolean,

    // Selection Specific
    values?: Array<{ value: string | number | Object, label: string, info?: string } | { id: string | number, [x: string]: any }>
    displayNone?: boolean,
    limit?: boolean,

    // Mask/Formatted specific
    mask?: string,

    // File Specific
    multiple?: boolean,
    fileType?: Array<string>

    // Currency and Percentage Props
    numFormatProps?: NumericFormatProps,

    // Search Specific
    labelFunc?: (option: any) => string,

    // additional function performed onBlur
    blurFunction?: () => void;
}

// Input Field Props
export interface InputFieldProps {
    // General attributes
    id: string,
    label: string,
    placeholder?: string,
    info?: string,
    helperText: string,
    error: boolean,
    touched: boolean,
    disabled?: boolean,

    // Input Functions
    handleChange: (value: any, field: string) => void,
    handleBlur: (e: any) => void,
    setTouched: (field: string) => void,
    setError: (field: string, message: string | undefined) => void,

    // Field Input Size
    layout?: { xs?: GridSize, md?: GridSize, lg: GridSize },
    size: 'small' | 'medium',
    variant: 'outlined' | 'standard',
    noUnderline: boolean,
    noLabel: boolean,
    errorInIcon: boolean,

    fieldType: InputTypes,

    value: string | number | Date | boolean | Array<File> | Object | null // | {id: number, [x: string]: any}

    // Date Specific 
    minDate?: Date,
    maxDate?: Date,
    nullable?: boolean,

    // Radio Specific
    radio?: string,

    // Selection Specific
    values?: Array<{ value: string | number | Object, label: string, info?: string } | { id: string | number, [x: string]: any }>
    displayNone?: boolean
    limit?: boolean,

    // Mask/Formatted specific
    mask?: string,

    // File Specific
    multiple?: boolean,
    fileType?: Array<string>

    // Currency and Percentage Props
    numFormatProps?: NumericFormatProps,

    // Search Specific
    labelFunc?: (option: any) => string,
    defaultValue?: any
}

// Checkbox Input Field Props
export interface CheckboxFieldProps extends InputFieldProps {
    value: boolean,

    fieldType: InputTypes.CHECKBOX
}

// Currency Input Field Props
export interface CurrencyFieldProps extends InputFieldProps {
    value: number,

    fieldType: InputTypes.CURRENCY,
    numFormatProps?: NumericFormatProps
}

// Date Input Field Props
export interface DateFieldProps extends Omit<InputFieldProps, "placeholder"> {
    value: string | number | Date | null,

    fieldType: InputTypes.DATE,

    minDate?: Date,
    maxDate?: Date,
    nullable?: boolean,
}

// File Input Field Props
export interface FileFieldProps extends Omit<InputFieldProps, "layout"> {
    value: Array<File>

    fieldType: InputTypes.FILE
}

// Formatted Input Field Props
export interface FormattedFieldProps extends InputFieldProps {
    value: string,

    fieldType: InputTypes.FORMATTED
}

// Password Input Field Props
export interface PasswordFieldProps extends InputFieldProps {
    value: string,

    fieldType: InputTypes.PASSWORD
}

// Percentage Input Field Props
export interface PercentageFieldProps extends InputFieldProps {
    value: number,

    fieldType: InputTypes.PERCENTAGE,
    numFormatProps?: NumericFormatProps,
}

// Radio Input Field Props
export interface RadioFieldProps extends InputFieldProps {
    value: string,

    defaultValue: string | null,

    values: Array<{ value: string | number, label: string, info?: string }>
}

// Selection Input Field Props
export interface SelectionFieldProps extends InputFieldProps {
    value: string | number,

    fieldType: InputTypes.SELECTION,

    values: Array<{ value: string | number, label: string }>,
    displayNone: boolean,
    limit?: boolean
}

// Selection Input Field Props
export interface SearchFieldProps extends InputFieldProps {
    value: string | number | any,

    fieldType: InputTypes.SEARCH,
    labelFunc: (option: any) => string,

    values: Array<{ id: string | number, [x: string]: any }>
}

// Suggestion Input Field Props
export interface SuggestionFieldProps extends InputFieldProps {

    defaultValue: any,
    value: any,

    fieldType: InputTypes.SUGGESTION,

    values: Array<{ value: string, label: string, inputValue?: string }>
}


export const defaultFileTypes = {
    // "application/*": [
    //     ".json",
    //     '.msword',
    //     '.pdf',
    //     '.typescript',
    //     '.vnd.ms-excel',
    //     '.vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //     '.vnd.ms-fontobject',
    //     '.vnd.ms-powerpoint',
    //     '.vnd.oasis.opendocument.presentation',
    //     '.vnd.oasis.opendocument.spreadsheet',
    //     '.vnd.oasis.opendocument.text',
    //     '.vnd.openxmlformats-officedocument.presentationml.presentation',
    //     '.vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //     '.vnd.openxmlformats-officedocument.wordprocessingml.document',
    //     '.x-7z-compressed',
    //     '.xml',
    //     '.zip',
    // ],
    "image/*": [
        '.jpeg',
        '.jpeg',
        '.png',
    ],
    "text/*": [
        '.calendar',
        '.css',
        '.csv',
        '.html',
        '.html',
        '.plain'
    ]
};

