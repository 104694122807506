import { ReactNode } from "react";
// MUI
import { AppBar, Box, Drawer, Toolbar, Typography } from "@mui/material";

// ----------------------------------------------------------------------
// Props

interface SideDrawerProps {
    title: string
    open: boolean,
    onClose: (open: boolean) => void,
    children: ReactNode,
    size?: 'small' | 'medium' | 'large' | 'x-large'
}

// ----------------------------------------------------------------------

// Drawer Component Wrapper, encompasses components in a right-hand side drawer
export default function SideDrawer(props: SideDrawerProps) {
    const { title, open, onClose, children, size = 'medium' } = props;

    let width: number | string;
    switch (size) {
        case 'x-large':
            width = 1000;
            break;

        case 'large':
            width = 800;
            break;

        case 'small':
            width = 300;
            break;

        case 'medium':
        default:
            width = 500;
            break;
    }

    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor='right'
            sx={{ zIndex: 1200, height: '100%' }}
        >
            <Box sx={{ width: width, height: '100%', p: 1, mt: '64px'}}>
                <AppBar position='absolute'>
                    <Toolbar>
                        <Typography variant='h4'>{title}</Typography>
                    </Toolbar>
                </AppBar>
                {children}
            </Box>

        </Drawer>
    )
}