import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {CheckFlags} from "../../../types/GeneralTypes";

export interface WarningsSummary {
    capital: {
        preForecast: number
    },
    maturity: {
        preForecast: number,
        updatedMaturity: number,
    },
    otherTransactions: {
        preForecast: number
    },
    sellRepay: {
        afterMaturity: number,
        exceed: number,
        preForecast: number,
    },
    transfer: {
        afterMaturity: number,
        exceed: number,
        preForecast: number,
    },
    wipCheck: {
        preForecast: number,
        updatedWip: number,
        unallocated: number,
        misallocated: number,
        missingData: number,
    },
    advise: number,
    alert: number
}

export const warningSelector = createSelector(
    (state: RootState) => state.capitalBudget.warnings || null,
    (warnings): WarningsSummary => {
        let summary = {
            capital: {
                preForecast: 0
            },
            maturity: {
                preForecast: 0,
                updatedMaturity: 0,
            },
            otherTransactions: {
                preForecast: 0
            },
            sellRepay: {
                afterMaturity: 0,
                exceed: 0,
                preForecast: 0,
            },
            transfer: {
                afterMaturity: 0,
                exceed: 0,
                preForecast: 0,
            },
            wipCheck: {
                preForecast: 0,
                updatedWip: 0,
                unallocated: 0,
                misallocated: 0,
                missingData: 0,
            },
            advise: 0,
            alert: 0
        }

        if (warnings) {
            // Summaries Capital Transactions
            warnings.capital.advise.forEach(() => {
                summary.advise++;
                summary.capital.preForecast++;
            });

            // Summaries Maturity Changes
            warnings.maturity.advise.forEach((w) => {
                summary.advise++;
                switch (w.flag) {
                    case CheckFlags.UPDATED_MATURITY:
                        summary.maturity.updatedMaturity++;
                        break;
                    case CheckFlags.PRE_FORECAST:
                        summary.maturity.preForecast++;
                        break;
                }
            })

            // Summaries Other Transactions
            warnings.otherTransactions.advise.forEach(() => {
                summary.advise++;
                summary.otherTransactions.preForecast++;
            });

            // Summaries SellRepay
            warnings.sellRepay.advise.forEach(() => {
                summary.advise++;
                summary.sellRepay.preForecast++;
            });
            warnings.sellRepay.alert.forEach((a) => {
                summary.alert++;
                switch (a.flag) {
                    case CheckFlags.AFTER_MATURITY:
                        summary.sellRepay.afterMaturity++;
                        break;
                    case CheckFlags.EXCEEDED:
                        summary.sellRepay.exceed++;
                        break;
                }
            });

            // Summaries Transfer
            warnings.transfer.advise.forEach(() => {
                summary.advise++;
                summary.transfer.preForecast++;
            });
            warnings.transfer.alert.forEach((a) => {
                summary.alert++;
                switch (a.flag) {
                    case CheckFlags.AFTER_MATURITY:
                        summary.transfer.afterMaturity++;
                        break;
                    case CheckFlags.EXCEEDED:
                        summary.transfer.exceed++;
                        break;
                }
            });

            // Summaries WIP
            warnings.wipCheck.advise.forEach((w) => {
                summary.advise++;
                switch (w.flag) {
                    case CheckFlags.PRE_FORECAST:
                        summary.wipCheck.preForecast++;
                        break;
                    case CheckFlags.UPDATED_WIP:
                        summary.wipCheck.updatedWip++;
                        break;
                    case CheckFlags.WIP_MISSING_DRAWDOWN:
                        summary.wipCheck.missingData++;
                        break;
                }
            });
            warnings.wipCheck.alert.forEach((w) => {
                summary.alert++;
                switch (w.flag) {
                    case CheckFlags.UNALLOCATED:
                        summary.wipCheck.unallocated++;
                        break;
                    case CheckFlags.MISALLOCATED:
                        summary.wipCheck.misallocated++;
                        break;
                    case CheckFlags.WIP_MISSING_COMMITMENT:
                        summary.wipCheck.missingData++;
                        break;
                    case CheckFlags.WIP_MISSING_TENOR:
                        summary.wipCheck.missingData++;
                        break;
                    case CheckFlags.WIP_MISSING_CLOSE_DATE:
                        summary.wipCheck.missingData++;
                        break;
                }
            });
        }

        return summary;
    }
)
