import {RootState} from "../../store";
import {createSelector} from "@reduxjs/toolkit";
import {fundSelector, fundsSelector, getForecastDate, periodSelector} from "./generalSelectors";
import {checkInPeriod, generatePeriods} from "../../../utils/DateUtils";
import {Capital} from "../../../types/forecastTypes";
import {addValues} from "../../../utils/mathUtil";
import {HorizontalTableRow} from "../../../components";
import {fCurrency} from "../../../utils/formatNumber";
import {CellType} from "../../../types/InputTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";

// Retrieves all capital transactions
export const retrieveAllCapitalTransactions = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.capital || [],
    (state: RootState) => state.capitalBudget.warnings?.capital.advise || [],
    (capitalTransactions, checks) => {
        return capitalTransactions.reduce((transactions: Array<Capital>, t) => {
            if (t.status === SaveStatus.REMOVED) return transactions;

            const check = checks.find(c => c.id === t.id);
            transactions.push({
                ...t,
                ...(check) ? {advise: check.flag} : {}
            })

            return transactions;
        }, [])
    }
)

// Retrieves All Capital Transactions and filters by fund if necessary
export const retrieveCapitalTransactions = createSelector(
    retrieveAllCapitalTransactions,
    fundSelector,
    fundsSelector,
    (capital, fund, funds) => {
        if (fund) {
            // Filters Capital by fund and calculates allocated portions from feeders.
            const feeders = funds.reduce((feedingFunds, f) => {
                if (f.allocations) {
                    f.allocations.forEach(a => {
                        if (a.fund === fund.label) {
                            feedingFunds.set(f.label, {allocation: a.allocation});
                        }
                    })

                }

                return feedingFunds
            }, new Map())

            return capital.reduce((transactions: Array<Capital>, c) => {
                if (c.fund === fund.label) transactions.push(c);

                const feed = feeders.get(c.fund);

                if (feed) {
                    transactions.push({
                        ...c,
                        amount: c.amount * feed.allocation
                    })
                }

                return transactions;
            }, []);
        }
        return capital;
    }
);

// Retrieves and formats Capital Table to reflect capital across forecast
export const capitalTableSelector = createSelector(
    retrieveCapitalTransactions,
    periodSelector,
    getForecastDate,
    (capitalTransactions, budgetPeriods, forecastDate) => {
        if (!forecastDate) return {rows: [], columns: []}

        const {
            weeks,
            months
        } = generatePeriods(budgetPeriods.weeks, budgetPeriods.months, forecastDate);

        // Group Capital Transactions by entity
        let entities = capitalTransactions.reduce((entities: Array<{
            name: string,
            transactions: Array<Capital>
        }>, ct) => {

            const entityIndex = entities.findIndex(e => e.name === ct.name);

            if (entityIndex > -1) {
                entities[entityIndex].transactions.push(ct)
            } else {
                entities.push({
                    name: ct.name,
                    transactions: [ct]
                })
            }

            return entities;
        }, [])

        months[0].sx = {borderLeft: 2, borderLeftColor: 'primary.main'};

        const periods = [...weeks, ...months];

        periods.forEach((period) => {
            entities.forEach(entity => {
                let amount = 0;

                entity.transactions.forEach(t => {
                    if (checkInPeriod(t.date, period)) {
                        amount = addValues(amount, t.amount);
                    }
                })

                period[entity.name] = amount;
                period.total = addValues(period.total, amount);
            })
            period.sx = {...period.sx, minWidth: 120}
        })

        let rows: Array<HorizontalTableRow> = entities.map(entity => {
            return {
                formatter: fCurrency,
                id: entity.name,
                label: entity.name,
                headSX: {minWidth: 200},
                type: CellType.CURRENCY
            }
        })

        rows = rows.sort((a, b) => (a.label > b.label) ? 1 : -1)

        if (rows.length > 0) {
            rows.push({
                formatter: fCurrency,
                id: 'total',
                label: 'Total',
                headSX: {
                    minWidth: 200,
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    bgcolor: 'primary.light',
                    borderBottomColor: 'primary.main',
                    fontWeight: 'bold',
                    color: 'common.white',
                },
                type: CellType.CURRENCY,
                sx: {
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    fontWeight: 'bold',
                    color: 'common.white',
                    bgcolor: 'primary.light',
                }
            })
        }

        return {
            rows,
            columns: periods
        }
    }
)

// Retrieve All unique Capital transactions entitiies
export const retrieveAllCapitalEntities = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.capital || [],
    (capitalTransactions) => {
        return capitalTransactions.reduce((entities: Array<string>, ct: Capital) => {
            if (!entities.includes(ct.name)) {
                entities.push(ct.name)
            }

            return entities
        }, [])
    }
)

// Retrieves Capital Transactions for calculations whether base or current
export const retrieveCapitalTransactionsForCalc = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.capital || [],
    (_state: RootState, base?: boolean) => (base),
    (capital, base) => {
        return capital.reduce((transactions: Array<Capital>, t) => {
            if (base) {
                if (t.status === SaveStatus.NEW) return transactions;
                else if ((t.status === SaveStatus.EDITED && t.previous) || (t.status === SaveStatus.REMOVED && t.previous)) transactions.push(t.previous);
                else transactions.push(t);
            } else {
                if (t.status === SaveStatus.REMOVED) return transactions;
                else transactions.push(t)
            }
            return transactions;
        }, []);
    }
)