import {useState} from 'react';
import {WIPLoan} from "../../types/externalDataTypes";
// MUI
import {Grid, Container} from '@mui/material';
// Components
import {Page} from "../../layouts/components";
import {
    SideDrawer,
    DataTable,
    valueDate,
    valuePercentage,
    valueCurrency
} from "../../components";
import ForecastConfigBar from "./ForecastConfigBar";
// Store
import {useAppSelector} from "../../store/store";
import {retrieveAllWIPLoans, retrieveNewLoansAllocations} from "../../store/capitalBudget/selectors/wipSelector";
import {fundSelector, underlyingFundsSelector} from "../../store/capitalBudget/selectors/generalSelectors";
import WIPDataDisplay from "../../sections/Budget/Configurations/WIPDataDisplay";
import {manualDealsSelector} from "../../store/capitalBudget/selectors/manualDealSelector";
import {ManualDeal} from "../../types/forecastTypes";
import ManualLoanDataDisplay from "../../sections/Budget/Configurations/ManualLoanDataDisplay";
import {GridColDef} from "@mui/x-data-grid";
import {generalRow} from "../../components/DataTable/DataTableUtils";

// Data
let columns: GridColDef[] = [
    {field: 'ncinoId', headerName: 'nCino ID', width: 180, ...generalRow, hide: true},
    {field: 'name', headerName: 'Name', flex: 1, minWidth: 300, ...generalRow, cellClassName: () => 'name-stick'},
    {field: 'owner', headerName: 'Owner', minWidth: 150, ...generalRow},
    {field: 'industry', headerName: 'Industry', flex: 0.5, minWidth: 225, ...generalRow},
    {field: 'adjustedCloseDate', headerName: 'Projected Close', ...valueDate, width: 150},
    {field: 'rating', headerName: 'Metrics Rating', ...generalRow, width: 125},
    {
        field: 'adjustedTenor',
        headerName: 'Tenor (yrs)', ...generalRow,
        width: 100,
        valueFormatter: (value) => (value.value && value.value !== 0) ? parseFloat(value.value).toFixed(2) : undefined,
        align: 'right'
    },
    {field: 'upfront', headerName: 'Upfront', ...valuePercentage, width: 100, hide: true},
    {field: 'margin', headerName: 'Margin', ...valuePercentage, width: 100, hide: true},
    {field: 'lineFee', headerName: 'Line', ...valuePercentage, width: 100, hide: true},
    {...valueCurrency, field: 'adjustedCommitment', headerName: 'Commitment', width: 175},
    {field: 'percentageUndrawn', headerName: '% Undrawn at Close', ...valuePercentage, width: 175},
    {...valueCurrency, field: 'adjustedDrawdown', headerName: 'Drawn', width: 175, cellClassName: () => 'fund-border'},
]

// Capital Budget Summary App Page
export default function NewCommitments() {

    const loading = useAppSelector(state => state.forecast.loading);
    const wip = useAppSelector(state => retrieveAllWIPLoans(state));
    const newLoans = useAppSelector(state => retrieveNewLoansAllocations(state, false));
    const manualLoans = useAppSelector(state => manualDealsSelector(state));
    const fund = useAppSelector(state => fundSelector(state));
    const funds = useAppSelector(state => underlyingFundsSelector(state));

    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [selectedLoan, setSelected] = useState<WIPLoan | null>(null);
    const [selectedManualLoan, setSelectedManualLoan] = useState<ManualDeal | null>(null);

    // HANDLES Selection of loan to show data
    const handleSelectNewLoan = (row: string | null) => {
        if (!row) {
            setSelectedId(null)
            setSelected(null);
            setSelectedManualLoan(null)
        }
        const newLoan = newLoans.find(l => l.ncinoId === row);
        if (newLoan) {
            if (newLoan?.manual) {
                const loan = manualLoans.find(l => l.id === newLoan?.manual);
                setSelectedId(row);
                setSelectedManualLoan(loan as any);
            } else {
                const loan = wip.find(l => l.ncino_id === newLoan?.ncinoId);
                setSelectedId(row);
                setSelected(loan as any);
            }
        }
    }

    return (
        <>
            <Page title="Metrics - CB - New Commitments">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='New Commitments'/>
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                <DataTable
                                    title={'Loans'}
                                    columns={[
                                        ...columns,
                                        ...(fund && fund.type === 'UNDERLYING' ?
                                                [{
                                                    ...valueCurrency,
                                                    field: fund.label,
                                                    headerName: fund.label,
                                                    width: 120,
                                                }]
                                                :
                                                [...funds.map(f => ({
                                                    ...valueCurrency, field: f.label, headerName: f.label, width: 120,
                                                }) as GridColDef)]
                                        )

                                    ]}
                                    data={newLoans}
                                    loading={loading}
                                    density='compact'
                                    selectedRow={(selectedId || null)}
                                    selectRow={(row) => handleSelectNewLoan(row as string)}
                                    selectKey='ncinoId'
                                    showColumns
                                    showExport
                                    search
                                    sort={{field: 'adjustedCloseDate', sort: 'asc'}}
                                    additionalDataGridSx={{
                                        '& .name-stick': {
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 1,
                                            bgcolor: 'white'
                                        },
                                        '& .fund-border': {borderRight: 2}
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Container>
            </Page>
            {selectedLoan &&
                <SideDrawer
                    title='Details'
                    open={!!selectedLoan}
                    onClose={() => setSelected(null)}
                >
                    <WIPDataDisplay
                        loan={selectedLoan}
                        onClose={() => setSelected(null)}
                    />
                </SideDrawer>
            }
            {selectedManualLoan &&
                <SideDrawer
                    title='Details'
                    open={!!selectedManualLoan}
                    onClose={() => setSelectedManualLoan(null)}
                >
                    <ManualLoanDataDisplay
                        loan={selectedManualLoan}
                        onClose={() => setSelectedManualLoan(null)}
                    />
                </SideDrawer>
            }
        </>
    )
}