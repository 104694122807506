// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {CapitalBudgetState} from "../capitalBudgetSlice";

// Reducer Related to Capital Budget - FUM
const fumReducers: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // UPDATE FUM
    updateFUM: (state, action: PayloadAction<{[x: string]: number}>) => {
        if (state.forecastData) {
            let currentFUM = state.forecastData.fum;

            currentFUM.forEach(fund => {
                if (action.payload[fund.fund]) {
                    fund.amount = action.payload[fund.fund];
                }
            })

            state.forecastData.fum = currentFUM;
            state.changes = true;
        }
    }
}

export default fumReducers;