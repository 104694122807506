import {HorizontalTableRow} from "../../../../components";
import {fCurrency} from "../../../../utils/formatNumber";
import {CellType} from "../../../../types/InputTypes";

export const SourcesUsesRows: Array<HorizontalTableRow> = [
    {
        id: 'cashBalanceOpening',
        label: 'Cash Opening',
        headSX: {
            minWidth: 250,
            bgcolor: 'info.dark',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'primary.main',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        formatter: fCurrency, type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'sourceHeader',
        label: 'Sources',
        headSX: {minWidth: 250, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main'}
    },
    {id: 'subscriptions', label: 'Investor Inflows', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'netRepayments',
        label: 'Net Loan Repayments',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        subRows: [
            {id: 'expectedRepaymentsCash', label: 'Loan Repayments', formatter: fCurrency, type: CellType.CURRENCY},
            {
                id: 'earlyRepaymentsCash',
                label: 'Notified Early Repayments',
                formatter: fCurrency,
                type: CellType.CURRENCY
            },
            {id: 'extensionCash', label: 'Extended Loan Repayments', formatter: fCurrency, type: CellType.CURRENCY},
            {
                id: 'selldownsCash',
                label: 'Asset Sell Downs/Partial Repayments',
                formatter: fCurrency,
                type: CellType.CURRENCY
            },
            {id: 'transfersOutCash', label: 'Transfers Out', formatter: fCurrency, type: CellType.CURRENCY},
            {id: 'transfersInOffsetCash', label: 'Transfers In Offset', formatter: fCurrency, type: CellType.CURRENCY},
        ]
    },
    {id: 'debtDraw', label: 'Required Debt Drawn/Asset Transfer', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'adjustmentsPos',
        label: 'Other Adjustments',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {borderColor: 'primary.main'}
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'usesHeader',
        label: 'Uses',
        headSX: {minWidth: 150, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main'}
    },
    {id: 'firstDrawdown', label: 'New Loans (First Drawdown at FC)', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'usesRepaymentOffset',
        label: '',
        subRows: [
            {
                id: 'extensionOffsetCash',
                label: 'IC Approved Extended Loans',
                formatter: fCurrency,
                type: CellType.CURRENCY
            },
            {id: 'earlyRepOffsetCash', label: 'Early Repayments Offset', formatter: fCurrency, type: CellType.CURRENCY},
            {
                id: 'selldownOffsetCash',
                label: 'Asset Sell Downs/Partial Repayments Offset',
                formatter: fCurrency,
                type: CellType.CURRENCY
            },
            {id: 'transfersInCash', label: 'Transfers In', formatter: fCurrency, type: CellType.CURRENCY},
            {
                id: 'transfersOutOffsetCash',
                label: 'Transfers Out Offset',
                formatter: fCurrency,
                type: CellType.CURRENCY
            },
        ]
    },
    {
        id: 'redemptionOffset',
        label: 'Investor Redemptions (Incl. 1 period buffer)',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {id: 'creCTC', label: 'CRE (CTC)', formatter: fCurrency, type: CellType.CURRENCY},
    // {id: 'corpCTC', label: 'Corporate (CTC)', formatter: fCurrency, type: CellType.CURRENCY},
    {id: 'cashDistribution', label: 'Cash Distribution Net of DRP', formatter: fCurrency, type: CellType.CURRENCY},
    {
        id: 'revolverDrawdown',
        label: 'Confirmed drawdowns from Revolvers',
        formatter: fCurrency,
        type: CellType.CURRENCY
    },
    {
        id: 'adjustmentsNeg',
        label: 'Other Adjustments',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {borderColor: 'info.lighter'}
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'total',
        label: 'Net Sources and Uses',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'cashBalanceClosing',
        label: 'Cash Closing (assuming no RCF, Capex draws)',
        headSX: {minWidth: 250, bgcolor: 'primary.light', fontWeight: 'bold', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.light', fontWeight: 'bold', color: 'white'},
        formatter: fCurrency, type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'debtFacilitiesHeader',
        label: 'Debt Facilities',
        headSX: {minWidth: 250, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main', textAlign: 'center'},
        subRows: [
            {
                id: 'newFacility',
                label: 'Changes in Bank Facilities (In Pipeline)',
                formatter: fCurrency,
                type: CellType.CURRENCY,
                headSX: {borderColor: 'secondary.light'}
            },
        ]
    },
];

export const sourcesUsesRowsSecond: Array<HorizontalTableRow> = [

    {
        id: 'totalAvaFacility',
        label: 'Total Available Facilities',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            minWidth: 250,
            bgcolor: 'info.dark',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'primary.main',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.dark', fontWeight: 'bold'},
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'netLiquidityHeader',
        label: 'Net Liquidity',
        headSX: {minWidth: 250, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main', textAlign: 'center'},
        subRows: [
            {id: 'revolversUndrawn', label: 'Revolvers', formatter: fCurrency, type: CellType.CURRENCY},
            {id: 'capexUndrawn', label: 'Capex', formatter: fCurrency, type: CellType.CURRENCY},
            {
                id: 'otherUndrawn',
                label: 'Other',
                formatter: fCurrency,
                type: CellType.CURRENCY,
                sx: {borderColor: 'secondary.light'}
            },

        ]
    },
    {
        id: 'totalLiquidity',
        label: 'Undrawn Revolving Loan Commitments',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            minWidth: 250,
            bgcolor: 'info.dark',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'primary.main',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.dark', fontWeight: 'bold'},
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'liquidityCommitment',
        label: 'Net Committed Liquidity',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {minWidth: 250, bgcolor: 'primary.light', fontWeight: 'bold', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.light', fontWeight: 'bold', color: 'white'},
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'unusedFacilities',
        label: 'Fund Finance (uncommited)',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
    {
        id: 'netLiquidity',
        label: 'Net Total Liquidity',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {minWidth: 250, bgcolor: 'primary.light', fontWeight: 'bold', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.light', fontWeight: 'bold', color: 'white'},
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'creUndrawn',
        label: 'Balance of Cost To Complete',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
    {
        id: 'surplusDeficit',
        label: 'Surplus/Deficit',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {minWidth: 250, bgcolor: 'primary.light', fontWeight: 'bold', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.light', fontWeight: 'bold', color: 'white'},
    },
];