import {useState} from "react";
import clsx from "clsx";
import {addValues} from "../../utils/mathUtil";
import {formatNameInitial} from "../../utils/generalUtils";
import {fCurrency} from "../../utils/formatNumber";
// MUI
import {Box, Checkbox, Container, FormControlLabel, Grid, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
import {DataTable, valueCurrency, valueDate} from "../../components";
// store
import {useAppSelector} from "../../store/store";
import {
    retrieveFundFinanceSelector
} from "../../store/capitalBudget/selectors/fundFinanceSelector";

const fundFinanceClassName = (params: GridCellParams) => {
    return clsx('', {
        'selected-parent': (!!params.row.fundFinanceId && params.row.parent),
        'unselected-parent': (!params.row.fundFinanceId && params.row.parent),
        'unselected-child': (!params.row.fundFinanceId && !params.row.parent),
    });
};

export const FundFinanceColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Deal',
        flex: 1,
        minWidth: 250,
        cellClassName: fundFinanceClassName
    },
    {
        field: 'adjustedMasterMaturity',
        headerName: 'Master Maturity',
        ...valueDate,
        width: 130,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'owner',
        headerName: 'Owner',
        width: 120,
        valueFormatter: (params) => formatNameInitial(params.value),
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'trancheId',
        headerName: 'Tranche ID',
        width: 90,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'currentBalance',
        headerName: 'Drawn',
        width: 120,
        ...valueCurrency,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'undrawnBalance',
        headerName: 'Undrawn',
        width: 120,
        ...valueCurrency,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'currentLimit',
        headerName: 'Commitment',
        width: 120,
        ...valueCurrency,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'maturity',
        headerName: 'Maturity',
        ...valueDate,
        width: 130,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
    {
        field: 'adjustedMasterLimit',
        headerName: 'Master Limit',
        width: 120,
        ...valueCurrency,
        cellClassName: (params: GridCellParams) => {
            return clsx('', {
                'selected-parent': (!!params.row.fundFinanceId && params.row.parent),
                'unselected-parent': (!params.row.fundFinanceId && params.row.parent),
                'unselected-child': (!params.row.fundFinanceId && !params.row.parent),
                'master-border': true
            });
        },
        sortable: false
    },
    {
        field: 'unusedMaster',
        headerName: 'Unused Limit',
        ...valueCurrency,
        width: 130,
        cellClassName: fundFinanceClassName,
        sortable: false
    },
]

export default function FundFinance() {

    const loading = useAppSelector(state => state.forecast.loading);
    const fundFinances = useAppSelector(state => retrieveFundFinanceSelector(state, true));

    const [show, setShow] = useState<boolean>(true)

    let totals = {
        'Master Limit': 0,
        Drawn: 0,
        Undrawn: 0,
        Commitment: 0,
        'Unused Limit': 0,
    }

    const data = fundFinances.filter(f => {
        if (f.fundFinanceId) {
            totals['Master Limit'] = addValues(totals['Master Limit'], f.adjustedMasterLimit);
            totals.Drawn = addValues(totals.Drawn, f.currentBalance);
            totals.Undrawn = addValues(totals.Undrawn, f.undrawnBalance);
            totals.Commitment = addValues(totals.Commitment, f.currentLimit);
            totals['Unused Limit'] = addValues(totals['Unused Limit'], f.unusedMaster);
        }

        return !!f.fundFinanceId
    }).sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }).reduce((finances: Array<any>, f) => {
        finances.push({
            ...f,
            parent: true
        });
        if (show) {
            f.loans.forEach((loan: any) => {
                finances.push({
                    id: `${f.id}${loan.trancheId}` || loan.ncinoId,
                    ...loan
                })
            })
        }
        return finances;
    }, []);

    return (
        <>
            <Page title="Metrics - CB - Refinances">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Refinances'/>
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <Grid container direction='column' sx={{width: '100%', height: '100%'}}>
                                <DataTable
                                    title='Fund Finances'
                                    columns={[
                                        ...FundFinanceColumns,
                                    ]}
                                    data={data}
                                    loading={loading}
                                    additionalDataGridSx={{
                                        '& .selected-parent': {
                                            bgcolor: 'info.lighter',
                                            fontWeight: 'bold',
                                        },
                                        '& .unselected-parent': {
                                            bgcolor: 'grey.400',
                                            fontWeight: 'bold',
                                        },
                                        '& .unselected-child': {
                                            bgcolor: 'grey.300',
                                        },
                                        '& .master-border': {borderLeft: 2}
                                    }}
                                    customFilterComponent={
                                        <ShowTranches show={show} setShow={setShow}/>
                                    }
                                />
                                <Box sx={{bgcolor: 'primary.main', borderRadius: 1}}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                {FundFinanceColumns.map((column, c) => {
                                                    const sx = {color: 'common.white', ...(column.flex) ? {flex: column.flex} : {...(column.width) ? {width: column.width} : {}}, ...(column.minWidth) ? {minWidth: column.minWidth} : {}}
                                                    const totalCol = totals[column.headerName as keyof typeof totals];

                                                    return (
                                                        <TableCell
                                                            key={c}
                                                            align={column.align}
                                                            sx={sx}
                                                            style={{fontWeight: 'bold'}}
                                                        >
                                                            {c === 0 &&
                                                                <>Total (Selected)</>
                                                            }
                                                            {c > 2 && totalCol && (
                                                                <>
                                                                    {fCurrency(totalCol)}
                                                                </>
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    )
}

function ShowTranches({show, setShow}: { show: boolean, setShow: (show: boolean) => void }) {
    return (
        <Box sx={{px: 1}}>
            <FormControlLabel
                control={<Checkbox checked={!show} onChange={() => setShow(!show)} color={'secondary'}/>}
                label='Hide Loans:'
                labelPlacement='start'
            />
        </Box>
    )
}