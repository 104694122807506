import {memo, useState} from "react";
import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";
import {FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";
import {LoanTags} from "../../../../types/capitalBudgetEnums";
import {addValues} from "../../../../utils/mathUtil";
import {
    Box,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {Add as AddIcon, Remove as RemoveIcon} from '@mui/icons-material';
import DrillDownSimple from "../../../../components/DrillDownsSimple";
import {ratingSelection} from "../../../../utils/CapitalBudgetUtils";
import {fCurrency, fPercent} from "../../../../utils/formatNumber";
import {valueCurrency} from "../../../../components";
import LoansDrilldownTable from "./LoansDrilldownTable";

interface CreditRatingsDrillDownProps {
    period: ForecastPeriod,
    column: investmentReportColumn,
    avaCapToggle: boolean,
    fund: FundDetails | null
}

function CreditRatingsDrillDown({period, column, avaCapToggle, fund}: CreditRatingsDrillDownProps) {

    let ratings: { [x: string]: number } = {}

    period.book.filter((l) => (l.tags.includes(LoanTags.ACTIVE))).forEach((loan) => {
        if (ratings[loan.rating || 'NR']) {
            ratings[loan.rating || 'NR'] = addValues(ratings[loan.rating || 'NR'], loan.updatedValue);
        } else {
            ratings[loan.rating || 'NR'] = loan.updatedValue;
        }
    })

    if (avaCapToggle) {
        if (ratings['aa-']) ratings['aa-'] = addValues(ratings['aa-'], column.availCap);
        else ratings['aa-'] = column.availCap;
    }

    const [invGradeExpand, setInvGradeExpand] = useState<boolean>(false);
    const [subGradeExpand, setSubGradeExpand] = useState<boolean>(false);
    const [rating, setRating] = useState<string>(Object.keys(ratings)[0]);

    return (
        <>
            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto', minHeight: 800}}>
                <Box>
                    <Grid>
                        <Table size="small">
                            <TableBody>
                                <TableRow sx={{bgcolor: 'primary.light'}}>
                                    <TableCell colSpan={3} sx={{}}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <IconButton
                                                sx={{color: 'white'}}
                                                onClick={() => setInvGradeExpand(!invGradeExpand)}
                                            >
                                                {invGradeExpand ?
                                                    <RemoveIcon/>
                                                    :
                                                    <AddIcon/>
                                                }
                                            </IconButton>
                                            <Typography
                                                variant='h6'
                                                sx={{color: 'white'}}
                                            >
                                                Investment Grade {fPercent(column.investmentGrade)}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Typography
                                            variant='h6'
                                            sx={{color: 'white'}}
                                        >
                                            {fCurrency(ratingSelection.slice(0, 9).filter(rating => !!ratings[rating.value]).reduce((total, rating) => {
                                                return addValues(total, ratings[rating.value]);
                                            }, 0),)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        {invGradeExpand &&
                            <DrillDownSimple
                                data={[
                                    ...ratingSelection.slice(0, 9).filter(rating => !!ratings[rating.value]).map(rating => {
                                        return {
                                            label: rating.label,
                                            value: ratings[rating.value],
                                        }
                                    }),
                                ]}
                            />
                        }
                        <Table size="small">
                            <TableBody>
                                <TableRow sx={{bgcolor: 'primary.light'}}>
                                    <TableCell colSpan={3} sx={{}}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <IconButton
                                                sx={{color: 'white'}}
                                                onClick={() => setSubGradeExpand(!subGradeExpand)}
                                            >
                                                {subGradeExpand ?
                                                    <RemoveIcon/>
                                                    :
                                                    <AddIcon/>
                                                }
                                            </IconButton>
                                            <Typography
                                                variant='h6'
                                                sx={{color: 'white'}}
                                            >
                                                Investment Grade {fPercent(column.subInvestmentGrade)}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Typography
                                            variant='h6'
                                            sx={{color: 'white'}}
                                        >
                                            {fCurrency(ratingSelection.slice(9).filter(rating => !!ratings[rating.value]).reduce((total, rating) => {
                                                return addValues(total, ratings[rating.value]);
                                            }, 0))}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        {subGradeExpand &&
                            <DrillDownSimple
                                data={[
                                    ...ratingSelection.slice(9).filter(rating => !!ratings[rating.value]).map(rating => {
                                        return {
                                            label: rating.label,
                                            value: ratings[rating.value],
                                        }
                                    }),
                                ]}
                            />
                        }
                    </Grid>


                    <DrillDownSimple
                        data={[
                            {
                                label: 'Total',
                                value: (avaCapToggle) ? column.analyAUM : column.totalCommitment,
                                textColor: 'white',
                                sx: {bgcolor: 'primary.main'}
                            },
                        ]}
                    />
                </Box>
                <Box sx={{py: 1, flex: 1, minHeight: 200}}>
                    <Box sx={{height: '99%'}}>
                        <LoansDrilldownTable
                            title={`${(rating || '').toUpperCase()} Rated Loans`}
                            period={period}
                            fund={fund}
                            additionalColumns={[
                                {
                                    field: 'value',
                                    headerName: 'Commitment',
                                    ...valueCurrency,
                                    width: 120
                                }
                            ]}
                            filterFunction={(loan: CalculationLoanType) => ((loan.tags.includes(LoanTags.ACTIVE) && (loan.rating === rating)))}
                            customFilterComponent={<RatingFilter rating={rating} setRating={setRating} ratings={ratingSelection.filter(r => !!ratings[r.value])}/>}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

function RatingFilter({rating, setRating, ratings}: { rating: string, setRating: (rank: string) => void, ratings: Array<{label: string, value: string}> }) {
    return (
        <>
            <Box sx={{px: 1}}>
                Debt Seniority:
                <Select
                    value={rating}
                    onChange={(e) => setRating(e.target.value as string)}
                    sx={{minWidth: 100, color: 'common.white'}}
                    label={'Rating'}
                    size={"small"}
                >
                    {ratings.map(r => (
                        <MenuItem value={r.value} key={r.value}>{r.label}</MenuItem>
                    ))}
                </Select>
            </Box>
        </>
    )
}

export default memo(CreditRatingsDrillDown);