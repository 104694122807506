import {useState} from 'react';
// MUI
import {Grid, Container, IconButton} from '@mui/material';
// Components
import {Page} from "../../../layouts/components";
import ForecastConfigBar from "../ForecastConfigBar";
import {
    actionRow,
    AlertDialog,
    DataTable,
    SideDrawer,
    WarningsIcon
} from "../../../components";
// Store
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {retrieveSelldownRepayments} from "../../../store/capitalBudget/selectors/selldownRepaymentsSelector";
import SelldownRepaymentsForm from "../../../sections/Budget/Configurations/SelldownRepaymentsForm";
import {
    addMultipleSelldownRepayment,
    removeSelldownRepayment,
    updateSelldownRepayment
} from "../../../store/capitalBudget/capitalBudgetSlice";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {warningDataGridSx, warningsGeneralRow, warningValueCurrency, warningValueDate} from "./DataTableWarningsUtilts";
import {warningCellClassName} from "./OtherTransactions";
import TrancheSelldownRepayForm from "../../../sections/Budget/Configurations/TrancheSelldownRepayForm";

// Data
// ----------------------------------------------------------------------
// Column definitions
let columns: GridColDef[] = [
    {
        field: 'warning',
        headerName: '',
        align: 'center',
        ...warningsGeneralRow,
        width: 50,
        renderCell: (params: GridCellParams) => (
            <>
                {!!params.row.alert ?
                    <WarningsIcon value={params.row.alert}/>
                    :
                    (!!params.row.advise && <WarningsIcon value={params.row.advise}/>)
                }
            </>
        ),
    },
    {field: 'trancheId', headerName: 'Tranche ID', width: 100, ...warningsGeneralRow},
    {
        field: 'borrower',
        headerName: 'Borrower',
        flex: 1,
        ...warningsGeneralRow
    },
    {
        field: 'fund',
        headerName: 'Fund',
        ...warningsGeneralRow,
    },
    {
        field: 'date',
        headerName: 'Date',
        ...warningValueDate,
        width: 120,
    },
    {
        ...warningValueCurrency,
        field: 'amount',
        headerName: 'Amount',
        minWidth: 150,
    },
    {
        ...warningValueCurrency,
        field: 'commitment',
        headerName: 'Original Commitment',
        minWidth: 175,
    },
];
// ----------------------------------------------------------------------


// Capital Budget Summary App Page
export default function SellRepay() {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(state => state.forecast.loading);
    const selldownsRepayments = useAppSelector(state => retrieveSelldownRepayments(state));

    // Create and Update selldown repayment
    const [form, setFormOpen] = useState<any | null>(null);

    // Sends an editable version on selldown repayment to update form
    const handleEditTransaction = (row: number) => {
        const transaction = selldownsRepayments.find(t => t.id === row);
        if (transaction) {
            setFormOpen({
                id: row,
                trancheId: transaction.trancheId,
                fund: transaction.fund,
                date: transaction.date,
                amount: transaction.amount
            })
        }
    }

    // Remove selldown repayment
    const [openDelete, setOpenDelete] = useState<number | null>(null)

    return (
        <>
            <Page title="Metrics - CB - Capital Changes">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Selldowns and Partial Repayments' configuration/>
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                                <DataTable
                                    title='Selldowns and Repayments'
                                    data={selldownsRepayments}
                                    loading={loading}
                                    sort={{field: 'date', sort: 'asc'}}
                                    columns={[
                                        ...columns,
                                        {
                                            ...actionRow,
                                            renderCell: (params: GridCellParams) => (
                                                <>
                                                    <IconButton
                                                        sx={{color: 'primary.main'}}
                                                        style={{padding: '0px', outline: 'none'}}
                                                        onClick={() => handleEditTransaction(params.row.id)}
                                                        size="small">
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{color: 'primary.main'}}
                                                        style={{padding: '0px', outline: 'none'}}
                                                        onClick={() => setOpenDelete(params.row.id)}
                                                        size="small">
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </>
                                            ),
                                            cellClassName: warningCellClassName
                                        }
                                    ]}
                                    search
                                    create={() => setFormOpen(true)}
                                    showExport={false}
                                    additionalDataGridSx={warningDataGridSx}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
            <SideDrawer
                title='Create Selldown/Partial Repayment'
                open={(!!form)}
                onClose={() => setFormOpen(null)}
                size={'large'}
            >
                {form?.id ?
                    <SelldownRepaymentsForm
                        editValues={form}
                        onClose={() => setFormOpen(null)}
                        editTransaction={(values) => {
                            dispatch(updateSelldownRepayment(values));
                            setFormOpen(null);
                        }}
                    />
                    :
                    <TrancheSelldownRepayForm
                        onClose={() => setFormOpen(null)}
                        submitTransactions={(values) => {
                            console.log(values)
                            dispatch(addMultipleSelldownRepayment(values));
                            setFormOpen(null);
                        }}
                    />
                }
            </SideDrawer>
            <AlertDialog
                title='Delete Selldown/Partial Repayment'
                open={!!openDelete}
                handleClose={() => setOpenDelete(null)}
                handleConfirm={() => {
                    dispatch(removeSelldownRepayment(openDelete))
                    setOpenDelete(null)
                }}
            />
        </>
    )
}