import {WIPComment} from "../../../types/forecastTypes";
import {WIPLoan} from "../../../types/externalDataTypes";
import {RefinanceChecks, WipCommentCheck} from "../../../types/capitalBudgetTypes";
import {SaveStatus} from "../../../types/capitalBudgetEnums";

// Checks for wip Comments that no longer correspond with a pipeline loan.
export function wipCommentsCheck(wipComments: Array<WIPComment>, pipeline: Array<WIPLoan>): WipCommentCheck {
    const checks: RefinanceChecks = {
        remove: []
    };

    wipComments.forEach((wipComment) => {
        if (wipComment.status === SaveStatus.REMOVED) return;
        const loan = pipeline.find(l => l.ncino_id === wipComment.ncinoId);
        if (!loan) checks.remove.push(wipComment.id);
    });

    return checks;
}