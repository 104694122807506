import {useState} from "react";
import {cleanHTML} from "../../../utils/generalUtils";
import {
    Box,
    Button,
    IconButton,
    Typography
} from "@mui/material";
import {Close as CloseIcon, Edit as EditIcon, Launch as LaunchIcon} from "@mui/icons-material";
import {DataDisplay} from "../../../components";
import {formatDate} from "../../../utils/DateUtils";
import {fCurrency, fPercent} from "../../../utils/formatNumber";
import {MCPRatings} from "../../../types/capitalBudgetEnums";
import {WIPLoan} from "../../../types/externalDataTypes";
import {WIPCommentEdit} from "./WIPCommentEdit";
import {useDispatch} from "react-redux";
import {addWIPComment, removeWIPComment, updateWIPComment} from "../../../store/capitalBudget/capitalBudgetSlice";
import {WIPComment} from "../../../types/forecastTypes";
import {useAppSelector} from "../../../store/store";
import {retrieveWIPComment} from "../../../store/capitalBudget/selectors/wipSelector";

export default function WIPDataDisplay({loan, onClose}: { loan: WIPLoan, onClose: () => void }) {
    const dispatch = useDispatch();

    const [editComment, setComment] = useState<{
        id: string | number | null,
        ncinoId: string,
        notes: string
    } | null>(null);
    const comment: WIPComment | null = useAppSelector(state => retrieveWIPComment(state, loan.ncino_id));

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <IconButton size='small' onClick={() => onClose()}>
                    <CloseIcon/>
                </IconButton>
            </Box>
            <Box sx={{px: 5, pt: 1, mt: -4}}>
                <DataDisplay
                    dataValues={[
                        {label: 'Asset Name', data: loan.name},
                        {label: 'Status', data: loan.llc_bi_stage},
                        {label: 'Owner', data: (loan.owner && loan.owner.name) ? loan.owner.name : null},
                        {
                            label: 'Origination Director',
                            data: (loan.llc_bi_product_package && loan.llc_bi_product_package.primary_origination_director) ? loan.llc_bi_product_package.primary_origination_director?.name : null
                        },
                        {
                            label: 'Deal Financial Close',
                            data: (loan.llc_bi_product_package && loan.llc_bi_product_package.project_financial_close_date) ? formatDate(new Date(loan.llc_bi_product_package.project_financial_close_date), 'dd-MM-yyyy') : null
                        },
                        {label: 'Tranche Type', data: loan.llc_bi_product},
                        {label: 'Ranking', data: loan.ranking_debt_seniority},
                        {
                            label: 'Commitment',
                            data: (loan.llc_bi_amount) ? fCurrency(loan.llc_bi_amount) : null
                        },
                        {
                            label: 'Expected Drawdown at Close',
                            data: (loan.expected_drawdown_at_close) ? fCurrency(loan.expected_drawdown_at_close) : null
                        },
                        {
                            label: 'Tenor',
                            data: (loan.remaining_tenor) ? loan.remaining_tenor.toString() : null
                        },
                        {
                            label: 'Rating',
                            data: (loan.metrics_rating) ? MCPRatings[loan.metrics_rating] : null
                        },
                        {label: 'Industry Segment', data: loan.llc_bi_product_package?.industry},
                        {label: 'Approval Stage', data: loan.llc_bi_stage},
                        {label: 'Deal Status', data: loan.llc_bi_product_package?.llc_bi_status},
                        {
                            label: 'Fees', subData: [...loan.llc_bi_fees.map(fee => {
                                return [
                                    {label: 'Type', data: fee.llc_bi_fee_type},
                                    {label: 'Percentage', data: fPercent(fee.llc_bi_percentage / 100)},
                                    {label: 'Amount', data: fCurrency(fee.llc_bi_amount)},
                                    {label: 'Calculation', data: fee.llc_bi_calculation_type},
                                ]
                            })]
                        },
                        {
                            label: 'Pricing', subData: [...loan.llc_bi_pricing_streams.map(stream => {
                                return [
                                    {
                                        label: 'Date Effective',
                                        data: formatDate(new Date(stream.llc_bi_effective_date), 'dd-MM-yyyy')
                                    },
                                    {label: 'Term Units', data: stream.llc_bi_term_unit},
                                    {
                                        label: 'Term Length',
                                        data: (stream.llc_bi_term_length) ? stream.llc_bi_term_length.toString() : null
                                    },
                                    {
                                        label: '',
                                        alwaysExpand: true,
                                        subData: [...stream.llc_bi_pricing_rate_components.map(component => {
                                            return [
                                                {
                                                    label: 'Interest Rate Type',
                                                    data: component.llc_bi_interest_rate_type
                                                },
                                                {
                                                    label: 'All in Rate',
                                                    data: (component.llc_bi_all_in_rate) ? fPercent(component.llc_bi_all_in_rate / 100) : null
                                                },
                                                {
                                                    label: 'Rate',
                                                    data: (component.llc_bi_rate) ? fPercent(component.llc_bi_rate / 100) : null
                                                },
                                                {
                                                    label: 'Spread',
                                                    data: (component.llc_bi_spread) ? fPercent(component.llc_bi_spread / 100) : null
                                                },
                                                {label: 'Index', data: component.llc_bi_index},
                                                {label: 'Margin Type', data: component.margin_type},
                                            ]
                                        })]
                                    },
                                ]
                            })]
                        },
                        {
                            label: 'Domicile',
                            data: (loan.llc_bi_product_package?.state_of_exposure === 'Offshore') ? loan.llc_bi_product_package?.offshore_location : 'Australia'
                        },
                        {label: 'Base Currency', data: loan.currency_iso_code},
                        {label: 'Multi Currency', data: loan.multi_currency_option || null}
                    ]}
                />
                {loan.narrative_comments &&
                    <Box sx={{pt: 2}}>
                        <Typography sx={{color: 'primary.main', fontWeight: 'bold', p: 0, m: 0}}>
                            Comments to Partners:
                        </Typography>
                        <div dangerouslySetInnerHTML={{__html: cleanHTML(loan.narrative_comments)}}/>
                    </Box>
                }
                {loan.secretary_notes &&
                    <Box>
                        <Typography sx={{color: 'primary.main', fontWeight: 'bold', p: 0, m: 0}}>
                            Secretary Notes:
                        </Typography>
                        {loan.secretary_notes.map(note => (
                            <Box key={note.date.toString()} sx={{pt: 1, display: 'flex', alignItems: 'center'}}>
                                <Typography sx={{fontWeight: 'bold', color: 'primary.main', mr: 1}}>
                                    - {formatDate(new Date(note.date), 'dd-MMM-yyyy')}:
                                </Typography>
                                <Typography>
                                    {note.note}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                }
                <Box sx={{pt: 2, display: 'flex', justifyContent: 'space-between'}}>
                    <Typography sx={{color: 'primary.main', fontWeight: 'bold', p: 0, m: 0, pb: 1}}>
                        Additional Comments:
                    </Typography>
                    <IconButton size='small' onClick={() => setComment({id: comment?.id || null, ncinoId: loan.ncino_id, notes: comment?.notes || ''})}>
                        <EditIcon/>
                    </IconButton>
                </Box>
                <Box>
                    {comment &&
                        <Typography>
                            {comment.notes}
                        </Typography>
                    }
                </Box>

            </Box>
            <Box sx={{
                px: 5,
                pt: 5,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end', alignItems: 'center'
            }}>
                <Button
                    endIcon={<LaunchIcon/>}
                    onClick={() => window.open(`https://metricscp.lightning.force.com/${loan.ncino_id}`, '_blank')}
                >
                    Go To nCino/Salesforce
                </Button>
            </Box>
            {editComment &&
                <WIPCommentEdit
                    wipComment={editComment}
                    open={!!editComment}
                    onClose={() => setComment(null)}
                    editValues={(values: { id: string | number, ncinoId: string, notes: string }) => {
                        dispatch(updateWIPComment(values));
                        setComment(null)
                    }}
                    addValues={(values: { ncinoId: string, notes: string }) => {
                        dispatch(addWIPComment(values));
                        setComment(null)
                    }}
                    removeValues={(id: string | number) => {
                        dispatch(removeWIPComment(id));
                        setComment(null)
                    }}
                />
            }
        </>
    )
}