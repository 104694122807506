import {formatDate} from "../../utils/DateUtils";
import {fCurrency} from "../../utils/formatNumber";
/// MUI
import {
    Grid,
    Container, Box, Table, TableBody, TableRow, TableCell
} from '@mui/material';
import {GridColDef} from "@mui/x-data-grid";
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
import {DataTable, valueCurrency, valueDate, valuePercentage} from "../../components";
import {generalRow} from "../../components/DataTable/DataTableUtils";
// STORE
import {useAppSelector} from "../../store/store";
import {BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, LabelList} from 'recharts';
import {
    retrieveDebtMaturityGraphData,
    retrieveDebtMaturityTable
} from "../../store/capitalBudget/selectors/Reports/debtMaturitySelector";


let DebtMaturityProfileColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', ...generalRow, hide: true},
    {
        field: 'fund',
        headerName: 'Fund',
        minWidth: 100,
        ...generalRow
    },
    {
        field: 'name',
        headerName: 'Tranche',
        flex: 1,
        ...generalRow,
        minWidth: 200,
    },
    {
        field: 'maturity',
        headerName: 'Maturity',
        ...valueDate,
        width: 100,
        valueFormatter: (params: any) => {
            return formatDate(params.value, 'dd-MMM-yy')
        },
    },
    {
        field: 'margin',
        headerName: 'Margin',
        minWidth: 100,
        ...valuePercentage
    },
    {
        field: 'fee',
        headerName: 'C Fee',
        minWidth: 100,
        ...valuePercentage
    },
    {
        ...valueCurrency,
        valueFormatter: (params: any) => fCurrency(params.value, 2),
        field: 'total',
        headerName: 'Total',
        width: 100,
        sortable: true,
        cellClassName: () => 'total-border'
    },
];

export default function DebtMaturityProfile() {

    const loading = useAppSelector((state) => state.capitalBudget.loading);
    const facilitiesData = useAppSelector(state => retrieveDebtMaturityTable(state));

    return (
        <>
            <Page title="Metrics - CB - Debt Profile">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Debt Maturity Profile'/>
                        </Grid>
                        <Grid container item sx={{flex: 1, overflowY: 'auto', width: '100%', py: 1}}>
                            <Grid xs={12} item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1, minHeight: 300}}>
                                <Grid container direction='row' sx={{width: '100%', height: '100%', bgcolor: 'common.white'}}>
                                    <DebtMaturityGraph/>
                                </Grid>
                            </Grid>
                            <Grid  container xs={12} item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}} direction="column">
                                <Grid item sx={{flex: 1, minHeight: 300}}>
                                    <DataTable
                                        title='Debt Facilities ($M)'
                                        columns={[
                                            ...DebtMaturityProfileColumns,
                                            ...facilitiesData.columns,
                                        ]}
                                        data={facilitiesData.facilities}
                                        loading={loading}
                                        showExport={false}
                                        additionalDataGridSx={{
                                            '& .total-border': {borderRight: 2},
                                            '& .other-lender': {borderLeft: 2},
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Box sx={{bgcolor: 'primary.main', borderRadius: 1}}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={5} align={'right'} style={{fontWeight: 'bold'}}
                                                               sx={{color: 'white', flexGrow: 1}}>
                                                        Total:
                                                    </TableCell>
                                                    <TableCell align={'right'} style={{fontWeight: 'bold'}}
                                                               sx={{color: 'white', width: 100}}>
                                                        {fCurrency(facilitiesData.total, 2)}
                                                    </TableCell>
                                                    {facilitiesData.columns.map(c => (
                                                        <TableCell align={'right'} style={{fontWeight: 'bold'}}
                                                                   sx={{color: 'white', width: c.width}} key={c.field}>
                                                            {fCurrency(facilitiesData.providers[c.field], 2)}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    )
}

const DebtMaturityGraph = () => {

    const graphData = useAppSelector(state => retrieveDebtMaturityGraphData(state));

    // Custom Label for Loan Bar
    const renderCustomizedLabel = (props: any, label: string) => {
        const {x, y, width, height, value} = props;

        if (!value || value === 0) return null;

        // Label Positioning
        const adjustedX = x + width / 2;
        let adjustedY = y - 10;

        // Adjust position if label is expected to be outside the graph
        if ((y - height / 2) < 0) {
            adjustedY = 200; // Adjust to be within the graph
        }

        return (
            <text
                x={adjustedX}
                y={adjustedY}
                fill="#000"
                textAnchor={'start'}
                dominantBaseline="middle"
                transform={`rotate(-90, ${adjustedX}, ${(adjustedY)})`}
                fontSize="10px"
            >
                {label}
            </text>
        );
    };

    // Tooltip for Bar Section
    const renderCustomisedTooltip = (props: any) => {
        const {active, payload, label} = props;

        if (active && payload && payload.length) {

            const loans = [...payload.reduce((loans: any, p: any) => {
                const [loanName, providerName] = p.dataKey.split('.');

                if (!loanName || !providerName) return loans;

                const loan = loans.get(loanName) || {
                    name: loanName,
                    total: 0,
                    providers: []
                };

                loan.providers.push({provider: providerName, value: p.value});
                loan.total += p.value;

                loans.set(loanName, loan);

                return loans;
            }, new Map()).values()];

            return (
                <div className="custom-tooltip"
                     style={{backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc'}}>
                    <p className="label"><b><u>{`${formatDate(label, 'MMM-yyyy')}`}</u></b></p>
                    {loans.map((loan: any, index: number) => (
                        <div key={`loan-${index}`}>
                            <p><b>{`${loan.name}: $${loan.total}M`}</b></p>
                            <ul>
                                {loan.providers.map((provider: any, index: number) => (
                                    <li key={`provider-${index}`}>{`${provider.provider}: $${provider.value}M`}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" height="100%" style={{paddingTop: '20px'}}>
            <BarChart data={graphData.facilities}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="date" tickFormatter={(date) => formatDate(date, 'MMM-yy')}/>
                <YAxis tickFormatter={(value) => `$${value}M`}/>
                <Tooltip
                    content={(props: any) => renderCustomisedTooltip(props)}
                />
                <Legend
                    payload={Object.entries(graphData.providers).map(([provider, color]) => ({
                        id: provider,
                        type: 'square',
                        value: provider,
                        color
                    }))}
                />
                {graphData.facilities.filter((f: any) => f.date !== '').map((facility: any) => {
                    return facility.loans.map((loan: any, l: number) => {
                        const loanProviders = Object.entries(facility[loan]).length;
                        return Object.entries(facility[loan]).map(([provider, value], index) => {
                            return (
                                <Bar
                                    key={`${facility.date}-${loan}.${provider}`}
                                    dataKey={`${loan}.${provider}`}
                                    stackId={l}
                                    fill={graphData.providers[provider]}
                                >
                                    {((index + 1) === loanProviders) && (
                                        <LabelList
                                            dataKey={`${loan}`}
                                            content={(props: any) => renderCustomizedLabel(props, `${loan}`)}
                                        />
                                    )}
                                </Bar>
                            )
                        })
                    })
                })}
            </BarChart>
        </ResponsiveContainer>
    );
}