import {Forecast} from "../../types/forecastTypes";
import {createSlice} from "@reduxjs/toolkit";
import {
    changeForecastDate,
    initialBudgetRetrieval,
    retrieveForecasts,
    saveForecast,
    saveNewForecast
} from "./forecastFunctions";

export interface ForecastState {
    forecasts: Array<Forecast>,
    forecast: Forecast | null,
    loading: boolean,
    saving: boolean,
}

const initialState: ForecastState = {
    forecasts: [],
    forecast: null,
    loading: false,
    saving: false
}

export const forecastSlice = createSlice({
    name: 'forecast',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // INITIAL FORECAST LOAD
            .addCase(initialBudgetRetrieval.pending, (state) => {
                state.loading = true;
                state.forecasts = [];
            })
            .addCase(initialBudgetRetrieval.fulfilled, (state, action) => {
                state.loading = false;
                // console.log(action.payload);
                state.forecast = action.payload.forecast;
                state.forecasts = action.payload.forecasts;
            })
            // CHANGE FORECAST
            .addCase(changeForecastDate.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeForecastDate.fulfilled, (state, action) => {
                state.loading = false;
                state.forecast = action.payload.forecast;
            })
            // RETRIEVE FORECASTS
            .addCase(retrieveForecasts.pending, (state) => {
                state.loading = true;
                state.forecasts = [];
            })
            .addCase(retrieveForecasts.fulfilled, (state, action) => {
                state.loading = false;
                state.forecasts = action.payload;
            })

            // CREATE FORECASTS
            .addCase(saveNewForecast.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveNewForecast.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    state.forecast = action.payload.forecast;
                    state.forecasts = action.payload.forecasts;
                }
            })

            // UPDATE FORECASTS
            .addCase(saveForecast.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveForecast.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    state.forecast = action.payload.forecast;
                }
            })
    }
})

export default forecastSlice.reducer;