import {createSlice} from "@reduxjs/toolkit";
import {
    ExternalData
} from "../../types/externalDataTypes";
import {initialBudgetRetrieval} from "../forecast/forecastFunctions";


export interface ExternalDataSlice {
    externalData: ExternalData | null,
    loading: boolean
}

const initialState: ExternalDataSlice = {
    externalData: {
        accountBalance: null,
        bbsw: null,
        debtFacilities: null,
        holding: null,
        portfolio: null,
        modifications: null,
        ncinoWip: null,
        fx: null,
        fundFinance: null
    },
    loading: false
}

const blankExternal: ExternalData = {
    accountBalance: null,
    bbsw: null,
    debtFacilities: null,
    holding: null,
    portfolio: null,
    modifications: null,
    ncinoWip: null,
    fx: null,
    fundFinance: null
}

export const externalDataSlice = createSlice({
    name: 'external-data',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // INITIAL FORECAST LOAD
            .addCase(initialBudgetRetrieval.pending, (state) => {
                state.loading = true;
                state.externalData = blankExternal;
            })
            .addCase(initialBudgetRetrieval.fulfilled, (state, action) => {
                state.loading = false;
                state.externalData = action.payload.externalData;
            })
    }
})

// export const {} = externalDataSlice.actions;

export default externalDataSlice.reducer;