import {createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import {addNotification} from "../notifications/notificationSlice";
import {
    apiDeleteRequests,
    apiGetRequests,
    apiPostRequests,
    apiPutRequests,
    multipleAsyncGetRequest,
    multipleAsyncPostRequest
} from "../apiUtils";
import {
    Capital,
    Forecast, FundFinance, ManualDeal,
    MaturityChanges, NewDeal,
    OtherTransaction, Refinance,
    SelldownRepayment,
    Transfer, WIPComment
} from "../../types/forecastTypes";
import {addDays, checkDateSame, formatDate} from "../../utils/DateUtils";
import {RootState} from "../store";
import {SaveStatus} from "../../types/capitalBudgetEnums";

// Initital Data Retrieval
export const initialBudgetRetrieval = createAsyncThunk('forecast/initial', async (_, thunkAPI) => {
    try {
        const forecastDate = addDays(new Date(), -1);

        const forecasts = await apiGetRequests('capital-budget/forecast', thunkAPI) as Array<Forecast>;

        const latest = forecasts[forecasts.length - 1];

        const data = await retrieveLatestForecast(forecastDate, latest);

        return {
            forecasts,
            ...data
        }

    } catch (error) {
        let message = 'Problem occurred retrieving forecasts';

        if (error instanceof Error && error.message !== 'AUTH_ERROR') {
            message = `Error: ${error.message}`;
            thunkAPI.dispatch(addNotification(message, 'error'));
        }

        console.log(error)

        return thunkAPI.rejectWithValue(message);
    }
})


// Retrieve Forecasts
export const retrieveForecasts = createAsyncThunk('forecast/retrieveForecasts', async (_, thunkAPI) => {
    try {
        return await apiGetRequests('capital-budget/forecast', thunkAPI);
    } catch (error) {
        let message = 'Problem occurred retrieving forecasts';

        if (error instanceof Error && error.message !== 'AUTH_ERROR') {
            message = `Error: ${error.message}`;
            thunkAPI.dispatch(addNotification(message, 'error'));
        }

        console.log(error)

        return thunkAPI.rejectWithValue(message);
    }
})

// Select New Forecast Date
export const changeForecastDate = createAsyncThunk('forecast/changeDate', async (date: Date | number, thunkAPI) => {
    try {
        const state: RootState = thunkAPI.getState() as RootState;
        const forecasts = state.forecast.forecasts;


        if (checkDateSame(date, addDays(new Date(), -1))) {
            const latest = forecasts[forecasts.length - 1];

            return await retrieveLatestForecast(date, latest);
        } else {
            const changeForecast = forecasts.find(f => checkDateSame(f.forecastDate, date))

            if (changeForecast && changeForecast.id) {
                // LOAD DATA
                let dataQueries: {[x: string]: string} = {
                    forecast: `capital-budget/forecast/${changeForecast.id}`,
                    bbsw: `external-data/bbsw?date=${formatDate(changeForecast.forecastDate, 'yyyy-MM-dd')}`,
                    accountBalance: `external-data/account-balance/${changeForecast.accountBalanceId}`,
                    debtFacilities: `external-data/debt-facility/${changeForecast.debtFacilityId}`,
                    holding: `external-data/holding/${changeForecast.holdingId}`,
                    portfolio: `external-data/axcess/${changeForecast.axcessId}`,
                    modifications: `external-data/modification/${changeForecast.modificationId}`,
                    ncinoWip: `external-data/ncino/${changeForecast.ncinoId}`,
                    fx: `external-data/fx/${changeForecast.fxId}`, // TODO: possible be last datte of the previous month
                    fundFinanceExt: `external-data/fund-finance${(changeForecast.fundFinanceId) ? `/${changeForecast.fundFinanceId}` : `?latest=true`}`,
                    capital: `capital-budget/forecast/${changeForecast.id}/capital-transaction`,
                    fundFinance: `capital-budget/forecast/${changeForecast.id}/fund-finance`,
                    otherTransactions: `capital-budget/forecast/${changeForecast.id}/other-transaction`,
                    newDeals: `capital-budget/forecast/${changeForecast.id}/new-deal`,
                    manualDeals: `capital-budget/forecast/${changeForecast.id}/manual-deal`,
                    maturityChanges: `capital-budget/forecast/${changeForecast.id}/maturity-change`,
                    refinances: `capital-budget/forecast/${changeForecast.id}/refinance`,
                    selldownRepayments: `capital-budget/forecast/${changeForecast.id}/sell-repay`,
                    transfers: `capital-budget/forecast/${changeForecast.id}/transfer`,
                    funds: `capital-budget/fund?date=${formatDate(changeForecast.forecastDate, 'yyyy-MM-dd')}`,
                    fum: `capital-budget/forecast/${changeForecast.id}/fum-temp`,
                    wipComments: 'capital-budget/wip-comment'
                };

                if (changeForecast.fundFinanceId) {
                    dataQueries.fundFinanceExt = `external-data/fund-finance/${changeForecast.fundFinanceId}`;
                }

                let {
                    forecast,
                    accountBalance,
                    bbsw,
                    debtFacilities,
                    holding,
                    portfolio,
                    modifications,
                    ncinoWip,
                    fx,
                    fundFinanceExt,
                    capital,
                    fundFinance,
                    otherTransactions,
                    manualDeals,
                    newDeals,
                    maturityChanges,
                    refinances,
                    selldownRepayments,
                    transfers,
                    funds,
                    fum,
                    wipComments
                } = await multipleAsyncGetRequest(dataQueries);

                forecast.forecastDataDate = forecast.forecastDate;

                return {
                    forecast,
                    externalData: {
                        accountBalance,
                        bbsw,
                        debtFacilities,
                        holding,
                        portfolio,
                        modifications,
                        ncinoWip,
                        fx,
                        fundFinance: fundFinanceExt || null
                    },
                    forecastData: {
                        capital,
                        fundFinance,
                        otherTransactions,
                        manualDeals,
                        newDeals,
                        maturityChanges,
                        refinances,
                        selldownRepayments,
                        transfers,
                        funds,
                        fum,
                        wipComments
                    }
                }
            } else {
                thunkAPI.dispatch(addNotification('Forecast Not Found!', 'error'));
                return thunkAPI.rejectWithValue('Forecast not found.');
            }
        }


    } catch (error) {
        let message = 'Problem occurred loading forecast';
        if (error instanceof Error && error.message !== 'AUTH_ERROR') {
            message = `Error: ${error.message}`;
            thunkAPI.dispatch(addNotification(message, 'error'));
        }
        console.log(error)
        return thunkAPI.rejectWithValue(message);
    }
})

// Retrieves the latest forecast
const retrieveLatestForecast = async (date: Date | number, latest: Forecast) => {
    // const monthEnd = formatDate(findLastMonthEnd(new Date(date)), 'yyyy-MM-dd')

    const {
        accountBalance,
        bbsw,
        debtFacilities,
        holding,
        portfolio,
        modifications,
        ncinoWip,
        fx,
        fundFinanceExt,
        capital,
        fundFinance,
        otherTransactions,
        newDeals,
        manualDeals,
        maturityChanges,
        selldownRepayments,
        transfers,
        refinances,
        funds,
        fum,
        wipComments
    } = await multipleAsyncGetRequest({
        accountBalance: 'external-data/account-balance?latest=true',
        bbsw: 'external-data/bbsw?latest=true',
        debtFacilities: 'external-data/debt-facility?latest=true',
        holding: 'external-data/holding?latest=true',
        portfolio: 'external-data/axcess?latest=true',
        modifications: 'external-data/modification?latest=true',
        ncinoWip: 'external-data/ncino?latest=true',
        fx: `external-data/fx?latest=true`,
        fundFinanceExt: `external-data/fund-finance?latest=true`,
        capital: `capital-budget/forecast/${latest.id}/capital-transaction`,
        fundFinance: `capital-budget/forecast/${latest.id}/fund-finance`,
        otherTransactions: `capital-budget/forecast/${latest.id}/other-transaction`,
        newDeals: `capital-budget/forecast/${latest.id}/new-deal`,
        manualDeals: `capital-budget/forecast/${latest.id}/manual-deal`,
        maturityChanges: `capital-budget/forecast/${latest.id}/maturity-change`,
        selldownRepayments: `capital-budget/forecast/${latest.id}/sell-repay`,
        transfers: `capital-budget/forecast/${latest.id}/transfer`,
        refinances: `capital-budget/forecast/${latest.id}/refinance`,
        funds: `capital-budget/fund?date=${formatDate(date, 'yyyy-MM-dd')}`,
        fum: `capital-budget/forecast/${latest.id}/fum-temp`,
        wipComments: 'capital-budget/wip-comment'
    });

    const forecast: Forecast = {
        ...latest,

        forecastDate: date,
        forecastDataDate: latest.forecastDate,
        referenceId: latest.id,
        accountBalanceId: accountBalance.id,
        axcessId: portfolio.id,
        debtFacilityId: debtFacilities.id,
        fxId: fx.id,
        fundFinanceId: fundFinanceExt.id,
        holdingId: holding.id,
        modificationId: modifications.id,
        ncinoId: ncinoWip.id,
        new: !checkDateSame(date, latest.forecastDate),
    }

    return {
        forecast,
        externalData: {
            accountBalance,
            bbsw,
            debtFacilities,
            holding,
            portfolio,
            modifications,
            ncinoWip,
            fx,
            fundFinance: fundFinanceExt
        },
        forecastData: {
            capital,
            fundFinance,
            otherTransactions,
            manualDeals,
            newDeals,
            maturityChanges,
            refinances,
            selldownRepayments,
            transfers,
            funds,
            fum,
            wipComments
        }
    }
}

// Retrieves forecast
const retrieveForecast = async (date: Date | number, forecastBase: Forecast) => {
    // const monthEnd = formatDate(findLastMonthEnd(new Date(date)), 'yyyy-MM-dd')

    let dataQueries: {[x: string]: string} = {
        accountBalance: `external-data/account-balance/${forecastBase.accountBalanceId}`,
        bbsw: `external-data/bbsw?date=${formatDate(date, 'yyyy-MM-dd')}`,
        debtFacilities: `external-data/debt-facility/${forecastBase.debtFacilityId}`,
        holding: `external-data/holding/${forecastBase.holdingId}`,
        portfolio: `external-data/axcess/${forecastBase.axcessId}`,
        modifications: `external-data/modification/${forecastBase.modificationId}`,
        ncinoWip: `external-data/ncino/${forecastBase.ncinoId}`,
        // fx: `external-data/fx?date=${monthEnd}`,
        fx: `external-data/fx/${forecastBase.fxId}`,
        capital: `capital-budget/forecast/${forecastBase.id}/capital-transaction`,
        fundFinance: `capital-budget/forecast/${forecastBase.id}/fund-finance`,
        otherTransactions: `capital-budget/forecast/${forecastBase.id}/other-transaction`,
        newDeals: `capital-budget/forecast/${forecastBase.id}/new-deal`,
        manualDeals: `capital-budget/forecast/${forecastBase.id}/manual-deal`,
        maturityChanges: `capital-budget/forecast/${forecastBase.id}/maturity-change`,
        selldownRepayments: `capital-budget/forecast/${forecastBase.id}/sell-repay`,
        transfers: `capital-budget/forecast/${forecastBase.id}/transfer`,
        refinances: `capital-budget/forecast/${forecastBase.id}/refinance`,
        funds: `capital-budget/fund?date=${formatDate(date, 'yyyy-MM-dd')}`,
        fum: `capital-budget/forecast/${forecastBase.id}/fum-temp`,
        wipComments: 'capital-budget/wip-comment'
    }

    if (forecastBase.fundFinanceId) {
        dataQueries.fundFinanceExt = `external-data/fund-finance/${forecastBase.fundFinanceId}`;
    }

    const {
        accountBalance,
        bbsw,
        debtFacilities,
        holding,
        portfolio,
        modifications,
        ncinoWip,
        fx,
        fundFinanceExt,
        capital,
        fundFinance,
        otherTransactions,
        newDeals,
        manualDeals,
        maturityChanges,
        selldownRepayments,
        transfers,
        refinances,
        funds,
        fum,
        wipComments
    } = await multipleAsyncGetRequest(dataQueries);

    const forecast: Forecast = {
        ...forecastBase,

        forecastDate: date,
        forecastDataDate: forecastBase.forecastDate,
        referenceId: forecastBase.id,
        accountBalanceId: accountBalance.id,
        axcessId: portfolio.id,
        debtFacilityId: debtFacilities.id,
        fxId: fx.id,
        holdingId: holding.id,
        modificationId: modifications.id,
        ncinoId: ncinoWip.id,
        new: !checkDateSame(date, forecastBase.forecastDate),
    }

    return {
        forecast,
        externalData: {
            accountBalance,
            bbsw,
            debtFacilities,
            holding,
            portfolio,
            modifications,
            ncinoWip,
            fx,
            fundFinance: fundFinanceExt || null
        },
        forecastData: {
            capital,
            fundFinance,
            otherTransactions,
            manualDeals,
            newDeals,
            maturityChanges,
            refinances,
            selldownRepayments,
            transfers,
            funds,
            fum,
            wipComments
        }
    }
}

// Creates New Forecast
export const saveNewForecast = createAsyncThunk('forecast/createForecast', async (_, thunkAPI) => {
    try {
        const state: RootState = thunkAPI.getState() as RootState;
        if (state.forecast.forecast && state.capitalBudget.forecastData) {
            const forecastState = state.forecast.forecast;


            const newForecast: any = {
                name: 'DEFAULT',
                descr: '',
                active: true,

                forecastDate: formatDate(state.forecast.forecast.forecastDate, 'yyyy-MM-dd'),

                accountBalanceId: forecastState.accountBalanceId,
                axcessId: forecastState.axcessId,
                debtFacilityId: forecastState.debtFacilityId,
                fxId: forecastState.fxId,
                fundFinanceId: forecastState.fundFinanceId,
                holdingId: forecastState.holdingId,
                modificationId: forecastState.modificationId,
                ncinoId: forecastState.ncinoId,
            }

            let forecast: any = await apiPostRequests(`capital-budget/forecast`, {forecast: newForecast}, thunkAPI);

            newForecast.id = forecast.forecastId;

            // Save Capital
            const capitalTransactions = state.capitalBudget.forecastData.capital.reduce((changes: Array<any>, c) => {
                if (c.status === SaveStatus.REMOVED) return changes;
                changes.push(c)
                return changes;
            }, [])

            // Save Fund Finances
            const fundFinances = state.capitalBudget.forecastData.fundFinance.reduce((changes: Array<any>, ff) => {
                if (ff.status === SaveStatus.REMOVED) return changes;
                changes.push(ff)
                return changes;
            }, []);

            // Save Maturity Changes
            const maturityChanges = state.capitalBudget.forecastData.maturityChanges.reduce((changes: Array<any>, mc) => {
                if (mc.status === SaveStatus.REMOVED) return changes;
                changes.push({
                    ...mc,
                    amendedMaturity: formatDate(mc.amendedMaturity, 'yyyy-MM-dd'),
                    originalMaturity: formatDate(mc.originalMaturity, 'yyyy-MM-dd'),
                })
                return changes;
            }, []);

            // Save Manual Deals
            const manualDeal = state.capitalBudget.forecastData.manualDeals.reduce((changes: Array<any>, md) => {
                if (md.status === SaveStatus.REMOVED) return changes;
                changes.push({
                    ...md,
                    closeDate: formatDate(md.closeDate, 'yyyy-MM-dd'),
                })
                return changes;
            }, []);

            // Save New Loans
            const {newDeal, manualNewDeals} = state.capitalBudget.forecastData.newDeals.reduce((changes: { newDeal: Array<any>, manualNewDeals: Array<any>}, nd) => {
                if (nd.status === SaveStatus.REMOVED) return changes;
                if (nd.manual) {
                    changes.manualNewDeals.push(nd);
                } else {
                    changes.newDeal.push(nd)
                }
                return changes;
            }, {newDeal: [], manualNewDeals: []});

            // Save Other Transactions
            const otherTransactions = state.capitalBudget.forecastData.otherTransactions.reduce((changes: Array<any>, ot) => {
                if (ot.status === SaveStatus.REMOVED) return changes;
                changes.push(ot)
                return changes;
            }, []);

            // Save Refinances
            const refinances = state.capitalBudget.forecastData.refinances.reduce((changes: Array<any>, sr) => {
                if (sr.status === SaveStatus.REMOVED) return changes;
                changes.push(sr)
                return changes;
            }, []);

            // Save Selldowns and Repayments
            const sellRepay = state.capitalBudget.forecastData.selldownRepayments.reduce((changes: Array<any>, sr) => {
                if (sr.status === SaveStatus.REMOVED) return changes;
                changes.push(sr)
                return changes;
            }, []);

            // Save Transfers
            const transfers = state.capitalBudget.forecastData.transfers.reduce((changes: Array<any>, sr) => {
                if (sr.status === SaveStatus.REMOVED) return changes;
                changes.push(sr)
                return changes;
            }, []);

            // Save WIP Comments, Only if new
            const wipComments = state.capitalBudget.forecastData.wipComments.reduce((changes: {new: Array<any>, updates: Array<any>, remove: Array<any>}, wc) => {
                if (wc.status === SaveStatus.NEW) {
                    changes.new.push(wc)
                } else if (wc.status === SaveStatus.EDITED) {
                    changes.updates.push(wc)
                } else if (wc.status === SaveStatus.REMOVED) {
                    changes.remove.push(wc)
                }

                return changes;
            }, {new: [], updates: [], remove: []})

            const results = await multipleAsyncPostRequest({
                capitalQuery: {
                    query: `capital-budget/forecast/${forecast.forecastId}/capital-transaction`,
                    body: {capitalTransactions}
                },
                fundFinances: {
                    query: `capital-budget/forecast/${forecast.forecastId}/fund-finance`,
                    body: {fundFinances}
                },
                maturityChangesQuery: {
                    query: `capital-budget/forecast/${forecast.forecastId}/maturity-change`,
                    body: {maturityChanges}
                },
                manualDealsQuery: {
                    query: `capital-budget/forecast/${forecast.forecastId}/manual-deal`,
                    body: {manualDeal}
                },
                newDealsQuery: {
                    query: `capital-budget/forecast/${forecast.forecastId}/new-deal`,
                    body: {newDeal}
                },
                otherTransactionsQuery: {
                    query: `capital-budget/forecast/${forecast.forecastId}/other-transaction`,
                    body: {otherTransactions}
                },
                refinancesQuery: {
                    query: `capital-budget/forecast/${forecast.forecastId}/refinance`,
                    body: {refinances}
                },
                selldownRepaymentsQuery: {
                    query: `capital-budget/forecast/${forecast.forecastId}/sell-repay`,
                    body: {sellRepay}
                },
                transfersQuery: {
                    query: `capital-budget/forecast/${forecast.forecastId}/transfer`,
                    body: {transfers}
                },
                fumQuery: {
                    query: `capital-budget/forecast/${forecast.forecastId}/fum-temp`,
                    body: {fum: state.capitalBudget.forecastData.fum}
                }
            })

            // CRUD WIP Comments
            await apiPostRequests(`capital-budget/wip-comment`, {comments: wipComments.new}, thunkAPI);
            await apiPutRequests(`capital-budget/wip-comment`, {comments: wipComments.updates}, thunkAPI);
            await apiDeleteRequests(`capital-budget/wip-comment`, {comments: wipComments.remove}, thunkAPI);


            // ----------------------------------------------------------------------------------------------------------------------------------
            const manualDealsInsertion = manualNewDeals.reduce((deals: Array<any>, deal) => {
                const manualDeal = results.manualDealsQuery.find((md: any) => md.manualReference === deal.manual);
                if (manualDeal) {
                    deals.push({
                        ...deal,
                        manual: manualDeal.id
                    })
                }
                return deals;
            }, []);

            if (manualDealsInsertion.length > 0) {
                await apiPostRequests(`capital-budget/forecast/${forecast.forecastId}/new-deal`, {newDeal: manualDealsInsertion});
            }

            // ----------------------------------------------------------------------------------------------------------------------------------

            const data = await retrieveLatestForecast(newForecast.forecastDate, newForecast);
            forecast = await apiGetRequests(`capital-budget/forecast/${newForecast.id}`, thunkAPI) as Forecast;
            const forecasts = await apiGetRequests('capital-budget/forecast', thunkAPI) as Array<Forecast>;

            return {
                ...data,
                forecasts,
                forecast
            }
        } else {
            thunkAPI.dispatch(addNotification('Cannot find Forecast Details to save.', 'error'));
        }
    } catch (error) {
        let message = 'Problem occurred creating version';
        if (error instanceof Error && error.message !== 'AUTH_ERROR') {
            message = `Error: ${error.message}`;
            thunkAPI.dispatch(addNotification(message, 'error'));
        }
        console.log(error);
        return thunkAPI.rejectWithValue(message);
    }
})

export const saveForecast = createAsyncThunk('forecast/saveForecast', async (_, thunkAPI) => {
    try {
        const state: RootState = thunkAPI.getState() as RootState;
        if (state.forecast.forecast && state.capitalBudget.forecastData) {
            let forecast = state.forecast.forecast;

            await apiPutRequests(`capital-budget/forecast/${forecast.id}`, {forecast}, thunkAPI);

            // -------------------------- SAVE Capital Transactions
            const capitalChanges = filterChanges<Capital>(state.capitalBudget.forecastData.capital);

            // CREATES
            if (capitalChanges.new.length > 0) await apiPostRequests(`capital-budget/forecast/${forecast.id}/capital-transaction`, {capitalTransactions: capitalChanges.new}, thunkAPI);

            // UPDATES
            if (capitalChanges.updated.length > 0) await apiPutRequests(`capital-budget/forecast/${forecast.id}/capital-transaction`, {capitalTransactions: capitalChanges.updated}, thunkAPI);

            // REMOVES
            if (capitalChanges.delete.length > 0) await apiDeleteRequests(`capital-budget/forecast/${forecast.id}/capital-transaction`, {capitalTransactions: capitalChanges.delete}, thunkAPI);

            // -------------------------- SAVE Refinances
            const fundFinance = filterChanges<FundFinance>(state.capitalBudget.forecastData.fundFinance)

            // CREATES
            if (fundFinance.new.length > 0) await apiPostRequests(`capital-budget/forecast/${forecast.id}/fund-finance`, {fundFinances: fundFinance.new}, thunkAPI);

            // UPDATES
            if (fundFinance.updated.length > 0) await apiPutRequests(`capital-budget/forecast/${forecast.id}/fund-finance`, {fundFinances: fundFinance.updated}, thunkAPI);

            // REMOVES
            if (fundFinance.delete.length > 0) await apiDeleteRequests(`capital-budget/forecast/${forecast.id}/fund-finance`, {fundFinances: fundFinance.delete}, thunkAPI);


            // -------------------------- SAVE Maturity Changes
            const maturityChanges = filterChanges<MaturityChanges>(state.capitalBudget.forecastData.maturityChanges)

            // CREATES
            if (maturityChanges.new.length > 0) await apiPostRequests(`capital-budget/forecast/${forecast.id}/maturity-change`, {maturityChanges: maturityChanges.new}, thunkAPI);

            // UPDATES
            if (maturityChanges.updated.length > 0) await apiPutRequests(`capital-budget/forecast/${forecast.id}/maturity-change`, {maturityChanges: maturityChanges.updated}, thunkAPI);

            // REMOVES
            if (maturityChanges.delete.length > 0) await apiDeleteRequests(`capital-budget/forecast/${forecast.id}/maturity-change`, {maturityChanges: maturityChanges.delete}, thunkAPI);

            // -------------------------- SAVE NEW DEALS
            const newDeal = filterChanges<NewDeal>(state.capitalBudget.forecastData.newDeals)

            // CREATES
            if (newDeal.new.length > 0) {
                await apiPostRequests(`capital-budget/forecast/${forecast.id}/new-deal`, {newDeal: newDeal.new.filter(nd => !nd.manual)}, thunkAPI);
            }

            // UPDATES
            if (newDeal.updated.length > 0) await apiPutRequests(`capital-budget/forecast/${forecast.id}/new-deal`, {newDeal: newDeal.updated}, thunkAPI);

            // REMOVES
            if (newDeal.delete.length > 0) await apiDeleteRequests(`capital-budget/forecast/${forecast.id}/new-deal`, {newDeal: newDeal.delete}, thunkAPI);

            // -------------------------- SAVE Manual Deal
            const manualDeal = filterChanges<ManualDeal>(state.capitalBudget.forecastData.manualDeals);

            // CREATES
            if (manualDeal.new.length > 0) {
                const newManualDealResults = await apiPostRequests(`capital-budget/forecast/${forecast.id}/manual-deal`, {manualDeal: manualDeal.new}, thunkAPI);
                let newDealsFromManual: Array<any> = [];
                newDeal.new.filter(nd => !!nd.manual).forEach(deal => {
                    const manualDeal = newManualDealResults.find((md: any) => md.manualReference === deal.manual);
                    if (manualDeal) {
                        newDealsFromManual.push({
                            ...deal,
                            manual: manualDeal.id
                        })
                    }
                })
                await apiPostRequests(`capital-budget/forecast/${forecast.id}/new-deal`, {newDeal: newDealsFromManual})
            }

            // UPDATES
            if (manualDeal.updated.length > 0) await apiPutRequests(`capital-budget/forecast/${forecast.id}/manual-deal`, {manualDeal: manualDeal.updated}, thunkAPI);

            // REMOVES
            if (manualDeal.delete.length > 0) await apiDeleteRequests(`capital-budget/forecast/${forecast.id}/manual-deal`, {manualDeal: manualDeal.delete}, thunkAPI);


            // -------------------------- SAVE Other transactions
            const otherTransactions = filterChanges<OtherTransaction>(state.capitalBudget.forecastData.otherTransactions)

            // CREATES
            if (otherTransactions.new.length > 0) await apiPostRequests(`capital-budget/forecast/${forecast.id}/other-transaction`, {otherTransactions: otherTransactions.new}, thunkAPI);

            // UPDATES
            if (otherTransactions.updated.length > 0) await apiPutRequests(`capital-budget/forecast/${forecast.id}/other-transaction`, {otherTransactions: otherTransactions.updated}, thunkAPI);

            // REMOVES
            if (otherTransactions.delete.length > 0) await apiDeleteRequests(`capital-budget/forecast/${forecast.id}/other-transaction`, {otherTransactions: otherTransactions.delete}, thunkAPI);

            // -------------------------- SAVE Refinances
            const refinances = filterChanges<Refinance>(state.capitalBudget.forecastData.refinances)

            // CREATES
            if (refinances.new.length > 0) await apiPostRequests(`capital-budget/forecast/${forecast.id}/refinance`, {refinances: refinances.new}, thunkAPI);

            // UPDATES
            if (refinances.updated.length > 0) await apiPutRequests(`capital-budget/forecast/${forecast.id}/refinance`, {refinances: refinances.updated}, thunkAPI);

            // REMOVES
            if (refinances.delete.length > 0) await apiDeleteRequests(`capital-budget/forecast/${forecast.id}/refinance`, {refinances: refinances.delete}, thunkAPI);


            // -------------------------- SAVE Selldowns and Repayments
            const sellRepay = filterChanges<SelldownRepayment>(state.capitalBudget.forecastData.selldownRepayments)

            // CREATES
            if (sellRepay.new.length > 0) await apiPostRequests(`capital-budget/forecast/${forecast.id}/sell-repay`, {sellRepay: sellRepay.new}, thunkAPI);

            // UPDATES
            if (sellRepay.updated.length > 0) await apiPutRequests(`capital-budget/forecast/${forecast.id}/sell-repay`, {sellRepay: sellRepay.updated}, thunkAPI);

            // REMOVES
            if (sellRepay.delete.length > 0) await apiDeleteRequests(`capital-budget/forecast/${forecast.id}/sell-repay`, {sellRepay: sellRepay.delete}, thunkAPI);

            // -------------------------- SAVE TRANSFERS
            const transfers = filterChanges<Transfer>(state.capitalBudget.forecastData.transfers)

            // CREATES
            if (transfers.new.length > 0) await apiPostRequests(`capital-budget/forecast/${forecast.id}/transfer`, {transfers: transfers.new}, thunkAPI);

            // UPDATES
            if (transfers.updated.length > 0) await apiPutRequests(`capital-budget/forecast/${forecast.id}/transfer`, {transfers: transfers.updated}, thunkAPI);

            // REMOVES
            if (transfers.delete.length > 0) await apiDeleteRequests(`capital-budget/forecast/${forecast.id}/transfer`, {transfers: transfers.delete}, thunkAPI);

            // UPDATES FUM
            await apiPutRequests(`capital-budget/forecast/${forecast.id}/fum-temp`, {fum: state.capitalBudget.forecastData.fum}, thunkAPI);

            // -------------------------- SAVE WIP COMMENTS
            const wipComments = filterChanges<WIPComment>(state.capitalBudget.forecastData.wipComments)

            // CREATES
            if (wipComments.new.length > 0) await apiPostRequests(`capital-budget/wip-comment/`, {comments: wipComments.new}, thunkAPI);

            // UPDATES
            if (wipComments.updated.length > 0) await apiPutRequests(`capital-budget/wip-comment/`, {comments: wipComments.updated}, thunkAPI);

            // REMOVES
            if (wipComments.delete.length > 0) await apiDeleteRequests(`capital-budget/wip-comment/`, {comments: wipComments.delete}, thunkAPI);

            const data = await retrieveForecast(forecast.forecastDate, forecast);
            forecast = await apiGetRequests(`capital-budget/forecast/${forecast.id}`, thunkAPI) as Forecast;

            return {
                ...data,
                forecast
            }
        } else {
            thunkAPI.dispatch(addNotification('Cannot find Forecast Details to save.', 'error'));
        }
    } catch (error) {
        let message = 'Problem occurred creating version';
        if (error instanceof Error && error.message !== 'AUTH_ERROR') {
            message = `Error: ${error.message}`;
            thunkAPI.dispatch(addNotification(message, 'error'));
        }
        console.log(error);
        return thunkAPI.rejectWithValue(message);
    }
})


interface ForecastType {
    status: SaveStatus,
    previous?: any
}
function filterChanges<T extends  ForecastType>(values: Array<T>): {new: Array<T>, updated: Array<T>, delete: Array<T>} {
    return values.reduce((updates: {new: Array<T>, updated:  Array<T>, delete:  Array<T>}, v) => {
        const newV = _.cloneDeep(v);
        switch (newV.status) {
            case SaveStatus.NEW:
                updates.new.push(newV)
                break;

            case SaveStatus.EDITED:
                if (newV?.previous) delete newV.previous
                updates.updated.push(newV)
                break;

            case SaveStatus.REMOVED:
                if (newV?.previous) delete newV.previous
                updates.delete.push(newV)
                break;
        }
        return updates;
    }, {
        new: [],
        updated: [],
        delete: []
    })
}