// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {Capital} from "../../../types/forecastTypes"
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {checkObjectChanged} from "../../../utils/generalUtils";
import {checkDateSame} from "../../../utils/DateUtils";

// Reducer Related to Capital Budget - Capital
const capitalReducers: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    // Create new Capital Transactions
    addCapitalTransaction: {
        prepare(newTransaction: Capital) {
            return {
                payload: {
                    ...newTransaction,
                    id: nanoid(),
                    status: SaveStatus.NEW
                }
            }
        },
        reducer(state, action: PayloadAction<Capital>) {
            if (state.forecastData) {
                state.forecastData.capital = [action.payload, ...state.forecastData.capital];
                state.changes = true;
            }
        }
    },
    // Update Capital Transaction
    updateCapitalTransaction: (state, action) => {
        if (state.forecastData) {
            const index = state.forecastData.capital.findIndex(c => c.id === action.payload.id && c.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;

                if (state.forecastData.capital[index].status !== SaveStatus.NEW) {
                    const existing = state.forecastData.capital[index];
                    if (!checkDateSame(existing.date, action.payload.date)) changed = true;
                    changed = (checkObjectChanged(existing, action.payload, ['investorType', 'amount', 'category', 'fund']) ? true : changed);
                    if (changed) {
                        if (!existing.previous) action.payload.previous = existing;
                        action.payload.status = SaveStatus.EDITED;
                        state.forecastData.capital[index] = action.payload;
                        state.changes = true;
                    }
                } else {
                    state.forecastData.capital[index] = {...state.forecastData.capital[index], ...action.payload};
                }
            }
        }
    },
    // Remove a Capital Provider
    removeCapitalTransaction: (state, action: PayloadAction<number>) => {
        if (state.forecastData) {
            const index = state.forecastData?.capital.findIndex(c => c.id === action.payload && c.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.forecastData.capital[index].status !== SaveStatus.NEW) {
                    state.forecastData.capital[index].status = SaveStatus.REMOVED;
                } else {
                    const transactions = state.forecastData.capital;
                    transactions.splice(index, 1);
                    state.forecastData.capital = transactions;
                    state.changes = true;
                }
            }
        }
    }
}

export default capitalReducers;