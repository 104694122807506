// Imports
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
// Local Imports
import {SaveStatus} from "../../../types/capitalBudgetEnums";
import {CapitalBudgetState} from "../capitalBudgetSlice";
import {MaturityChanges, NewDeal} from "../../../types/forecastTypes";
import {checkObjectChanged} from "../../../utils/generalUtils";

type UpdateNewLoanPayload = {
    id: number,
    amendedcloseDate: Date | number,
    amendedCommitment: null | number,
    amendedTenor: number | null,
    amendedDrawdown: number | null
}

// Reducer Related to Capital Budget - New Loans
const newLoansReducer: ValidateSliceCaseReducers<CapitalBudgetState, SliceCaseReducers<CapitalBudgetState>> = {
    //Add New Loan
    addNewLoan: {
        prepare(ncinoId: string) {
            return {
                payload: {
                    id: nanoid(),
                    ncinoId: ncinoId
                }
            }
        },
        reducer(state, action: PayloadAction<{ id: number, ncinoId: string }>) {
            if (state.forecastData && state.externalData) {
                const index = state.forecastData.newDeals.findIndex(l => l.ncinoId === action.payload.ncinoId && l.status !== SaveStatus.REMOVED);
                if (index === -1) {
                    const wipLoan = state.externalData.ncinoWip?.deals.find(d => d.ncino_id === action.payload.ncinoId);
                    if (wipLoan) {
                        const newLoan: NewDeal = {
                            ...action.payload,
                            dealId: wipLoan.llc_bi_product_package.id,
                            allocation: {},
                            closeDate: wipLoan.llc_bi_close_date,
                            amendedCloseDate: null,
                            commitment: wipLoan.llc_bi_amount || 0,
                            amendedCommitment: null,
                            tenor: (wipLoan.llc_bi_term_months || 0) / 12,
                            amendedTenor: null,
                            drawdown: wipLoan.expected_drawdown_at_close,
                            amendedDrawdown: null,
                            lastModified: wipLoan.last_modified,
                            manual: null,
                            status: SaveStatus.NEW
                        }
                        state.forecastData.newDeals = [...state.forecastData.newDeals, newLoan];
                        state.changes = true;
                    }
                }

            }
        }
    },
    updateNewLoan: (state, action: PayloadAction<UpdateNewLoanPayload>) => {
        if (state.forecastData) {
            const index = state.forecastData.newDeals.findIndex(nd => nd.id === action.payload.id && nd.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;

                if (state.forecastData.newDeals[index].status !== SaveStatus.NEW) {
                    const existing = state.forecastData.newDeals[index];
                    changed = (checkObjectChanged(existing, action.payload, ['amendedCloseDate', 'amendedCommitment', 'amendedTenor', 'amendedDrawdown']) ? true : changed);

                    if (changed) {
                        let allocation = state.forecastData.newDeals[index].allocation;
                        if (state.forecastData.newDeals[index].amendedCommitment !== action.payload.amendedCommitment) allocation = {};

                        state.forecastData.newDeals[index] = {
                            ...state.forecastData.newDeals[index],
                            ...action.payload,
                            previous: (!existing.previous) ? existing : existing.previous,
                            allocation,
                            status: SaveStatus.EDITED
                        }
                        state.changes = true;

                    }
                } else {
                    state.forecastData.newDeals[index] = {
                        ...state.forecastData.newDeals[index],
                        ...action.payload,
                        status: SaveStatus.NEW
                    }
                }
            }
        }
    },
    updateAllocation: (state, action: PayloadAction<{ id: number, allocation: { [x: string]: number } }>) => {
        if (state.forecastData) {
            const index = state.forecastData.newDeals.findIndex(nd => nd.id === action.payload.id && nd.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;
                if (state.forecastData.newDeals[index].status !== SaveStatus.NEW) {
                    const existing = state.forecastData.newDeals[index];
                    changed = (checkObjectChanged(existing, action.payload, ['allocation']) ? true : changed);

                    if (changed) {
                        state.forecastData.newDeals[index].allocation = action.payload.allocation;
                        state.forecastData.newDeals[index].status = SaveStatus.EDITED;
                        state.forecastData.newDeals[index].previous = (existing.previous) ? existing.previous : existing;
                    }
                    state.changes = true;
                } else {
                    state.forecastData.newDeals[index].allocation = action.payload.allocation;
                }
            }
        }
    },
    //Remove New Loan Allocations
    removeNewLoan: (state, action: PayloadAction<string>) => {
        if (state.forecastData) {
            const index = state.forecastData.newDeals.findIndex(nd => nd.ncinoId === action.payload && nd.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (state.forecastData.newDeals[index].status !== SaveStatus.NEW) {
                    state.forecastData.newDeals[index].status = SaveStatus.REMOVED;
                } else {
                    const newDeals = state.forecastData.newDeals;
                    newDeals.splice(index, 1);
                    state.forecastData.newDeals = newDeals;
                }
                state.changes = true;
            }
        }
    },
    // Bulk Remove Loan
    removeNewLoanBulk: (state, action: PayloadAction<Array<string | number>>) => {
        action.payload.forEach(id => {
            if (state.forecastData) {
                const index = state.forecastData.newDeals.findIndex(nd => nd.id === id && nd.status !== SaveStatus.REMOVED);
                if (index !== -1) {
                    if (state.forecastData.newDeals[index].status !== SaveStatus.NEW) {
                        state.forecastData.newDeals[index].status = SaveStatus.REMOVED;
                    } else {
                        const newDeals = state.forecastData.newDeals;
                        newDeals.splice(index, 1);
                        state.forecastData.newDeals = newDeals;
                    }
                    state.changes = true;
                }
            }
        })
    },
    bulkUpdateChanges: (state, action: PayloadAction<Array<{ id: number, attribute: keyof MaturityChanges, value: any }>>) => {
        action.payload.forEach(deal => {
            if (state.forecastData) {
                const index = state.forecastData.newDeals.findIndex(nd => nd.id === deal.id && nd.status !== SaveStatus.REMOVED);
                if (index !== -1) {
                    const newDeal = state.forecastData.newDeals[index];

                    state.forecastData.newDeals[index] = {
                        ...newDeal,
                        status: SaveStatus.EDITED,
                        [deal.attribute]: deal.value,
                        previous: (!newDeal.previous) ? newDeal : newDeal.previous
                    }
                }
            }
        })
    }
}

export default newLoansReducer;