import {ChangeEvent, memo, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
// Local Imports
import {DataTableProps} from '.';
import {dataGridFormatting} from './DataTableUtils';
import {escapeRegExp} from '../../utils/generalUtils';
// MUI
import {
    DataGrid,
    GridOverlay,
    GridRowParams,
    GridSortModel
} from '@mui/x-data-grid'
import {LinearProgress} from '@mui/material';
// Component
import {CustomToolbar} from './components';
import _ from "lodash";

/**
 * Standardised Data Table component for any tables providing multiple options
 * for customising the table based on the DataGrid from MUI
 */
export default memo(function DataTable(props: DataTableProps) {
    const navigate = useNavigate();
    // Destructure Props
    const {
        title,
        columns,
        data,
        loading,
        density = 'compact',
        link,
        selectedRow,
        selectRow,
        hideFooter = (data.length < 100),
        showFilter = false,
        showColumns = false,
        showExport = true,
        search = false,
        sort,
        disableSort = false,
        customFilter,
        customFilterComponent,
        create,
        additionalDataGridSx = {},
        selectKey = 'id'
    } = props;

    const [searchText, setSearchText] = useState(''); // Handles State of search string in Custom Table Search Bar
    const [rows, setRows] = useState<Array<any>>([]); // Copies data to a mutable source
    const [sortModel, setSort] = useState<GridSortModel>((sort) ? [sort] : []); // Handles Sorting and sort priority

    // Copies data values to rows state as a mutable source
    useEffect(() => {
        let tempRows = _.cloneDeep(data);

        setRows(tempRows);
    }, [setRows, data])

    // Handles linking/routing if rows are selected
    const handleLink = (id: number) => {
        if (link) {
            navigate(`${link.route}/${id}`)
        }
    }

    // Handles selection and deselection of rows
    const handleRowSelection = (rowId: string | number) => {
        if (selectRow) {
            if (selectedRow === rowId) {
                selectRow(null);
            } else {
                selectRow(rowId);
            }
        }

        // if (selectedRow === newModel[0]) {
        //     if (selectRow) selectRow(null);
        // } else {
        //     if (selectRow && newModel[0]) selectRow(newModel[0] as number);
        // }
    }

    //sorts data if sort is enabled
    const handleSortChange = (newModel: GridSortModel) => {
        if (!disableSort) {
            setSort(newModel);
        }
    }

    // Gathers selection props to pass to Data Grid
    const dataGridProps = {
        disableSelectionOnClick: (!selectRow),
        // onSelectionModelChange: (selectRow) ? handleRowSelection : () => {
        // },
        selectionModel: (selectedRow) ? [...[selectedRow]] : [],
    }

    // Handles Search of Strings in table
    useEffect(() => {
        const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
        const filteredRows = data.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                if (row[field]) {
                    return searchRegex.test(row[field].toString());
                }
                return false
            });
        });
        setRows(filteredRows);
    }, [searchText, data, columns])

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
    };

    return (
        <>
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: (sort) ? [sort] : []
                    }
                }}

                rowHeight={40}

                sx={{
                    ...additionalDataGridSx, ...dataGridFormatting,
                    ...(selectRow ? {
                        "&:hover": {
                            cursor: "pointer",
                        }
                    } : {}),
                    borderColor: 'primary.main',
                    bgcolor: 'primary.main'
                }}

                columns={columns}
                rows={rows}

                loading={loading}
                density={density}

                hideFooterSelectedRowCount={hideFooter}
                hideFooterPagination={hideFooter}

                components={{
                    Toolbar: CustomToolbar, // Insertion of custom toolbar
                    LoadingOverlay: CustomLoadingOverlay, // Inserts loading
                }}

                componentsProps={{
                    // Props for Custom Toolbar
                    toolbar: {
                        title,
                        showFilter,
                        showColumns,
                        showExport,
                        search,
                        value: searchText,
                        onChange: (event: ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch(''),
                        customFilter,
                        customFilterComponent,
                        create
                    },
                    panel: {
                        sx: {
                            '& .MuiPaper-root': {
                                bgcolor: 'common.grey'
                            },
                        }
                    }
                }}

                // Row selection
                onRowClick={(param: GridRowParams) => {
                    const id = (link && link.field) ? param.row[`${link.field}`] : param.id
                    handleRowSelection(param.id as string)
                    handleLink(id as number)
                    // SELECT ROW
                    let key;
                    try {
                        key = param.row[selectKey]
                    } catch (err) {
                        key = null
                    }
                    handleRowSelection(key)
                }}

                // Handles sort model changes
                onSortModelChange={handleSortChange}
                sortModel={sortModel}
                {...dataGridProps}

                disableVirtualization
            />
        </>
    )
})


// Component for loading bar
function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%'
                }}
            >
                <LinearProgress/>
            </div>
        </GridOverlay>
    )
}