// import {useState} from 'react';
import {DataTable, valueCurrency, valueDate} from "../../components";
// MUI
import {Grid, Container} from '@mui/material';
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
// Store
import {useAppSelector} from "../../store/store";
import {retrieveCapitalTransactions} from "../../store/capitalBudget/selectors/capitalSelectors";
import {GridColDef} from "@mui/x-data-grid";
import {generalRow} from "../../components/DataTable/DataTableUtils";

// ----------------------------------------------------------------------

let columns: GridColDef[] = [
    {field: 'id', headerName: 'id', ...generalRow, hide: true},
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 300,
        sortable: true,
        ...generalRow
    },
    {
        field: 'investorType',
        headerName: 'Investor Type',
        minWidth: 130,
        flex: 0.5,
        sortable: true,
        ...generalRow
    },
    {
        field: 'transactionType',
        headerName: 'Transaction Type',
        minWidth: 150,
        flex: 0.5,
        sortable: true,
        ...generalRow
    },
    {
        field: 'date',
        headerName: 'Date',
        minWidth: 100,
        sortable: true,
        ...valueDate,
        width: 140,
    },
    {
        field: 'fund',
        headerName: 'Fund',
        minWidth: 100,
        sortable: true,
        ...generalRow
    },
    {
        ...valueCurrency,
        field: 'amount',
        headerName: 'Amount',
        flex: 0.5,
        minWidth: 150,
        sortable: true,
    },
    {
        field: 'category',
        headerName: 'Category',
        minWidth: 120,
        flex: 0.5,
        sortable: true,
        ...generalRow
    }
]
// ----------------------------------------------------------------------

// Capital Budget Summary App Page
export default function Capital() {
    const loading = useAppSelector(state => state.forecast.loading);

    const capitalTransaction = useAppSelector(state => retrieveCapitalTransactions(state));

    return (
        <Page title="Metrics - CB - Capital">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                <Grid container direction='column' sx={{height: 1, flex: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <ForecastConfigBar title='Capital'/>
                    </Grid>
                    <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                        <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                            <DataTable
                                title={'Capital Transactions'}
                                columns={[...columns]}
                                data={capitalTransaction}
                                loading={loading}
                                showExport={true}
                                showColumns={true}
                                sort={{field: 'date', sort: 'asc'}}
                                search
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}