// MUI
import {Grid, Container} from '@mui/material';
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
// Store
// Data

// Capital Budget Summary App Page
export default function AccountBalances() {

    return (
        <>
            <Page title="Metrics - CB - New Commitments">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Account Balances'/>
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <Grid container direction='row' sx={{width: '100%', height: '100%'}}>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    )
}