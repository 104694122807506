import {useState} from 'react';
// MUI
import {Grid, Container} from '@mui/material';
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
import {HorizontalTable, HorizontalTableColumn} from "../../components";
// Store
import {useAppSelector} from "../../store/store";
import {fundForecastReport} from "../../store/capitalBudget/selectors/Reports/fundForecastReport";

// Capital Budget Summary App Page
export default function FundForecast() {
    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const calculating = useAppSelector(state => state.capitalBudget.calculating);
    const {data, rows} = useAppSelector(state => fundForecastReport(state));

    const [column, selectCol] = useState<HorizontalTableColumn | null>(null);

    return (
        <Page title="Metrics - Fund Forecast">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                <Grid container direction='column' sx={{height: 1, flex: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <ForecastConfigBar title='Fund Forecast'/>
                    </Grid>
                    <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                        <HorizontalTable
                            rows={rows}
                            data={(data) ? data : []}
                            loading={dataLoading || calculating}

                            // selectedRow={row}
                            selectedCol={column}
                            // rowSelect={selectRow}
                            colSelect={selectCol}

                            selectableCell
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}